import React from 'react'

import get from 'lodash/get'
import PT from 'prop-types'

import { capitalize } from '../../utils/stringManipulation'

import PersonContact from './PersonContact'

const PersonNameColumn = props => {
  const val = get(props, 'val')
  const row = get(props, 'row')
  const handleNameClick = get(props, 'handleNameClick')

  return row ? (
    <>
      <div
        className="person-link"
        style={{ cursor: 'pointer', fontWeight: 'bold', marginBottom: 5 }}
        onClick={handleNameClick}
      >
        {capitalize(val ? val : '')}
      </div>
      <PersonContact val={val} row={row} />
    </>
  ) : null
}

PersonNameColumn.propTypes = {
  handleNameClick: PT.func.isRequired,
  row: PT.object.isRequired,
  val: PT.string,
}

export default PersonNameColumn
