import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { getUserDetailsForSearchQuery } from '../../../apolloClient/operations/userData/getUserDetailsForSearchQuery'
import { isPublic } from '../../../utils/routing/routes'

const useUserForSearchRoute = () => {
  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const { loading, data, error } = useQuery(getUserDetailsForSearchQuery, {
    fetchPolicy: 'cache-first',
    skip: isPublicRoute,
  })

  const userProfile = get(data, 'getUserProfile')

  return {
    errorGettingUserProfile: error,
    gettingUserProfile: loading,
    userProfile,
  }
}

export default useUserForSearchRoute
