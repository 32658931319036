import React, { useState, useEffect } from 'react'

import { Modal, Button, Input, Form, Typography, Divider } from 'antd'
import { Link } from 'react-router-dom'

import FormRadioButtons from '../../../../components/forms/FormRadioButtons'
import { useCreditUsage } from '../../../../hooks/data/useCreditUsage'
import numberConverter from '../../../../utils/numberConverter'
import { MY_ACCOUNT_ROUTES } from '../../../../utils/routing/routes'

import DownloadSummary from './components/DownloadSummary'

const { Text } = Typography

const DataDownloadForm = ({
  downloadFile,
  totalMatches,
  teDownloadCredits,
  toggleModal,
  visible,
  allowSizeAdjustment,
  selectedCandidateIds,
  requestingDownload,
}) => {
  const [limited, setLimited] = useState(false)
  const [fileName, setFileName] = useState(null)
  const maxDownloadSizeFull = parseInt(process.env.REACT_APP_MAX_DOWNLOAD_SIZE)
  const maxDownloadSizeLimited = parseInt(
    process.env.REACT_APP_MAX_DOWNLOAD_SIZE_LIMITED,
  )

  /**
   *
   * @returns boolean
   */
  const getMaxDownloadSize = () => {
    const maxSize = limited ? maxDownloadSizeLimited : maxDownloadSizeFull
    return totalMatches > maxSize ? maxSize : totalMatches
  }

  const maxDownloadSize = getMaxDownloadSize()

  const [candidateSize, setCandidateSize] = useState(maxDownloadSize)

  const downloadingByCandidateId =
    selectedCandidateIds && selectedCandidateIds.length > 0

  useEffect(() => {
    const newCandidateSize =
      candidateSize > maxDownloadSize ? maxDownloadSize : candidateSize
    setCandidateSize(newCandidateSize)
  }, [candidateSize, maxDownloadSize])

  const formItemLayout = {
    labelCol: {
      md: { span: 4 },
      sm: { span: 8 },
      xs: { span: 24 },
    },
    wrapperCol: {
      md: { span: 20 },
      sm: { span: 16 },
      xs: { span: 24 },
    },
  }

  const onFileNameChange = e => {
    setFileName(e.target.value)
  }

  const onCandidateSizeChange = value => {
    setCandidateSize(value ? value : 0)
  }

  const handleLimitedChange = e => {
    e.preventDefault()
    setLimited(e.target.value)
    setCandidateSize(100)
  }

  const onSubmit = async (e, remainingCredits) => {
    e.preventDefault()
    await downloadFile({
      candidateSize,
      fileName,
      limited,
      remainingCredits,
      toggleModal,
    })
  }

  const downloadDescription = downloadingByCandidateId ? (
    <Text strong>{selectedCandidateIds.length} Records Selected</Text>
  ) : (
    <Text strong>{numberConverter(totalMatches)} Search Results</Text>
  )

  const downloadOptions = [
    {
      label: (
        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Text strong>Data + Email Validation (1 Credit Per Record)</Text>
          <i>*Email validation may take up to 1 hour</i>
        </div>
      ),
      value: false,
    },
    {
      label: (
        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Text strong>
            Data Only, without email validation (0.5 Credits Per Record)
          </Text>
          <i>*Immediate download</i>
        </div>
      ),
      value: true,
    },
  ]

  const { creditsUsage } = useCreditUsage({
    variables: {
      limited,
      recordSize: downloadingByCandidateId
        ? selectedCandidateIds.length
        : candidateSize,
    },
  })

  const disabled =
    !fileName ||
    !fileName.length ||
    !candidateSize ||
    creditsUsage > teDownloadCredits

  const remainingCredits = teDownloadCredits - creditsUsage

  return (
    <Modal
      afterClose={() => setLimited(false)}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={toggleModal}
      width={'550px'}
    >
      <div style={{ fontSize: '20px', marginBottom: '20px' }}>Download</div>
      <div style={{ padding: '0 0 12px 12px' }}>{downloadDescription}</div>
      <Form onSubmit={e => onSubmit(e, remainingCredits)}>
        <Form.Item name="fileName" label="File Name" {...formItemLayout}>
          <Input value={fileName} onChange={onFileNameChange} />
        </Form.Item>
        <Divider style={{ marginBottom: 12 }} />
        <FormRadioButtons
          handleChange={handleLimitedChange}
          style={{ paddingLeft: 12 }}
          input={{
            //defaultValue: false,
            style: {
              display: 'flex',
              flexDirection: 'column',
              lineHeight: '30px',
            },
            value: limited,
          }}
          label={<Text strong>Select a Download Option</Text>}
          options={downloadOptions}
        />
        <Divider style={{ marginBottom: 12 }} />
        <div style={{ paddingLeft: 20 }}>
          <Link
            to={`${MY_ACCOUNT_ROUTES.BUY_CREDITS}`}
            style={{ float: 'right' }}
          >
            <Button type="primary" ghost>
              Buy Credits
            </Button>
          </Link>
          <Text strong>Summary</Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DownloadSummary
            teDownloadCredits={teDownloadCredits}
            creditsUsage={creditsUsage}
            allowSizeAdjustment={allowSizeAdjustment}
            formItemLayout={formItemLayout}
            maxDownloadSize={maxDownloadSize}
            candidateSize={candidateSize}
            onCandidateSizeChange={onCandidateSizeChange}
            downloadingByCandidateId={downloadingByCandidateId}
            selectedCandidateIds={selectedCandidateIds}
          />
        </div>
        <Form.Item>
          <Button
            loading={requestingDownload}
            disabled={disabled}
            style={{ width: '100%' }}
            type="primary"
            onClick={e => onSubmit(e, remainingCredits)}
          >
            Confirm Transaction and Download
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DataDownloadForm
