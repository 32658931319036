import React from 'react'

import { Button, List } from 'antd'
import moment from 'moment'

import { useDeleteUser } from '../../../../../../../../../hooks/data/admin/useDeleteUser'

const PendingInvitationListItem = props => {
  const { createdAt, email, id, isAdmin } = props
  const { deleteUser } = useDeleteUser({
    errorMessage: 'Error cancelling the invitation',
    successMessage: 'Invitation cancelled',
  })

  const handleCancelInvitation = () => {
    deleteUser({ userId: id })
  }

  return (
    <List.Item className="admin-list-item">
      <List.Item.Meta
        title={`${isAdmin ? 'Admin ' : ''} Invite sent to: ${email}`}
        description={`Sent at: ${moment
          .utc(createdAt)
          .local()
          .format('YYYY-MM-DD HH:mm:ss')}`}
      />
      <Button icon="delete" type="danger" onClick={handleCancelInvitation}>
        Cancel Invite
      </Button>
    </List.Item>
  )
}

PendingInvitationListItem.propTypes = {}

export default PendingInvitationListItem
