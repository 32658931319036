import chunk from 'lodash/chunk'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import keys from 'lodash/keys'
import set from 'lodash/set'
import transform from 'lodash/transform'

import { filterNames } from '../constants/filterNames'
import { locationKeys, locationParamKeys } from '../constants/location'
import { queryParamKeys, stringSeparator } from '../constants/queryParams'

export const processCandidateSortOptionsParams = candidateSortOptionsQueryParams => {
  const candidatesSortOptionsObj = {}
  candidatesSortOptionsObj[filterNames.candidatesSortOptions] = {
    ...candidateSortOptionsQueryParams,
  }

  return candidatesSortOptionsObj
}

export const processLocationParams = locationParams => {
  if (keys(locationParams).length < 4) {
    return
  }
  const locationObject = {
    location: {
      geoData: {
        lat: get(locationParams, locationKeys.locationLat),
        lng: get(locationParams, locationKeys.locationLng),
      },
      name: get(locationParams, locationKeys.locationName),
      radius: get(locationParams, locationKeys.locationRadius),
    },
  }
  return locationObject
}

export const processStandardParams = params => {
  const transformedParams = transform(
    params,
    (result, value, key) => {
      if (key === 'include_previous') {
        set(result, 'include_previous', value === 'true')
        return
      }
      if (key === 'company_sizes') {
        set(result, 'company_sizes', chunk(value.split(','), 2))
        return
      }
      if (key === 'geo_points') {
        set(result, 'geo_points', chunk(value.split(','), 2))
        return
      }
      result[key.replace(' ', '_').toLowerCase()] = value.split(stringSeparator) // split by special chars to avoid issues with comma in strings
    },
    {},
  )
  return transformedParams
}

export const separateParams = queryParams => {
  const candidateSortOptionsParams = {}
  const locationParams = {}
  const standardParams = {}
  let selectedSearchGroup = null

  keys(queryParams).forEach(key => {
    const value = get(queryParams, `${key}`)
    if (findIndex(locationParamKeys, pKey => pKey === key) !== -1) {
      set(locationParams, `${key}`, value)
    } else if (key === filterNames.candidatesSortOptions) {
      set(candidateSortOptionsParams, `${key}`, JSON.parse(value))
    } else if (key === queryParamKeys.selectedSearchGroup) {
      selectedSearchGroup = value
    } else {
      set(standardParams, `${key}`, value)
    }
  })

  return {
    candidateSortOptionsParams,
    locationParams: processLocationParams(locationParams),
    selectedSearchGroup,
    standardParams: processStandardParams(standardParams),
  }
}
