import React, { useEffect, useState } from 'react'

import { Button, Form, Input } from 'antd'
import { isEqual, pick } from 'lodash'
import get from 'lodash/get'

import usePrevious from '../../../../../../../hooks/common/usePrevious'
import { useClientCustomBranding } from '../../../../../../../hooks/data/clientCustomBranding/useClientCustomBranding'
import { useCreateClientCustomBranding } from '../../../../../../../hooks/data/clientCustomBranding/useCreateClientCustomBranding'
import { useEditClientCustomBranding } from '../../../../../../../hooks/data/clientCustomBranding/useEditClientCustomBranding'
import feedback from '../../../../../../../utils/feedback'
import { brandingDefaults } from '../../../../../../Unauthenticated/PublicSearch/components/components/PublicSearchModalContents'
import FunctionalityContainer from '../FunctionalityContainer'

import CustomBrandingPreview from './components/CustomBrandingPreview'

const EDIT_MODES = {
  create: 'Create',
  edit: 'Edit',
}

const getFormFields = () => [
  {
    Component: Input,
    formItemProps: { key: 'Title', label: 'Title', required: true },
    inputProps: {
      autoFocus: true,
      id: 'header',
      placeholder: brandingDefaults['header'],
    },
    name: 'header',
  },
  {
    Component: Input,
    formItemProps: { key: 'Subheader', label: 'Description' },
    inputProps: {
      autoFocus: true,
      id: 'subheader',
      placeholder: brandingDefaults['subheader'],
    },
    name: 'subheader',
  },
  {
    Component: Input,
    formItemProps: { key: 'Button Text', label: 'Button Text' },
    inputProps: {
      autoFocus: true,
      id: 'buttonText',
      placeholder: brandingDefaults['buttonText'],
    },
    name: 'buttonText',
  },
  {
    Component: Input,
    formItemProps: { key: 'Button Link', label: 'Button Link' },
    inputProps: {
      autoFocus: true,
      id: 'buttonLink',
      placeholder: `${process.env.REACT_APP_FRONT_END_HOST}${brandingDefaults['buttonLink']}`,
    },
    name: 'buttonLink',
  },
  {
    Component: Input,
    formItemProps: { key: 'Disclaimer', label: 'Disclaimer' },
    inputProps: {
      autoFocus: true,
      id: 'disclaimer',
      placeholder: brandingDefaults['disclaimer'],
    },
    name: 'disclaimer',
  },
]

const CustomBrandingForm = () => {
  const [startingValues, setStartingValues] = useState({})
  const [formValues, setFormValues] = useState({})
  const [editMode, setEditMode] = useState(EDIT_MODES.edit)

  const { clientCustomBrandingData: brandingData } = useClientCustomBranding()

  const { editCustomBranding } = useEditClientCustomBranding({
    onCompleted: () => {
      setStartingValues(formValues)
      feedback.success({ content: 'Successfully saved custom branding' })
    },
  })

  const { createCustomBranding } = useCreateClientCustomBranding({
    onCompleted: data => {
      const brandingData = get(data, 'createClientCustomBranding')
      const id = get(brandingData, 'id')
      setFormValues({
        ...formValues,
        id,
      })
      setStartingValues(brandingData)
      setFormValues(brandingData)
      setEditMode(EDIT_MODES.edit)
      feedback.success({ content: 'Successfully saved custom branding' })
    },
  })

  const prevBrandingData = usePrevious(brandingData)
  useEffect(() => {
    if (!isEqual(prevBrandingData, brandingData)) {
      if (brandingData) {
        setEditMode(EDIT_MODES.edit)
        setFormValues(brandingData)
        setStartingValues(brandingData)
      } else {
        setEditMode(EDIT_MODES.create)
      }
    }
  }, [brandingData, prevBrandingData])

  const handleInputChange = e => {
    const target = get(e, 'target')

    setFormValues({
      ...formValues,
      [get(target, 'id')]: get(target, 'value'),
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    const action =
      editMode === EDIT_MODES.create ? createCustomBranding : editCustomBranding

    const variables = pick(formValues, [
      'id',
      'buttonDescription',
      'buttonLink',
      'buttonText',
      'disclaimer',
      'subheader',
      'header',
    ])

    if (editMode === EDIT_MODES.create) {
      variables['subdomain'] = process.env.REACT_APP_CUSTOM_SUBDOMAIN
    }

    try {
      action({
        variables,
      })
    } catch (error) {
      feedback.error(error)
    }
  }
  const fields = getFormFields()

  return (
    <>
      <FunctionalityContainer title={`${editMode} Custom Branding`}>
        <Form onSubmit={onSubmit}>
          {fields.map(field => {
            const { Component, inputProps, formItemProps } = field
            return (
              // eslint-disable-next-line react/jsx-key
              <Form.Item {...formItemProps}>
                <Component
                  {...inputProps}
                  onChange={handleInputChange}
                  value={formValues[inputProps.id]}
                />
              </Form.Item>
            )
          })}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
              disabled={isEqual(startingValues, formValues)}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <CustomBrandingPreview brandingData={formValues} />
      </FunctionalityContainer>
    </>
  )
}

export default CustomBrandingForm
