import React from 'react'

import { CrowdSegmentProvider } from '../../Authenticated/CrowdSegment/crowd-context'

import PublicSearch from './components/PublicSearch'

const PublicSearchPage = () => {
  return (
    <CrowdSegmentProvider>
      <PublicSearch />
    </CrowdSegmentProvider>
  )
}

export default PublicSearchPage
