import get from 'lodash/get'

import { useCreateRequisition } from './useCreateRequisition'

export const useOptionToCreateNewRequisition = props => {
  const { requisitions = [] } = props
  const { createJobRequisition } = useCreateRequisition()

  const createNewRequisition = async selectedRequisitionId => {
    let newRequisitionId = selectedRequisitionId

    const requisitionExists = requisitionId =>
      typeof requisitions.find(req => req.id === requisitionId) !== 'undefined'
    if (!requisitionExists(selectedRequisitionId)) {
      const response = await createJobRequisition({
        variables: { name: selectedRequisitionId },
      })

      newRequisitionId = get(response, 'data.createJobRequisition.id')
    }

    return newRequisitionId
  }

  return { createNewRequisition }
}
