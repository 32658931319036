import React, { useState } from 'react'

import { Button, Form, Icon, Input } from 'antd'
import { Auth } from 'aws-amplify'
import get from 'lodash/get'
import values from 'lodash/values'

import errorService from '../../../utils/analytics/error'
import UnauthenticatedCard from '../components/UnauthenticatedCard'

const FORM_FIELD_TITLES = {
  EMAIL: 'email',
  NEW_PASSWORD: 'newPassword',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  OLD_PASSWORD: 'oldPassword',
}

const SignUpFromInvitation = props => {
  const getFieldDecorator = get(props, 'form.getFieldDecorator')
  const [loggingIn, setLoggingIn] = useState(false)
  const { getFieldsValue } = props.form
  const fields = values(FORM_FIELD_TITLES)
  const formValues = getFieldsValue(fields)

  const handleAuthChallenge = async cognitoUser => {
    const { challengeName } = cognitoUser
    const { firstName, lastName, newPassword } = formValues
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      try {
        await Auth.completeNewPassword(cognitoUser, newPassword, {
          family_name: lastName,
          given_name: firstName,
        })
      } catch (error) {
        setLoggingIn(false)
        errorService.report(error, 'auth challenge')
      }
    }
  }

  const onSubmit = async () => {
    setLoggingIn(true)

    try {
      const email = get(formValues, 'email')
      const oldPassword = get(formValues, 'oldPassword')
      const cognitoUser = await Auth.signIn(email, oldPassword)
      handleAuthChallenge(cognitoUser)
      setLoggingIn(false)
    } catch (error) {
      setLoggingIn(false)
      errorService.report(error, 'signing up from invitation')
    }
  }

  return (
    <UnauthenticatedCard>
      <h2 style={{ fontWeight: 'bold', textAlign: 'center' }}>
        Welcome to CrowdSegment!
      </h2>
      <h5 style={{ marginBottom: 40, textAlign: 'center' }}>
        Please enter your details along with the current password sent to you
        via email and your new password to finish creating your account.
      </h5>
      <Form hideRequiredMark className="signup-form antd-auth-form">
        <Form.Item>
          {getFieldDecorator(FORM_FIELD_TITLES.EMAIL, {
            rules: [
              { message: 'A value is required', required: true },
              { message: 'Please provide a valid email', type: 'email' },
              { max: 250, message: 'Must be less than 250 characters' },
            ],
          })(<Input placeholder="Email" id={FORM_FIELD_TITLES.EMAIL} />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(FORM_FIELD_TITLES.FIRST_NAME, {
            rules: [
              { message: 'A value is required', required: true },
              { max: 250, message: 'Must be less than 250 characters' },
            ],
          })(
            <Input
              placeholder="First Name"
              id={FORM_FIELD_TITLES.FIRST_NAME}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(FORM_FIELD_TITLES.LAST_NAME, {
            rules: [
              { message: 'A value is required', required: true },
              { max: 250, message: 'Must be less than 250 characters' },
            ],
          })(
            <Input placeholder="Last Name" id={FORM_FIELD_TITLES.LAST_NAME} />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(FORM_FIELD_TITLES.OLD_PASSWORD, {
            rules: [
              {
                message:
                  'A value is required. Please check your email to retrieve your temporary password.',
                required: true,
              },
            ],
          })(
            <Input
              placeholder="Temporary Password"
              id={FORM_FIELD_TITLES.OLD_PASSWORD}
              type="password"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(FORM_FIELD_TITLES.NEW_PASSWORD, {
            rules: [
              {
                message: 'Cannot contain only whitespace',
                whitespace: true,
              },
              { message: 'A value is required', required: true },
              { max: 250, message: 'Must be less than 250 characters' },
              {
                message:
                  'Password must be at least 8 characters long and contain at least one lower and one upper case letter, and one number',
                // eslint-disable-next-line no-useless-escape
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{6,99}$/,
              },
            ],
          })(
            <Input
              placeholder="New Password"
              id={FORM_FIELD_TITLES.NEW_PASSWORD}
              type="password"
            />,
          )}
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            loading={loggingIn}
            htmlType="submit"
            style={{ marginLeft: 8 }}
            type="primary"
            onClick={() => onSubmit()}
            data-testid="signup-button"
          >
            Submit
            <Icon type={loggingIn ? 'spin' : 'double-right'} />
          </Button>
        </div>
      </Form>
    </UnauthenticatedCard>
  )
}

const SignUpFromInvitationForm = Form.create({
  name: 'SignUpFromInvitationForm',
})(SignUpFromInvitation)
export default SignUpFromInvitationForm
