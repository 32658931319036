import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { getMembersQuery } from '../../../apolloClient/operations/admin/getMembersQuery'
import errorService from '../../../utils/analytics/error'
import { pickWithInitialState } from '../../../utils/data/utils'
import feedback from '../../../utils/feedback'

export const useClientMembers = () => {
  const { data, loading } = useQuery(getMembersQuery, {
    onError: error => {
      errorService.report(error, 'getMembersQuery')
      feedback.error({
        title: 'Error fetching team members..Please try again',
      })
    },
  })
  const currentUserId = get(data, 'getUserProfile.id', '')
  const clientName = get(data, 'getUserProfile.client.name', '')
  const members = get(data, 'getUserProfile.client.members')
  const subscription = get(data, 'getUserProfile.client.subscription')
  const { admins, nonAdmins, invitations } = pickWithInitialState(members, [
    ['admins', []],
    ['nonAdmins', []],
    ['invitations', []],
  ])

  return {
    admins,
    clientName,
    currentUserId,
    invitations,
    loadingMembers: loading,
    nonAdmins,
    subscription,
  }
}
