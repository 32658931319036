import React from 'react'
import PT from 'prop-types'
import { Card } from 'antd'

const ManageMyRequisitionsContainer = props => {
  const { children, extra, title } = props
  return (
    <Card bodyStyle={{ paddingTop: 8 }} extra={extra} title={title}>
      {children}
    </Card>
  )
}

ManageMyRequisitionsContainer.propTypes = {
  extra: PT.oneOfType([PT.element, PT.node]),
  title: PT.string
}

export default ManageMyRequisitionsContainer
