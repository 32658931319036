import React from 'react'

import { GenericForm, PasswordInput } from 'components'
import validator from 'validator'

/**
 * Gets base form fields
 */
const getFormFields = props => [
  {
    Component: PasswordInput,
    formItemProps: {
      label: 'Current Password',
    },
    inputProps: {
      autoFocus: true,
      type: 'password',
    },
    name: 'currentPassword',
  },
  {
    Component: PasswordInput,
    formItemProps: {
      label: 'New Password',
    },
    inputProps: { type: 'password' },
    name: 'newPassword',
  },
  {
    Component: PasswordInput,
    formItemProps: {
      label: 'Confirm New Password',
    },
    inputProps: { type: 'password' },
    name: 'newPasswordConfirmation',
  },
]

/**
 * Validation function
 * @param {Object} param
 */
const isLength = (value = '') =>
  validator.isLength(value, { min: 6 }) ? undefined : 'Invalid Password'

const checkEquality = (value, candidate) =>
  value === candidate ? undefined : 'Passwords must match'

const validate = ({
  currentPassword = '',
  newPassword = '',
  newPasswordConfirmation = '',
}) => ({
  currentPassword: isLength(currentPassword),
  newPassword: isLength(newPassword),
  newPasswordConfirmation:
    isLength(newPasswordConfirmation) ||
    checkEquality(newPassword, newPasswordConfirmation),
})

const ChangePasswordForm = props => {
  const fields = getFormFields(props)

  return (
    <GenericForm
      fields={fields}
      formProps={{ onSubmit: props.onSubmit, validate }}
      submitButtonContent="Change Password"
      submitButtonProps={{ block: true, style: { marginTop: 16 } }}
    />
  )
}

ChangePasswordForm.propTypes = {}

export default ChangePasswordForm
