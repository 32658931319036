import React from 'react'

import { Modal } from 'antd'
import get from 'lodash/get'

import AddPaymentForm from '../../../../components/payment/AddPaymentForm'
import { theme } from '../../../../theme'

const accentColor = get(theme, 'colors.accent')

const AddPaymentModal = props => {
  const modalShown = get(props, 'modalShown')
  const message = get(props, 'message')
  const closeModal = get(props, 'closeModal')
  const title = get(props, 'title')
  const closable = get(props, 'closable')
  const afterUpdate = get(props, 'afterUpdate')
  const client = get(props, 'client')
  const isAdmin = get(props, 'isAdmin')

  return (
    <Modal
      visible={modalShown}
      closable={false}
      footer={null}
      zIndex={1004}
      bodyStyle={{
        borderTop: `4px solid ${accentColor}`,
      }}
      onCancel={closable ? closeModal : null}
      destroyOnClose
      width={600}
    >
      <AddPaymentForm
        message={message}
        title={title}
        afterUpdate={afterUpdate}
        client={client}
        isAdmin={isAdmin}
      />
    </Modal>
  )
}

export default AddPaymentModal
