import React, { useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Divider, List, Typography, Row, Col } from 'antd'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'

import { updatePaymentMethodMutation } from '../../apolloClient/operations/payments/updatePaymentMutation'
import { getUserForSubscriptionQuery } from '../../apolloClient/operations/userData/getUserForSubscriptionQuery'
import { useDynamicUser } from '../../hooks/data/loggedInUser/useDynamicUser'
import useAmountPastDue from '../../hooks/data/payments/useAmountPastDue'
import ShowCharge from '../../routes/Unauthenticated/TeRegistration/components/ShowCharge'
import errorService from '../../utils/analytics/error'
import feedback from '../../utils/feedback'
import StripeCard from '../stripeCard'

import ChargeSummary from './components/ChargeSummary'
import NonAdminMessage from './components/NonAdminMessage'
import PaymentFormFooter from './components/PaymentFormFooter'

const { Paragraph, Title } = Typography

const UpdatePaymentForm = props => {
  const message = get(props, 'message')
  const title = get(props, 'title')
  const afterUpdate = get(props, 'afterUpdate')
  const clientName = get(props, 'clientName')
  const isAdmin = get(props, 'isAdmin')
  const permissions = get(props, 'permissions')
  const [stripeToken, setStripeToken] = useState(null)

  const { onPaymentPlan, needsUpdatedPayment } = permissions
  const componentIsMounted = useRef(true)
  const { chargesPastDue, loading } = useAmountPastDue({
    needsUpdatedPayment,
    onPaymentPlan,
  })

  const { userProfile } = useDynamicUser({
    userQuery: getUserForSubscriptionQuery,
  })

  const paymentMethods = get(userProfile, 'client.subscription.paymentMethods')

  const [updatePayment, { loading: updatingPayment }] = useMutation(
    updatePaymentMethodMutation,
    {
      onCompleted: () => {
        componentIsMounted.current = false
        feedback.success({ content: 'Payment updated successfully' })
        afterUpdate()
      },
      onError: error => {
        errorService.report(error, 'Payment Modal - Update Payment Method')
        feedback.error({
          title:
            'There was a problem updating your payment method... Please try again',
        })
      },
    },
  )

  const onSubmit = () => {
    updatePayment({
      variables: {
        ...{
          stripeCardToken: stripeToken,
        },
      },
    })
  }

  return (
    <>
      <Title level={3}>
        {title ? title : 'Please update your payment information'}
      </Title>
      {isAdmin ? (
        <>
          {' '}
          <div style={{ padding: '0 22px' }}>
            <Paragraph type="secondary">{message}</Paragraph>
            <List
              itemLayout="vertical"
              header="Payment Methods"
              dataSource={paymentMethods}
              locale={{ emptyText: 'No payment methods available' }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={capitalize(get(item, 'brand', ''))}
                    description={get(item, 'last4', '')}
                  />
                </List.Item>
              )}
            />
            <StripeCard
              setStripeToken={setStripeToken}
              clientName={clientName}
            />
            <Row>
              <Col span={12}>
                <ChargeSummary
                  showDiscount={chargesPastDue && chargesPastDue.discount}
                  loading={loading}
                  chargeData={chargesPastDue}
                />
              </Col>
            </Row>
            <div style={{ textAlign: 'right' }}>
              {chargesPastDue && get(chargesPastDue, 'discount') && (
                <>
                  <ShowCharge
                    loading={loading}
                    chargeText="Subtotal"
                    amount={get(chargesPastDue, 'subtotal')}
                  />
                  <ShowCharge
                    loading={loading}
                    chargeText="Discount"
                    amount={get(chargesPastDue, 'discount')}
                  />
                  <Divider style={{ margin: 3, padding: 0 }} />
                </>
              )}
            </div>
          </div>
          <PaymentFormFooter
            onSubmit={onSubmit}
            stripeToken={stripeToken}
            addingPayment={updatingPayment}
          />
        </>
      ) : (
        <NonAdminMessage />
      )}
    </>
  )
}

export default UpdatePaymentForm
