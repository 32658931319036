import React, { useMemo } from 'react'

import { Modal } from 'antd'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

import TextAreaInput from '../../../../../../components/forms/FormTextareaInput'
import TextInput from '../../../../../../components/forms/FormTextInput'
import GenericForm from '../../../../../../components/forms/GenericForm'
import { useHelpRequest } from '../../../../../../hooks/data/useHelpRequest'
import errorService from '../../../../../../utils/analytics/error'
import feedback from '../../../../../../utils/feedback'

/**
 * Gets base form fields
 */
const getFormFields = () => [
  {
    Component: TextInput,
    formItemProps: { label: 'Subject' },
    inputProps: {
      autoFocus: true,
      placeholder: 'Feedback on...',
    },
    name: 'subject',
  },
  {
    Component: TextAreaInput,
    formItemProps: { label: 'How can we help / how can we improve?' },
    inputProps: { rows: 4 },
    name: 'message',
  },
]

/**
 * Validation function
 * @param {Object} param
 */
const validate = ({ subject = '' }) => ({
  subject: subject.length > 1 ? undefined : 'Subject is required',
})

const FeedbackForm = props => {
  const { modalProps } = props
  const page = _.get(props, 'location.pathname')
  const { submitHelpRequest } = useHelpRequest()

  const onSubmit = sendHelpRequest => async formValues => {
    try {
      const { message, subject } = _.pick(formValues, ['message', 'subject'])
      await sendHelpRequest({
        variables: {
          data: { message, page, subject },
        },
      })
      modalProps.closeModal()
    } catch (error) {
      errorService.report(error, 'Feedback form - Send help request')
      feedback.error({
        content: error.message,
        title: 'Error sending request!',
      })
    }
  }

  const fields = useMemo(() => getFormFields(), [])

  const memoizedOnSubmit = _.memoize(onSubmit)

  return (
    <Modal
      {...modalProps}
      className="feedback-modal-container"
      destroyOnClose
      footer={null}
      mask={false}
      onCancel={modalProps.closeModal}
      title="Feedback"
      width={300}
    >
      <GenericForm
        className="small-space-between-fields"
        fields={fields}
        formProps={{
          onSubmit: memoizedOnSubmit(submitHelpRequest),
          validate,
        }}
        submitButtonContent="Send"
        submitButtonProps={{ block: true, style: { marginTop: 10 } }}
      />
    </Modal>
  )
}

export default withRouter(FeedbackForm)
