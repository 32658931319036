import { useMutation } from '@apollo/client'

import { disableUserAdminAccessMutation } from '../../../apolloClient/operations/admin/disableUserAdminAccessMutation'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useDisableUserAdminAccess = () => {
  const [disableUserAdminAccess] = useMutation(disableUserAdminAccessMutation, {
    onCompleted: () => {
      feedback.success({
        content: 'Admin access updated successfully',
      })
    },
    onError: error => {
      errorService.report(error, 'update admin access')
      feedback.error({
        content: error.message,
        title: 'Error updating admin access',
      })
    },
  })

  return { disableUserAdminAccess }
}
