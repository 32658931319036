import React from 'react'

import { Button, Layout, Row } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { withRouter } from 'react-router-dom'

import MobileLogo from '../../../../assets/logos/crowdsegment-logo-round-edges.png'
import Logo from '../../../../assets/logos/cs-logo-srch-word-plain-purple.png'
import { UNAUTHENTICATED_ROUTES } from '../../../../utils/routing/routes'

const { Header } = Layout
const MOBILE_WIDTH = 768

const logo = process.env.REACT_APP_CUSTOM_CLIENT_LOGO
  ? process.env.REACT_APP_CUSTOM_CLIENT_LOGO
  : Logo

const mobileLogo = process.env.REACT_APP_CUSTOM_CLIENT_LOGO_MOBILE
  ? process.env.REACT_APP_CUSTOM_CLIENT_LOGO_MOBILE
  : MobileLogo

const UnauthenticatedHeader = props => {
  const { borderBottom = true } = props

  const buttons = [
    {
      buttonProps: {},
      route: UNAUTHENTICATED_ROUTES.AUTHENTICATE,
      title: 'Log In',
    },
    {
      buttonProps: { type: 'primary' },
      route: UNAUTHENTICATED_ROUTES.CS_SIGNUP,
      title: 'Sign Up',
    },
  ]

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` })

  return (
    <>
      <Header
        style={{
          backgroundColor: '#ffffff',
          borderBottom: `${borderBottom ? '3px solid #eee' : 'none'}`,
        }}
      >
        <Row
          type="flex"
          style={{ height: '100%', margin: '0 auto', maxWidth: 1200 }}
        >
          <Row
            type="flex"
            align="middle"
            justify="start"
            style={{ height: '100%', textAlign: 'center' }}
          >
            <a href="https://www.crowdsegment.com">
              <img
                className="logo-image"
                alt="CrowdSegment Logo"
                src={isMobile ? mobileLogo : logo}
              />
            </a>
          </Row>
          <div style={{ marginLeft: 'auto' }}>
            {buttons.map(({ buttonProps, title, route }) => (
              <Button
                style={{ margin: '0px 8px 0px 8px' }}
                key={route}
                {...buttonProps}
                onClick={() => props.history.push(route)}
              >
                {title}
              </Button>
            ))}
          </div>
        </Row>
      </Header>
    </>
  )
}

export default withRouter(UnauthenticatedHeader)
