import React from 'react'

import { Form, Radio } from 'antd'
import PT from 'prop-types'

const FormRadioButtons = props => {
  const { input, label, options, handleChange, ...rest } = props

  return (
    <Form.Item label={label} colon={false} {...rest}>
      <Radio.Group {...input} onChange={handleChange}>
        {options.map(option => {
          return (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )
}

const OptionPT = PT.shape({
  label: PT.string.isRequired,
  value: PT.string.isRequired,
})

FormRadioButtons.propTypes = {
  options: PT.arrayOf(OptionPT).isRequired,
}

export default FormRadioButtons
