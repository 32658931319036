import React, { useMemo } from 'react'

import { Form } from 'antd'
import _fp from 'lodash/fp'
import get from 'lodash/get'
import toString from 'lodash/toString'
import PT from 'prop-types'

import CustomButton from '../../../../../../../../../../components/forms/CustomButton'
import InputWithArrayValue from '../../../../../../../../../../components/forms/InputWithArrayValue/index'
import useTotalChargeForAddSeats from '../../../../../../../../../../hooks/data/useTotalChargeForAddSeats'

import TotalCharges from './TotalCharges'

/**
 * Themable Form.Item
 */
const SFormItem = Form.Item

const getFormFields = props => {
  const getEmailHelp = get(props, 'getEmailHelp')
  const isFieldsTouched = get(props, 'form.isFieldsTouched')
  return [
    {
      Component: InputWithArrayValue,
      fieldConfig: {
        rules: [
          {
            message: 'Email required',
            required: true,
            type: 'array',
          },
        ],
      },
      formItemProps: { label: 'Invite by Email' },
      getHelp: values => {
        const emails = get(values, 'emails')
        return isFieldsTouched() && getEmailHelp(emails)
      },
      inputProps: {
        autoFocus: true,
        placeholder: 'john.smith@email.com',
        ref: React.createRef(),
      },
      name: 'emails',
    },
  ]
}

const MemberInviteForm = props => {
  const {
    formProps,
    submitButtonContent,
    submitButtonHidden,
    submitButtonProps,
    submitForm,
    validate,
    subscription,
  } = props
  const { getFieldDecorator, getFieldsValue, setFields } = props.form

  const { emails } = props.form.getFieldsValue()
  const totalSeatsToAdd = emails ? emails.length : 0
  const {
    addSeatsCharge,
    loading: loadingChargeForSeats,
  } = useTotalChargeForAddSeats(totalSeatsToAdd)

  const isAdmin = get(props, 'form.getFieldValue')('isAdmin')
  const errors = validate({
    emails,
    isAdmin,
  })
  const hasErrors = errors.emails

  const fields = useMemo(() => getFormFields(props), [props])
  return (
    <Form
      hideRequiredMark={true}
      {...formProps}
      onSubmit={e => {
        e.preventDefault()
        submitForm(getFieldsValue())
      }}
    >
      {fields.map(
        ({
          Component,
          formItemProps,
          inputProps,
          name,
          fieldConfig,
          getHelp,
        }) => {
          return (
            <SFormItem
              key={name}
              // hasFeedback
              {...formItemProps}
              help={getHelp && getHelp(getFieldsValue([name]))}
            >
              {getFieldDecorator(
                name,
                fieldConfig,
              )(<Component {...inputProps} setFields={setFields} />)}
            </SFormItem>
          )
        },
      )}
      {!!subscription && (
        <TotalCharges
          loadingChargeForSeats={loadingChargeForSeats}
          addSeatsCharge={addSeatsCharge}
        />
      )}
      {submitButtonHidden ? null : (
        <CustomButton
          content={submitButtonContent}
          htmlType="submit"
          type="primary"
          {...submitButtonProps}
          disabled={hasErrors}
        />
      )}
    </Form>
  )
}

MemberInviteForm.propTypes = {
  fields: PT.array.isRequired,
  formProps: PT.object,
  submitButtonContent: PT.oneOfType([PT.element, PT.node, PT.string]),
  submitButtonHidden: PT.bool,
  submitButtonProps: PT.object,
  validate: PT.func.isRequired,
}

export default Form.create({ name: 'MemberInviteForm' })(MemberInviteForm)

const hasNoErrors = value =>
  _fp.pipe(
    _fp.map(fn => fn(toString(value))),
    _fp.every(_fp.identity),
  )

export const getError = (value, rules, message) =>
  hasNoErrors(value)(rules) ? undefined : message
