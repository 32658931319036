import React from 'react'
import { Form, Input } from 'antd'
import { getHelp, getValidateStatus } from '../../../utils/forms'

const FormTextareaInput = props => {
  const { input, label, meta, ...rest } = props

  return (
    <Form.Item
      hasFeedback
      help={getHelp(meta)}
      label={label}
      validateStatus={getValidateStatus(meta)}
    >
      <Input.TextArea rows={4} {...input} {...rest} />
    </Form.Item>
  )
}

export default FormTextareaInput
