const { default: gql } = require('graphql-tag')

export const enableUserAdminAccessMutation = gql`
  mutation EnableUserAdminAccess($userId: ID!) {
    enableUserAdminAccess(userId: $userId) {
      id
      isAdmin
    }
  }
`
