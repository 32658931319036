import React, { useState, useEffect, useCallback } from 'react'

import { Icon, Modal, Table, Tooltip } from 'antd'
import gql from 'graphql-tag'
import get from 'lodash/get'

import { apolloClient } from '../../../../../../../../apolloClient/index'
import { theme } from '../../../../../../../../theme'
import errorService from '../../../../../../../../utils/analytics/error'
import numberConverter from '../../../../../../../../utils/numberConverter/index'
import { capitalize } from '../../../../../../../../utils/stringManipulation'

const accentColor = get(theme, 'colors.accent')

export const pullTransactionsHistoryForCustomerQuery = gql`
  query PullTransactionsHistoryForCustomer {
    pullTransactionsHistoryForCustomer {
      amount
      balance
      date
      downloadLink
      status
    }
  }
`

const TransactionHistoryModal = props => {
  const modalShown = get(props, 'modalShown')
  const closeModal = get(props, 'closeModal')
  const [transactions, setTransactions] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const columns = [
    {
      dataIndex: 'date',
      key: 'date',
      title: 'Date',
    },
    {
      align: 'center',
      dataIndex: 'amount',
      key: 'amount',
      render: val => `$${numberConverter(val, 2)}`,
      title: 'Amount',
    },
    {
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      render: val => capitalize(val),
      title: 'Status',
    },
    {
      align: 'center',
      dataIndex: 'balance',
      key: 'balance',
      render: val => `$${numberConverter(val, 2)}`,
      title: 'Balance',
    },
    {
      align: 'center',
      dataIndex: 'downloadLink',
      key: 'downloadLink',
      render: val => (
        <Tooltip title="Download Invoice">
          <a href={val}>
            <Icon type="download" />
          </a>
        </Tooltip>
      ),
      title: '',
    },
  ]

  const getTransactions = useCallback(async () => {
    setLoading(true)
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        query: pullTransactionsHistoryForCustomerQuery,
      })
      setLoading(false)
      const transactions = get(
        response,
        'data.pullTransactionsHistoryForCustomer',
      )
      setTransactions(transactions)
    } catch (error) {
      setLoading(false)
      errorService.report(error, 'Get transaction history for customer')
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    async function fetch() {
      if (isSubscribed) {
        await getTransactions()
      }
    }
    fetch()
    return () => (isSubscribed = false)
  }, [getTransactions])

  return (
    <Modal
      visible={modalShown}
      closable={false}
      footer={null}
      bodyStyle={{
        borderTop: `4px solid ${accentColor}`,
      }}
      onCancel={closeModal}
      destroyOnClose
    >
      <Table
        pagination={{ current: page, onChange: setPage, pageSize: 5 }}
        loading={loading}
        columns={columns}
        dataSource={transactions}
        rowKey="date"
      />
    </Modal>
  )
}

export default TransactionHistoryModal
