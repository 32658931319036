import React from 'react'

import { Button } from 'antd'
import Box from 'ui-box'

const ClearButton = ({ children, wrapperProps, ...rest }) => (
  <Box {...wrapperProps}>
    <Button style={{ border: 'none', boxShadow: 'none', padding: 0 }} {...rest}>
      {children}
    </Button>
  </Box>
)

export default ClearButton
