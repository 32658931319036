import React from 'react'
import FunctionalityContainer from '../FunctionalityContainer'
import BasicInfoForm from './components/BasicInfoForm'

const BasicInfo = props => {
  return (
    <FunctionalityContainer title="Basic Info">
      <BasicInfoForm />
    </FunctionalityContainer>
  )
}

BasicInfo.propTypes = {}

export default BasicInfo
