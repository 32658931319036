import React, { useState } from 'react'

import { Row, Col, Button, Popover, Typography, Icon } from 'antd'
import get from 'lodash/get'
import PT from 'prop-types'

import { apolloClient } from '../../../../../apolloClient'
import { skillDescriptionQuery } from '../../../../../apolloClient/operations/lookup/skillDescriptionQuery'
import colors from '../../../../../hocs/colors'
import { capitalize } from '../../../../../utils/stringManipulation'

const { Title, Paragraph } = Typography

export const SkillsBars = props => {
  const toggleSkill = get(props, 'toggleSkill')
  const selected = get(props, 'selected')
  const [skillDescription, setSkillDescription] = useState(null)
  const getSkillDescription = async skillName => {
    // TODO - refactor to match new pattern
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: skillDescriptionQuery,
        variables: { skillName },
      })
      const description = get(result, 'data.skillDescription')
      setSkillDescription(description)
    } catch (error) {
      console.log(error)
      setSkillDescription('Description Unavailable')
    }
  }

  const handleVisibleChange = visible => {
    if (visible && !skillDescription) {
      getSkillDescription(get(props, 'name'))
    }
  }
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col md={24 / (props.values.length + 1)}>
        <div style={{ alignItems: 'center', display: 'flex', marginTop: 12 }}>
          <div style={{ display: 'flex' }}>
            <Button
              type="link"
              size="large"
              style={selected ? { color: 'red' } : { color: 'green' }}
              icon={selected ? 'minus-circle' : 'plus-circle'}
              onClick={() => toggleSkill(get(props, 'name'))}
            />
          </div>
          <Popover
            placement="right"
            title={
              <Title level={4}>
                {capitalize(get(props, 'name'))}{' '}
                <a
                  href={`https://www.youtube.com/results?search_query=${get(
                    props,
                    'name',
                  )}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Icon
                    type="youtube"
                    theme="filled"
                    style={{ color: '#ff1800' }}
                  />
                </a>
              </Title>
            }
            content={
              <Paragraph className="skill-description-container">
                {skillDescription}
              </Paragraph>
            }
            onVisibleChange={handleVisibleChange}
            overlayStyle={{ width: 800 }}
          >
            <div style={{ fontSize: 14, fontWeight: '600' }}>
              {capitalize(get(props, 'name', ''))}
            </div>
          </Popover>
        </div>
      </Col>
      {props.values.map((child, index) => (
        <Col
          key={`${get(child, 'value', 0)}${index}`}
          md={24 / (props.values.length + 1)}
          className="skill-bar-column"
        >
          <div
            key={get(child, 'key')}
            style={{
              background: colors.gray4,
              borderRadius: 6,
              marginTop: 5,
            }}
          >
            <div
              className="scale-bars"
              style={{
                background: colors[get(child, 'name', '#fff')],
                borderColor: colors[get(child, 'name', '#fff')],
                borderRadius: 5,
                height: 15,
                minWidth: 10,
                opacity: 0.8,
                padding: '0 5px',
                width: `${get(child, 'value', 0)}%`,
              }}
            />
          </div>
        </Col>
      ))}
    </Row>
  )
}

SkillsBars.propTypes = {
  description: PT.string,
  name: PT.string,
  values: PT.array,
}

export default SkillsBars
