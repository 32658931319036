import { useCallback, useState } from 'react'

const useModalManagement = (initialState = false) => {
  const [visible, setVisibility] = useState(initialState)

  const closeModal = useCallback(() => setVisibility(false), [setVisibility])
  const openModal = useCallback(() => setVisibility(true), [setVisibility])

  return { closeModal, openModal, visible }
}

export default useModalManagement
