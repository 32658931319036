import React from 'react'

import { Button, Result } from 'antd'

const ErrorPage = () => {
  return (
    <div style={{ margin: '80px auto', maxWidth: 500, padding: '20px' }}>
      <Result
        status="warning"
        title="Something went wrong..."
        subTitle="This error has been reported to the devleopment team along with your user ID. Please try again later."
        extra={
          <Button
            type="primary"
            size="large"
            href="https://www.crowdsegment.com"
          >
            CrowdSegment Home Page
          </Button>
        }
      />
    </div>
  )
}

export default ErrorPage
