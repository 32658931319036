import React from 'react'

import { Alert, Button } from 'antd'
import get from 'lodash/get'

const PaymentRequestAlert = props => {
  const permissions = get(props, 'permissions')
  const openPaymentModal = get(props, 'openPaymentModal')
  const styles = get(props, 'styles')
  const { onPaymentPlan, isAdmin } = permissions

  return !onPaymentPlan && !!isAdmin ? (
    <Alert
      type="info"
      message={
        <div>
          <Button type="primary" ghost onClick={openPaymentModal}>
            Create your Subscription
          </Button>{' '}
          {'Start your subscription now for 5,000 bonus credits per user!'}
        </div>
      }
      showIcon
      closable
      style={styles ? styles : null}
    />
  ) : null
}

export default PaymentRequestAlert
