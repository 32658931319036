import React from 'react'

import { Drawer } from 'antd'

import CompanyView from '../CompanyView'
import PaymentRequestAlert from '../PaymentRequestAlert'

const CompanyDrawer = props => {
  const {
    closeDrawer,
    visible,
    permissions,
    openPaymentModal,
    selectedCompany,
    toggleSkill,
    selectedSkills,
    width = 600,
  } = props
  return (
    <Drawer
      title="Company Details"
      placement="right"
      closable={true}
      destroyOnClose
      getContainer={false}
      onClose={closeDrawer}
      visible={visible}
      style={{ overflow: 'auto' }}
      width={width}
      zIndex={1002}
    >
      <PaymentRequestAlert
        permissions={permissions}
        openPaymentModal={openPaymentModal}
        styles={{ marginBottom: 20 }}
      />
      <CompanyView
        companyDetails={selectedCompany}
        toggleSkill={toggleSkill}
        selectedSkills={selectedSkills}
        permissions={permissions}
        openPaymentModal={openPaymentModal}
      />
    </Drawer>
  )
}

export default CompanyDrawer
