import _ from 'lodash'
import _fp from 'lodash/fp'
import get from 'lodash/get'
import head from 'lodash/head'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import omitBy from 'lodash/omitBy'
import partition from 'lodash/partition'
import sortBy from 'lodash/sortBy'
import transform from 'lodash/transform'
import uniqueBy from 'lodash/uniqBy'

import { REPORT_TYPES } from '../../applicationConstants'
import { filterNames } from '../../constants/filterNames'
import { stringSeparator } from '../../constants/queryParams'

/**
 * Function factory for extractors
 * @param {String} key
 */
export const extractKeyFromList = (key = '') => _fp.map(_fp.get(key))

/**
 * Gets ids from a list
 */
export const extractIdsFromList = extractKeyFromList('id')

/**
 * Gets keys from a list
 */
export const extractKeysFromList = extractKeyFromList('key')

/**
 * Checks if value has some length
 * @param {String|Array} val
 */
export const hasLength = (val = '') => val.length > 0

/**
 * Like lodash.pick but with default state
 * @param {Object} data
 * @param {Array} valuesToRetrieve
 */
export const pickWithInitialState = (data = {}, valuesToRetrieve = []) =>
  valuesToRetrieve.reduce((acc, valueToRetrieve) => {
    const [key, initialState] = valueToRetrieve
    acc[key] =
      _.get(data, key, initialState) === null
        ? initialState
        : _.get(data, key, initialState)
    return acc
  }, {})

/**
 * Converts words to a sentence
 */
export const toSentence = _fp.pipe(
  _fp.snakeCase,
  _fp.split('_'),
  _fp.map(_fp.capitalize),
  _fp.join(' '),
)

/**
 * Utility to separate out react and tableau reports in separate array from single array of reports
 */
export const separateOutReports = reports => {
  const reportsByType = partition(reports, r => r.type === REPORT_TYPES.react)
  let reactReports = head(reportsByType)
  let tableauReports = last(reportsByType)

  reactReports = uniqueBy(reactReports, r => r.name)
  reactReports = sortBy(reactReports, report =>
    report.name === 'Graduate Outcomes' ? 0 : 1,
  )

  tableauReports = uniqueBy(tableauReports, r => r.name)
  tableauReports = sortBy(tableauReports, report =>
    report.name === 'Graduate Outcomes' ? 0 : 1,
  )

  return {
    reactReports,
    tableauReports,
  }
}

export const removeFalsey = obj =>
  omitBy(
    obj,
    (val, key) => isNil(val) || (isArray(get(obj, key)) && !val.length),
  )

export const createQueryStringForTalentSearch = params => {
  const searches = transform(
    params,
    (result, value, key) => {
      if (
        key !== filterNames.location &&
        key !== filterNames.candidatesSortOptions &&
        !!value &&
        !!value.length
      ) {
        result.push(
          `${key}=${encodeURIComponent(
            Array.isArray(value) &&
              key !== filterNames.geoPoints &&
              key !== filterNames.companySizes
              ? value.join(stringSeparator)
              : value,
          )}`,
        )
      } else if (key === filterNames.includePrevious) {
        result.push(`${key}=${value}`)
      }
    },
    [],
  )

  if (params && params.location) {
    const latidude = get(params, 'location.geoData.lat')
    const longitude = get(params, 'location.geoData.lng')
    const radius = get(params, 'location.radius')
    const name = get(params, 'location.name')

    name && searches.push(`location_name=${get(params, 'location.name', '')}`)
    radius && searches.push(`location_radius=${get(params, 'location.radius')}`)
    latidude &&
      searches.push(`location_lat=${get(params, 'location.geoData.lat')}`)
    longitude &&
      searches.push(`location_lng=${get(params, 'location.geoData.lng')}`)
  }

  if (params && params[filterNames.candidatesSortOptions]) {
    const sortOptionsValue = JSON.stringify(
      params[filterNames.candidatesSortOptions],
    )

    searches.push(`${filterNames.candidatesSortOptions}=${sortOptionsValue}`)
  }

  const query = `?${searches.join('&')}`
  return query
}
