import React from 'react'

import { Row, Result, Col, Skeleton, Typography, Icon } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'

import HeatMapChart from '../../../../components/charts/HeatMapChart'
import HeatMapLegend from '../../../../components/charts/HeatMapLegend'
import MapBubbleLegend from '../../../../components/charts/MapBubbleLegend'
import colors from '../../../../hocs/colors'
import { IMAGES } from '../../../../utils/applicationConstants'
import { pickWithInitialState } from '../../../../utils/data/utils'
import numberConverter from '../../../../utils/numberConverter'
import { capitalize } from '../../../../utils/stringManipulation'
import StatCountUpTE from '../../../Authenticated/components/AuthenticatedLayout/components/ReportElements/StatCountUpTE'
import { searchSettings } from '../static/searchSettings'

import SingleValueCircle from './components/SingleValueCircle'

const { Text } = Typography

const AnalyticsTab = ({
  analyticsData,
  isMobile,
  error,
  loading,
  suppliedFilters,
  toggleFilter,
  selectedStates,
  handleGeoSelection,
  geoFilterApplied,
}) => {
  const toggleStateFilter = value => {
    toggleFilter(searchSettings.states.name, value)
  }
  const onStateClick = stateName => {
    toggleStateFilter(stateName)
  }
  let {
    total_matches,
    avg_age,
    map_locations,
    veterans_pct,
    gender_males_pct,
    companies_count,
    // national_avg_age
  } = pickWithInitialState(analyticsData, [
    ['avg_age', 0],
    ['map_locations', null],
    ['total_matches', 0],
    ['gender_males_pct', 0],
    ['veterans_pct', 0],
    ['companies_count', 0],
    // [('national_avg_age', 38)]
  ])
  const national_avg_age = 38
  const topStates = get(analyticsData, 'top_states', [])
  const displayPopulation =
    total_matches > 999999
      ? numberConverter(total_matches / 1000000, 2)
      : total_matches

  if (error) {
    return (
      <Result
        status="warning"
        title="Error Encountered"
        subTitle="Check back later, or try another search. "
      />
    )
  }
  const textLength = displayPopulation.toString().length
  const magicFontSize = textLength <= 4 ? 60 : 60 - 10 * (textLength - 4)

  return (
    <div className="te-tab-container" data-testid="analytics-tab">
      <Row style={{ marginBottom: 15 }}>
        <Col xs={12} sm={12} md={6}>
          <div
            className="te-card-content"
            style={{ height: isMobile ? 250 : 290 }}
          >
            <div className="text-muted text-center">Crowd Size</div>
            <div className="vert-parent">
              <StatCountUpTE
                className="vert-child"
                style={{ marginTop: 15, textAlign: 'center' }}
                fontSize={magicFontSize}
                data={displayPopulation}
                weight="bold"
                decimals={total_matches > 999999 ? 2 : 0}
              />
            </div>
            <div
              style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}
            >
              {total_matches > 999999 ? 'Million People' : 'People'}
            </div>
            {!!companies_count && (
              <>
                <div className="vert-parent">
                  <StatCountUpTE
                    className="vert-child"
                    style={{ marginTop: 14, textAlign: 'center' }}
                    fontSize={magicFontSize - 15}
                    data={companies_count}
                  />
                </div>
                <div style={{ fontSize: 16, textAlign: 'center' }}>
                  {companies_count === 1 ? 'Company' : 'Companies'}
                </div>
              </>
            )}
          </div>
        </Col>
        <Col xs={12} sm={12} md={18}>
          <div
            className="te-card-content"
            style={{ height: isMobile ? 250 : 290 }}
          >
            <Row style={{ marginBottom: 0 }}>
              <Col md={6}>
                <div className="te-callout-label">Average Age</div>
                <StatCountUpTE
                  style={{ margin: '40px 0 20px', textAlign: 'center' }}
                  data={avg_age}
                  weight={500}
                />
                <div className="te-insight">
                  {`Population ${
                    Math.abs(avg_age - national_avg_age) > 5
                      ? 'significantly'
                      : 'slightly'
                  }
                  ${
                    avg_age - national_avg_age > 0 ? 'older' : 'younger'
                  } than the national average.`}
                </div>
              </Col>
              {!isMobile && (
                <Col span={6}>
                  <div className="te-callout-label">Veterans</div>
                  <div className="te-circle">
                    <img
                      src="https://resources.steppingblocks.com/hubfs/Icons/CrowdSegment/veteran_4.png"
                      alt="company logo"
                      className="company-logo"
                      style={{ height: '60px', width: '60px' }}
                    />
                  </div>
                  <StatCountUpTE
                    data={veterans_pct}
                    fontSize={20}
                    style={{ marginTop: 10, textAlign: 'center' }}
                    color={colors.blue5}
                    title="Veterans"
                    suffix="%"
                  />
                </Col>
              )}
              {!isMobile && (
                <Col span={12}>
                  <Row style={{ margin: '0 -8px' }}>
                    <div className="te-callout-label">Gender Distribution</div>
                    <Col span={12}>
                      <SingleValueCircle
                        label="Males"
                        value={gender_males_pct}
                        color={colors.blue2}
                      />
                    </Col>
                    <Col span={12}>
                      <SingleValueCircle
                        label="Females"
                        value={
                          gender_males_pct !== null ? 100 - gender_males_pct : 0
                        }
                        color={colors.orange2}
                      />
                    </Col>
                    <Col
                      span={24}
                      className="te-insight"
                      style={{ marginTop: 40, padding: 10 }}
                    >
                      {`This population has
                    ${
                      gender_males_pct > 40 && gender_males_pct < 60
                        ? 'slightly'
                        : 'significantly'
                    }
                    more ${gender_males_pct > 50 ? 'males' : 'females'}
                    than ${gender_males_pct > 50 ? 'females' : 'males'}.`}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="te-card-content">
            <Meta title="Geographic Location" />
            <Row style={{ margin: '15px 0' }}>
              <Col xs={24} md={6} style={{ paddingLeft: 0 }}>
                {!isMobile && (
                  <Skeleton loading={loading} paragraph={{ rows: 8 }}>
                    <div className="click-list" style={{ minHeight: 300 }}>
                      {topStates.map((state, index) => {
                        const selected =
                          selectedStates &&
                          selectedStates.includes(get(state, 'key'))

                        return (
                          <div
                            key={`${state}${index}`}
                            className="click-list-item"
                            onClick={() => onStateClick(get(state, 'key'))}
                          >
                            <div>
                              <div style={{ float: 'left' }}>
                                <span
                                  style={{
                                    color: selected ? 'red' : 'green',
                                    width: '15px',
                                  }}
                                >
                                  <Icon
                                    type={
                                      selected ? 'minus-circle' : 'plus-circle'
                                    }
                                  />
                                </span>
                                <Text style={{ marginLeft: 10 }}>
                                  {capitalize(get(state, 'key', ''))}
                                </Text>
                              </div>
                              <div style={{ float: 'right' }}>
                                <Text strong>{`${(
                                  (get(state, 'doc_count', 0) * 100) /
                                  total_matches
                                ).toFixed(1)}%`}</Text>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </Skeleton>
                )}
                <MapBubbleLegend />
                <HeatMapLegend />
              </Col>
              <Col
                xs={24}
                md={18}
                className="shadow-light"
                style={{ padding: 0 }}
              >
                {!loading ? (
                  <HeatMapChart
                    data={map_locations}
                    height={isMobile ? 200 : 500}
                    width="100%"
                    zoom={get(suppliedFilters, 'location.geoData') ? 6 : 3.5}
                    latitude={get(
                      suppliedFilters,
                      'location.geoData.lat',
                      36.83591384968037,
                    )}
                    longitude={get(
                      suppliedFilters,
                      'location.geoData.lng',
                      -97.54197148859241,
                    )}
                    mapSelectionEnabled
                    handleSelection={handleGeoSelection}
                    filtersApplied={geoFilterApplied}
                  />
                ) : (
                  <div>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_BUCKET}${IMAGES.blankMap}`}
                      alt="Blank Map"
                      style={{ height: isMobile ? 200 : 500 }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AnalyticsTab
