import React, { useState } from 'react'

import { Icon, Tooltip } from 'antd'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import colors from '../../../hocs/colors'
import DataDownloadForm from '../../../routes/Authenticated/CrowdSegment/components/DataDownloadForm'
import { isPublic } from '../../../utils/routing/routes'

const CandidateDownload = props => {
  const downloading = get(props, 'downloading')
  const requestingDownload = get(props, 'requestingDownload')
  const downloadFile = get(props, 'downloadFile')
  const teDownloadCredits = get(props, 'teDownloadCredits')
  const totalMatches = get(props, 'totalMatches')
  const styleOverride = get(props, 'styleOverride')
  const allowSizeAdjustment = get(props, 'allowSizeAdjustment', true)
  const selectedCandidateIds = get(props, 'selectedCandidateIds')
  const setPublicSearchCTAVisible = get(props, 'setPublicSearchCTAVisible')
  const [dataDownloadFormVisible, setDataDownloadFormVisible] = useState(false)

  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const toggleDataDownloadForm = () =>
    setDataDownloadFormVisible(!dataDownloadFormVisible)

  const handleClick = () => {
    if (isPublicRoute) {
      setPublicSearchCTAVisible(true)
    } else {
      toggleDataDownloadForm()
    }
  }

  const downloadIcon = (
    <>
      <DataDownloadForm
        downloadFile={downloadFile}
        totalMatches={totalMatches}
        teDownloadCredits={teDownloadCredits}
        toggleModal={toggleDataDownloadForm}
        visible={dataDownloadFormVisible}
        allowSizeAdjustment={allowSizeAdjustment}
        selectedCandidateIds={selectedCandidateIds}
        requestingDownload={requestingDownload}
      />
      <Tooltip title="CSV Download">
        <Icon
          type="download"
          style={{
            ...{
              color: colors.primary,
              cursor: 'pointer',
              float: 'right',
              fontSize: 40,
              margin: 12,
              width: 40,
            },
            ...styleOverride,
          }}
          onClick={handleClick}
        />
      </Tooltip>
    </>
  )
  return (
    <>
      {downloading ? (
        <Icon
          type="sync"
          spin
          style={{
            ...{
              color: colors.primary,
              float: 'right',
              fontSize: 40,
              marginBottom: 10,
              width: 50,
            },
            ...styleOverride,
          }}
        />
      ) : (
        downloadIcon
      )}
    </>
  )
}

export default CandidateDownload
