import React from 'react'

import Spinner from 'react-spinkit'

const WholePageLoader = () => (
  <div className="page-spinner">
    <Spinner
      style={{ transform: 'scale(3)' }}
      fadeIn="none"
      name="ball-clip-rotate-multiple"
    />
  </div>
)

export default WholePageLoader
