import React from 'react'

import { Modal } from 'antd'
import HubspotForm from 'react-hubspot-form'

import { useCrowdSegmentDispatch } from '../../../Authenticated/CrowdSegment/crowd-context'

const PublicContactFormModal = props => {
  const { visible } = props
  const { setPublicContactFormVisible } = useCrowdSegmentDispatch()
  const handleCancel = () => {
    setPublicContactFormVisible(false)
  }
  return (
    <Modal
      title="Unlock this Crowd with your info!"
      visible={visible}
      closable={false}
      footer={null}
    >
      <HubspotForm
        portalId="19969389"
        formId="2622e8ee-e14e-4f15-8bab-7b41f6eab42f"
        loading={<div>Loading...</div>}
        onSubmit={() => handleCancel()}
        // TODO: Need to make the modal close on submit
      />
    </Modal>
  )
}

export default PublicContactFormModal
