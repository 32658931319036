import React from 'react'

import { Result, Table, Icon, Button } from 'antd'
import Meta from 'antd/lib/card/Meta'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import { useLocation } from 'react-router'

import CompanyLogo from '../../../../components/media/CompanyLogo'
import CompanyLinksColumn from '../../../../components/TableColumns/CompanyLinksColumn'
import CompanySalaryColumn from '../../../../components/TableColumns/CompanySalaryColumn'
import NotFoundColumn from '../../../../components/TableColumns/NotFoundColumn'
import useModalManagement from '../../../../hooks/common/useModalManagement/index'
import { useSelectedCompany } from '../../../../hooks/data/selectedCompany/useSelectedCompany'
import numberConverter from '../../../../utils/numberConverter'
import { roundUpToNearestDecimal } from '../../../../utils/numbers/index'
import { isPublic } from '../../../../utils/routing/routes'
import { capitalize } from '../../../../utils/stringManipulation'
import { useCrowdSegmentDispatch } from '../crowd-context'
import { searchSettings } from '../static/searchSettings'

import CompanyDrawer from './components/CompanyDrawer'

const CompaniesTab = ({
  data,
  error,
  loading,
  openPaymentModal,
  page,
  permissions,
  selectedCompanies,
  selectedSkills,
  setPage,
  toggleFilter,
  totalMatches,
}) => {
  const {
    visible,
    closeModal: closeDrawer,
    openModal: openDrawer,
  } = useModalManagement(false)
  const { setPublicSearchCTAVisible } = useCrowdSegmentDispatch()
  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const { getSelectedCompanyDetails, selectedCompany } = useSelectedCompany({
    openDrawer,
  })

  const isSelected = companyName => {
    const found =
      findIndex(selectedCompanies, company => company === companyName) !== -1

    return found
  }

  const addCompanyClick = company => {
    toggleFilter('companies', company)
  }

  const toggleSkill = skillName =>
    toggleFilter(searchSettings.required_skills.name, skillName)

  const companyNameClick = (companyName, id) => {
    if (!id) return
    if (isPublicRoute) {
      setPublicSearchCTAVisible(true)
    } else {
      getSelectedCompanyDetails(companyName)
    }
  }

  const getColumns = () => {
    return [
      {
        dataIndex: 'id',
        render: (val, row) => {
          const companyName = get(row, 'company_name')

          return isSelected(companyName) ? (
            <Icon
              style={{ color: 'red' }}
              type="minus-circle"
              onClick={() => addCompanyClick(companyName)}
            />
          ) : (
            <Icon
              style={{ color: 'green' }}
              type="plus-circle"
              onClick={() => addCompanyClick(companyName)}
            />
          )
        },
        title: '',
      },
      {
        dataIndex: 'company_name',
        render: (val, row) => {
          const id = get(row, 'id')
          const website = get(row, 'website')
          const industry = capitalize(get(row, 'industry'))

          return val ? (
            <>
              <span
                onClick={() => companyNameClick(val)}
                style={id ? { cursor: 'pointer' } : {}}
              >
                <CompanyLogo size={60} id={id} />
              </span>
              <div
                style={{ fontWeight: 'bold' }}
                className={id ? `person-link` : ''}
                onClick={() => companyNameClick(val, id)}
              >
                {val ? val : ''}
              </div>
              <a
                style={{ textDecoration: 'none' }}
                href={`https://www.${website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
              <div className="text-muted">{industry}</div>
            </>
          ) : (
            <NotFoundColumn centered={false} />
          )
        },
        title: 'Company',
        width: 280,
      },
      {
        align: 'center',
        dataIndex: 'company_size',
        key: 'company_size',
        render: (val, row) => {
          const sbSize = get(row, 'size_sb')
          if (!!val || !!sbSize) {
            return (
              <div>
                <div>
                  {get(row, 'size_sb') &&
                    `${numberConverter(get(row, 'size_sb'))} on CrowdSegment`}
                </div>
                {val && <div className="text-muted">{val}</div>}
              </div>
            )
          } else {
            return <NotFoundColumn centered={true} />
          }
        },
        title: 'Est. Employees',
      },
      {
        align: 'center',
        dataIndex: 'growth_rate',
        key: 'growth_rate',
        render: val =>
          val === null || val === undefined || val === '' || isNaN(val) ? (
            <NotFoundColumn centered={true} />
          ) : (
            <div>{roundUpToNearestDecimal(val, 2)}%</div>
          ),
        title: () => (
          <div>
            <span className="ant-table-column-title">3-Year Growth Rate</span>
          </div>
        ),
        width: 160,
      },
      {
        align: 'center',
        dataIndex: 'average_salary',
        render: (val, row) => <CompanySalaryColumn val={val} row={row} />,
        title: 'Avg. Salary & Tenure',
      },
      {
        align: 'center',
        dataIndex: 'company_name',
        render: val => {
          return <CompanyLinksColumn val={val} />
        },
        title: 'Links',
      },
    ]
  }

  if (error) {
    return (
      <Result
        status="warning"
        title="Error Encountered"
        subTitle="Check back later, or try another search. "
      />
    )
  }

  return (
    <>
      <CompanyDrawer
        closeDrawer={closeDrawer}
        visible={visible}
        permissions={permissions}
        openPaymentModal={openPaymentModal}
        selectedCompany={selectedCompany}
        toggleSkill={toggleSkill}
        selectedSkills={selectedSkills}
      />
      <div className="results-table">
        <Meta
          title="Matched Companies"
          description="Employer insights from this crowd."
          style={{ marginLeft: 16, marginRight: 16 }}
        />
        <Table
          indentSize={15}
          loading={loading}
          columns={getColumns()}
          dataSource={data.filter(record => !!record.company_name)}
          pagination={
            isPublicRoute ? false : { current: page, onChange: setPage }
          }
          rowKey={record =>
            `${get(record, 'company_name')}${get(record, 'id')}`
          }
        />
        {isPublicRoute && (
          <div
            style={{
              background: '#d4d4de45',
              borderRadius: 10,
              margin: '50px 20px',
              padding: '40px 20px',
              textAlign: 'center',
            }}
          >
            {totalMatches < 5 ? (
              <h4>Sign up today to access the full power of CrowdSegment!</h4>
            ) : (
              <h4>
                Sign up today to access{' '}
                <b>{numberConverter(totalMatches - data.length)}</b> other
                companies in this search!
              </h4>
            )}
            <Button
              type="primary"
              href="/signup"
              size="large"
              style={{ marginTop: 15 }}
            >
              Sign Up Now
            </Button>
            <div className="text-muted" style={{ marginTop: 10 }}>
              Try it for free. No credit card required
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CompaniesTab
