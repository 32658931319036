import React from 'react'

import { Checkbox, Row } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import toString from 'lodash/toString'
import PT from 'prop-types'

import { capitalize } from '../../../../../utils/stringManipulation'

const CheckboxList = props => {
  const {
    metric,
    options,
    selectedFilters,
    toggleExtraFilter,
    toggleAllExtraFilters,
  } = props

  const someSelected =
    selectedFilters.length > 0 && selectedFilters.length < options.length

  const allChecked = selectedFilters.length === options.length

  const onCheckAllChange = e => {
    const nextCheckAll = get(e, 'target.checked')
    const checkedItems = nextCheckAll
      ? cloneDeep(options).map(opt => opt.key)
      : []
    toggleAllExtraFilters(metric, checkedItems)
  }

  return (
    <div style={{ maxHeight: 500, overflowY: 'auto' }}>
      <Row
        style={{
          borderBottom: '0.5px solid',
          marginBottom: 10,
          paddingBottom: 2,
        }}
      >
        <Checkbox
          defaultChecked={false}
          indeterminate={someSelected}
          onChange={onCheckAllChange}
          checked={allChecked}
        >
          Select All
        </Checkbox>
      </Row>
      <Row>
        {options.map((option, index) => {
          const { key } = option
          const checked =
            typeof selectedFilters.find(filt => filt === key) !== 'undefined'

          const handleChange = () => {
            toggleExtraFilter(metric, key)
          }

          return (
            <Checkbox
              key={`${key}${index}`}
              value={key}
              style={{ display: 'block', marginLeft: 0 }}
              onChange={handleChange}
              checked={checked}
            >
              {capitalize(toString(key))}
            </Checkbox>
          )
        })}
      </Row>
    </div>
  )
}

const OptionPT = PT.shape({
  docCount: PT.number,
  id: PT.string,
})

CheckboxList.propTypes = {
  metric: PT.string,
  options: PT.arrayOf(OptionPT).isRequired,
  selectedFilters: PT.func,
  toggleAllExtraFilters: PT.func,
  toggleExtraFilter: PT.func,
}

export default CheckboxList
