import React from 'react'

import { Card, Typography } from 'antd'

const UnauthenticatedCard = props => (
  <Card bordered style={{ borderTop: '4px solid #4e57d3' }}>
    <div style={{ margin: '0px 0px 16px 0px' }}>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {props.title}
      </Typography.Title>
      <Typography.Text level={5} type="secondary" style={{ margin: 0 }}>
        {props.subtitle}
      </Typography.Text>
    </div>
    {props.children}
  </Card>
)

export default UnauthenticatedCard
