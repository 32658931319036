import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Col, Slider, Icon, Tooltip, Spin } from 'antd'
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import PT from 'prop-types'

import { apolloClient } from '../../../../../apolloClient'
import { countQuery } from '../../../../../apolloClient/operations/search/dataQueries'
import usePrevious from '../../../../../hooks/common/usePrevious'
import { extractKeysFromList } from '../../../../../utils/data/utils'
import numberConverter from '../../../../../utils/numberConverter'

/** @typedef {import('antd/lib/slider').SliderMarks} SliderMarks*/

const getValueInSalaryFormat = value => `${Math.round(value / 1000)}k`

const RangeFilters = props => {
  const { metric, onChange, options, selectedFilters, title, info } = props

  const [fetchingCounts, setFetchingCounts] = useState(false)
  const [count, setCount] = useState(null)

  const handleChange = values => {
    onChange(values)
  }

  const prevFilters = usePrevious(selectedFilters)

  const fetchCounts = useCallback(
    async filters => {
      if (isEqual(prevFilters, filters)) {
        return
      }
      if (!filters || !filters.length) {
        setCount(null)

        return
      }
      setFetchingCounts(true)
      try {
        const result = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: countQuery,
          variables: {
            filters: { [metric]: filters },
          },
        })
        const count = get(result, 'data.count')
        setCount(count)
        setFetchingCounts(false)
      } catch (error) {
        console.log(error)
        setFetchingCounts(false)
      }
    },
    [metric, prevFilters],
  )

  const fetch = useCallback(
    filters => {
      fetchCounts(filters)
    },
    [fetchCounts],
  )

  useEffect(() => fetch(selectedFilters), [selectedFilters, fetch])

  /**
   * Selectable options (names) of the facet values
   */
  const selectableOptions = useMemo(() => extractKeysFromList(options), [
    options,
  ])

  const min = head(selectableOptions)
  const max = last(selectableOptions)

  const isMoney = metric => includes(['incomes', 'estSalary', 'salary'], metric)

  /** @type {SliderMarks} */
  const marks = {}

  marks[min] = min > 1000 && isMoney(metric) ? getValueInSalaryFormat(min) : min
  marks[max] =
    max > 1000 && isMoney(metric)
      ? `${getValueInSalaryFormat(max)}+`
      : `${max}+`

  selectedFilters.forEach(rangeValue => {
    marks[rangeValue] =
      rangeValue > 1000 && isMoney(metric)
        ? getValueInSalaryFormat(rangeValue)
        : rangeValue
  })

  return (
    <Col span={24} style={{ paddingBottom: '20px' }}>
      <div
        style={{
          margin: '0 auto',
          marginBottom: 10,
          // position: 'relative',
          width: '95%',
        }}
      >
        <span
          style={{
            fontSize: 12,
            fontWeight: 'bold',
          }}
        >
          {title}{' '}
          {info && (
            <Tooltip placement="top" title={info}>
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </span>
        <span
          style={{
            float: 'right',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          {fetchingCounts ? (
            <Spin size="small" />
          ) : (
            count > 0 && numberConverter(count)
          )}
        </span>
        <Slider
          range
          marks={marks}
          min={min}
          max={max}
          value={selectedFilters.length ? selectedFilters : [min, max]}
          step={max - min > 1000 ? 1000 : 1}
          defaultValue={selectedFilters.length ? selectedFilters : [min, max]}
          onChange={handleChange}
          style={{ margin: '5px 15px' }}
        />
      </div>
    </Col>
  )
}

const OptionPT = PT.shape({
  docCount: PT.number,
  id: PT.number,
})

RangeFilters.propTypes = {
  info: PT.string,
  metric: PT.string,
  onChange: PT.func,
  options: PT.arrayOf(OptionPT).isRequired,
  selectedFilters: PT.array,
  title: PT.string,
}

export default RangeFilters
