import { useEffect } from 'react'

const useOnClickOutsideElement = (elementRef, handler) => {
  useEffect(() => {
    const listener = event => {
      if (
        !elementRef.current ||
        !elementRef.current ||
        !elementRef.current.contains ||
        elementRef.current.contains(event.target)
      ) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [elementRef, handler])
}

export default useOnClickOutsideElement
