import React, { useRef, useState } from 'react'

import { Typography, Row, Col } from 'antd'
import get from 'lodash/get'

import { useAddPayment } from '../../hooks/data/payments/useAddPayment'
import { useTotalChargeOnAddPayment } from '../../hooks/data/payments/useTotalChargeOnAddPayment'
import feedback from '../../utils/feedback'
import StripeCard from '../stripeCard'

import ChargeSummary from './components/ChargeSummary'
import CouponCodeEntry from './components/CouponCodeEntry'
import NonAdminMessage from './components/NonAdminMessage'
import PaymentFormFooter from './components/PaymentFormFooter'
const { Paragraph, Title } = Typography

const AddPaymentForm = props => {
  const message = get(props, 'message')
  const title = get(props, 'title')
  const afterUpdate = get(props, 'afterUpdate')
  const client = get(props, 'client')
  const isAdmin = get(props, 'isAdmin')
  const [stripeToken, setStripeToken] = useState(null)
  const [couponError, setCouponError] = useState(false)
  const [couponCode, setCouponCode] = useState(null)
  const [finalCouponCode, setFinalCouponCode] = useState(null)

  const componentIsMounted = useRef(true)

  const { addPayment, addingPayment } = useAddPayment({
    client,
    onCompleted: () => {
      componentIsMounted.current = false
      feedback.success({ content: 'Payment updated successfully' })
      afterUpdate()
    },
  })

  const afterTotalChargeCompleted = data => {
    if (componentIsMounted.current === true) {
      setCouponError(false)
      if (couponCode && get(data, 'discount')) {
        setFinalCouponCode(couponCode)
      } else if (
        couponCode &&
        !get(data, 'pullTotalChargeOnAddPayment.discount')
      ) {
        setCouponError(true)
        setFinalCouponCode(null)
      } else setFinalCouponCode(null)
    }
  }

  const {
    getTotal,
    loadingTotalCharge,
    chargeData,
  } = useTotalChargeOnAddPayment({
    couponCode,
    onCompleted: afterTotalChargeCompleted,
  })

  const onSubmit = () => {
    addPayment({
      variables: {
        couponCode,
        stripeCardToken: stripeToken,
      },
    })
  }

  return (
    <div>
      <div style={{ padding: '0 22px' }}>
        <Title level={3}>
          {title ? title : 'Please update your payment information'}
        </Title>
        {isAdmin ? (
          <>
            {' '}
            <Paragraph type="secondary">{message}</Paragraph>
            <StripeCard
              setStripeToken={setStripeToken}
              clientName={get(client, 'name')}
            />
            <Row>
              <CouponCodeEntry
                couponError={couponError}
                onSearch={value => {
                  if (componentIsMounted.current === true) {
                    setCouponCode(value)
                  }
                  getTotal({ couponCode: value })
                }}
              />
              <Col span={12}>
                <ChargeSummary
                  chargeData={chargeData}
                  showDiscount={!!finalCouponCode}
                  loading={loadingTotalCharge}
                />
              </Col>
            </Row>
            <PaymentFormFooter
              onSubmit={onSubmit}
              stripeToken={stripeToken}
              addingPayment={addingPayment}
            />
          </>
        ) : (
          <NonAdminMessage />
        )}
      </div>
    </div>
  )
}

export default AddPaymentForm
