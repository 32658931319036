import React from 'react'

import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

import CenteredCard from '../../../../components/layout/CenteredCard/CenteredCard'
import UpdatePaymentForm from '../../../../components/payment/UpdatePaymentForm'
import { useUserForUpdatePayment } from '../../../../hooks/data/loggedInUser/useUserForUpdatePayment'
import { pickWithInitialState } from '../../../../utils/data/utils'
import { AUTHENTICATED_ROUTES } from '../../../../utils/routing/routes'
import AuthenticatedLayout from '../AuthenticatedLayout/index'

const UpdatePayment = () => {
  const history = useHistory()
  const { userData, loadingUserProfile } = useUserForUpdatePayment()
  const { isAdmin, subscription, client } = pickWithInitialState(userData, [
    ['isAdmin', false],
    ['subscription', null],
    ['client', null],
  ])

  const needsUpdatedPayment = get(subscription, 'needsUpdatedPayment')

  const permissions = {
    isAdmin,
    needsUpdatedPayment,
  }

  const toCrowdSegment = () => {
    history.push(AUTHENTICATED_ROUTES.CROWD_SEGMENT)
  }

  return loadingUserProfile ? null : (
    <AuthenticatedLayout>
      <CenteredCard>
        <UpdatePaymentForm
          title="Please update your payment information"
          message="There was a problem processing your payment."
          afterUpdate={toCrowdSegment}
          clientName={get(client, 'name')}
          isAdmin={isAdmin}
          permissions={permissions}
        />
      </CenteredCard>
    </AuthenticatedLayout>
  )
}

export default UpdatePayment
