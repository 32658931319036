import gql from 'graphql-tag'

export const createSavedSearchMutation = gql`
  mutation CreateSavedSearch(
    $name: String!
    $requisitionId: String!
    $searchParams: JSON!
    $description: String
  ) {
    createSavedSearch(
      name: $name
      requisitionId: $requisitionId
      searchParams: $searchParams
      description: $description
    ) {
      id
      name
    }
  }
`
