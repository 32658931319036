import { useReducer } from 'react'

import { useApolloClient } from '@apollo/client'
import get from 'lodash/get'

import { getValidatedEmailQuery } from '../../../components/TableColumns/gqlQueries'
import feedback from '../../../utils/feedback'

const initialState = {
  data: null,
  error: null,
  loading: false,
  notFound: false,
}

function reducer(state, newState) {
  return {
    ...state,
    ...newState,
  }
}

export const useGetValidatedEmail = onCompleted => {
  const client = useApolloClient()
  const [state, dispatch] = useReducer(reducer, initialState)

  const afterQuery = candidateContactInfo => {
    const { validatedEmail, personalEmail } = candidateContactInfo

    if (onCompleted) {
      onCompleted(candidateContactInfo)
    }
    if (!validatedEmail && !personalEmail) {
      dispatch({ notFound: true })
    }
  }

  const getValidatedEmail = async variables => {
    try {
      dispatch({ loading: true })
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: getValidatedEmailQuery,
        variables,
      })
      const candidateContactInfo = get(
        response,
        'data.getValidatedEmail.contactInfo',
      )

      afterQuery(candidateContactInfo)
      dispatch({ loading: false })
    } catch (error) {
      feedback.error({ title: error.message })
      dispatch({ loading: false })
    }
  }

  return {
    errorGettingValidatedEmail: state.error,
    getValidatedEmail,
    gettingValidatedEmail: state.loading,
    notFound: state.notFound,
  }
}
