import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { pullPricesForAdditionalCreditsQuery } from '../../../../apolloClient/operations/payments/pullPricesForAdditionalCredits'

export const useGetCreditPrices = () => {
  const { loading, data, error } = useQuery(pullPricesForAdditionalCreditsQuery)
  const prices = get(data, 'pullPricesForAdditionalCredits')
  return { error, loading, prices }
}

export default useGetCreditPrices
