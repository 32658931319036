import React from 'react'

import { Checkbox, Col, Row } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import remove from 'lodash/remove'

const genderOptions = ['m', 'f']

const genderNames = {
  f: 'Female',
  m: 'Male',
}

const GenderSelect = ({ selectedGenders, setFilterValue }) => {
  const checkOne = value => {
    let updatedGenders = selectedGenders ? cloneDeep(selectedGenders) : []

    if (findIndex(selectedGenders, e => e === value) === -1) {
      updatedGenders.push(value)
    } else {
      remove(updatedGenders, e => e === value)
    }

    setFilterValue('gender', updatedGenders)
  }

  return (
    <Col lg={24} md={24}>
      <div
        style={{
          margin: '0 auto',
          marginBottom: 10,
          position: 'relative',
          width: '95%',
        }}
      >
        <span style={{ fontSize: 12, fontWeight: 'bold' }}>Gender</span>
        <Row style={{ marginBottom: 0, paddingLeft: 10 }}>
          {genderOptions.map(option => {
            const checked = findIndex(selectedGenders, e => e === option) !== -1

            const handleChange = e => {
              checkOne(option, e.target.checked)
            }
            return (
              <Checkbox
                key={option}
                value={option}
                onChange={handleChange}
                checked={checked}
              >
                {genderNames[option]}
              </Checkbox>
            )
          })}
        </Row>
      </div>
    </Col>
  )
}

export default GenderSelect
