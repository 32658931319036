import React from 'react'

import { Typography, Button, Popconfirm } from 'antd'
import CountUp from 'react-countup'

import { usePurchaseCredits } from '../../../../../../../../hooks/data/crowdSegmentClient/usePurchaseCredits'
import { colors } from '../../../../../../../../theme'
import numberConverter from '../../../../../../../../utils/numberConverter'

const { Title } = Typography

const BuyCreditCard = props => {
  const { price, credits, style } = props
  const { purchaseCredits } = usePurchaseCredits()
  const onAddCredits = () => {
    purchaseCredits({ variables: { credits } })
  }

  return (
    <div className="pricing-card" style={style}>
      <div
        style={{
          background: colors.accent,
          borderRadius: '9px 9px 0 0',
          padding: '15px',
          textAlign: 'center',
        }}
      >
        <Title style={{ color: 'white', marginBottom: 0 }}>
          <CountUp start={0} end={credits} separator="," delay={0.25} />
        </Title>
        <Title style={{ color: 'white', margin: 0 }} level={4}>
          Download Credits
        </Title>
      </div>
      <div style={{ padding: 20, textAlign: 'center' }}>
        <Title style={{ marginBottom: 0 }}>${numberConverter(price)}</Title>
        <div>${(price / credits).toFixed(2)} per credit</div>
      </div>
      <div className="text-center">
        <Popconfirm
          onConfirm={onAddCredits}
          title={`Are you sure you want to add ${credits} download credits with the purchase of $${price}?`}
          okText="Yes"
          placement="rightBottom"
        >
          <Button type="primary" size="large" style={{ marginBottom: 20 }}>
            Add Credits
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

export default BuyCreditCard
