import React, { useEffect, useRef, useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import { Button, Icon, Input, List, Tooltip, Typography } from 'antd'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { Link } from 'react-router-dom'

import colors from '../../../../../hocs/colors'
import useOnClickOutsideElement from '../../../../../hooks/common/useOnClickOutsideElement'
import { encryptStr } from '../../../../../utils/crypto/cryptoUtils'
import { createQueryStringForTalentSearch } from '../../../../../utils/data/utils'
import { copyToClipboard } from '../../../../../utils/general'
import { getPublicSearchLink } from '../../../../../utils/routing'
import { AUTHENTICATED_ROUTES } from '../../../../../utils/routing/routes'

const updateSearchMutation = gql`
  mutation ModifySavedSearch($name: String, $description: String, $id: ID!) {
    modifySavedSearch(name: $name, description: $description, id: $id) {
      id
      name
      description
    }
  }
`

const { Text, Paragraph } = Typography

const EditableSavedSearch = props => {
  const { search } = props

  const initialValues = {
    description: search.description,
    name: search.name,
  }

  const [savedSearchValues, setSavedSearchValues] = useState(initialValues)
  const [searchFields, setSearchFields] = useState(initialValues)
  const [editing, setEditing] = useState(false)

  const createPublicLink = async e => {
    e.preventDefault()
    const searchLink = getPublicSearchLink(search.id)
    await copyToClipboard({ value: searchLink })
  }

  const [
    saveSearch,
    { error, data, called, loading: savingSearch },
  ] = useMutation(updateSearchMutation, {
    onCompleted: data => {
      const savedValues = {
        description: get(data, 'modifySavedSearch.description'),
        name: get(data, 'modifySavedSearch.name'),
      }

      setSavedSearchValues(savedValues)
    },
  })

  const handleSaveSearch = () => {
    saveSearch({
      variables: {
        id: search.id,
        ...searchFields,
      },
    })
  }

  useEffect(() => {
    if (called && !error && !!data) {
      const newValues = {
        description: get(data, 'modifyTESearch.description'),
        name: get(data, 'modifyTESearch.name'),
      }
      setSavedSearchValues(newValues)
      setEditing(false)
    }
  }, [called, data, error])

  const handleSearchFieldsEdit = e => {
    const { value, id } = e.target
    setSearchFields({
      ...searchFields,
      [id]: value,
    })
  }

  const resetSearchFields = () => {
    setSearchFields(initialValues)
  }

  const fieldsEdited = !isEqual(savedSearchValues, searchFields)
  const listItemRef = useRef('')
  useOnClickOutsideElement(listItemRef, () => {
    setEditing(false)
  })

  return (
    <List.Item
      key={search.id}
      actions={[
        <Link
          key="internal-search"
          to={`${
            AUTHENTICATED_ROUTES.CROWD_SEGMENT
          }/?encryptedParams=${encryptStr(
            createQueryStringForTalentSearch(search.searchParams),
          )}`}
          style={{ color: '#5099de', cursor: 'pointer' }}
        >
          <Icon type="search" /> Perform Search
        </Link>,
        <Tooltip
          title="Generate public link"
          key="public-link"
          style={{ color: '#5099de', cursor: 'pointer' }}
        >
          <Link
            to=""
            onClick={e => createPublicLink(e)}
            style={{ color: '#5099de', cursor: 'pointer' }}
          >
            <Icon type="link" /> Public Link
          </Link>
        </Tooltip>,
      ]}
    >
      <div
        ref={listItemRef}
        onClick={() => setEditing(true)}
        className="saved-search-list-item"
        style={{ width: '100%' }}
      >
        <List.Item.Meta
          title={
            editing ? (
              <Input
                autoFocus={true}
                id="name"
                value={get(searchFields, 'name')}
                onChange={handleSearchFieldsEdit}
              />
            ) : (
              <>
                <Text strong>{searchFields.name}</Text>
                <Icon
                  type="edit"
                  onClick={() => setEditing(true)}
                  style={{ marginLeft: 6 }}
                />
              </>
            )
          }
          description={
            searchFields.description && !editing ? (
              <Paragraph id="description" ellipsis style={{ maxWidth: 275 }}>
                {searchFields.description}
              </Paragraph>
            ) : editing ? (
              <>
                <Input.TextArea
                  autoFocus={false}
                  id="description"
                  value={get(searchFields, 'description')}
                  onChange={handleSearchFieldsEdit}
                  placeholder={
                    searchFields.description
                      ? searchFields.description
                      : 'Description'
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '4px',
                  }}
                >
                  <Button
                    disabled={!fieldsEdited}
                    icon="save"
                    style={fieldsEdited ? { color: colors.primary } : null}
                    onClick={handleSaveSearch}
                    loading={savingSearch}
                    key="save-edits"
                  >
                    Save
                  </Button>
                  <Button
                    icon="close"
                    style={{ color: colors.primary }}
                    onClick={() => {
                      resetSearchFields()
                      setEditing(false)
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : null
          }
        />
      </div>
    </List.Item>
  )
}

export default EditableSavedSearch
