import { useLazyQuery } from '@apollo/client'
import get from 'lodash/get'

import { getCandidatesDataByIdQuery } from '../../../apolloClient/operations/lookup/getCandidatesDataByIdQuery'

export const useGetCandidatesById = () => {
  const [getCandidatesDataById, { data, loading }] = useLazyQuery(
    getCandidatesDataByIdQuery,
    {
      fetchPolicy: 'network-only',
    },
  )
  const candidatesData = get(data, 'getCandidatesDataById', [])

  return {
    candidatesData,
    getCandidatesDataById,
    loadingCandidateDetails: loading,
  }
}
