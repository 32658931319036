import gql from 'graphql-tag'

export const createJobRequisitionMutation = gql`
  mutation CreateJobRequisition($name: String!) {
    createJobRequisition(name: $name) {
      id
      name
      savedSearches {
        id
      }
      candidateData {
        id
      }
    }
  }
`
