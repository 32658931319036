import React from 'react'

import { Typography, Select, Spin } from 'antd'
import gql from 'graphql-tag'
import { startCase } from 'lodash'
import debounce from 'lodash/debounce'

import { getNonEmptyFiltersCount, TOOLTIP_MSGS } from '../..'
import colors from '../../../../../hocs/colors'
import useFetchAutoSuggestions from '../../../../../hooks/data/search/useFetchAutoSuggestions'
import { filterNames } from '../../../../../utils/constants/filterNames'
import { stringSeparator } from '../../../../../utils/constants/queryParams'
import { capitalize } from '../../../../../utils/stringManipulation'
import { findFilterByDisplayName } from '../../static/searchSettings'

const { Option, OptGroup } = Select
const { Paragraph } = Typography
const openTextSearchQuery = gql`
  query OpenTextSearch($prefix: String!) {
    openSearchAutocompleteSuggestions(prefix: $prefix)
  }
`
const filterIcons = {
  companies: 'fal fa-building',
  // certifications: 'fal fa-plus-circle', //not showing up in autocomplete
  industries: 'fal fa-industry-alt',
  job_category: 'fal fa-pencil-paintbrush',
  job_titles: 'fal fa-user-tie',
  majors: 'fal fa-graduation-cap',
  required_skills: 'fal fa-tools',
  skills: 'fal fa-tools',
  // specialties: 'fal fa-shopping-cart',
  universities: 'fal fa-university',
}

const searchableCategories = [
  filterNames.jobTitles,
  filterNames.jobCategory,
  filterNames.skills,
  filterNames.companies,
  // filterNames.certifications, //not showing up in autocomplete
  filterNames.industries,
  // filterNames.specialties,
  filterNames.universities,
  filterNames.majors,
  filterNames.requiredSkills,
]

const OpenTextSearch = props => {
  const {
    filtersToApply,
    toggleFilter,
    totalCount,
    // submittable,
    fetchingTotal,
    // onSubmit,
  } = props
  const {
    autoSuggestions,
    fetchAutoSuggestions,
    fetchingAutoSuggestions,
  } = useFetchAutoSuggestions({
    queryName: 'openSearchAutocompleteSuggestions',
    suggestionsQuery: openTextSearchQuery,
  })

  const filteredAutoSuggestions = autoSuggestions
    .filter(suggestion => searchableCategories.includes(suggestion.title))
    .sort(
      // sort categories to match order of the "searchableCategories" list
      (a, b) => {
        return (
          searchableCategories.indexOf(a.title) -
          searchableCategories.indexOf(b.title)
        )
      },
    )

  const fetchAutoSuggestionsWithDebounce = debounce(fetchAutoSuggestions, 500)

  const onSelect = (val, option) => {
    const { group } = option.props
    const category =
      group === filterNames.skills ? filterNames.requiredSkills : group
    toggleFilter(category, val.label)
  }

  const handleSearch = value => {
    fetchAutoSuggestionsWithDebounce(value)
  }

  const createLabeledValueForCategory = (category, values) => {
    if (Array.isArray(values)) {
      return values.map(val => {
        return {
          key: `${stringSeparator}${category}${stringSeparator}${val}`,
          label: (
            <span>
              <i
                className={filterIcons[category]}
                style={{ color: colors.primary, paddingRight: 4 }}
              />{' '}
              {capitalize(val)}
            </span>
          ),
        }
      })
    }
  }

  const getSelectedFiltersToDisplay = filters => {
    // filters: {"job_titles":["Founder"],"required_skills":["Fundraising"]}
    const values = Object.keys(filters).reduce((acc, category) => {
      if (searchableCategories.includes(category)) {
        const val = filters[category]
        let displayVal = createLabeledValueForCategory(category, val)
        acc.push(displayVal)
      }
      return acc
    }, [])
    // @ts-ignore
    return values ? values.flat(1) : []
  }

  const getFilterNameFromDisplayName = displayname => {
    const foundFilter = findFilterByDisplayName(displayname)
    return foundFilter ? foundFilter.name : displayname
  }

  const findFilterToApplyFromDeselectedDisplay = displayValue => {
    const arrayFromValue = displayValue.split(stringSeparator)
    const { categoryDisplay, val } = {
      categoryDisplay: arrayFromValue[1],
      val: arrayFromValue[2],
    }
    const categoryName = getFilterNameFromDisplayName(categoryDisplay)
    return { category: categoryName, val }
  }

  const onDeselect = value => {
    const { key } = value
    const { category, val } = findFilterToApplyFromDeselectedDisplay(key)
    toggleFilter(category, val)
  }

  const numberOfFiltersWithValue = getNonEmptyFiltersCount(
    filtersToApply,
    searchableCategories,
  )

  return (
    <>
      <Select
        showSearch
        className="crowd-search-select"
        placeholder="Start typing to build your crowd"
        showArrow={false}
        style={{ width: '100%' }}
        onSelect={onSelect}
        labelInValue
        onDeselect={onDeselect}
        onSearch={handleSearch}
        mode="multiple"
        size="large"
        notFoundContent={fetchingAutoSuggestions ? <Spin size="small" /> : null}
        value={getSelectedFiltersToDisplay(filtersToApply)}
        // @ts-ignore
        getPopupContainer={trigger => trigger.parentNode}
      >
        {filteredAutoSuggestions.length &&
          filteredAutoSuggestions.map(suggestion => {
            const options = suggestion.children
            return (
              <OptGroup
                label={
                  <span className="search-category">
                    <i
                      className={filterIcons[suggestion.title]}
                      style={{ fontSize: 14, paddingRight: 10 }}
                    />
                    {startCase(suggestion.title)}
                  </span>
                }
                key={suggestion.title}
              >
                {options.map((
                  /** @type {{ title: String; value: any; }} */ option,
                ) => {
                  return (
                    <Option
                      key={`${suggestion.title}${stringSeparator}${option.title}`}
                      // @ts-ignore
                      group={suggestion.title}
                    >
                      {capitalize(option.title)}
                    </Option>
                  )
                })}
              </OptGroup>
            )
          })}
      </Select>
      {numberOfFiltersWithValue > 0 &&
        totalCount > parseInt(process.env.REACT_APP_MAX_SEARCH_SIZE) &&
        !fetchingTotal && (
          <Paragraph
            type="danger"
            style={{
              fontSize: 13,
              marginTop: 5,
              textAlign: 'right',
            }}
          >
            {TOOLTIP_MSGS.tooBroadOpenText}
          </Paragraph>
        )}
    </>
  )
}

export default OpenTextSearch
