import React, { useEffect, useState } from 'react'

import {
  Col,
  Row,
  Button,
  List,
  Drawer,
  Tooltip,
  Dropdown,
  Menu,
  Empty,
  Icon,
} from 'antd'
import Meta from 'antd/lib/card/Meta'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import { Link } from 'react-router-dom'

import withTeAuth from '../../../hocs/withTeAuth'
import usePrevious from '../../../hooks/common/usePrevious'
import { useDeleteJobRequisition } from '../../../hooks/data/requisitions/useDeleteJobRequisition'
import { useRequisitions } from '../../../hooks/data/requisitions/useRequisitions'
import { AUTHENTICATED_ROUTES } from '../../../utils/routing/routes'
import { fullName } from '../../../utils/stringManipulation'
import AuthenticatedLayout from '../components/AuthenticatedLayout/index'
import CandidateView from '../CrowdSegment/components/CandidateView'
import NewRequisitionModal from '../CrowdSegment/components/NewRequisitionModal'

import ManageMyRequisitionsContainer from './components/ManageMyRequisitionsContainer'
import RequisitionInfo from './components/RequisitionInfo'

export const requisitionFilterNames = {
  allCrowds: 'All Crowds',
  byUser: 'By User',
  myCrowds: 'My Crowds',
}

const Requisitions = props => {
  const { permissions, updateUser, clientName, teDownloadCredits } = props
  const [
    candidateDetailDrawerVisible,
    setCandidateDetailsDrawerShown,
  ] = useState(false)

  const [modalShown, setModalShown] = useState(false)
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [filteredRequisitions, setFilteredRequisitions] = useState([])
  const [teamMemberIdToFilterBy, setTeamMemberIdToFilterBy] = useState(null)
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedRequisitionFilter, setSelectedRequisitionFilter] = useState(
    requisitionFilterNames.myCrowds,
  )
  const { jobRequisitions, loadingRequisitions, userId } = useRequisitions()
  const { deleteJobRequisition } = useDeleteJobRequisition()

  const getTeamMembers = requisitions => {
    const uniqueTeamMembers = reduce(
      requisitions,
      (acc, val) => {
        const user = get(val, 'createdBy')
        !includes(acc, user) && acc.push(user)
        return acc
      },
      [],
    )

    return uniqueTeamMembers
  }

  const prevJobRequisitions = usePrevious(jobRequisitions)

  useEffect(() => {
    if (!isEqual(prevJobRequisitions, jobRequisitions)) {
      const teamMembers = getTeamMembers(jobRequisitions)
      setTeamMembers(teamMembers)
    }
  }, [jobRequisitions, prevJobRequisitions])

  const filterByUserClick = e => {
    const { key } = e
    setSelectedRequisitionFilter(requisitionFilterNames.byUser)
    setTeamMemberIdToFilterBy(key)
  }

  const previousSelectedRequisitionFilter = usePrevious(
    selectedRequisitionFilter,
  )
  const previousUserId = usePrevious(userId)
  const previousJobRequisitions = usePrevious(jobRequisitions)
  const previousTeamMemberIdToFilterBy = usePrevious(teamMemberIdToFilterBy)

  useEffect(() => {
    const filterRequisitionsByUser = userId => {
      const filteredRequisitions = jobRequisitions.filter(
        requisition =>
          requisition.createdBy && requisition.createdBy.id === userId,
      )
      setFilteredRequisitions(filteredRequisitions)
    }

    const filterRequisitions = () => {
      const filteredReqs = jobRequisitions

      switch (selectedRequisitionFilter) {
        case requisitionFilterNames.myCrowds:
          if (userId) {
            filterRequisitionsByUser(userId)
          }
          break

        case requisitionFilterNames.byUser:
          filterRequisitionsByUser(teamMemberIdToFilterBy)
          break

        case requisitionFilterNames.allCrowds:
          setFilteredRequisitions(jobRequisitions)
          break

        default:
          return filteredReqs
      }
    }

    if (
      previousSelectedRequisitionFilter !== selectedRequisitionFilter ||
      previousUserId !== userId ||
      !isEqual(previousJobRequisitions, jobRequisitions) ||
      previousTeamMemberIdToFilterBy !== teamMemberIdToFilterBy
    ) {
      filterRequisitions()
    }
  }, [
    previousUserId,
    previousJobRequisitions,
    previousTeamMemberIdToFilterBy,
    previousSelectedRequisitionFilter,
    selectedRequisitionFilter,
    userId,
    jobRequisitions,
    teamMemberIdToFilterBy,
  ])

  const toggleModal = () => setModalShown(!modalShown)

  const updateRequisitionOnState = updatedRequisition => {
    const requisitionsList = cloneDeep(jobRequisitions)
    const indexToUpdate = findIndex(
      requisitionsList,
      o => o.id === updatedRequisition.id,
    )
    requisitionsList.splice(indexToUpdate, 1, updatedRequisition)
  }

  const deleteRequisition = async requisition => {
    deleteJobRequisition({ requisitionId: requisition.id })
  }

  return (
    <AuthenticatedLayout title="My Crowds" gutters={true}>
      <Drawer
        title="Candidate View"
        placement="right"
        closable={true}
        destroyOnClose
        getContainer={false}
        onClose={() => setCandidateDetailsDrawerShown(false)}
        visible={candidateDetailDrawerVisible}
        style={{ overflow: 'auto' }}
        width={400}
      >
        <CandidateView
          candidateData={selectedCandidate}
          toggleCandidatesForm={() => {}}
          setSelectedCandidateData={setSelectedCandidate}
          allowSave={false}
          permissions={permissions}
        />
      </Drawer>
      <NewRequisitionModal
        toggleReqModal={toggleModal}
        requisitionModalShown={modalShown}
      />
      <Meta
        description="Segment contacts, collect insights, and keep track of candidates with Crowds."
        style={{
          margin: '-10px auto 20px',
          maxWidth: 1100,
          textAlign: 'center',
        }}
      />
      <Row>
        <Col className="max-1200">
          <>
            <Link to={`${AUTHENTICATED_ROUTES.CROWD_SEGMENT}`}>
              <Button
                ghost
                icon="search"
                type="primary"
                style={{ marginBottom: 10 }}
              >
                New Search
              </Button>
            </Link>
            <Button
              icon="plus"
              type="primary"
              onClick={toggleModal}
              style={{
                marginLeft: 10,
              }}
            >
              Create Crowd
            </Button>
            <Button.Group style={{ float: 'right' }}>
              <Button
                ghost={
                  selectedRequisitionFilter !== requisitionFilterNames.myCrowds
                }
                type="primary"
                onClick={() =>
                  setSelectedRequisitionFilter(requisitionFilterNames.myCrowds)
                }
              >
                {requisitionFilterNames.myCrowds}
              </Button>
              <Button
                ghost={
                  selectedRequisitionFilter !== requisitionFilterNames.allCrowds
                }
                type="primary"
                onClick={() =>
                  setSelectedRequisitionFilter(requisitionFilterNames.allCrowds)
                }
              >
                All Crowds
              </Button>
              <Dropdown
                overlay={
                  <Menu onClick={filterByUserClick}>
                    {teamMembers.length ? (
                      teamMembers.map(member => {
                        if (!member) return null
                        const { id } = member

                        return (
                          <Menu.Item key={id}>{fullName(member)}</Menu.Item>
                        )
                      })
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Users"
                        style={{ margin: 50 }}
                      />
                    )}
                  </Menu>
                }
              >
                <Button
                  ghost={
                    selectedRequisitionFilter !== requisitionFilterNames.byUser
                  }
                  type="primary"
                >
                  {requisitionFilterNames.byUser} <Icon type="down" />
                </Button>
              </Dropdown>
            </Button.Group>
          </>
          <ManageMyRequisitionsContainer>
            <List
              dataSource={filteredRequisitions}
              loading={loadingRequisitions}
              renderItem={requisition => (
                <RequisitionInfo
                  key={get(requisition, 'id')}
                  requisition={requisition}
                  availableRequisitions={jobRequisitions.filter(
                    req => req.id !== requisition.id,
                  )}
                  toggleCandidateModal={() => {}}
                  updateRequisitionOnState={updateRequisitionOnState}
                  deleteRequisition={deleteRequisition}
                  setSelectedCandidate={setSelectedCandidate}
                  setCandidateDetailsDrawerShown={
                    setCandidateDetailsDrawerShown
                  }
                  permissions={permissions}
                  clientName={clientName}
                  teDownloadCredits={teDownloadCredits}
                  updateUser={updateUser}
                />
              )}
              style={{ marginTop: 16 }}
            />
          </ManageMyRequisitionsContainer>
          <div style={{ marginTop: -20, textAlign: 'center' }}>
            <Tooltip title="Create a New Crowd">
              <Button
                icon="plus"
                type="primary"
                shape="circle"
                size="large"
                onClick={toggleModal}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
    </AuthenticatedLayout>
  )
}

export default withTeAuth(Requisitions)
