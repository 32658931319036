import React from 'react'
import { Card, List } from 'antd'
import Box from 'ui-box'
import { hasLength } from '../../../../../../../../../utils/data/utils'

const MembersList = props => {
  const {
    containerProps,
    data,
    EmptyComponent,
    extra,
    loading,
    renderItem,
    title
  } = props

  return (
    <Box {...containerProps}>
      <Card extra={extra} title={title} bodyStyle={{ padding: 0 }}>
        {loading || hasLength(data) ? (
          <List
            loading={loading}
            dataSource={data}
            renderItem={item => renderItem({ item })}
          />
        ) : (
          EmptyComponent
        )}
      </Card>
    </Box>
  )
}

MembersList.propTypes = {}

export default MembersList
