import get from 'lodash/get'

import { pullTotalChargeOnAddPaymentQuery } from '../../../apolloClient/operations/payments/pullTotalChargeOnAddPayment'

const { useQuery } = require('@apollo/client')

export const useTotalChargeOnAddPayment = ({ couponCode, onCompleted }) => {
  const { data, loading, error, refetch } = useQuery(
    pullTotalChargeOnAddPaymentQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted,
      variables: {
        couponCode,
      },
    },
  )
  const chargeData = get(data, 'pullTotalChargeOnAddPayment')

  return {
    chargeData,
    errorFetchingTotal: error,
    getTotal: refetch,
    loadingTotalCharge: loading,
  }
}
