import React from 'react'

import get from 'lodash/get'

import { salaryRange } from '../../utils/stringManipulation'

import NotFoundColumn from './NotFoundColumn'

const PersonSalaryColumn = props => {
  const val = get(props, 'val')
  const row = get(props, 'row')

  return val ? (
    <>
      <div style={{ textAlign: 'center' }}>
        {get(row, 'current_position.salary', 0) && (
          <div style={{ color: 'green', fontSize: 16 }}>
            {salaryRange(get(row, 'current_position.salary', 0))}
          </div>
        )}
        <div className="text-muted">
          {val
            ? get(row, 'state')
              ? `${val}, ${get(row, 'state')}`
              : val
            : get(row, 'state', '')}
        </div>
      </div>
    </>
  ) : (
    <NotFoundColumn centered={true} />
  )
}

export default PersonSalaryColumn
