import { useMutation } from '@apollo/client'
import get from 'lodash/get'

import { deleteJobRequisitionMutation } from '../../../apolloClient/operations/requisitions/deleteJobRequisitionMutation'
import feedback from '../../../utils/feedback'

export const useDeleteJobRequisition = () => {
  const [deleteRequisitionCallback] = useMutation(
    deleteJobRequisitionMutation,
    {
      onCompleted: () => {
        feedback.success({ content: 'Crowd deleted successfully' })
      },
      onError: () => {
        feedback.error({
          title:
            'There was an error downloading your file... please try again.',
        })
      },
    },
  )

  const deleteJobRequisition = variables => {
    const requisitionId = get(variables, 'requisitionId')

    return deleteRequisitionCallback({
      update: cache => {
        const normalizedId = cache.identify({
          __typename: 'JobRequisition',
          id: requisitionId,
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      variables,
    })
  }

  return { deleteJobRequisition }
}
