import React from 'react'

import { Checkbox, Col, Icon, Row, Tooltip } from 'antd'
import isEqual from 'lodash/isEqual'

const SingleCheckWithCustomValues = props => {
  const { options, title, setFilterValue, name, value, info, label } = props
  const checked = isEqual(value, options.checked) ? true : false

  const handleChange = e => {
    const value = e.target.checked ? options.checked : options.unchecked
    setFilterValue(name, value)
  }

  return (
    <Col lg={24} md={24}>
      <div
        style={{
          margin: '0 auto',
          marginBottom: 10,
          position: 'relative',
          width: '95%',
        }}
      >
        <span style={{ fontSize: 12, fontWeight: 'bold' }}>
          {title}{' '}
          {info && (
            <Tooltip placement="top" title={info}>
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </span>
        <Row style={{ marginBottom: 0, paddingLeft: 10 }}>
          <Checkbox checked={checked} onChange={handleChange}>
            {label}
          </Checkbox>
        </Row>
      </div>
    </Col>
  )
}

export default SingleCheckWithCustomValues
