import React from 'react'

import { Table } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'

import { formatMoney } from '../../../../../../../../utils/stringManipulation'

import InvoiceFooter from './InvoiceFooter'

const UpcomingInvoiceTable = props => {
  const { upcomingCharge = {} } = props
  const { chargeItems = [], dueDate } = upcomingCharge

  const columns = [
    {
      dataIndex: 'description',
      render: text => text,
      title: 'Description',
    },
    {
      dataIndex: 'timePeriod',
      render: text => text,
      title: 'Period',
    },
    {
      dataIndex: 'qty',
      render: text => text,
      title: 'Qty.',
    },
    {
      dataIndex: 'unitPrice',
      render: text => (text ? `$${text}` : null),
      title: 'Unit Price',
    },
    {
      dataIndex: 'amount',
      render: text => formatMoney(text),
      title: 'Amount',
    },
  ]
  return (
    <Table
      rowKey={row => get(row, 'description')}
      style={{ marginBottom: 20 }}
      columns={columns}
      dataSource={chargeItems}
      title={() => (
        <Meta
          description={`This is a preview of the invoice that will be billed on ${dueDate}. It may change if the subscription is updated.`}
        />
      )}
      footer={() => <InvoiceFooter invoice={upcomingCharge} />}
    />
  )
}

export default UpcomingInvoiceTable
