import gql from 'graphql-tag'

export const pullTotalChargeOnAddPaymentQuery = gql`
  query PullTotalChargeOnAddPayment($couponCode: String) {
    pullTotalChargeOnAddPayment(couponCode: $couponCode) {
      chargeItems {
        amount
        description
        qty
        timePeriod
        unitPrice
      }
      discount {
        amount
        couponCode
        discountDisplay
      }
      dueDate
      subtotal
      total
    }
  }
`
