import React from 'react'

import { Button, Tooltip, Typography } from 'antd'
import { useMediaQuery } from 'react-responsive'

import numberConverter from '../../../../../utils/numberConverter'
import { useCrowdSegmentDispatch } from '../../crowd-context'
import { getNonEmptyFiltersCount, TOOLTIP_MSGS } from '../../index'
import OpenTextSearch from '../components/OpenTextSearch'
import SaveSearch from '../components/SaveSearch'

import SearchGroups from './SearchGroups'

const { Paragraph } = Typography

const SearchHeader = ({
  clearMultipleFilters,
  filtersToApply,
  loading,
  fetchingTotal,
  onSubmit,
  setFilterValue,
  submittable,
  suggestions,
  toggleFilter,
  totalCount,
  updateAppliedFilters,
  tooltipMessage,
  selectedFilters,
  toggleAllFilters,
  selectedSearchGroup,
  isSystemAdmin,
}) => {
  const { clearAllStateData } = useCrowdSegmentDispatch()

  const MOBILE_WIDTH = 768
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` })

  const numberOfFiltersWithValue = getNonEmptyFiltersCount(filtersToApply)

  const totalCountDisplay = totalCount => {
    if (numberOfFiltersWithValue < 1) return ''
    else if (totalCount > process.env.REACT_APP_MAX_SEARCH_SIZE)
      return 'Overcrowded'
    else if (totalCount) return `${numberConverter(totalCount)} Profiles`
    else return ''
  }

  const searchButton = (
    <Button
      className="text-center"
      type="primary"
      style={{ marginRight: 5, minWidth: 150 }}
      disabled={!submittable}
      onClick={onSubmit}
      loading={loading}
      icon="search"
      size="large"
    >
      {!loading && `${totalCountDisplay(totalCount)}`}
    </Button>
  )

  const tooBroadMsg =
    numberOfFiltersWithValue > 0 &&
    totalCount &&
    totalCount > parseInt(process.env.REACT_APP_MAX_SEARCH_SIZE) &&
    !fetchingTotal ? (
      <div
        className="max-1200"
        style={{ marginTop: -8, padding: '0', textAlign: 'right' }}
      >
        <Paragraph type="danger" style={{ fontSize: 13, marginBottom: 3 }}>
          {TOOLTIP_MSGS.tooBroad}
        </Paragraph>
      </div>
    ) : null

  return (
    <div className={isMobile ? '' : 'search-header'}>
      <div
        style={{
          padding: '10px 50px',
        }}
      >
        <div className="max-1200">
          <div style={{ display: 'flex', marginBottom: '12px', width: '100%' }}>
            <OpenTextSearch
              filtersToApply={filtersToApply}
              toggleFilter={toggleFilter}
              totalCount={totalCount}
              submittable={submittable}
              fetchingTotal={fetchingTotal}
              onSubmit={onSubmit}
            />
          </div>
          <div
            style={
              isMobile
                ? { margin: '0 -15px' }
                : { alignItems: 'center', display: 'flex' }
            }
          >
            <SearchGroups
              searchButton={searchButton}
              clearMultipleFilters={clearMultipleFilters}
              filtersToApply={filtersToApply}
              selectedSearchGroup={selectedSearchGroup}
              suggestions={suggestions}
              toggleFilter={toggleFilter}
              updateAppliedFilters={updateAppliedFilters}
              setFilterValue={setFilterValue}
              toggleAllFilters={toggleAllFilters}
              isSystemAdmin={isSystemAdmin}
            />
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  // float: 'right',
                  padding: '5px 0',
                  textAlign: 'right',
                }}
              >
                <Tooltip title="Clear this Crowd">
                  <Button
                    type="danger"
                    ghost
                    icon="delete"
                    onClick={() => clearAllStateData()}
                  />
                </Tooltip>
                <SaveSearch selectedFilters={selectedFilters} />
              </div>
              <div>
                <Tooltip title={tooltipMessage}>{searchButton}</Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      {tooBroadMsg}
    </div>
  )
}

export default SearchHeader
