import gql from 'graphql-tag'

export const getUserForAuthQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      email
      firstName
      lastName
      isAdmin
      isSystemAdmin
      createdAt
      client {
        id
        name
        trialExpired
        settings {
          id
          credits
        }
        subscription {
          id
          needsUpdatedPayment
          canceled
        }
      }
    }
  }
`
