import React, { useState } from 'react'

import { Auth } from 'aws-amplify'
import _ from 'lodash'
import get from 'lodash/get'
import qs from 'query-string'

import LoginForm from '../../../components/forms/LoginForm'
import { UNAUTHENTICATED_ROUTES } from '../../../utils/routing/routes'
import { useCrowdSegmentState } from '../../Authenticated/CrowdSegment/crowd-context'
import CenteredLayoutWide from '../components/CenteredLayoutWide'
import NavLinks from '../components/NavLinks'
import UnauthenticatedCard from '../components/UnauthenticatedCard'

const Authenticate = props => {
  const queryParams = qs.parse(_.get(props, 'location.search'))
  const showImpersonation = _.get(queryParams, 'showImpersonation', false)
  const [loggingIn, setLoggingIn] = useState(false)

  const { unauthenticatedUser } = useCrowdSegmentState()

  const onSubmit = async formData => {
    setLoggingIn(true)
    try {
      const email = get(formData, 'email')
      const password = get(formData, 'password')
      await Auth.signIn(email, password)
      setLoggingIn(false)
    } catch (error) {
      setLoggingIn(false)
    }
  }

  return (
    <CenteredLayoutWide>
      <div
        style={{ margin: '80px auto', maxWidth: 650 }}
        className="antd-auth-form"
      >
        <UnauthenticatedCard>
          <h2
            style={{
              fontWeight: 'bold',
              marginBottom: 40,
              textAlign: 'center',
            }}
            data-testid="login-greeting"
          >
            Welcome Back! :)
          </h2>
          <LoginForm
            unauthenticatedUser={unauthenticatedUser}
            onSubmit={onSubmit}
            showImpersonation={showImpersonation}
            loggingIn={loggingIn}
          />
          <NavLinks
            containerProps={{ marginTop: '16px' }}
            links={[
              {
                label: 'Forgot password?',
                route: UNAUTHENTICATED_ROUTES.FORGOT_PASSWORD,
              },
              {
                label: 'Create New Account',
                route: UNAUTHENTICATED_ROUTES.CS_SIGNUP,
              },
            ]}
          />
        </UnauthenticatedCard>
      </div>
    </CenteredLayoutWide>
  )
}

export default Authenticate
