import gql from 'graphql-tag'

export const getUserForMyAccountQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      isAdmin
      client {
        id
        subscription {
          id
        }
      }
    }
  }
`
