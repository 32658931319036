import { useMutation } from '@apollo/client'

import { enableUserAccessMutation } from '../../../apolloClient/operations/admin/enableUserAccessMutation'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useEnableUserAccess = () => {
  const [enableUserAccess] = useMutation(enableUserAccessMutation, {
    onCompleted: () => {
      feedback.success({
        content: 'Access updated successfully',
      })
    },
    onError: error => {
      errorService.report(error, 'update user access')
      feedback.error({
        content: error.message,
        title: 'Error updating user access',
      })
    },
  })

  return { enableUserAccess }
}
