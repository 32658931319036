import { useMutation } from '@apollo/client'

import { enableUserAdminAccessMutation } from '../../../apolloClient/operations/admin/enableUserAdminAccessMutation'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useEnableUserAdminAccess = () => {
  const [enableUserAdminAccess] = useMutation(enableUserAdminAccessMutation, {
    onCompleted: () => {
      feedback.success({
        content: 'Admin access updated successfully',
      })
    },
    onError: error => {
      errorService.report(error, 'update admin access')
      feedback.error({
        content: error.message,
        title: 'Error updating admin access',
      })
    },
  })

  return { enableUserAdminAccess }
}
