import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { isPublic } from '../../../utils/routing/routes'

export const useDynamicUser = ({ userQuery }) => {
  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))
  const { data, loading, error, refetch } = useQuery(userQuery, {
    skip: isPublicRoute,
  })
  const userProfile = get(data, 'getUserProfile')

  return {
    errorGettingUserProfile: error,
    gettingUserProfile: loading,
    refetch,
    userProfile,
  }
}
