import React, { useCallback } from 'react'

import { Button, Icon } from 'antd'
import get from 'lodash/get'
import PT from 'prop-types'
import { withRouter } from 'react-router-dom'

const HeaderLinkButton = props => {
  const { icon, name, theme, history, location, route, ...rest } = props
  const pathname = get(location, 'pathname')
  const selected =
    route === '/app' ? pathname === route : pathname.startsWith(route)
  const navigate = useCallback(() => history.push(route), [history, route])
  return (
    <Button
      {...rest}
      className="header-button"
      style={{
        borderBottom: selected ? '4px solid #4e57d3' : '',
        color: selected ? '#000000' : '#4c4c4c',
      }}
      type="link"
      onClick={navigate}
    >
      <Icon
        type={icon}
        theme={theme}
        style={{ display: 'block', fontSize: 20 }}
      />
      <span style={{ fontSize: 12, margin: 0 }}>{name}</span>
    </Button>
  )
}

HeaderLinkButton.propTypes = {
  route: PT.string.isRequired,
}

export default withRouter(HeaderLinkButton)
