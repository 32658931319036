import { useState } from 'react'

import get from 'lodash/get'

import { apolloClient } from '../../../apolloClient'
import { companyDetailsQuery } from '../../../apolloClient/operations/search/dataQueries'
import errorService from '../../../utils/analytics/error'

export const useSelectedCompany = ({ openDrawer }) => {
  const [selectedCompany, setCompanyDetails] = useState(null)
  const [loadingCompany, setLoadingCompany] = useState(false)
  const getSelectedCompanyDetails = async companyName => {
    setLoadingCompany(true)
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: companyDetailsQuery,
        variables: {
          filters: {
            company_names: [companyName],
          },
        },
      })
      const companyDetails = get(result, 'data.companyDetails')
      setCompanyDetails(companyDetails)
      setLoadingCompany(false)
      openDrawer()
    } catch (error) {
      setLoadingCompany(false)
      errorService.report(error, 'Get Company Details')
      console.log(error)
    }
  }

  return { getSelectedCompanyDetails, loadingCompany, selectedCompany }
}
