import React from 'react'

import { get } from 'lodash'
import { useHistory } from 'react-router-dom'

import CenteredCard from '../../../../components/layout/CenteredCard/CenteredCard'
import ResubscribeForm from '../../../../components/payment/ResubscribeForm'
import { useUserForResubscribe } from '../../../../hooks/data/loggedInUser/useUserForResubscribe'
import { AUTHENTICATED_ROUTES } from '../../../../utils/routing/routes'
import AuthenticatedLayout from '../AuthenticatedLayout/index'

const Resubscribe = () => {
  const history = useHistory()
  const { userData, loadingUserProfile } = useUserForResubscribe()
  const isAdmin = get(userData, 'isAdmin')
  const clientName = get(userData, 'client.name')

  const toCrowdSegment = () => {
    history.push(AUTHENTICATED_ROUTES.CROWD_SEGMENT)
  }

  return loadingUserProfile ? null : (
    <AuthenticatedLayout>
      <CenteredCard styleOverride={{ padding: 24 }}>
        <ResubscribeForm
          afterUpdate={toCrowdSegment}
          title="Reactivate Your Subscription"
          closable={false}
          modalShown={true}
          message="Add payment method to activate your subscription"
          clientName={clientName}
          isAdmin={isAdmin}
          closeModal={() => {}}
        />
      </CenteredCard>
    </AuthenticatedLayout>
  )
}

export default Resubscribe
