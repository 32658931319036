export const locationKeys = {
  locationName: 'location_name',
  locationRadius: 'location_radius',
  locationLat: 'location_lat',
  locationLng: 'location_lng'
}

export const locationParamKeys = [
  locationKeys.locationName,
  locationKeys.locationRadius,
  locationKeys.locationLat,
  locationKeys.locationLng
]
