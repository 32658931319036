import React, { useCallback } from 'react'

import { Divider, Icon, Layout, Popover } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { withRouter } from 'react-router-dom'

import MobileLogo from '../../../../../../assets/logos/crowdsegment-logo-round-edges.png'
import Logo from '../../../../../../assets/logos/cs-logo-srch-word-plain-purple.png'
import errorService from '../../../../../../utils/analytics/error'
import { useProtectAuthenticatedRoutes } from '../../../../../../utils/authentication'
import { pickWithInitialState } from '../../../../../../utils/data/utils'
import { AUTHENTICATED_ROUTES } from '../../../../../../utils/routing/routes'
import { useCrowdSegmentDispatch } from '../../../../CrowdSegment/crowd-context'

import HeaderLinkButton from './components/HeaderLinkButton'

const { Header } = Layout
const MOBILE_WIDTH = 768

const AuthenticatedHeader = props => {
  const { borderBottom = true } = props
  const { clearAllStateData } = useCrowdSegmentDispatch()
  const { userProfile } = useProtectAuthenticatedRoutes(props.history)
  const { email, id, isAdmin } = pickWithInitialState(userProfile, [
    ['email', null],
    ['id', null],
    ['isAdmin', null],
  ])
  if (!!email && !!id) {
    errorService.setUserContext({ email: email, id: id })
  }

  const navigate = useCallback(
    route => {
      props.history.push(route)
    },
    [props.history],
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` })
  const logo = process.env.REACT_APP_CUSTOM_CLIENT_LOGO
    ? process.env.REACT_APP_CUSTOM_CLIENT_LOGO
    : Logo

  const mobileLogo = process.env.REACT_APP_CUSTOM_CLIENT_LOGO_MOBILE
    ? process.env.REACT_APP_CUSTOM_CLIENT_LOGO_MOBILE
    : MobileLogo

  const headerLinks = (
    <div style={{ lineHeight: 'normal' }}>
      <HeaderLinkButton
        route={AUTHENTICATED_ROUTES.CROWD_SEGMENT}
        icon="search"
        theme="outlined"
        name="Find People"
      />
      <>
        <HeaderLinkButton
          data-testid="projects-link"
          route={AUTHENTICATED_ROUTES.REQUISITIONS}
          icon="team"
          theme="outlined"
          name="My Crowds"
        />
        {!isMobile && (
          <HeaderLinkButton
            route={AUTHENTICATED_ROUTES.DOWNLOADS}
            icon="download"
            theme="outlined"
            name="Downloads"
          />
        )}
        {!isMobile && (
          <Divider type="vertical" style={{ height: 40, marginTop: 10 }} />
        )}
        {isAdmin && (
          <HeaderLinkButton
            route={AUTHENTICATED_ROUTES.MANAGE_TEAM}
            icon="crown"
            theme="outlined"
            name="Admin"
          />
        )}
        <HeaderLinkButton
          route={AUTHENTICATED_ROUTES.MY_ACCOUNT}
          icon="user"
          name="My Account"
          data-testid="my-account"
        />
      </>
    </div>
  )

  return (
    <Header
      style={
        borderBottom
          ? { background: '#fff', borderBottom: '3px solid #eee' }
          : { background: '#fff' }
      }
    >
      <div className="header-container">
        <img
          alt="CrowdSegment Logo"
          src={isMobile ? mobileLogo : logo}
          style={
            isMobile
              ? { height: 40, marginTop: 11, width: 40 }
              : {
                  height: 36,
                  marginTop: 11,
                }
          }
          onClick={() => {
            clearAllStateData()
            navigate(AUTHENTICATED_ROUTES.CROWD_SEGMENT)
          }}
        />
        <div>
          {isMobile ? (
            <Popover
              content={headerLinks}
              trigger="click"
              placement="bottomRight"
            >
              <Icon type="menu" />
            </Popover>
          ) : (
            <>{headerLinks}</>
          )}
        </div>
      </div>
    </Header>
  )
}

export default withRouter(AuthenticatedHeader)
