import { useState } from 'react'

import gql from 'graphql-tag'
import get from 'lodash/get'

import { apolloClient } from '../../../apolloClient'
import errorService from '../../../utils/analytics/error'

const getDataForPublicSearchQuery = gql`
  query getDataForPublicSearch($id: ID!) {
    getDataForPublicSearch(id: $id) {
      name
      id
      searchData
      description
    }
  }
`

export const usePublicSearch = () => {
  const [publicSearchData, setPublicSearchData] = useState(null)
  const [loadingPublicSearchData, setLoadingPublicSearchData] = useState(false)

  const getPublicSearchData = async savedSearchId => {
    setLoadingPublicSearchData(true)
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: getDataForPublicSearchQuery,
        variables: {
          id: savedSearchId,
        },
      })
      const searchData = get(result, 'data.getDataForPublicSearch')
      setPublicSearchData(searchData)
      setLoadingPublicSearchData(false)
      return searchData
    } catch (error) {
      errorService.report(error, 'Get Public Search Data')
      setLoadingPublicSearchData(false)
    }
  }

  return { getPublicSearchData, loadingPublicSearchData, publicSearchData }
}
