import get from 'lodash/get'

import { pullUpcomingChargeQuery } from '../../../apolloClient/operations/payments/pullUpcomingChargeQuery'

const { useQuery } = require('@apollo/client')

export const useUpcomingCharge = () => {
  const { data, loading, error, refetch } = useQuery(pullUpcomingChargeQuery, {
    fetchPolicy: 'network-only',
  })
  const chargeData = get(data, 'pullUpcomingChargeForCustomer')

  return {
    chargeData,
    errorFetchingUpcomingCharge: error,
    getTotal: refetch,
    loadingUpcomingCharge: loading,
  }
}
