import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { map } from 'lodash'

import colors from '../../../../../hocs/colors'
import numberConverter from '../../../../../utils/numberConverter'

const DistributionSalary = ({ title, containerProps, data, height = 200 }) => {
  const dataSeries = map(data, 'y')
  const dataCategories = map(data, 'name')
  const options = {
    chart: {
      height: height,
      type: 'column',
    },
    credits: {
      enabled: false, // this is to not show highcharts.com text
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: 4,
        pointWidth: 40,
      },
    },
    series: [
      {
        color: colors.salary,
        data: dataSeries,
      },
    ],
    title: undefined,
    tooltip: {
      enabled: false,
    },
    xAxis: {
      categories: dataCategories,
      labels: {
        formatter: function() {
          return `$${numberConverter(this.value / 1000)}k`
        },
        style: {
          fontFamily: 'Verdana, sans-serif',
          fontSize: '13px',
        },
      },
      type: 'category',
    },
    yAxis: {
      endOnTick: false,
      type: 'logarithmic',
      visible: false,
    },
  }

  return (
    <div>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={options}
      />
      <div className="text-center" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        {title}
      </div>
    </div>
  )
}

export default DistributionSalary
