import gql from 'graphql-tag'

export const pullUpcomingChargeQuery = gql`
  query PullUpcomingCharge {
    pullUpcomingChargeForCustomer {
      chargeItems {
        amount
        description
        qty
        timePeriod
        unitPrice
      }
      discount {
        amount
        couponCode
        discountDisplay
      }
      dueDate
      subtotal
      total
    }
  }
`
