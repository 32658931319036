import React from 'react'

import { Layout } from 'antd'

import UnauthenticatedHeader from '../UnauthenticatedHeader'

const { Content } = Layout

const CenteredLayoutWide = props => {
  const { borderBottom = true } = props

  return (
    <Layout>
      <UnauthenticatedHeader borderBottom={borderBottom} />
      <Content className={props.className}>{props.children}</Content>
    </Layout>
  )
}
export default CenteredLayoutWide
