import React from 'react'

import { Col, Checkbox, Row, Icon, Tooltip } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import colors from '../../../../hocs/colors/index.js'

const sizeOptions = [[1, 10], [10, 50], [50, 100], [100, 1000], [1000]]

const CompanySizeSelect = props => {
  const selectedSizes = get(props, 'selectedSizes')
  const displayName = get(props, 'displayName')
  const setFilterValue = get(props, 'setFilterValue')
  const info = get(props, 'info')

  const onCheckAllChange = e => {
    const value = get(e, 'target.checked')
    const checkedItems = value ? sizeOptions : []
    setFilterValue('company_sizes', checkedItems)
  }

  const checkOne = (value, checked, index) => {
    let updatedSizes = selectedSizes ? cloneDeep(selectedSizes) : []
    if (index === -1) {
      updatedSizes.push(value)
    } else {
      updatedSizes.splice(index, 1)
    }
    setFilterValue('company_sizes', updatedSizes)
  }

  const someSelected =
    selectedSizes &&
    selectedSizes.length > 0 &&
    selectedSizes.length < sizeOptions.length

  const allChecked =
    selectedSizes && selectedSizes.length === sizeOptions.length

  const getDisplayValue = size =>
    !size ? size : size.length > 1 ? `${size[0]} - ${size[1]}` : `${size[0]} +`

  return (
    <Col lg={24} md={24}>
      <div
        style={{
          margin: '0 auto',
          marginBottom: 20,
          position: 'relative',
          width: '95%',
        }}
      >
        <span style={{ fontSize: 12, fontWeight: 'bold' }}>
          {`${displayName} `}
          <Tooltip placement="top" title={info}>
            <Icon type="info-circle" />
          </Tooltip>
        </span>
        <Row
          style={{
            borderBottom: `0.5px solid ${colors.gray4}`,
            marginBottom: 10,
            paddingBottom: 2,
            paddingLeft: 10,
          }}
        >
          <Checkbox
            defaultChecked={false}
            indeterminate={someSelected}
            onChange={onCheckAllChange}
            checked={allChecked}
          >
            Select All
          </Checkbox>
        </Row>
        <Row style={{ display: 'flex', flexFlow: 'wrap', paddingLeft: 10 }}>
          {sizeOptions.map(option => {
            const indexInSelectedSizes = !selectedSizes
              ? -1
              : findIndex(selectedSizes, size => {
                  return isEqual(size, option)
                })
            const checked = indexInSelectedSizes !== -1

            const handleChange = e => {
              checkOne(option, e.target.checked, indexInSelectedSizes)
            }
            return (
              <Checkbox
                key={getDisplayValue(option)}
                value={getDisplayValue(option)}
                style={{ marginLeft: 0 }}
                onChange={handleChange}
                checked={checked}
              >
                {getDisplayValue(option)}
              </Checkbox>
            )
          })}
        </Row>
      </div>
    </Col>
  )
}

export default CompanySizeSelect
