import { useMutation } from '@apollo/client'
import get from 'lodash/get'

import { getUserForAuthQuery } from '../../../apolloClient/operations/common/auth'
import { createSubscriptionForExistingCustomerMutation } from '../../../apolloClient/operations/subscriptions/resubscribeMutation'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useResubscribe = options => {
  const afterUpdate = get(options, 'afterUpdate')
  const [resubscribe, { loading }] = useMutation(
    createSubscriptionForExistingCustomerMutation,
    {
      onCompleted: () => {
        feedback.success({ content: 'Subscription Activated' })
        if (afterUpdate) {
          afterUpdate()
        }
      },
      onError: error => {
        console.log(error)
        errorService.report(error, 'Reactivate Subscription')
        feedback.error({
          title:
            'There was a problem activating your subscription... Please try again',
        })
      },
      update: (cache, { data: { createSubscriptionForExistingCustomer } }) => {
        const userData = cache.readQuery({
          query: getUserForAuthQuery,
        })
        const cachedSubscription = get(
          userData,
          'getUserProfile.client.subscription',
        )
        const normalizedId = cache.identify({
          __typename: 'Subscription',
          id: get(cachedSubscription, 'id'),
        })
        cache.evict({ id: normalizedId })
        cache.gc()
        cache.modify({
          fields: {
            subscription() {
              const ref = {
                __ref: cache.identify(createSubscriptionForExistingCustomer),
              }
              return ref
            },
          },
          id: cache.identify(get(userData, 'getUserProfile.client')),
        })
      },
    },
  )

  return { resubscribe, resubscribing: loading }
}
