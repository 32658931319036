import { round } from 'lodash'
import get from 'lodash/get'
import truncate from 'lodash/truncate'

export const stringTruncate = string => truncate(string, { length: 20 })

// This same function is used in GOGE, so make sure to update both places.
export const capitalize = (str = '') => {
  if (str === null) return ''
  if (typeof str !== 'string') return str
  if (str && str !== str.toLowerCase()) return str
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  const wordSeparators = /([ :â€"â€"-])/
  return str
    .split(wordSeparators)
    .map(function(current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }
      // /* Ignore intentional capitalization */
      // if (current.substr(1).search(/[A-Z]|\../) > -1) {
      //   return current
      // }
      // /* Ignore URLs */
      // if (array[index + 1] === ':' && array[index + 2] !== '') {
      //   return current
      // }
      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function(match) {
        return match.toUpperCase()
      })
    })
    .join('')
}

const rangeModifications = [
  { drop: 0.15, max: 50000, min: 25000, raise: 0.15 },
  { drop: 0.15, max: 70000, min: 50000, raise: 0.15 },
  { drop: 0.17, max: 100000, min: 70000, raise: 0.17 },
  { drop: 0.2, max: 150000, min: 100000, raise: 0.2 },
  { drop: 0.22, max: 200000, min: 150000, raise: 0.22 },
]
export const salaryRange = salary => {
  let displaySalary = ''
  try {
    if (typeof salary !== 'number' || salary < 1) return null
    if (salary < 30000) return '<$30k'
    if (salary >= 200000) return '$200k+'
    rangeModifications.forEach(r => {
      if (r.min <= salary <= r.max) {
        displaySalary = `$${round((salary * (1 - r.drop)) / 1000)}k - $${round(
          (salary * (1 + r.raise)) / 1000,
        )}k`
      }
    })
    return displaySalary
  } catch {
    return null
  }
}

export const yearRange = (year_start, year_stop) => {
  if (year_stop === new Date().getFullYear()) year_stop = 'Present'
  if (year_start && year_stop && year_start !== year_stop)
    return `(${year_start} - ${year_stop})`
  if (year_start) return `(${year_start})`
  if (year_stop) return `(- ${year_stop})`
  else return ''
}
export const milestoneDesc = (title, company, year_start, year_stop) => {
  let atCo = company ? `@${capitalize(company)}` : ''
  let msdesc = `${capitalize(title)} ${atCo} ${yearRange(
    year_start,
    year_stop,
  )}`
  return msdesc
}

export const SEARCH_ENGINES = {
  bing: 'bing',
  google: 'google',
  linkedIn: 'linkedIn',
}

const SEARCH_ENGINES_ENDPOINTS = {
  bing: 'https://www.bing.com/search?q=',
  google: 'https://www.google.com/search?q=',
  linkedIn: 'https://www.linkedin.com/search/results/all/?keywords=',
}

const SEARCH_ENGINES_COMPANIES_ENDPOINTS = {
  ...SEARCH_ENGINES_ENDPOINTS,
  ...{
    linkedIn: 'https://www.linkedin.com/search/results/companies/?keywords=',
  },
}

export const makeIntoExternalLink = link => `https://www.${link}`

export const getCandidateSearchString = (candidate = '') => {
  const searchString = `${
    get(candidate, 'full_name') ? get(candidate, 'full_name') : ''
  } ${
    get(candidate, 'current_position.company')
      ? get(candidate, 'current_position.company')
      : ''
  }`
  return searchString
}

export const createCandidateSearchQuery = (searchEngine, candidate) => {
  const searchEngineEndpoint = get(
    SEARCH_ENGINES_ENDPOINTS,
    `${searchEngine}`,
    '',
  )
  return `${searchEngineEndpoint}${encodeURIComponent(
    getCandidateSearchString(candidate),
  )}`
}

export const createCompanySearchQuery = (searchEngine, companyName) => {
  const searchEngineEndpoint = get(
    SEARCH_ENGINES_COMPANIES_ENDPOINTS,
    `${searchEngine}`,
    '',
  )
  return `${searchEngineEndpoint}${encodeURIComponent(companyName)}`
}

export const removeTimestampFromFilename = filename => {
  try {
    return filename.slice(0, -40)
  } catch {
    return filename
  }
}

export const insertAt = (position, original, character) =>
  original.substring(0, position) + character + original.substring(position)

export const formatMoney = moneyNum => {
  let moneyStr = moneyNum.toString()
  if (moneyStr.charAt(0) === '-') {
    return insertAt(1, moneyStr, '$')
  } else {
    return insertAt(0, moneyStr, '$')
  }
}

export const fullName = user =>
  `${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`
