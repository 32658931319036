import React from 'react'

import { Modal } from 'antd'
import get from 'lodash/get'

import { useCreateRequisition } from '../../../../hooks/data/requisitions/useCreateRequisition'
import RequisitionDetailForm from '../../Requisitions/components/RequisitionDetailForm'

const NewRequisitionModal = props => {
  const requisitionModalShown = get(props, 'requisitionModalShown')
  const toggleReqModal = get(props, 'toggleReqModal')

  const { createJobRequisition } = useCreateRequisition({
    afterCreate: toggleReqModal,
  })

  const createRequisition = variables => {
    createJobRequisition({ variables })
  }

  return (
    <Modal
      visible={requisitionModalShown}
      destroyOnClose
      footer={null}
      onCancel={toggleReqModal}
      width="700px"
    >
      <RequisitionDetailForm onSubmit={createRequisition} />
    </Modal>
  )
}

export default NewRequisitionModal
