import React from 'react'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import omitBy from 'lodash/omitBy'

import {
  getRangeFilterDefault,
  isRangeFilter,
} from '../../../static/defaultStateValues'
import { getSearchGroups } from '../../../static/searchSettings'
import SearchDropdown from '../../components/SearchDropdown'
import SearchGroup from '../../components/SearchGroup'

const SearchGroups = props => {
  const {
    clearMultipleFilters,
    filtersToApply,
    selectedSearchGroup,
    suggestions,
    toggleFilter,
    updateAppliedFilters,
    setFilterValue,
    toggleAllFilters,
    searchButton,
    isSystemAdmin,
  } = props

  const searchGroups = getSearchGroups(isSystemAdmin)

  const getFiltersToApplyForSearches = fields =>
    omitBy(fields, (value, key) => {
      const matchedFilterToApply = get(filtersToApply, key)

      if (isRangeFilter(value.name)) {
        return (
          isEqual(matchedFilterToApply, getRangeFilterDefault(value.name)) ||
          !matchedFilterToApply ||
          !matchedFilterToApply.length
        )
      }
      if (key === 'location') {
        // do not return location key if location object is undefined or all the keys inside location object has null or undefined value
        // TODO: check to see if we can avoid adding the keys inside location object if the values are null
        return (
          !matchedFilterToApply ||
          !keys(omitBy(matchedFilterToApply, value => !value)).length
        )
      } else {
        return !matchedFilterToApply || !matchedFilterToApply.length
      }
    })

  const clearFilters = e => filters => {
    e.preventDefault()
    clearMultipleFilters(filters)
  }

  return (
    <div style={{ flex: 1 }}>
      {searchGroups.map(searchGroup => {
        const { title, searches, groupName } = searchGroup

        return (
          <SearchDropdown
            key={title}
            content={
              <SearchGroup
                searches={searches}
                filtersToApply={filtersToApply}
                suggestions={suggestions}
                toggleFilter={toggleFilter}
                updateAppliedFilters={updateAppliedFilters}
                setFilterValue={setFilterValue}
                searchButton={searchButton}
                toggleAllFilters={toggleAllFilters}
              />
            }
            clearFilters={clearFilters}
            selectedFilters={getFiltersToApplyForSearches(searches)}
            title={title}
            defaultOpen={selectedSearchGroup === groupName}
          />
        )
      })}
    </div>
  )
}

export default SearchGroups
