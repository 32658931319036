import gql from 'graphql-tag'

export const downloadPersonsDataByIdMutation = gql`
  mutation DownloadPersonsDataById(
    $candidateIds: [ID]!
    $fileName: String!
    $requisitionId: String
    $notificationToken: String
    $limited: Boolean!
  ) {
    downloadPersonsDataById(
      candidateIds: $candidateIds
      fileName: $fileName
      requisitionId: $requisitionId
      limited: $limited
      notificationToken: $notificationToken
    ) {
      creditsUsed
      fileName
      id
      url
      validated
    }
  }
`

export const downloadPersonsDataByFiltersMutation = gql`
  mutation DownloadPersonsDataByFilters(
    $filters: JSON!
    $fileName: String!
    $limited: Boolean!
    $notificationToken: String
    $recordSize: Int!
  ) {
    downloadPersonsDataByFilters(
      filters: $filters
      fileName: $fileName
      limited: $limited
      notificationToken: $notificationToken
      recordSize: $recordSize
    ) {
      creditsUsed
      fileName
      id
      url
      validated
    }
  }
`

export const downloadQueryObjects = {
  downloadPersonsDataByFilters: {
    mutation: downloadPersonsDataByFiltersMutation,
    name: 'downloadPersonsDataByFilters',
  },
  downloadPersonsDataById: {
    mutation: downloadPersonsDataByIdMutation,
    name: 'downloadPersonsDataById',
  },
}
