import feedback from '../../../utils/feedback'

const { useMutation } = require('@apollo/client')

const {
  submitHelpRequestMutation,
} = require('../../../apolloClient/operations/userFeedback/submitHelpRequestMutation')

export const useHelpRequest = () => {
  const [submitHelpRequest, { loading: submittingHelpRequest }] = useMutation(
    submitHelpRequestMutation,
    {
      onCompleted: () =>
        feedback.success({ content: 'Request sent successfully' }),
    },
  )

  return { submitHelpRequest, submittingHelpRequest }
}
