import React from 'react'

import { Icon, List, Popconfirm, Switch } from 'antd'
import PT from 'prop-types'

import { useDeleteUser } from '../../../../../../../../../hooks/data/admin/useDeleteUser'
import { useDisableUserAccess } from '../../../../../../../../../hooks/data/admin/useDisableMemberAccess'
import { useDisableUserAdminAccess } from '../../../../../../../../../hooks/data/admin/useDisableUserAdminAccess'
import { useEnableUserAccess } from '../../../../../../../../../hooks/data/admin/useEnableMemberAccess'
import { useEnableUserAdminAccess } from '../../../../../../../../../hooks/data/admin/useEnableUserAdminAccess'

const MemberListItem = props => {
  const { enabled, email, firstName, lastName, id, isAdmin } = props
  const fullName = `${firstName} ${lastName}`
  const { deleteUser } = useDeleteUser()
  const { enableUserAccess } = useEnableUserAccess()
  const { disableUserAccess } = useDisableUserAccess()
  const { enableUserAdminAccess } = useEnableUserAdminAccess()
  const { disableUserAdminAccess } = useDisableUserAdminAccess()

  const onAccessChange = value => {
    const payload = { variables: { userId: id } }
    if (value) {
      enableUserAccess(payload)
    } else {
      disableUserAccess(payload)
    }
  }

  const onAdminAccessChange = value => {
    const payload = { variables: { userId: id } }
    if (value) {
      enableUserAdminAccess(payload)
    } else {
      disableUserAdminAccess(payload)
    }
  }

  const onDeleteUser = () => {
    deleteUser({ userId: id })
  }

  return (
    <List.Item className="admin-list-item">
      <List.Item.Meta title={fullName} description={email} />
      <div className="action-container" style={{ marginRight: 55 }}>
        <Switch
          defaultChecked={isAdmin}
          value={isAdmin}
          onChange={onAdminAccessChange}
        />
      </div>
      <div className="action-container" style={{ marginRight: 30 }}>
        <Switch
          defaultChecked={enabled}
          value={enabled}
          onChange={onAccessChange}
        />
      </div>
      <div className="action-container">
        <Popconfirm
          title="Are you sure you want to delete this user? Your monthly charge will be reduced by one seat."
          onConfirm={onDeleteUser}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Icon style={{ fontSize: 20 }} type="delete" theme="twoTone" />
        </Popconfirm>
      </div>
    </List.Item>
  )
}

MemberListItem.propTypes = {
  accessDenied: PT.bool.isRequired,
  email: PT.string.isRequired,
  fullName: PT.string.isRequired,
}

export default MemberListItem
