import React from 'react'

import { Icon, Tooltip } from 'antd'

import colors from '../../hocs/colors'

const MoreInfo = props => {
  const { text } = props

  return (
    <Tooltip placement="topLeft" title={text}>
      <Icon type="info-circle" style={{ color: colors.primary, padding: 6 }} />
    </Tooltip>
  )
}

export default MoreInfo
