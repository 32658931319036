import gql from 'graphql-tag'

export const getUserDetailsForSearchQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      isSystemAdmin
      isAdmin
      roles
      client {
        trialExpired
        settings {
          id
          credits
        }
        id
        name
        subscription {
          id
          needsUpdatedPayment
        }
        jobRequisitions {
          id
          name
          savedSearches {
            id
          }
          candidateData {
            id
          }
        }
      }
    }
  }
`
