import React from 'react'

import { Layout } from 'antd'

import UnauthenticatedHeader from '../UnauthenticatedHeader'

const CenteredAuthenticatedLayout = props => {
  const { borderBottom = true } = props
  return (
    <Layout>
      <UnauthenticatedHeader borderBottom={borderBottom} />
      {props.children}
    </Layout>
  )
}

export default CenteredAuthenticatedLayout
