import { useMutation } from '@apollo/client'

import { editClientCustomBrandingMutation } from '../../../apolloClient/operations/clientCustomBranding/clientCustomBranding'
import feedback from '../../../utils/feedback'

export const useEditClientCustomBranding = ({ onCompleted }) => {
  const [editCustomBranding] = useMutation(editClientCustomBrandingMutation, {
    onCompleted,
    onError: () =>
      feedback.error({
        title: 'Something went wrong! Please try again',
      }),
  })

  return { editCustomBranding }
}
