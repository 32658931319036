import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import get from 'lodash/get'

import errorService from '../../../../utils/analytics/error'

export const pullTotalChargeOnResubscribeQuery = gql`
  query PullTotalChargeOnResubscribe($couponCode: String) {
    pullTotalChargeOnResubscribe(couponCode: $couponCode) {
      chargeItems {
        amount
        description
        qty
        timePeriod
        unitPrice
      }
      discount {
        amount
        couponCode
        discountDisplay
      }
      dueDate
      subtotal
      total
    }
  }
`

const useChargeOnResubscribe = variables => {
  const { data, loading } = useQuery(pullTotalChargeOnResubscribeQuery, {
    fetchPolicy: 'network-only',
    onError: error => {
      errorService.report(error, 'Get charges on resubscribe for customer')
    },
    variables,
  })
  const chargesOnResubscribe = get(data, 'pullTotalChargeOnResubscribe')

  return {
    chargesOnResubscribe,
    loadingCharges: loading,
  }
}

export default useChargeOnResubscribe
