import gql from 'graphql-tag'

export const addCandidatesToJobRequisitionMutation = gql`
  mutation AddCandidatesToJobRequisition(
    $requisitionId: String!
    $candidatesToAdd: JSON!
  ) {
    addCandidatesToJobRequisition(
      requisitionId: $requisitionId
      candidatesToAdd: $candidatesToAdd
    ) {
      id
      name
      candidateData {
        id
      }
    }
  }
`
