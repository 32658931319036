import gql from 'graphql-tag'

export const getUserForCreditsTabQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      isAdmin
      client {
        id
        settings {
          id
          credits
        }
      }
    }
  }
`
