import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { getRequisiitonsQuery } from '../../../apolloClient/operations/requisitions/getRequisitionsQuery'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useRequisitions = () => {
  const { data, loading } = useQuery(getRequisiitonsQuery, {
    onError: error => {
      errorService.report(error, 'getJobRequisitionsQuery')
      feedback.error({
        title: 'Error fetching Crowds..Please try again',
      })
    },
  })
  const userId = get(data, 'getUserProfile.id')
  const jobRequisitions = get(data, 'getUserProfile.client.jobRequisitions', [])
  return { jobRequisitions, loadingRequisitions: loading, userId }
}
