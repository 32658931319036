import firebase from 'firebase/app'
import 'firebase/messaging'
import get from 'lodash/get'
import feedback from '../feedback'

const config = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: 'peak-castle-237819.firebaseapp.com',
  databaseURL: 'https://peak-castle-237819.firebaseio.com',
  projectId: 'peak-castle-237819',
  storageBucket: 'peak-castle-237819.appspot.com',
  messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID
}

firebase.initializeApp(config)
let messaging
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (firebase.messaging.isSupported()) {
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(firebaseToken => {
          console.log(firebaseToken)
          resolve(firebaseToken)
        })
        .catch(err => {
          console.log(err)
          resolve(null)
          //reject(err)
        })
    } else {
      resolve(null)
    }
  })

export const onMessageListener = () =>
  new Promise(resolve => {
    if (firebase.messaging.isSupported()) {
      messaging.onMessage(payload => {
        console.log('Message received FCM ', payload)
        let { title, body } = payload.data

        if (!title && !body) {
          title = get(payload, 'notification.title', null)
          body = get(payload, 'notification.body', null)
        }

        if (title || body) feedback.info({ content: body, title, duration: 10 })
        resolve(payload)
      })
    } else {
      resolve(null)
    }
  })
