import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { pullTotalChargeForAddSeatsQuery } from '../../../apolloClient/operations/admin/totalChargeForAddSeatsQuery'
import errorService from '../../../utils/analytics/error'

const useTotalChargeForAddSeats = seats => {
  const { data, loading } = useQuery(pullTotalChargeForAddSeatsQuery, {
    onError: error =>
      errorService.report(error, 'Get add seats charge for customer'),
    variables: { seats },
  })
  const addSeatsCharge = get(data, 'pullTotalChargeForAddSeats')

  return { addSeatsCharge, loading }
}

export default useTotalChargeForAddSeats
