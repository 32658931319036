import React, { useState } from 'react'

import { Select, Spin, Icon, Tooltip } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import get from 'lodash/get'

import { apolloClient } from '../../../../apolloClient'
import { locationAutocompleteSuggestionsQuery } from '../../../../apolloClient/operations/lookup/autocompleteSuggestionsQueries'
import { locationGeometryDetailsQuery } from '../../../../apolloClient/operations/lookup/locationGeometryDetailsQuery'

const { Option } = Select
const DefaultRadius = 10
const distanceOptions = [10, 30, 50, 100, 150]

const LocationInput = ({ selectedLocation, updateAppliedFilters }) => {
  const [fetchingAutoSuggestions, setFetchingAutoSuggestions] = useState(false)
  const [autoSuggestions, setAutoSuggestions] = useState([])

  const onChangeLocation = async selectedName => {
    let objToUpdate = {}
    if (selectedName) {
      if (selectedLocation) objToUpdate = cloneDeep(selectedLocation)

      const selectedObj = find(
        autoSuggestions,
        o => o.description === selectedName,
      )

      objToUpdate['name'] = get(selectedObj, 'description')
      if (!objToUpdate.radius) objToUpdate['radius'] = DefaultRadius

      // get lat and long values for selected location
      try {
        const result = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: locationGeometryDetailsQuery,
          variables: { placeId: get(selectedObj, 'place_id', '') },
        })

        objToUpdate['geoData'] = get(result, 'data.locationGeometryDetails')
      } catch (error) {
        console.log(error)
      }
    } else {
      objToUpdate = null
    }

    updateAppliedFilters('location', objToUpdate)
  }

  const onChangeRadius = value => {
    let objToUpdate = {}
    if (selectedLocation) objToUpdate = cloneDeep(selectedLocation)

    objToUpdate['radius'] = value

    updateAppliedFilters('location', objToUpdate)
  }

  const fetchAutoSuggestions = async prefix => {
    if (prefix && prefix !== '') {
      try {
        setFetchingAutoSuggestions(true)
        const result = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: locationAutocompleteSuggestionsQuery,
          variables: { prefix },
        })

        const autoSuggestions = get(
          result,
          'data.locationAutocompleteSuggestions',
        )
        setAutoSuggestions(autoSuggestions)
      } catch (error) {
        setFetchingAutoSuggestions(false)
      }
    }
  }

  const fetchAutoSuggestionsWithDebounce = debounce(fetchAutoSuggestions, 500)

  const clearSuggestions = () => setAutoSuggestions([])

  return (
    <>
      <div style={{ marginBottom: 5 }}>
        {`Within `}
        <Select
          style={{ width: 68 }}
          onChange={onChangeRadius}
          value={get(selectedLocation, 'radius', DefaultRadius)}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {distanceOptions.map(opt => (
            <Option value={opt} key={opt}>
              {opt}
            </Option>
          ))}
        </Select>
        {` miles of `}
        <Tooltip
          placement="top"
          title="Search within radius of a specific point"
        >
          <Icon type="info-circle" />
        </Tooltip>
      </div>
      <div
        style={{
          margin: '0 auto',
          position: 'relative',
          width: '100%',
        }}
      >
        <Select
          style={{ width: '100%' }}
          showSearch
          allowClear
          showArrow={false}
          notFoundContent={
            fetchingAutoSuggestions ? <Spin size="small" /> : null
          }
          onSearch={fetchAutoSuggestionsWithDebounce}
          value={get(selectedLocation, 'name')}
          onChange={onChangeLocation}
          onFocus={clearSuggestions}
        >
          {autoSuggestions &&
            autoSuggestions.map(o => (
              <Option key={get(o, 'description', '')}>
                {get(o, 'description')}
              </Option>
            ))}
        </Select>
      </div>
    </>
  )
}

export default LocationInput
