import React from 'react'

import { Modal } from 'antd'
import { get } from 'lodash'

import { useClientCustomBranding } from '../../../../hooks/data/clientCustomBranding/useClientCustomBranding'
import { pickWithInitialState } from '../../../../utils/data/utils'
import { useCrowdSegmentDispatch } from '../../../Authenticated/CrowdSegment/crowd-context'

import PublicSearchModalContents from './components/PublicSearchModalContents'

const PublicSearchCTA = props => {
  const { visible } = props
  const { clientCustomBrandingData } = useClientCustomBranding()

  const {
    header,
    subheader,
    buttonText,
    buttonLink,
    disclaimer,
  } = pickWithInitialState(props, [
    ['header', get(clientCustomBrandingData, 'header')],
    ['subheader', get(clientCustomBrandingData, 'subheader')],
    ['buttonText', get(clientCustomBrandingData, 'buttonText')],
    ['buttonLink', get(clientCustomBrandingData, 'buttonLink')],
    ['disclaimer', get(clientCustomBrandingData, 'disclaimer')],
  ])

  const { setPublicSearchCTAVisible } = useCrowdSegmentDispatch()
  const handleCancel = () => {
    setPublicSearchCTAVisible(false)
  }
  return (
    <Modal visible={visible} maskClosable onCancel={handleCancel} footer={null}>
      <PublicSearchModalContents
        header={header}
        subheader={subheader}
        buttonText={buttonText}
        buttonLink={buttonLink}
        disclaimer={disclaimer}
      />
    </Modal>
  )
}

export default PublicSearchCTA
