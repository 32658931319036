import React, { useEffect } from 'react'

// import _ from 'lodash'
import { Button, Form, Input } from 'antd'
import get from 'lodash/get'
import PT from 'prop-types'

import usePrevious from '../../../hooks/common/usePrevious'
import {
  useCrowdSegmentDispatch,
  useCrowdSegmentState,
} from '../../../routes/Authenticated/CrowdSegment/crowd-context'
import feedback from '../../../utils/feedback'

const LoginForm = props => {
  const showImpersonation = get(props, 'showImpersonation')
  const onSubmit = get(props, 'onSubmit')
  const loggingIn = get(props, 'loggingIn')
  const getFieldDecorator = get(props, 'form.getFieldDecorator')
  const { unauthenticatedUser } = useCrowdSegmentState()
  const { setUnauthenticatedUser } = useCrowdSegmentDispatch()

  const prevUnauthenticatedUser = usePrevious(unauthenticatedUser)

  const FORM_FIELD_TITLES = {
    EMAIL: 'email',
    IMPERSONATEE_EMAIL: 'impersonateeEmail',
    PASSWORD: 'password',
  }

  useEffect(() => {
    if ((FORM_FIELD_TITLES.EMAIL, unauthenticatedUser, props.form)) {
      const currEmail = get(unauthenticatedUser, 'email', null)
      const { setFieldsValue } = get(props.form, 'setFieldsValue')
      if (setFieldsValue) {
        setFieldsValue({ [FORM_FIELD_TITLES.EMAIL]: currEmail })
      }
    }
  }, [FORM_FIELD_TITLES.EMAIL, unauthenticatedUser, props.form])

  useEffect(() => {
    const prevEmail = get(prevUnauthenticatedUser, 'email', null)
    const currEmail = get(unauthenticatedUser, 'email', null)
    if (!prevEmail && !!currEmail) {
      const { setFieldsValue } = get(props.form, 'setFieldsValue')
      if (setFieldsValue) {
        setFieldsValue({ [FORM_FIELD_TITLES.EMAIL]: currEmail })
      }
    }
  }, [
    prevUnauthenticatedUser,
    unauthenticatedUser,
    FORM_FIELD_TITLES.EMAIL,
    props.form,
  ])

  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (err) {
        feedback.error({ title: err })
      }
      if (!err) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form hideRequiredMark onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator(FORM_FIELD_TITLES.EMAIL, {
          rules: [
            { message: 'A value is required', required: true },
            { message: 'Please provide a valid email', type: 'email' },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(
          <Input
            placeholder="Email"
            id={FORM_FIELD_TITLES.EMAIL}
            onChange={e =>
              setUnauthenticatedUser({ email: get(e, 'target.value') })
            }
          />,
        )}
      </Form.Item>
      {showImpersonation && (
        <Form.Item>
          {getFieldDecorator(FORM_FIELD_TITLES.IMPERSONATEE_EMAIL, {
            rules: [
              { message: 'A value is required', required: true },
              { message: 'Please provide a valid email', type: 'email' },
              { max: 250, message: 'Must be less than 250 characters' },
            ],
          })(
            <Input
              placeholder="Email of Impersonatee"
              id={FORM_FIELD_TITLES.IMPERSONATEE_EMAIL}
            />,
          )}
        </Form.Item>
      )}
      <Form.Item>
        {getFieldDecorator(FORM_FIELD_TITLES.PASSWORD, {
          rules: [
            {
              message: 'Cannot contain only whitespace',
              whitespace: true,
            },
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
            {
              message:
                'Password must be at least 8 characters long and contain at least one lower and one upper case letter, and one number',
              // eslint-disable-next-line no-useless-escape
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{6,99}$/,
            },
          ],
        })(
          <Input
            placeholder="Password"
            id={FORM_FIELD_TITLES.PASSWORD}
            type="password"
          />,
        )}
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          loading={loggingIn}
          htmlType="submit"
          style={{ width: '100%' }}
          type="primary"
          data-testid="signup-button"
        >
          Log In
        </Button>
      </div>
    </Form>
  )
}

LoginForm.propTypes = {
  loggingIn: PT.bool,
  onSubmit: PT.func.isRequired,
  showImpersonation: PT.bool,
  unauthenticatedUser: PT.object,
}

LoginForm.defaultProps = {
  onSubmit: console.log,
}

const wrappedForm = Form.create({ name: 'LoginForm' })(LoginForm)
export default wrappedForm
