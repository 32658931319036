import React, { useState, useEffect } from 'react'

import { Select, Col, Spin, Icon, Tooltip } from 'antd'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { apolloClient } from '../../../../apolloClient'
import { countQuery } from '../../../../apolloClient/operations/search/dataQueries'
import usePrevious from '../../../../hooks/common/usePrevious/index'
import numberConverter from '../../../../utils/numberConverter'
import { capitalize } from '../../../../utils/stringManipulation'

const { Option } = Select

const SearchBarWithStaticSuggestions = ({
  info,
  name,
  options,
  searchMode,
  selectedFilters,
  title,
  transformValue,
  updateAppliedFilters,
}) => {
  const [fetchingCounts, setFetchingCounts] = useState(false)
  const [count, setCount] = useState(null)

  const prevFilters = usePrevious(selectedFilters)

  const onSelectChange = value => {
    const finalValue = transformValue ? transformValue(value) : value
    updateAppliedFilters(name, finalValue)
  }

  const fetchCounts = async filters => {
    if (isEqual(prevFilters, filters)) {
      return
    }
    if (!filters || !filters.length) {
      setCount(null)
      return
    }
    setFetchingCounts(true)
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: countQuery,
        variables: { filters: { [name]: filters } },
      })
      const count = get(result, 'data.count')
      setCount(count)
      setFetchingCounts(false)
    } catch (error) {
      console.log(error)
      setFetchingCounts(false)
    }
  }

  const fetch = filters => {
    fetchCounts(filters)
  }

  // eslint-disable-next-line
  useEffect(() => fetch(selectedFilters), [selectedFilters])

  return (
    <Col lg={24} md={24}>
      <div
        style={{
          margin: '0 auto',
          marginBottom: 10,
          position: 'relative',
          width: '95%',
        }}
      >
        <span style={{ fontSize: 12, fontWeight: 'bold' }}>
          {title}{' '}
          {info && (
            <Tooltip placement="top" title={info}>
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </span>
        <span
          style={{
            float: 'right',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          {fetchingCounts ? (
            <Spin size="small" />
          ) : (
            count > 0 && numberConverter(count)
          )}
        </span>
        <Select
          mode={searchMode}
          style={{ width: '100%' }}
          showSearch
          showArrow={false}
          value={selectedFilters}
          onChange={onSelectChange}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {options &&
            options.map(o => <Option key={o}>{capitalize(o)}</Option>)}
        </Select>
      </div>
    </Col>
  )
}

export default SearchBarWithStaticSuggestions
