import React from 'react'

import { Button } from 'antd'
import get from 'lodash/get'

import { pickWithInitialState } from '../../../../../utils/data/utils'
import { UNAUTHENTICATED_ROUTES } from '../../../../../utils/routing/routes'

export const brandingDefaults = {
  buttonLink: UNAUTHENTICATED_ROUTES.CS_SIGNUP,
  buttonText: 'Sign Up Now!',
  disclaimer: 'Try it for free. No credit card required',
  header: 'Your custom crowds are waiting!',
  subheader:
    'Sign up now to access contact info, companies, candidates, downloads, and more!',
}

const PublicSearchModalContents = props => {
  const {
    header,
    subheader,
    buttonText,
    buttonLink,
    disclaimer,
  } = pickWithInitialState(props, [
    ['header', get(brandingDefaults, 'header')],
    ['subheader', get(brandingDefaults, 'subheader')],
    ['buttonText', get(brandingDefaults, 'buttonText')],
    ['buttonLink', get(brandingDefaults, 'buttonLink')],
    ['disclaimer', get(brandingDefaults, 'disclaimer')],
  ])

  return (
    <div className="text-center" style={{ padding: '20px' }}>
      <h3>{header}</h3>
      <h4>{subheader}</h4>
      <Button type="primary" href={buttonLink} style={{ marginTop: 20 }}>
        {buttonText}
      </Button>
      <div className="text-muted" style={{ marginTop: 10 }}>
        {disclaimer}
      </div>
    </div>
  )
}

export default PublicSearchModalContents
