import React, { useState } from 'react'

import { Button, Drawer, Icon, Select, Table, Tooltip } from 'antd'
import Meta from 'antd/lib/card/Meta'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import head from 'lodash/head'
import { useLocation } from 'react-router-dom'

import { downloadQueryObjects } from '../../../../apolloClient/operations/downloads/downloads'
import CandidateDownload from '../../../../components/TableColumns/components/CandidateDownload'
import FindEmail from '../../../../components/TableColumns/FIndEmail/index.js'
import PersonEducationColumn from '../../../../components/TableColumns/PersonEducationColumn'
import PersonEmployerColumn from '../../../../components/TableColumns/PersonEmployerColumn'
import PersonNameColumn from '../../../../components/TableColumns/PersonNameColumn'
import PersonSalaryColumn from '../../../../components/TableColumns/PersonSalaryColumn'
import colors from '../../../../hocs/colors'
import { useDownloadFile } from '../../../../hooks/data/downloads/useDownloadFile'
import { useGetCreditsUsageForEmailVerification } from '../../../../hooks/data/downloads/useGetCreditsUsageForEmailVerification'
import { useGetCandidatesById } from '../../../../hooks/data/lookup/useGetCandidatesDataById'
import { TE_CANDIDATE_SORT_OPTIONS } from '../../../../utils/applicationConstants'
import numberConverter from '../../../../utils/numberConverter'
import { isPublic } from '../../../../utils/routing/routes'
import { useCrowdSegmentDispatch } from '../crowd-context'
import { searchSettings } from '../static/searchSettings'

import CandidateView from './CandidateView'
import SaveCandidatesForm from './components/SaveCandidatesForm'
import PaymentRequestAlert from './PaymentRequestAlert'

const { Option } = Select
const BOOST_SCORE_BASE_VALUE = 10

const getCandidatesSortFilterFromValue = value => {
  switch (value) {
    case 'salary_desc':
      return {
        company_sizes: null,
        salary: BOOST_SCORE_BASE_VALUE,
        tenure: null,
      }
    case 'salary_asc':
      return {
        company_sizes: null,
        salary: -BOOST_SCORE_BASE_VALUE,
        tenure: null,
      }
    case 'co_size_desc':
      return {
        company_sizes: BOOST_SCORE_BASE_VALUE,
        salary: null,
        tenure: null,
      }
    case 'co_size_asc':
      return {
        company_sizes: -BOOST_SCORE_BASE_VALUE,
        salary: null,
        tenure: null,
      }
    case 'tenure_desc':
      return {
        company_sizes: null,
        salary: null,
        tenure: BOOST_SCORE_BASE_VALUE,
      }
    case 'tenure_asc':
      return {
        company_sizes: null,
        salary: null,
        tenure: -BOOST_SCORE_BASE_VALUE,
      }
    default:
      return {
        company_sizes: null,
        salary: null,
        tenure: null,
      }
  }
}

const getCandidatesSortValueFromFilter = (filter = {}) => {
  const salarySortValue = get(filter, 'salary')
  const sizeSortValue = get(filter, 'company_sizes')
  const tenureSortValue = get(filter, 'tenure')

  if (salarySortValue) {
    if (salarySortValue > 0) return 'salary_desc'
    else return 'salary_asc'
  } else if (sizeSortValue) {
    if (sizeSortValue > 0) return 'co_size_desc'
    else return 'co_size_asc'
  } else if (tenureSortValue) {
    if (tenureSortValue > 0) return 'tenure_desc'
    else return 'tenure_asc'
  } else return 'sort'
}

const CandidatesTab = ({
  data,
  extraFiltersToApply,
  filtersToApply,
  handleSubmit,
  loading,
  openPaymentModal,
  page,
  permissions,
  setPage,
  suppliedFilters,
  teDownloadCredits,
  totalMatches,
  toggleFilter,
  selectedSkills,
}) => {
  const [downloading] = useState(false)
  const [candidatesFormShown, setCandidatesFormShown] = useState(false)
  const [selectedCandidateData, setSelectedCandidateData] = useState([])
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState(false)
  const {
    candidatesData,
    getCandidatesDataById,
    loadingCandidateDetails,
  } = useGetCandidatesById()

  const {
    triggerDownload: triggerDownloadById,
    loading: requestingDownloadById,
  } = useDownloadFile({
    queryObject: downloadQueryObjects.downloadPersonsDataById,
  })

  const {
    triggerDownload: triggerDownloadByFilters,
    loading: requestingDownloadByFilters,
  } = useDownloadFile({
    queryObject: downloadQueryObjects.downloadPersonsDataByFilters,
  })

  const {
    creditChargePerVerification,
    gettingCreditsUsageForEmailVerification,
  } = useGetCreditsUsageForEmailVerification()

  const selectedCandidateIds = selectedCandidateData.map(
    candidate => candidate.id,
  )

  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const {
    setPublicSearchCTAVisible,
    updateCandidate,
  } = useCrowdSegmentDispatch()

  const toggleSkill = skillName =>
    toggleFilter(searchSettings.required_skills.name, skillName)

  const onSortOptionsChange = value => {
    const updatedFilters = cloneDeep(extraFiltersToApply)
    updatedFilters[
      'candidates_sort_options'
    ] = getCandidatesSortFilterFromValue(value)

    handleSubmit(null, {
      ...updatedFilters,
      ...filtersToApply,
    })
  }
  const showCandidateDetail = async candidateId => {
    try {
      getCandidatesDataById({
        variables: {
          candidateIds: [candidateId],
        },
      })
      setFiltersDrawerVisible(true)
    } catch (error) {
      console.log(error)
    }
  }

  const getColumns = () => [
    {
      dataIndex: 'full_name',
      key: 'full_name',
      render: (val, row) => (
        <PersonNameColumn
          val={val}
          row={row}
          handleNameClick={() =>
            isPublicRoute
              ? setPublicSearchCTAVisible(true)
              : showCandidateDetail(get(row, 'id'))
          }
          sensitiveDataHidden={false}
        />
      ),
      title: 'Candidate',
    },
    {
      dataIndex: 'current_position',
      key: 'current_position',
      render: (val, row) => (
        <PersonEmployerColumn row={row} currentPosition={val} />
      ),
      title: 'Current Position',
    },
    {
      dataIndex: 'highest_edu',
      key: 'highest_edu',
      render: (val, row) => <PersonEducationColumn val={val} row={row} />,
      title: 'Highest Education',
    },
    {
      dataIndex: 'city',
      key: 'city',
      render: (val, row) => <PersonSalaryColumn val={val} row={row} />,
      title: 'Est. Base Salary',
      width: 170,
    },
    {
      dataIndex: 'email',
      key: 'id',
      render: (val, row) => {
        return (
          <FindEmail
            creditChargePerVerification={creditChargePerVerification}
            gettingCreditsUsageForEmailVerification={
              gettingCreditsUsageForEmailVerification
            }
            val={val}
            row={row}
            setPublicSearchCTAVisible={setPublicSearchCTAVisible}
            noCreditsLeft={!isPublicRoute && teDownloadCredits < 0.5}
            isPublicRoute={isPublicRoute}
            updateCandidate={updateCandidate}
          />
        )
      },
      title: 'Email',
    },
  ]

  const downloadFile = async ({
    fileName,
    toggleModal,
    candidateSize,
    limited,
  }) => {
    const downloadingByCandidateId = selectedCandidateIds.length > 0
    const variables = downloadingByCandidateId
      ? { candidateIds: selectedCandidateIds, fileName, limited }
      : {
          fileName,
          filters: suppliedFilters,
          limited,
          recordSize: candidateSize,
        }
    if (downloadingByCandidateId) {
      triggerDownloadById({ variables })
    } else {
      triggerDownloadByFilters({ variables })
    }

    toggleModal()
  }

  const toggleCandidatesForm = () => {
    setCandidatesFormShown(!candidatesFormShown)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCandidateData(
        selectedRows.map(candidate => {
          return {
            id: candidate.id,
          }
        }),
      )
    },
  }

  const saveDisabled = selectedCandidateData.length > 0 ? false : true
  const handleSaveCandidatesClick = () => {
    if (isPublicRoute) {
      setPublicSearchCTAVisible(true)
    } else {
      toggleCandidatesForm()
    }
  }

  return (
    <>
      <Drawer
        title="Candidate View"
        placement="right"
        closable={true}
        destroyOnClose
        getContainer={false}
        onClose={() => setFiltersDrawerVisible(false)}
        visible={filtersDrawerVisible}
        style={{ overflow: 'auto' }}
        width={500}
        zIndex={1002}
      >
        <PaymentRequestAlert
          permissions={permissions}
          openPaymentModal={openPaymentModal}
          styles={{ marginBottom: 20 }}
        />
        <CandidateView
          loading={loadingCandidateDetails}
          candidateData={head(candidatesData)}
          toggleCandidatesForm={toggleCandidatesForm}
          setSelectedCandidateData={setSelectedCandidateData}
          allowSave={true}
          permissions={permissions}
          openPaymentModal={openPaymentModal}
          showCompanyView={true}
          toggleSkill={toggleSkill}
          selectedSkills={selectedSkills}
        />
      </Drawer>
      <div className="results-table">
        <SaveCandidatesForm
          visible={candidatesFormShown}
          toggleModal={toggleCandidatesForm}
          selectedCandidates={selectedCandidateData}
          isPublicRoute={isPublicRoute}
        />
        <CandidateDownload
          downloading={downloading}
          requestingDownload={
            requestingDownloadByFilters || requestingDownloadById
          }
          downloadFile={downloadFile}
          permissions={permissions}
          openPaymentModal={openPaymentModal}
          totalMatches={totalMatches}
          teDownloadCredits={teDownloadCredits}
          selectedCandidateIds={selectedCandidateIds}
          isPublicRoute={isPublicRoute}
          setPublicSearchCTAVisible={setPublicSearchCTAVisible}
        />
        {saveDisabled ? (
          <Tooltip title="Select candidates to save">
            <Icon
              type="save"
              style={{
                color: colors.gray2,
                cursor: 'not-allowed',
                float: 'right',
                fontSize: 40,
                margin: 12,
                width: 40,
              }}
              onClick={saveDisabled ? null : toggleCandidatesForm}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Save Candidates">
            <Icon
              type="save"
              style={{
                color: colors.primary,
                cursor: saveDisabled ? 'not-allowed' : 'pointer',
                float: 'right',
                fontSize: 40,
                margin: 12,
                width: 40,
              }}
              onClick={saveDisabled ? null : handleSaveCandidatesClick}
            />
          </Tooltip>
        )}
        <div
          style={{
            float: 'right',
            marginRight: 10,
          }}
        >
          <div style={{ fontSize: 12, fontWeight: 'bold' }}>Crowd Control</div>
          <Select
            loading={loading}
            onChange={onSortOptionsChange}
            style={{ width: 210 }}
            value={getCandidatesSortValueFromFilter(
              get(suppliedFilters, 'candidates_sort_options'),
            )}
          >
            {TE_CANDIDATE_SORT_OPTIONS.map(o => (
              <Option key={o.value} value={o.value}>
                {o.display}
              </Option>
            ))}
          </Select>
        </div>
        <Meta
          title="Matched Candidates"
          description="View and download information on your segmented crowd."
          style={{ marginLeft: 16, marginRight: 16 }}
        />
        <Table
          rowSelection={rowSelection}
          loading={loading}
          columns={getColumns()}
          dataSource={data}
          pagination={
            isPublicRoute ? false : { current: page, onChange: setPage }
          }
          rowKey={record => `${get(record, 'id')}`}
        />
        {isPublicRoute && (
          <div
            style={{
              background: '#d4d4de45',
              borderRadius: 10,
              margin: '50px 20px',
              padding: '40px 20px',
              textAlign: 'center',
            }}
          >
            {totalMatches < 5 ? (
              <h4>Sign up today to access the full power of CrowdSegment!</h4>
            ) : (
              <h4>
                Sign up today to access{' '}
                <b>{numberConverter(totalMatches - data.length)}</b> other
                people in this search!
              </h4>
            )}
            <Button
              type="primary"
              href="/signup"
              size="large"
              style={{ marginTop: 15 }}
            >
              Sign Up Now
            </Button>
            <div className="text-muted" style={{ marginTop: 10 }}>
              Try it for free. No credit card required
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CandidatesTab
