import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import colors from '../../../../../hocs/colors'
import numberConverter from '../../../../../utils/numberConverter'
import { capitalize } from '../../../../../utils/stringManipulation'

const DistributionChart = ({
  title,
  containerProps,
  data,
  capitalizeLabels,
  height,
}) => {
  const options = {
    chart: {
      height: height,
      type: 'column',
    },
    credits: {
      enabled: false, // this is to not show highcharts.com text
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: 4,
        pointWidth: 40,
      },
    },
    series: [
      {
        color: colors.blue2,
        data: data,
      },
    ],
    title: undefined,
    tooltip: {
      formatter: function() {
        return `${numberConverter(this.point.y)} people`
      },
    },
    xAxis: {
      labels: {
        formatter: function() {
          if (capitalizeLabels) {
            return `${capitalize(this.value)}`
          } else {
            return this.value
          }
        },
        style: {
          fontFamily: 'Verdana, sans-serif',
          fontSize: '13px',
        },
      },
      type: 'category',
    },
    yAxis: {
      endOnTick: false,
      visible: false,
    },
  }

  return (
    <div>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={options}
      />
      <div className="text-center" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        {title}
      </div>
    </div>
  )
}

export default DistributionChart
