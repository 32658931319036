import React from 'react'

import { Col, Row } from 'antd'

import AuthenticatedLayout from '../components/AuthenticatedLayout'

import ComponentMenu from './components/ComponentMenu'
import MyAccountNavigator from './components/MyAccountNavigator'

const MyAccount = () => {
  return (
    <AuthenticatedLayout title="My Account" gutters={true}>
      <div className="max-1200">
        <Row type="flex" align="top" gutter={16}>
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <ComponentMenu />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <MyAccountNavigator />
          </Col>
        </Row>
      </div>
    </AuthenticatedLayout>
  )
}

export default MyAccount
