import React from 'react'
import get from 'lodash/get'
import NotFoundColumn from './NotFoundColumn'
import numberConverter from '../../utils/numberConverter'
import { roundUpToNearestDecimal } from '../../utils/numbers'

const CompanySalaryColumn = props => {
  const val = get(props, 'val')
  const row = get(props, 'row')
  const tenure = get(row, 'average_tenure')
  return !!val || !!tenure ? (
    <>
      {val && (
        <div style={{ fontSize: 16, color: 'green' }}>
          ${numberConverter(val)}
        </div>
      )}
      {tenure && <div>{roundUpToNearestDecimal(tenure, 10)} years</div>}
    </>
  ) : (
    <NotFoundColumn centered={true} />
  )
}

export default CompanySalaryColumn
