import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { getUserForAuthQuery } from '../../../apolloClient/operations/common/auth'
import { useSendUserData } from '../useSendUserData'

const useUserForAuth = () => {
  const { loading, data, error, refetch } = useQuery(getUserForAuthQuery)
  const userProfile = get(data, 'getUserProfile')

  useSendUserData(userProfile)

  // Send email tag to Lucky Orange recording
  window._loq = window._loq || [] // ensure queue available
  window._loq.push(['tag', get(userProfile, 'email')])

  return {
    errorGettingUserProfile: error,
    gettingUserProfile: loading,
    refetch,
    userProfile,
  }
}

export default useUserForAuth
