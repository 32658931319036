import React from 'react'

import { Button, Row, Col } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'
import PT from 'prop-types'
import { Field, Form } from 'react-final-form'

import FormTextInput from '../../../../components/forms/FormTextInput/index'

const RequisitionDetailForm = props => {
  const onSubmit = get(props, 'onSubmit')

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        const disabled =
          !values.name || (values.name && !values.name.trim().length)
        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Meta
                title="Create Crowd"
                description="Use Crowds to keep track of candidate segments."
                style={{ marginBottom: 15 }}
              />
              <Row style={{ marginBottom: 30 }}>
                <Col>
                  <Field
                    data-testid="project-name-input"
                    autoFocus
                    label="Crowd Name*"
                    name="name"
                    component={FormTextInput}
                    width="80%"
                  />
                </Col>
              </Row>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Button
                data-testid="create-project"
                htmlType="submit"
                type="primary"
                disabled={disabled}
                size="large"
                block
              >
                Create Crowd
              </Button>
            </div>
          </form>
        )
      }}
    />
  )
}

RequisitionDetailForm.propTypes = {
  onSubmit: PT.func.isRequired,
}

export default RequisitionDetailForm
