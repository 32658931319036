import { useMutation } from '@apollo/client'

import { createClientCustomBrandingMutation } from '../../../apolloClient/operations/clientCustomBranding/clientCustomBranding'
import feedback from '../../../utils/feedback'

export const useCreateClientCustomBranding = ({ onCompleted }) => {
  const [createCustomBranding] = useMutation(
    createClientCustomBrandingMutation,
    {
      onCompleted,
      onError: () =>
        feedback.error({
          title: 'Something went wrong! Please try again',
        }),
    },
  )

  return { createCustomBranding }
}
