import React from 'react'

import { Carousel, Col, Row, Icon, Typography } from 'antd'
import get from 'lodash/get'
import { Link, useLocation } from 'react-router-dom'

import { encryptStr } from '../../../../../utils/crypto/cryptoUtils'
import { createQueryStringForTalentSearch } from '../../../../../utils/data/utils'
import {
  AUTHENTICATED_ROUTES,
  isPublic,
} from '../../../../../utils/routing/routes'
import { useCrowdSegmentDispatch } from '../../../CrowdSegment/crowd-context'

const { Paragraph } = Typography

const popularSearches = [
  {
    searchParams: {
      candidates_keywords: [
        'machine learning',
        'data science',
        'data scientist',
        'ai engineer',
      ],
      include_previous: false,
    },
    tagline: 'Recruiting',
    title: 'Data Science and ML Talent in the US',
  },
  {
    searchParams: {
      grad_years: ['2019', '2020', '2021', '2022'],
      include_previous: false,
      industries: ['Retail', 'Hospitality'],
      required_skills: ['spanish language', 'customer service'],
    },
    tagline: 'Recruiting',
    title: 'Bilingual Customer Service Reps (Recent Grads)',
  },
  {
    searchParams: {
      include_previous: false,
      required_skills: ['marketing automation'],
      skills_keywords: [
        'marketing workflows',
        'marketing sequences',
        'hubspot marketing',
        'google analytics marketing',
      ],
    },
    tagline: 'Recruiting',
    title: 'Marketing Automation Professionals',
  },
  {
    searchParams: {
      age: [22, 30],
      include_previous: false,
      job_category: ['Information Technology'],
      salary: [100000, 190000],
      years_of_experience: [1, 5],
    },
    tagline: 'Marketing and Prospecting',
    title: 'High Income Techies Under 30',
  },
  {
    searchParams: {
      include_previous: false,
      job_titles: [
        'Accountant',
        'Staff Accountant',
        'Project Accountant',
        'Tax Accountant',
        'Financial Accountant',
        'Certified Public Accountant',
      ],
      required_skills: ['data analysis'],
    },
    tagline: 'Talent and Recruiting',
    title: 'Accountants with Data Analysis Skills',
  },
  {
    searchParams: {
      include_previous: true,
      industries: ['healthcare'],
      job_titles: ['Cyber Security Engineer', 'Cyber Security Specialist'],
    },
    tagline: 'Talent and Recruiting',
    title: 'Cyber Engineers - Healthcare Experience',
  },
  {
    searchParams: {
      grad_years: ['2019', '2020', '2021', '2022'],
      highest_edu: ['bachelors'],
      include_previous: false,
      job_titles: [
        'Data Scientist',
        'Business Insight And Analytics Manager',
        'Data Analytics Specialist',
        'Analytics Consultant',
        'Business Analytics Director',
        'Advanced Analytics Associate',
        'Business Data Analyst',
      ],
    },
    tagline: 'Recruiting',
    title: 'Recent Grads with Data Science Skills',
  },
  {
    searchParams: {
      grad_years: ['2021', '2020', '2019'],
      highest_edu: ['bachelors'],
      include_previous: false,
      majors: ['marketing'],
      required_skills: ['social media marketing'],
    },
    tagline: 'Recruiting',
    title: 'Recent Social Marketing Grads',
  },
  {
    searchParams: {
      certifications: ['CompTIA Network+'],
      include_previous: false,
    },
    tagline: 'Recruiting',
    title: 'CompTIA Network+ Certified Professionals',
  },
  {
    searchParams: {
      company_sizes: [
        ['10', '50'],
        ['50', '100'],
      ],
      include_previous: false,
      job_titles: ['Chief Executive Officer', 'Founder'],
      skills_keywords: ['blockchain', 'cryptocurrency'],
    },
    tagline: 'Marketing and Prospecting',
    title: 'Founders of Tech Startups in Blockchain',
  },
  {
    searchParams: {
      include_previous: false,
      industries: ['construction'],
      states: ['Texas'],
    },
    tagline: 'Total Addressable Market',
    title: 'Construction Companies in Texas',
  },
  {
    searchParams: {
      company_sizes: [
        [50, 100],
        [100, 1000],
      ],
      company_type: ['Educational Institution'],
      include_previous: false,
      job_category: ['executive'],
    },
    tagline: 'Total Addressable Market',
    title: 'Executives at Colleges and Universities',
  },
  {
    searchParams: {
      companies_keywords: ['logistics'],
      company_sizes: [['100', '1000']],
      include_previous: false,
    },
    tagline: 'Market Research',
    title: 'Logistics Companies 100 - 1,000 Employees',
  },
  {
    searchParams: {
      age: [22, 50],
      company_sizes: [['100', '1000'], ['1000']],
      degree_level: ['bachelors'],
      include_previous: false,
      industries: ['information technology'],
      job_category: ['executive'],
      location: {
        geoData: { lat: '33.7489954', lng: '-84.3879824' },
        name: 'Atlanta, GA, USA',
        radius: '150',
      },
    },
    tagline: 'Business Development',
    title: 'Tech Executives in Atlanta',
  },
  {
    searchParams: {
      company_sizes: [['1000']],
      include_previous: false,
      industries: ['real estate'],
      states: [
        'Florida',
        'Georgia',
        'Alabama',
        'North Carolina',
        'South Carolina',
        'Tennessee',
      ],
    },
    tagline: 'Business Development',
    title: 'Large Southeast Real Estate companies',
  },
  {
    searchParams: {
      companies_keywords: ['interior design', 'interiors'],
      include_previous: false,
      states: ['California'],
    },
    tagline: 'Total Addressable Market',
    title: 'Top Interior Design Firms in California',
  },
]

const SearchLink = props => {
  const { search } = props
  const { setPublicSearchCTAVisible } = useCrowdSegmentDispatch()

  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const handleLinkClick = e => {
    if (isPublicRoute) {
      e.preventDefault()
      setPublicSearchCTAVisible(true)
    }
  }
  return (
    <div className="cp-parent">
      <div className="cp-title">
        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{ fontSize: 20, fontWeight: 'bold' }}
        >
          <Link
            onClick={handleLinkClick}
            to={
              isPublicRoute
                ? null
                : `${
                    AUTHENTICATED_ROUTES.CROWD_SEGMENT
                  }/?encryptedParams=${encryptStr(
                    createQueryStringForTalentSearch(search.searchParams),
                  )}`
            }
            data-testid="popular-search"
          >
            <Icon type="search" /> {search.title}
          </Link>
        </Paragraph>
      </div>
      <div
        className="text-muted"
        style={{ padding: '8px 15px', userSelect: 'none' }}
      >
        {search.tagline}
      </div>
    </div>
  )
}

const CrowdPleasers = props => {
  const { isPublicRoute = false } = props

  return (
    <Row>
      <Col>
        <h4>Crowd Pleasers</h4>
      </Col>
      <Carousel autoplay>
        <Row style={{ display: 'flex' }}>
          {popularSearches.slice(0, 4).map((s, index) => (
            <Col
              key={`${s.title} ${index}`}
              md={6}
              sm={12}
              style={{ display: 'flex' }}
            >
              <SearchLink search={s} isPublicRoute={isPublicRoute} />
            </Col>
          ))}
        </Row>
        <Row style={{ display: 'flex' }}>
          {popularSearches.slice(4, 8).map((s, index) => (
            <Col
              key={`${s.title} ${index}`}
              md={6}
              sm={12}
              style={{ display: 'flex' }}
            >
              <SearchLink search={s} isPublicRoute={isPublicRoute} />
            </Col>
          ))}
        </Row>
        <Row style={{ display: 'flex' }}>
          {popularSearches.slice(8, 12).map((s, index) => (
            <Col
              key={`${s.title} ${index}`}
              md={6}
              sm={12}
              style={{ display: 'flex' }}
            >
              <SearchLink search={s} isPublicRoute={isPublicRoute} />
            </Col>
          ))}
        </Row>
        <Row style={{ display: 'flex' }}>
          {popularSearches.slice(12, 16).map((s, index) => (
            <Col
              key={`${s.title} ${index}`}
              md={6}
              sm={12}
              style={{ display: 'flex' }}
            >
              <SearchLink search={s} isPublicRoute={isPublicRoute} />
            </Col>
          ))}
        </Row>
      </Carousel>
    </Row>
  )
}

export default CrowdPleasers
