import React, { useEffect, useRef, useState } from 'react'

import get from 'lodash/get'
import head from 'lodash/head'
import PT from 'prop-types'
import ReactMapGL, { NavigationControl } from 'react-map-gl'
import { Editor, EditorModes, RenderStates } from 'react-map-gl-draw'

import colors from '../../hocs/colors/index'

const zoomThreshold = 3.5

const statesLayer = {
  filter: ['==', 'isState', true],
  id: 'state-population',
  maxzoom: zoomThreshold,
  paint: {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'population'],
      0,
      colors.mapBlue1,
      500000,
      colors.mapBlue2,
      750000,
      colors.mapBlue3,
      1000000,
      colors.mapBlue4,
      2500000,
      colors.mapBlue5,
      5000000,
      colors.mapBlue6,
      7500000,
      colors.mapBlue7,
      10000000,
      colors.mapBlue8,
      25000000,
      colors.mapBlue9,
    ],
    'fill-opacity': 0.6,
  },
  source: 'population',
  'source-layer': 'state_county_population_2014_cen',
  type: 'fill',
}

const countiesLayer = {
  filter: ['==', 'isCounty', true],
  id: 'county-population',
  minzoom: zoomThreshold,
  paint: {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'population'],
      0,
      colors.mapBlue1,
      10000,
      colors.mapBlue2,
      25000,
      colors.mapBlue3,
      50000,
      colors.mapBlue4,
      100000,
      colors.mapBlue5,
      250000,
      colors.mapBlue6,
      500000,
      colors.mapBlue7,
      750000,
      colors.mapBlue8,
      1500000,
      colors.mapBlue9,
    ],
    'fill-opacity': 0.6,
  },
  source: 'population',
  'source-layer': 'state_county_population_2014_cen',
  type: 'fill',
}

const HeatMapChart = ({
  data,
  handleSelection,
  height,
  latitude,
  longitude,
  width,
  zoom,
  filtersApplied,
  mapSelectionEnabled,
}) => {
  const [viewport, updateViewport] = useState({
    height: height ? height : 300,
    latitude: latitude ? latitude : 39.8283,
    longitude: longitude ? longitude : -98.5795,
    minZoom: 2.6,
    pitch: 40,
    width,
    zoom: zoom ? zoom : 3,
  })
  const [selectedDrawMode, setSelectedDrawMode] = useState(
    EditorModes.READ_ONLY,
  )

  useEffect(
    () =>
      updateViewport({
        height: height ? height : 300,
        latitude: latitude ? latitude : 39.8283,
        longitude: longitude ? longitude : -98.5795,
        minZoom: 2.6,
        pitch: 40,
        width,
        zoom: zoom ? zoom : 3,
      }), // eslint-disable-next-line
    [latitude, longitude, zoom],
  )

  const [cursorStyle, setCursorStyle] = useState('grab')

  const heatMapRef = useRef(null)

  const maxCount = get(head(get(data, 'features')), 'properties.maxCount')

  const getEditHandleStyle = ({ state }) => {
    switch (state) {
      case RenderStates.SELECTED:
      case RenderStates.HOVERED:
      case RenderStates.UNCOMMITTED:
        return {
          fill: 'rgb(60, 178, 208)',
          fillOpacity: 1,
          r: 7,
          stroke: 'rgb(60, 178, 208)',
          strokeWidth: 2,
        }

      default:
        return {
          fill: 'rgb(60, 178, 208)',
          fillOpacity: 1,
          r: 5,
          stroke: 'rgb(60, 178, 208)',
          strokeWidth: 2,
        }
    }
  }

  const getFeatureStyle = ({ state }) => {
    switch (state) {
      case RenderStates.SELECTED:
      case RenderStates.HOVERED:
      case RenderStates.UNCOMMITTED:
      case RenderStates.CLOSING:
        return {
          fill: 'rgb(60, 178, 208)',
          fillOpacity: 0.3,
          stroke: 'rgb(60, 178, 208)',
          strokeDasharray: '4,2',
          strokeWidth: 2,
        }

      default:
        return {
          fill: 'rgb(60, 178, 208)',
          fillOpacity: 0.1,
          stroke: 'rgb(60, 178, 208)',
          strokeWidth: 2,
        }
    }
  }

  const onDelete = () => handleSelection([])

  const onUpdate = options => {
    if (options.editType === 'addFeature') {
      setSelectedDrawMode(EditorModes.EDITING)
      handleSelection(
        head(get(head(get(options, 'data', [])), 'geometry.coordinates')),
      )
    }
  }

  const renderToolbar = () => {
    return (
      <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
          title="Select Area"
          onClick={() => {
            setSelectedDrawMode(EditorModes.DRAW_RECTANGLE)
            setCursorStyle('crosshair')
          }}
        />
        <button
          style={
            filtersApplied ? null : { cursor: 'not-allowed', opacity: 0.5 }
          }
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
          title="Delete"
          onClick={filtersApplied ? () => onDelete() : null}
        />
      </div>
    )
  }

  const handleMapLoaded = () => {
    const map = heatMapRef.current.getMap()

    map.addSource('population', {
      type: 'vector',
      url: 'mapbox://mapbox.660ui7x6',
    })

    map.addLayer(statesLayer, 'waterway-label')

    map.addLayer(countiesLayer, 'waterway-label')

    map.addSource('peoplecount', {
      cluster: true,
      // Radius of each cluster when clustering points (defaults to 50)
      clusterProperties: {
        sum: ['+', ['get', 'count']],
      },

      clusterRadius: 60,

      data,
      type: 'geojson',
    })

    map.addLayer({
      filter: ['has', 'point_count'],
      id: 'clusters',
      paint: {
        'circle-color': [
          'step',
          ['get', 'sum'],
          colors.mapBubble1,
          maxCount / 10,
          colors.mapBubble2,
          maxCount / 3,
          colors.mapBubble3,
          maxCount,
          colors.mapBubble4,
        ],
        'circle-radius': [
          'step',
          ['get', 'sum'],
          15,
          maxCount / 10,
          20,
          maxCount / 3,
          25,
          maxCount,
          32,
        ],
      },
      source: 'peoplecount',
      type: 'circle',
    })

    map.addLayer({
      filter: ['has', 'sum'],
      id: 'cluster-count',
      layout: {
        'text-field': [
          'number-format',
          ['get', 'sum'],
          { locale: 'us', style: 'currency' },
        ],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      source: 'peoplecount',
      type: 'symbol',
    })

    map.addLayer({
      filter: ['!=', 'cluster', true],
      id: 'unclustered-point',
      paint: {
        'circle-color': '#fff',
        'circle-radius': [
          'step',
          ['get', 'count'],
          5,
          9,
          10,
          99,
          20,
          999,
          40,
          9999,
          60,
        ],
        'circle-stroke-color': '#fff',
        'circle-stroke-width': 1,
      },
      source: 'peoplecount',
      type: 'circle',
    })

    map.addLayer({
      filter: ['!=', 'cluster', true],
      id: 'unclustered-count',
      layout: {
        'text-field': [
          'number-format',
          ['get', 'count'],
          { 'max-fraction-digits': 0, 'min-fraction-digits': 0 },
        ],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 14,
      },
      paint: {
        'text-color': '#fc4e2a',
      },
      source: 'peoplecount',
      type: 'symbol',
    })
  }

  return (
    <div>
      <ReactMapGL
        {...viewport}
        onLoad={handleMapLoaded}
        onViewportChange={viewport => updateViewport(viewport)}
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        ref={heatMapRef}
      >
        <div
          style={{
            left: 0,
            padding: '10px',
            position: 'absolute',
            top: 0,
          }}
        >
          <NavigationControl
            showCompass={false}
            onViewportChange={viewport => updateViewport(viewport)}
          />
          {mapSelectionEnabled && renderToolbar()}
        </div>
        <Editor
          clickRadius={5}
          editHandleShape="circle"
          mode={selectedDrawMode}
          onUpdate={onUpdate}
          featureStyle={getFeatureStyle}
          editHandleStyle={getEditHandleStyle}
          style={{ cursor: cursorStyle }}
        />
      </ReactMapGL>
    </div>
  )
}

HeatMapChart.propTypes = {
  className: PT.string,
  data: PT.object,
  defaultZoom: PT.number,
  filtersApplied: PT.bool,
  height: PT.number.isRequired,
  mapSelectionEnabled: PT.bool.isRequired,
  width: PT.oneOfType([PT.number.isRequired, PT.string.isRequired]),
}

HeatMapChart.defaultProps = {
  height: 600,
}

export default HeatMapChart
