import React from 'react'

import { Radio, Icon } from 'antd'

import colors from '../../../../hocs/colors'

const SkillSorter = ({ currentSkills, onChange }) => (
  <div>
    <Radio.Group onChange={e => onChange(e.target.value)}>
      <Radio.Button checked={currentSkills === 'topSkills'} value="topSkills">
        <Icon
          type="fire"
          style={{
            color: colors.orange1,
            fontSize: 18,
            opacity: 0.8,
            verticalAlign: '0.15em',
          }}
        />
        <span className="legend-label">Volume</span>
      </Radio.Button>
      <Radio.Button
        checked={currentSkills === 'relevantSkills'}
        value="relevantSkills"
      >
        <Icon
          type="block"
          style={{
            color: colors.blue1,
            fontSize: 18,
            opacity: 0.8,
            verticalAlign: '0.15em',
          }}
        />
        <span className="legend-label">Relevance</span>
      </Radio.Button>
      <Radio.Button
        checked={currentSkills === 'highestPayingSkills'}
        value="highestPayingSkills"
      >
        <Icon
          type="dollar"
          style={{
            color: colors.green1,
            fontSize: 18,
            opacity: 0.8,
            verticalAlign: '0.15em',
          }}
        />
        <span className="legend-label">Salary</span>
      </Radio.Button>
    </Radio.Group>
  </div>
)

export default SkillSorter
