/* eslint-disable */
import findKey from 'lodash/findKey'

import {
  certificationsAutocompleteSuggestionsQuery,
  companyNameAutocompleteSuggestionsQuery,
  companyTypeAutocompleteSuggestionsQuery,
  degreeLevelAutocompleteSuggestionsQuery,
  industryAutocompleteSuggestionsQuery,
  jobCategoryAutocompleteSuggestionsQuery,
  jobTitleAutocompleteSuggestionsQuery,
  keywordAutocompleteSuggestionsQuery,
  majorAutocompleteSuggestionsQuery,
  requiredSkillAutocompleteSuggestionsQuery,
  schoolAutocompleteSuggestionsQuery,
  specialtiesAutocompleteSuggestionsQuery,
} from '../../../../apolloClient/operations/lookup/autocompleteSuggestionsQueries'
import { SearchGroupNames } from '../../../../common/enums/SearchGroupNames'
import { SearchGroupTitles } from '../../../../common/enums/SearchGroupTitles'
import { SearchModes } from '../../../../common/enums/SearchModes'
import { SearchStyles } from '../../../../common/enums/SearchStyles'
import { filterNames } from '../../../../utils/constants/filterNames'

/** @typedef {import('../../../../common/types/generic/SearchGroup').SearchGroup} SearchGroup */

/** @typedef {import('../../../../common/types/generic/SearchSettings').SearchSettings} SearchSettings*/

export const findFilterByName = filterName => {
  const matchingKey = findKey(
    searchSettings,
    filter => filter.name === filterName,
  )
  const matchingFilter = searchSettings[matchingKey]

  return matchingFilter
}

export const findFilterByDisplayName = displayName => {
  const matchingKey = findKey(
    searchSettings,
    filter => filter.displayName === displayName,
  )
  const matchingFilter = searchSettings[matchingKey]

  return matchingFilter
}

/** @type SearchSettings */
export const searchSettings = {
  age: {
    displayName: 'Age',
    info: 'Filter by age.',
    name: filterNames.age,
    searchStyle: SearchStyles.Slider,
  },
  candidates_keywords: {
    displayName: 'Job Title Keywords',
    info: 'Open text search for specific strings',
    name: filterNames.candidatesKeywords,
    searchMode: SearchModes.Tags,
  },
  certifications: {
    displayName: 'Certifications',
    info: 'Find candidates with specific certificaitons',
    name: filterNames.certifications,
    queryName: 'certificationsAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: certificationsAutocompleteSuggestionsQuery,
  },
  companies: {
    displayName: 'Companies',
    info: 'Find candidates who work for these specific companies',
    name: filterNames.companies,
    queryName: 'companyNameAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: companyNameAutocompleteSuggestionsQuery,
  },
  companies_keywords: {
    displayName: 'Company Name Keywords',
    info:
      'Search for candidates who work for companies matching your search strings',
    name: filterNames.companiesKeywords,
    searchMode: SearchModes.Tags,
  },
  company_sizes: {
    displayName: 'Company Size (# of Employees)',
    info: 'Find candidates who work for companies of a specific size',
    name: filterNames.companySizes,
    searchStyle: SearchStyles.Checkbox,
  },
  company_specialties: {
    info: 'Find companies by the products and services they offer',
    displayName: 'Products/Services',
    name: filterNames.companySpecialties,
    queryName: 'specialtiesAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: specialtiesAutocompleteSuggestionsQuery,
  },
  company_type: {
    displayName: 'Company Type',
    name: filterNames.companyType,
    queryName: 'companyTypeAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: companyTypeAutocompleteSuggestionsQuery,
  },
  first_names: {
    displayName: 'First Name',
    info: 'Search by first name (exact match)',
    name: filterNames.firstNames,
    searchMode: SearchModes.Tags,
  },
  gender: {
    displayName: 'Gender',
    info: 'Filter based on gender.',
    name: filterNames.gender,
  },
  grad_years: {
    displayName: 'Graduation Year',
    info: 'Find candidates with specific graduation dates',
    name: filterNames.gradYears,
    searchMode: SearchModes.Multiple,
  },
  highest_edu: {
    displayName: 'Highest Degree Level',
    info:
      'Search for candidates with specific degrees, like Masters or Doctorate.',
    name: filterNames.highestDegreeLevel,
    queryName: 'degreeLevelAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: degreeLevelAutocompleteSuggestionsQuery,
  },
  include_previous: {
    displayName: 'Include Previous Position?',
    name: filterNames.includePrevious,
    searchStyle: SearchStyles.SingleCheck,
  },
  industries: {
    displayName: 'Industry',
    info: 'Find candidates in specific industries',
    name: filterNames.industries,
    queryName: 'industryAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: industryAutocompleteSuggestionsQuery,
  },
  job_category: {
    displayName: 'Job Category',
    info: 'Filter by current job category.',
    name: filterNames.jobCategory,
    queryName: 'jobCategoryAutocompleteSuggestions',
    searchMode: SearchModes.Tags,
    suggestionsQuery: jobCategoryAutocompleteSuggestionsQuery,
  },
  job_titles: {
    displayName: 'Standard Job Titles',
    info: 'Find candidates that match our standard list of job titles.',
    name: filterNames.jobTitles,
    queryName: 'jobTitleAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: jobTitleAutocompleteSuggestionsQuery,
  },
  last_names: {
    displayName: 'Last Name',
    info: 'Search by last name (exact match)',
    name: filterNames.lastNames,
    searchMode: SearchModes.Tags,
  },
  location: {
    displayName: 'Locations',
    name: filterNames.location,
  },
  majors: {
    displayName: 'Major',
    info: 'Find candidates with specific majors',
    name: filterNames.majors,
    queryName: 'majorAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: majorAutocompleteSuggestionsQuery,
  },
  military_status: {
    displayName: 'Military/Veterans',
    info: 'Restrict search to only show veterans',
    label: 'Veterans Only',
    name: filterNames.militaryStatus,
    options: {
      checked: ['veterans'],
      unchecked: null,
    },
    searchStyle: SearchStyles.SingleCheckWithCustomValues,
  },
  // names: {
  //   displayName: 'Full Name',
  //   info: 'Search for candidates by full name (exact match)',
  //   name: 'names',
  //   searchMode: SearchModes.Tags,
  // },
  required_skills: {
    displayName: 'Required Skills',
    info: 'All candidates must have these skills',
    name: filterNames.requiredSkills,
    queryName: 'requiredSkillAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: requiredSkillAutocompleteSuggestionsQuery,
  },
  salary: {
    displayName: 'Salary',
    info: 'Search by estimated salary.',
    name: filterNames.salary,
    searchMode: SearchModes.Tags,
    searchStyle: SearchStyles.Slider,
    sliderMax: 300,
    suggestionsQuery: keywordAutocompleteSuggestionsQuery,
  },
  skills_keywords: {
    displayName: 'Skills Keywords',
    info: 'Open text search against candidate skills',
    name: filterNames.skillsKeywords,
    searchMode: SearchModes.Tags,
  },
  states: {
    displayName: 'States',
    info: 'Find candidates that work in specific states',
    name: filterNames.states,
    searchMode: SearchModes.Multiple,
    searchStyle: SearchStyles.ImmediateAutocomplete,
  },
  tags: {
    displayName: 'Tags',
    name: filterNames.tags,
    searchMode: SearchModes.Tags,
  },
  universities: {
    displayName: 'School',
    info: 'Find candidates who attended specific schools',
    name: filterNames.universities,
    queryName: 'schoolAutocompleteSuggestions',
    searchMode: SearchModes.Multiple,
    suggestionsQuery: schoolAutocompleteSuggestionsQuery,
  },
  // veteran_status: {
  //   displayName: 'Veteran Status',
  //   info: 'Filter based on service history.',
  //   name: filterNames.veteranStatus,
  //   searchStyle: SearchStyles.CheckboxGroup,
  // },
  years_of_experience: {
    displayName: 'Years of Experience',
    info: "Search by candidate's full history of experience.",
    name: filterNames.yearsOfExperience,
    searchMode: SearchModes.Tags,
    searchStyle: SearchStyles.Slider,
    sliderMax: 300,
  },
}

const {
  first_names,
  last_names,
  tags,
  companies,
  companies_keywords,
  company_sizes,
  company_type,
  highest_edu,
  industries,
  gender,
  job_category,
  job_titles,
  candidates_keywords,
  required_skills,
  skills_keywords,
  certifications,
  universities,
  majors,
  military_status,
  grad_years,
  states,
  location,
  years_of_experience,
  salary,
  company_specialties,
  // veteran_status,
  age,
  include_previous,
} = searchSettings

const companiesSearches = {
  companies,
  companies_keywords,
  industries,
  company_specialties,
  company_sizes,
  company_type,
}
const candidatesSearches = {
  job_titles,
  candidates_keywords,
  include_previous,
  job_category,
  years_of_experience,
  salary,
  tags,
}

const skillsSearches = {
  required_skills,
  skills_keywords,
}

const peopleSearches = {
  gender,
  // military_status,
  // veteran_status,
  age,
}

const locationSearches = {
  location,
  states,
}

const educationSearches = {
  certifications,
  universities,
  majors,
  highest_edu,
  grad_years,
}

const adminOnlySearches = {
  first_names,
  last_names,
  tags,
}

/**
 * @param {boolean} isSystemAdmin
 * @returns {Array.<SearchGroup>} SearchGroups
 */
export const getSearchGroups = isSystemAdmin => [
  ...(isSystemAdmin
    ? [
        {
          groupName: SearchGroupNames.Admin,
          searches: adminOnlySearches,
          title: SearchGroupTitles.Admin,
        },
      ]
    : []),
  {
    groupName: SearchGroupNames.Careers,
    searches: candidatesSearches,
    title: SearchGroupTitles.Careers,
  },
  {
    groupName: SearchGroupNames.People,
    searches: peopleSearches,
    title: SearchGroupTitles.People,
  },
  {
    groupName: SearchGroupNames.Skills,
    searches: skillsSearches,
    title: SearchGroupTitles.Skills,
  },
  {
    groupName: SearchGroupNames.Education,
    searches: educationSearches,
    title: SearchGroupTitles.Education,
  },
  {
    groupName: SearchGroupNames.Companies,
    searches: companiesSearches,
    title: SearchGroupTitles.Companies,
  },
  {
    groupName: SearchGroupNames.Locations,
    searches: locationSearches,
    title: SearchGroupTitles.Locations,
  },
]
