import React from 'react'

import { Divider, InputNumber, Typography } from 'antd'

import numberConverter from '../../../../../utils/numberConverter'

const { Text } = Typography

const DownloadSummary = props => {
  const {
    teDownloadCredits,
    creditsUsage,
    allowSizeAdjustment,
    maxDownloadSize,
    candidateSize,
    onCandidateSizeChange,
    downloadingByCandidateId,
    selectedCandidateIds,
  } = props

  const downloadSizeSummaryItem = downloadingByCandidateId ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '4px 0',
      }}
    >
      <Text>Records to Download: </Text>
      <Text strong>
        {numberConverter(selectedCandidateIds && selectedCandidateIds.length)}
      </Text>
    </div>
  ) : (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
      }}
    >
      <Text>Select Records to Download</Text>
      {allowSizeAdjustment && (
        <InputNumber
          min={1}
          max={maxDownloadSize}
          defaultValue={100}
          value={candidateSize}
          onChange={onCandidateSizeChange}
        />
      )}
    </div>
  )
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
      }}
    >
      <div style={{ width: '60%' }}>
        {downloadSizeSummaryItem}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 0',
          }}
        >
          <Text type="secondary">Available Credits: </Text>
          <Text strong>{numberConverter(teDownloadCredits, 1)}</Text>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 0',
          }}
        >
          <Text>Credits used in this download: </Text>
          <Text strong>{numberConverter(creditsUsage, 1)}</Text>
        </div>
        <Divider style={{ margin: '0 24px 0 0' }} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 0',
          }}
        >
          <Text type="secondary">Remaining credits after download: </Text>
          <Text strong>
            {numberConverter(teDownloadCredits - creditsUsage, 1)}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default DownloadSummary
