import { useState } from 'react'

import debounce from 'lodash/debounce'
import get from 'lodash/get'

import { apolloClient } from '../../../apolloClient'

const useFetchAutoSuggestions = ({ suggestionsQuery, queryName }) => {
  const [fetchingAutoSuggestions, setFetchingAutoSuggestions] = useState(false)
  const [autoSuggestions, setAutoSuggestions] = useState([])

  const fetchAutoSuggestions = async prefix => {
    if (!suggestionsQuery) return
    let finalPrefix = prefix

    if (finalPrefix && finalPrefix !== '') {
      try {
        setFetchingAutoSuggestions(true)
        const result = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: suggestionsQuery,
          variables: { prefix: finalPrefix },
        })
        const autoSuggestions = get(result, `data.${queryName}`)
        setAutoSuggestions(autoSuggestions)
        setFetchingAutoSuggestions(false)
      } catch (error) {
        setFetchingAutoSuggestions(false)
        console.log(error)
      }
    }
  }

  const fetchAutoSuggestionsWithDebounce = debounce(fetchAutoSuggestions, 500)

  return {
    autoSuggestions,
    fetchAutoSuggestions: fetchAutoSuggestionsWithDebounce,
    fetchingAutoSuggestions,
  }
}

export default useFetchAutoSuggestions
