import React from 'react'

import { Row, Col } from 'antd'

import Colors from '../../hocs/colors'

const MapBubbleLegend = () => {
  return (
    <div style={{ marginTop: 20 }}>
      <div className="caps-label">TARGET POPULATION</div>
      <Row className="map-bubble-container">
        <Col span={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble1,
              borderRadius: 25,
              height: 25,
              marginTop: 12,
              width: 25,
            }}
          />
          <div>least </div>
        </Col>
        <Col span={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble2,
              height: 30,
              marginTop: 8,
              width: 30,
            }}
          />
          <div>less </div>
        </Col>
        <Col span={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble3,
              height: 37,
              marginTop: 4,
              width: 37,
            }}
          />
          <div>more </div>
        </Col>
        <Col span={6}>
          <div
            className="map-bubble"
            style={{
              backgroundColor: Colors.mapBubble4,
              height: 44,
              width: 44,
            }}
          />
          <div>most </div>
        </Col>
      </Row>
    </div>
  )
}

export default MapBubbleLegend
