import React from 'react'

import { Typography } from 'antd'
const { Paragraph } = Typography

const NonAdminMessage = () => {
  return (
    <div style={{ padding: '0 22px' }}>
      <Paragraph type="secondary">
        Please contact your admin for access to CrowdSegment
      </Paragraph>
    </div>
  )
}

export default NonAdminMessage
