import gql from 'graphql-tag'

export const updatePaymentMethodMutation = gql`
  mutation updatePaymentMethod($stripeCardToken: String!, $couponCode: String) {
    updatePaymentMethod(
      stripeCardToken: $stripeCardToken
      couponCode: $couponCode
    ) {
      id
      createdAt
      canceled
      needsUpdatedPayment
      updatedAt
      paymentMethods {
        last4
        brand
      }
    }
  }
`
