import { message } from 'antd'

/**
 * @param {Object} clipboardParams
 * @param {string} clipboardParams.value
 * @param {function} [clipboardParams.onSuccess]
 * @param {function} [clipboardParams.onError]
 * @returns { Promise<void>}
 */
export const copyToClipboard = async ({ value, onSuccess, onError }) => {
  const defaultSuccesHandler = message.success('Link copied to clipboard', 0.5)
  let handleSuccess = onSuccess ? onSuccess : defaultSuccesHandler
  try {
    await navigator.clipboard.writeText(value)
    typeof handleSuccess === 'function' && handleSuccess()
  } catch (err) {
    onError && typeof onError === 'function' && onError(err)
  }
}
