import gql from 'graphql-tag'

export const removeCandidateFromRequisitionMutation = gql`
  mutation RemoveCandidateFromRequisition(
    $requisitionId: String!
    $candidateId: String!
  ) {
    removeCandidateFromRequisition(
      requisitionId: $requisitionId
      candidateId: $candidateId
    ) {
      id
      name
      candidateData {
        id
      }
    }
  }
`
