import React, { useEffect, useCallback, useState } from 'react'

import { Col, Icon, Row, Tabs, Spin, Typography, Button } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import set from 'lodash/set'
import uniq from 'lodash/uniq'
import qs from 'query-string'
import { useMediaQuery } from 'react-responsive'

import { apolloClient } from '../../../apolloClient'
import {
  companiesDataQuery,
  analyticsDataQuery,
  candidatesDataQuery,
  countQuery,
} from '../../../apolloClient/operations/search/dataQueries'
import SpinnerWithUberFacts from '../../../components/layout/SpinnerWithUberFacts'
import colors from '../../../hocs/colors'
import useModalManagement from '../../../hooks/common/useModalManagement/index'
import usePrevious from '../../../hooks/common/usePrevious'
import useUserForSearchRoute from '../../../hooks/data/loggedInUser/useUserForSearchRoute'
import errorService from '../../../utils/analytics/error'
import { filterNames } from '../../../utils/constants/filterNames'
import { decryptStr } from '../../../utils/crypto/cryptoUtils'
import { removeFalsey } from '../../../utils/data/utils'
import feedback from '../../../utils/feedback'
import { separateParams } from '../../../utils/queryParams/search'
import AuthenticatedLayout from '../components/AuthenticatedLayout'

import AddPaymentModal from './components/AddPaymentModal'
import AnalyticsTab from './components/AnalyticsTab'
import CandidatesTab from './components/CandidatesTab'
import CompaniesTab from './components/CompaniesTab'
import EducationTab from './components/EducationTab'
import EmploymentTab from './components/EmploymentTab'
import PaymentRequestAlert from './components/PaymentRequestAlert'
import SearchHeader from './components/SearchHeader'
import SkillsTab from './components/SkillsTab'
import { useCrowdSegmentDispatch, useCrowdSegmentState } from './crowd-context'
import { isRangeFilter } from './static/defaultStateValues'
import { searchSettings } from './static/searchSettings'
import WelcomeCard from './WelcomeCard'

const { Title, Paragraph } = Typography

const { TabPane } = Tabs

export const TOOLTIP_MSGS = {
  noResults:
    'This search will not return any results, please try adjusting your filters.',
  tooBroad:
    'Overcrowded segment. Please narrow down your search by adding more filters.',
  tooBroadOpenText: 'Overcrowded segment. Keep typing...',
}

export const getNonEmptyFiltersCount = (
  /** @type {{ [filter: any]; }} */ filters,
  /** @type {string[]} */ filterSubset,
) => {
  return Object.keys(filters).filter(key => {
    const value = filters[key]
    const passesFilter = filterSubset
      ? !isEmpty(value) && filterSubset.includes(key)
      : !isEmpty(value)

    return passesFilter
  }).length
}

export const tabKeys = {
  analytics: '1',
  candidates: '6',
  companies: '5',
  education: '4',
  employment: '2',
  skills: '3',
}

const TalentEye = props => {
  const [companiesTabPage, setCompaniesTabPage] = useState(1)
  const [candidatesTabPage, setCandidatesTabPage] = useState(1)
  const [selectedSearchGroup, setSelectedSearchGroup] = useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' })

  const { userProfile } = useUserForSearchRoute()

  const { isSystemAdmin = false, isAdmin = false, client } = pick(userProfile, [
    'isSystemAdmin',
    'isAdmin',
    'client',
  ])
  const { jobRequisitions, settings, trialExpired, subscription } = pick(
    client,
    ['settings', 'trialExpired', 'subscription', 'name', 'jobRequisitions'],
  )

  const permissions = {
    isAdmin,
    isSystemAdmin,
    onPaymentPlan: !!subscription,
    stripeNeedsUpdatedPayment: get(subscription, 'needsUpdatedPayment'),
    trialExpired,
  }
  const teDownloadCredits = get(settings, 'credits')

  const {
    activeTab,
    analyticsData,
    analyticsDataError,
    analyticsDataLoading,
    candidateDataError,
    candidateDataLoading,
    candidatesData,
    companiesData,
    companyDataError,
    companyDataLoading,
    educationData,
    employmentData,
    extraFiltersToApply,
    fetchingTotal,
    filtersToApply,
    searchDetailsToSave,
    skillsData,
    suggestions,
    suppliedFilters,
    totalCount,
  } = useCrowdSegmentState()

  const {
    // setSelectedSearchGroup,
    setActiveTab,
    setAnalyticsData,
    setAnalyticsDataError,
    setAnalyticsDataLoading,
    setCandidateDataError,
    setCandidateDataLoading,
    setCandidatesData,
    setCompaniesData,
    setCompanyDataError,
    setCompanyDataLoading,
    setEducationData,
    setEmploymentData,
    setExtraFilters,
    setFacets,
    setFetchingTotal,
    setFiltersToApply,
    setSaveSearchFormVisible,
    setSearchDetailsToSave,
    setSkillsData,
    setSuggestions,
    setSuppliedFilters,
    setTotalCount,
  } = useCrowdSegmentDispatch()

  const {
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
    visible: paymentModalVisible,
  } = useModalManagement(false)

  const convertValuesToNumbers = arr => {
    return arr.map(val => parseInt(val))
  }

  const filtersNotEmpty = useCallback(() => {
    const selectedFilterKeys = keys(filtersToApply).filter(key => {
      const val = get(filtersToApply, key)

      return (
        val &&
        !!val.length &&
        // disallow a search where only these filters have values
        key !== 'company_sizes'
      )
    })

    return (
      (selectedFilterKeys && selectedFilterKeys.length > 0) ||
      !!get(filtersToApply, 'location.name')
    )
  }, [filtersToApply])

  const separateFilters = useCallback(filtersReturned => {
    const filters = {}
    const extraFilters = {}
    keys(filtersReturned).forEach(key => {
      const value = get(filtersReturned, `${key}`)
      if (has(searchSettings, `${key}`)) {
        if (isRangeFilter(key)) {
          const numberValues = convertValuesToNumbers(value)
          set(filters, `${key}`, numberValues)
        } else {
          set(filters, `${key}`, value)
        }
      } else {
        if (key === 'geo_points') {
          const geoValue = value ? value : []
          set(extraFilters, `${key}`, geoValue)
        } else {
          set(extraFilters, `${key}`, value)
        }
      }
    })

    return {
      extraFilters,
      filters,
    }
  }, [])

  const fetchCompaniesData = useCallback(
    async filters => {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: companiesDataQuery,
        variables: {
          filters,
        },
      })

      setCompaniesData(get(result, 'data.companiesData.documents', []))
    },
    [setCompaniesData],
  )

  const handleSubmit = useCallback(
    async (e, filters) => {
      if (!filters && !filtersNotEmpty()) {
        return
      }

      const formattedFiltersToApply = formatFiltersToApply(filtersToApply)
      const filtersToSubmit =
        typeof filters === 'undefined'
          ? { ...formattedFiltersToApply, ...extraFiltersToApply }
          : filters
      const submittedViaClick = !!e
      try {
        setAnalyticsDataLoading(true)
        const result = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: analyticsDataQuery,
          variables: {
            filters: filtersToSubmit,
          },
        })
        setAnalyticsDataLoading(false)
        setAnalyticsDataError(null)

        const tabsData = get(result, 'data.analyticsData.custom_aggs')
        setAnalyticsData(get(tabsData, 'analytics'))
        setSkillsData(get(tabsData, 'skills'))
        setEmploymentData(get(tabsData, 'employment'))
        setEducationData(get(tabsData, 'education'))

        const facetsData = get(result, 'data.analyticsData.facets')
        setFacets(facetsData)

        const suggestionsData = get(result, 'data.analyticsData.suggestions')
        setSuggestions(suggestionsData)

        const filtersData = get(result, 'data.analyticsData.filters')
        setSuppliedFilters(filtersData)
        const { filters, extraFilters } = separateFilters(filtersData)
        setFiltersToApply(filters)
        setExtraFilters(extraFilters)
        if (submittedViaClick) {
          resetActiveTab()
        }
      } catch (error) {
        console.log(error)
        setAnalyticsDataLoading(false)
        setAnalyticsDataError(error)
        errorService.report(error, 'TalentEyeAnalyticsData')
        feedback.error({
          title: 'Error fetching results..Please try again',
        })
      }

      try {
        setCandidateDataLoading(true)
        const candidateDataResult = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: candidatesDataQuery,
          variables: {
            filters: filtersToSubmit,
          },
        })

        if (candidatesTabPage > 1) setCandidatesTabPage(1) // reset candidates tab page to 1
        setCandidateDataLoading(false)
        setCandidateDataError(null)

        setCandidatesData(
          get(candidateDataResult, 'data.candidatesData.documents', []),
        )
      } catch (error) {
        setCandidateDataLoading(false)
        setCandidateDataError(error)
        errorService.report(error, 'TalentEyeCandidateData')
      }
      try {
        setCompanyDataLoading(true)
        setCompanyDataError(null)

        await fetchCompaniesData(filtersToSubmit)

        if (companiesTabPage > 1) setCompaniesTabPage(1) // reset companies tab page to 1
        setCompanyDataLoading(false)
      } catch (error) {
        setCompanyDataError(error)
        setCompanyDataLoading(false)
        errorService.report(error, 'TalentEyeCompanyData')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filtersNotEmpty,
      filtersToApply,
      extraFiltersToApply,
      activeTab,
      companiesTabPage,
      candidatesTabPage,
      setAnalyticsDataLoading,
      setAnalyticsDataError,
      setAnalyticsData,
      setSkillsData,
      setEmploymentData,
      setEducationData,
      setFacets,
      setSuggestions,
      setSuppliedFilters,
      setFiltersToApply,
      setExtraFilters,
      setCandidateDataLoading,
      setCandidateDataError,
      setCandidatesData,
      setCompanyDataLoading,
      setCompanyDataError,
      fetchCompaniesData,
    ],
  )

  const searchQuery = get(props, 'location.search')
  const prevSearchQuery = usePrevious(searchQuery)

  useEffect(() => {
    const processQueryParams = () => {
      const searchQuery = get(props, 'location.search')
      if (!!searchQuery && !!searchQuery.length) {
        let queryParams = qs.parse(get(props, 'location.search'))
        if (queryParams.emailToImpersonate) {
          delete queryParams.emailToImpersonate

          return
        }

        // if search query with encryptedParams exists, then decrypt it first
        if (queryParams.encryptedParams) {
          const decryptedParams = decryptStr(queryParams.encryptedParams)
          queryParams = qs.parse(decryptedParams)
        }

        const {
          candidateSortOptionsParams,
          locationParams,
          standardParams,
          selectedSearchGroup,
        } = separateParams(queryParams)
        if (selectedSearchGroup) {
          setSelectedSearchGroup(selectedSearchGroup)
        } else {
          setSelectedSearchGroup(null)
        }

        // TODO - refactor this now that we only have one extra filter (geo_points)
        const { filters, extraFilters } = separateFilters({
          ...locationParams,
          ...standardParams,
          ...candidateSortOptionsParams,
        })

        setFiltersToApply(filters)
        setExtraFilters(extraFilters)

        // Don't submit search if the only query parameter was the selected search group
        if (!isEmpty(filters) || !isEmpty(extraFilters)) {
          setSearchDetailsToSave({ description: null, name: null })
          handleSubmit(null, { ...filters, ...extraFilters })
        }
      }
    }
    if (searchQuery !== prevSearchQuery) {
      processQueryParams()
    }
  }, [
    handleSubmit,
    props,
    separateFilters,
    setExtraFilters,
    setFiltersToApply,
    searchQuery,
    prevSearchQuery,
    setSearchDetailsToSave,
  ])

  const formatFiltersToApply = filters => {
    const clonedFilters = cloneDeep(filters)

    let locationObj = get(clonedFilters, 'location')

    // set location filters to null, when there is no geodata
    if (locationObj) {
      if (!locationObj.geoData) {
        locationObj = null
        clonedFilters['location'] = locationObj
      }
    }

    return clonedFilters
  }

  const toggleAllFilters = (key, filters) => {
    const updatedFilters = cloneDeep(filtersToApply)
    updatedFilters[key] = filters
    setFiltersToApply(updatedFilters)
  }

  const setRange = (key, filterValue) => {
    const updatedFilters = cloneDeep(extraFiltersToApply)
    set(updatedFilters, key, filterValue)
    setExtraFilters(updatedFilters)
  }
  const setFilterValue = (filterName, filterValue) => {
    let updatedFilters = cloneDeep(filtersToApply)
    if (typeof filterValue === 'undefined') {
      updatedFilters = omit(updatedFilters, filterName)
    } else {
      set(updatedFilters, `${filterName}`, filterValue)
    }
    setFiltersToApply(updatedFilters)
  }

  const toggleExtraFilter = (key, filterValue, isRangeFilter) => {
    if (isRangeFilter) {
      setRange(key, filterValue)

      return
    }
    const updatedFilters = cloneDeep(extraFiltersToApply)
    let selectedValues = get(updatedFilters, [key], [])
    const filterSelected = selectedValues.includes(filterValue)
    if (!filterSelected) {
      selectedValues.push(filterValue)
    } else {
      selectedValues = selectedValues.filter(val => val !== filterValue)
    }
    updatedFilters[key] = selectedValues
    setExtraFilters(updatedFilters)
  }

  const toggleFilter = (filterName, filterValue) => {
    const updatedFilters = cloneDeep(filtersToApply)
    let selectedValues = get(updatedFilters, [filterName], [])
    const filterSelected = selectedValues.includes(filterValue)
    if (!filterSelected) {
      selectedValues.push(filterValue)
    } else {
      selectedValues = selectedValues.filter(val => val !== filterValue)
    }
    updatedFilters[filterName] = selectedValues
    setFiltersToApply(updatedFilters)
  }

  const setExtraFilterValue = (filterName, filterValue) => {
    let updatedFilters = cloneDeep(extraFiltersToApply)
    if (typeof filterValue === 'undefined') {
      updatedFilters = omit(updatedFilters, filterName)
    } else {
      set(updatedFilters, `${filterName}`, filterValue)
    }
    setExtraFilters(updatedFilters)
  }

  const updateAppliedFilters = (name, value) => {
    const updatedFilters = cloneDeep(filtersToApply)
    updatedFilters[name] = value
    setFiltersToApply(updatedFilters)
  }

  const fetchTotalCount = useCallback(async () => {
    const formattedFiltersToApply = formatFiltersToApply(filtersToApply)
    const combinedFilters = {
      ...formattedFiltersToApply,
      ...extraFiltersToApply,
    }
    if (isEqual(combinedFilters, {})) {
      return
    }
    setFetchingTotal(true)
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: countQuery,
        variables: {
          filters: combinedFilters,
        },
      })
      const data = get(result, 'data')
      setFetchingTotal(false)
      setTotalCount(get(data, filterNames.totalCount))
    } catch (error) {
      console.log(error)
      setFetchingTotal(false)
    }
  }, [extraFiltersToApply, filtersToApply, setFetchingTotal, setTotalCount])

  useEffect(() => {
    let subscribed = true
    const fetchTotal = async () => await fetchTotalCount()
    if (subscribed) {
      fetchTotal()
    }

    return () => (subscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersToApply, extraFiltersToApply])

  const submittable =
    !isEqual(
      removeFalsey({ ...filtersToApply, ...extraFiltersToApply }),
      removeFalsey(suppliedFilters),
    ) &&
    filtersNotEmpty() &&
    totalCount <= parseInt(process.env.REACT_APP_MAX_SEARCH_SIZE) &&
    totalCount > 0

  const handleGeoSelection = points => {
    setExtraFilters({
      ...extraFiltersToApply,
      geo_points: points,
    })

    handleSubmit(null, {
      ...filtersToApply,
      ...extraFiltersToApply,
      geo_points: points,
    })
  }

  const resetActiveTab = () => {
    if (activeTab !== tabKeys.analytics) {
      setActiveTab(tabKeys.analytics)
    }
  }

  const clearMultipleFilters = filters => {
    const updatedFilters = cloneDeep(filtersToApply)
    const customizer = objValue => {
      if (isArray(objValue)) {
        return []
      } else return null
    }
    mergeWith(updatedFilters, filters, customizer)
    setFiltersToApply(updatedFilters)
  }

  const afterPayment = async () => {
    closePaymentModal()
    handleSubmit()
  }

  const getTooltipMsg = () => {
    if (totalCount === 0) {
      return TOOLTIP_MSGS.noResults
    } else if (totalCount > process.env.REACT_APP_MAX_SEARCH_SIZE) {
      return TOOLTIP_MSGS.tooBroad
    }
  }

  // TODO: Create showErrorMsg var for !submittable and filters > 0. Pass to OpenTextSearch and SearchHeader

  return (
    <AuthenticatedLayout borderBottom={false}>
      <AddPaymentModal
        closable={true}
        modalShown={paymentModalVisible}
        closeModal={closePaymentModal}
        title="Add Payment Information"
        message="Unlock the full power of CrowdSegment! A monthly subscription gives you unlimited access to CrowdSegment features and analytics. Subscribers get 5,000 bonus credits on signup, and are able to purchase additional on-demand credits."
        afterUpdate={afterPayment}
        isAdmin={get(permissions, 'isAdmin')}
        client={client}
      />
      <div>
        <Row
          style={{
            marginBottom: '0',
          }}
        >
          <div className={!isMobile ? 'sticky-header-wrapper' : ''}>
            <SearchHeader
              isSystemAdmin={isSystemAdmin}
              toggleAllFilters={toggleAllFilters}
              toggleFilter={toggleFilter}
              filtersToApply={filtersToApply}
              updateAppliedFilters={updateAppliedFilters}
              submittable={submittable}
              onSubmit={handleSubmit}
              suggestions={suggestions}
              loading={
                analyticsDataLoading || candidateDataLoading || fetchingTotal
              }
              fetchingTotal={fetchingTotal}
              totalCount={totalCount}
              setFilterValue={setFilterValue}
              clearMultipleFilters={clearMultipleFilters}
              tooltipMessage={getTooltipMsg()}
              selectedFilters={{
                ...filtersToApply,
                ...extraFiltersToApply,
              }}
              selectedSearchGroup={selectedSearchGroup}
            />
          </div>
          {!isMobile && (
            <div className="max-1200">
              <PaymentRequestAlert
                permissions={permissions}
                openPaymentModal={openPaymentModal}
              />
            </div>
          )}
          <Col span={24} className="te-results-container">
            {analyticsData ? (
              <>
                <Row
                  className="max-1200"
                  style={{ marginBottom: 15, marginTop: 15 }}
                >
                  <Col md={14}>
                    <Title
                      level={2}
                      style={{ marginBottom: 0 }}
                      editable={{
                        onChange: value =>
                          setSearchDetailsToSave({ name: value }),
                      }}
                    >
                      {get(searchDetailsToSave, 'name')
                        ? get(searchDetailsToSave, 'name')
                        : 'This crowd can be shared.'}
                    </Title>
                    <Paragraph
                      ellipsis={{ expandable: true, rows: 3 }}
                      editable={{
                        onChange: value =>
                          setSearchDetailsToSave({ description: value }),
                      }}
                    >
                      {get(searchDetailsToSave, 'description')
                        ? get(searchDetailsToSave, 'description')
                        : 'Simply edit the title and description to generate your shareable link!'}
                    </Paragraph>
                  </Col>
                  <Col md={10} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: colors.primary,
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginTop: 3,
                      }}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon="link"
                        onClick={() => setSaveSearchFormVisible(true)}
                      >
                        Share this Crowd
                      </Button>
                      <div
                        style={{
                          color: `${colors.primary} !important`,
                          marginBottom: 5,
                        }}
                      />
                      with your social crowd or work crowd.
                    </div>
                  </Col>
                </Row>
                <Tabs
                  activeKey={activeTab}
                  defaultActiveKey={tabKeys.analytics}
                  className="report-body talent-eye-tabs max-1200"
                  onTabClick={key => setActiveTab(key)}
                >
                  <TabPane tab="Analytics" key={tabKeys.analytics}>
                    <AnalyticsTab
                      isMobile={isMobile}
                      analyticsData={analyticsData}
                      error={analyticsDataError}
                      toggleExtraFilter={toggleExtraFilter}
                      toggleFilter={toggleFilter}
                      extraFiltersToApply={extraFiltersToApply}
                      filtersToApply={filtersToApply}
                      loading={analyticsDataLoading}
                      suppliedFilters={suppliedFilters}
                      selectedStates={get(
                        filtersToApply,
                        searchSettings.states.name,
                      )}
                      handleGeoSelection={handleGeoSelection}
                      geoFilterApplied={
                        !!get(extraFiltersToApply, 'geo_points') &&
                        !!get(extraFiltersToApply, 'geo_points').length
                      }
                    />
                  </TabPane>
                  <TabPane tab="Employment" key={tabKeys.employment}>
                    <EmploymentTab
                      employmentData={employmentData}
                      error={analyticsDataError}
                      toggleExtraFilter={toggleExtraFilter}
                      toggleFilter={toggleFilter}
                      extraFiltersToApply={extraFiltersToApply}
                      filtersToApply={filtersToApply}
                      loading={analyticsDataLoading}
                      suppliedFilters={suppliedFilters}
                      isMobile={isMobile}
                    />
                  </TabPane>
                  <TabPane tab="Skills" key={tabKeys.skills}>
                    <div className="te-card-content">
                      <SkillsTab
                        error={analyticsDataError}
                        skillsData={!!skillsData && skillsData}
                        toggleFilter={toggleFilter}
                        filtersToApply={filtersToApply}
                        isMobile={isMobile}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Education" key={tabKeys.education}>
                    <EducationTab
                      educationData={educationData}
                      error={analyticsDataError}
                      toggleFilter={toggleFilter}
                      filtersToApply={filtersToApply}
                      isMobile={isMobile}
                    />
                  </TabPane>
                  {/* TODO: Can we hide the tab following an error? Can't recreate the error case to test */}
                  <TabPane
                    tab={
                      <>
                        Companies{' '}
                        <Spin
                          spinning={companyDataLoading || candidateDataLoading}
                          size="small"
                        />{' '}
                        {companyDataError ? (
                          <Icon
                            type="exclamation-circle"
                            theme="twoTone"
                            twoToneColor="#eb2f96"
                          />
                        ) : (
                          ''
                        )}
                      </>
                    }
                    key={tabKeys.companies}
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="te-card-content" style={{ minWidth: 900 }}>
                      <div className="data-card">
                        <CompaniesTab
                          data={companiesData}
                          page={companiesTabPage}
                          setPage={setCompaniesTabPage}
                          permissions={permissions}
                          error={companyDataError}
                          selectedCompanies={get(filtersToApply, 'companies')}
                          loading={companyDataLoading || candidateDataLoading}
                          toggleFilter={toggleFilter}
                          selectedSkills={get(
                            filtersToApply,
                            searchSettings.required_skills.name,
                          )}
                          openPaymentModal={openPaymentModal}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        Candidates{' '}
                        <Spin spinning={candidateDataLoading} size="small" />{' '}
                        {candidateDataError ? (
                          <Icon
                            type="exclamation-circle"
                            theme="twoTone"
                            twoToneColor="#eb2f96"
                          />
                        ) : (
                          ''
                        )}
                      </>
                    }
                    key={tabKeys.candidates}
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="te-card-content" style={{ minWidth: 900 }}>
                      <div className="data-card">
                        <CandidatesTab
                          data={candidatesData}
                          error={candidateDataError}
                          extraFiltersToApply={extraFiltersToApply}
                          filtersToApply={filtersToApply}
                          handleSubmit={handleSubmit}
                          loading={
                            analyticsDataLoading ||
                            candidateDataLoading ||
                            fetchingTotal
                          }
                          page={candidatesTabPage}
                          setPage={setCandidatesTabPage}
                          suppliedFilters={suppliedFilters}
                          totalSkillsFilters={
                            uniq([
                              ...get(suppliedFilters, 'required_skills', []),
                            ]).length
                          }
                          permissions={permissions}
                          openPaymentModal={openPaymentModal}
                          teDownloadCredits={teDownloadCredits}
                          totalMatches={get(analyticsData, 'total_matches', 0)}
                          setExtraFilterValue={setExtraFilterValue}
                          selectedSkills={get(
                            filtersToApply,
                            searchSettings.required_skills.name,
                          )}
                          toggleFilter={toggleFilter}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </>
            ) : (
              <SpinnerWithUberFacts loading={analyticsDataLoading}>
                <WelcomeCard
                  filtersToApply={filtersToApply}
                  toggleFilter={toggleFilter}
                  totalCount={totalCount}
                  submittable={submittable}
                  onSubmit={handleSubmit}
                  requisitions={jobRequisitions}
                />
              </SpinnerWithUberFacts>
            )}
          </Col>
        </Row>
      </div>
    </AuthenticatedLayout>
  )
}

export default TalentEye
