import React, { useState, useEffect } from 'react'

import { Select, Button, Col, Spin, Icon, Tooltip } from 'antd'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'

import { apolloClient } from '../../../../apolloClient'
import { countQuery } from '../../../../apolloClient/operations/search/dataQueries'
import usePrevious from '../../../../hooks/common/usePrevious/index'
import numberConverter from '../../../../utils/numberConverter'
import { capitalize } from '../../../../utils/stringManipulation'

const { Option } = Select

const SearchBar = ({
  defaultAutoSuggestions = [],
  title,
  info,
  filterSuggestions,
  suggestionsQuery,
  queryName,
  toggleFilter,
  name,
  selectedFilters,
  updateAppliedFilters,
  searchMode,
}) => {
  const [fetchingAutoSuggestions, setFetchingAutoSuggestions] = useState(false)
  const [autoSuggestions, setAutoSuggestions] = useState(defaultAutoSuggestions)

  const [fetchingCounts, setFetchingCounts] = useState(false)
  const [count, setCount] = useState(null)

  const prevFilters = usePrevious(selectedFilters)

  const handleSuggValClick = clickedValue => {
    toggleFilter(name, clickedValue)
  }

  const onSelectChange = value => {
    updateAppliedFilters(name, value)
  }

  const fetchAutoSuggestions = async prefix => {
    if (!suggestionsQuery) return
    let finalPrefix = prefix
    if ((!prefix || prefix === '') && title === 'Industry') finalPrefix = 'all'

    if (finalPrefix && finalPrefix !== '') {
      try {
        setFetchingAutoSuggestions(true)
        const result = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: suggestionsQuery,
          variables: { prefix: finalPrefix },
        })
        const autoSuggestions = get(result, `data.${queryName}`)
        setAutoSuggestions(autoSuggestions)
        setFetchingAutoSuggestions(false)
      } catch (error) {
        setFetchingAutoSuggestions(false)
        console.log(error)
      }
    }
  }

  const fetchCounts = async filters => {
    if (isEqual(prevFilters, filters)) {
      return
    }
    if (!filters || !filters.length) {
      setCount(null)
      return
    }
    setFetchingCounts(true)
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: countQuery,
        variables: { filters: { [name]: filters } },
      })
      const count = get(result, 'data.count')
      setCount(count)
      setFetchingCounts(false)
    } catch (error) {
      console.log(error)
      setFetchingCounts(false)
    }
  }

  const fetch = filters => {
    fetchCounts(filters)
  }

  // eslint-disable-next-line
  useEffect(() => fetch(selectedFilters), [selectedFilters])

  const fetchAutoSuggestionsWithDebounce = debounce(fetchAutoSuggestions, 500)

  const suggestedValues = filterSuggestions
    ? filterSuggestions.filter(val => !includes(selectedFilters, val.key))
    : []

  return (
    <Col lg={24} md={24}>
      <div
        style={{
          margin: '0 auto',
          marginBottom: 10,
          position: 'relative',
          width: '95%',
        }}
      >
        <span style={{ fontSize: 12, fontWeight: 'bold' }}>
          {title}{' '}
          {info && (
            <Tooltip placement="top" title={info}>
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </span>
        <span
          style={{
            float: 'right',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          {fetchingCounts ? (
            <Spin size="small" />
          ) : (
            count > 0 && numberConverter(count)
          )}
        </span>
        <Select
          mode={searchMode}
          style={{ width: '100%' }}
          showSearch
          showArrow={false}
          notFoundContent={
            fetchingAutoSuggestions ? <Spin size="small" /> : null
          }
          onSearch={fetchAutoSuggestionsWithDebounce}
          value={selectedFilters}
          onChange={onSelectChange}
          getPopupContainer={trigger => trigger.parentNode}
          // onFocus={
          //   title === 'Industry' ? fetchAutoSuggestions : clearSuggestions
          // }
        >
          {autoSuggestions &&
            autoSuggestions.map(o => <Option key={o}>{capitalize(o)}</Option>)}
        </Select>
        <div style={{ textAlign: 'center' }}>
          {suggestedValues.map(value => (
            <Button
              key={value.key}
              style={{
                color: '#7ab8eb',
                fontWeight: 'bold',
                height: 25,
                padding: '0 5px',
              }}
              type="link"
              onClick={() => handleSuggValClick(value.key)}
            >
              <i className="fal fa-plus-circle" />
              &nbsp;
              {capitalize(value.key)}
            </Button>
          ))}
        </div>
      </div>
    </Col>
  )
}

export default SearchBar
