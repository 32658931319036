import gql from 'graphql-tag'

export const getValidatedEmailQuery = gql`
  query GetValidatedEmail($personId: String!) {
    getValidatedEmail(personId: $personId) {
      contactInfo {
        validatedEmail
        verificationReason
        personalEmail
        id
      }
      settings {
        id
        credits
      }
    }
  }
`
