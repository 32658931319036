import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { getCreditsUsageForEmailVerificationQuery } from '../../../apolloClient/operations/downloads/getCreditsUsageForEmailVerificationQuery'
import { isPublic } from '../../../utils/routing/routes'

export const useGetCreditsUsageForEmailVerification = () => {
  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const { data, loading, error } = useQuery(
    getCreditsUsageForEmailVerificationQuery,
    {
      skip: isPublicRoute,
    },
  )

  const creditChargePerVerification = get(
    data,
    'getCreditsUsageForEmailVerification',
  )

  return {
    creditChargePerVerification,
    errorGettingCreditsUsageForEmailVerification: error,
    gettingCreditsUsageForEmailVerification: loading,
  }
}
