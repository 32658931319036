import React from 'react'

import { Icon, Tooltip } from 'antd'
import get from 'lodash/get'

import {
  SEARCH_ENGINES,
  createCandidateSearchQuery,
  makeIntoExternalLink,
} from '../../utils/stringManipulation'

const copyToClipboard = value => {
  // Copy phone number to clipboard using dummy element, selecting it and finally removing it after copy
  const dummy = document.createElement('textarea')
  document.body.appendChild(dummy)
  dummy.value = value
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

const PersonContact = props => {
  const val = get(props, 'val')
  const row = get(props, 'row')
  const { social_link } = row
  const fullSocialLink = makeIntoExternalLink(social_link)
  return (
    <div style={{ cursor: 'pointer' }}>
      <div style={{ marginBottom: 5 }}>
        <Icon
          type="linkedin"
          theme="filled"
          style={{ color: '#0076b5', fontSize: 18, marginRight: 3 }}
          data-id={val}
          onClick={() => window.open(fullSocialLink, '_blank')}
        />
        <Icon
          type="google"
          theme="outlined"
          style={{ fontSize: 18, marginRight: 3 }}
          data-id={val}
          onClick={() =>
            window.open(
              createCandidateSearchQuery(SEARCH_ENGINES.google, row),
              '_blank',
            )
          }
        />
        <Icon
          type="search"
          theme="outlined"
          style={{ color: '#0076b5', fontSize: 18 }}
          data-id={val}
          onClick={() =>
            window.open(
              createCandidateSearchQuery(SEARCH_ENGINES.bing, row),
              '_blank',
            )
          }
        />
        {get(row, 'telephone_number') && (
          <Tooltip title="Copy to clipboard" trigger="click">
            <Icon
              type="phone"
              theme="twoTone"
              style={{ fontSize: 18, marginLeft: 5 }}
              data-id={get(row, 'telephone_number')}
              onClick={async e => copyToClipboard(e.currentTarget.dataset.id)}
            />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default PersonContact
