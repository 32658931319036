import React from 'react'

import { Icon, Tooltip } from 'antd'
import get from 'lodash/get'
import includes from 'lodash/includes'
import maxBy from 'lodash/maxBy'
import PT from 'prop-types'

import colors from '../../../../../hocs/colors'
import { capitalize } from '../../../../../utils/stringManipulation'

export const Bar = ({
  barColor,
  data = [],
  disabled,
  isSalary,
  onBarClick,
  selectedValue,
  maxHeight = 300,
  selectedFilters,
}) => {
  const max = maxBy(data, 'doc_count')

  let maxSalary = 0
  try {
    maxSalary = maxBy(data, 'metric')
    maxSalary = get(maxSalary, 'metric', false)
  } catch (error) {
    console.log(error)
    maxSalary = false
  }

  let computedBarcolor = isSalary ? colors.blue1 : colors.blue2
  computedBarcolor = barColor ? barColor : computedBarcolor

  const isSelected = key => includes(selectedFilters, key)

  return (
    <div
      style={{
        margin: '0 auto',
        maxHeight: maxHeight,
        maxWidth: 350,
        overflow: 'auto',
        paddingBottom: 20,
      }}
      className="scrolling-container"
    >
      {data.map((child, index) => {
        const key = get(child, 'key', '')
        const handleClick = () => onBarClick(key)
        return (
          <div
            onClick={disabled ? null : () => handleClick()}
            key={`${child}${index}`}
            style={{ cursor: disabled ? 'default' : 'pointer' }}
          >
            {!disabled && (
              <div
                style={{
                  clear: 'both',
                  color: isSelected(key) ? 'red' : 'green',
                  float: 'left',
                  paddingTop: get(child, 'metric') ? '22px' : '10px',
                  width: '3%',
                }}
              >
                {isSelected(key) ? (
                  <Icon type="minus-circle" />
                ) : (
                  <Icon type="plus-circle" />
                )}
              </div>
            )}
            <div
              style={{
                borderRadius: 3,
                float: !disabled ? 'right' : 'none',
                maxWidth: '90%',
                padding: '8px',
                width: '90%',
              }}
            >
              <Tooltip placement="topLeft">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: '600',
                    textTransform: 'uppercase',
                  }}
                >
                  {capitalize(get(child, 'key', ''))}
                </div>
                {maxSalary ? (
                  <div>
                    <div>
                      <div className="scale-bars-icon">
                        <Icon type="number" />
                      </div>
                      <div className="scale-bars-right">
                        <div
                          style={{
                            background:
                              selectedValue === get(child, 'key', '')
                                ? computedBarcolor
                                : selectedValue
                                ? colors.simpleBarNotClickedBackground
                                : computedBarcolor,
                            border: `1px solid ${computedBarcolor}`,
                            marginTop: 2,
                            width: `${(get(child, 'doc_count', 0) * 100) /
                              get(max, 'doc_count', 0)}%`,
                          }}
                          className="scale-bars"
                        />
                      </div>
                    </div>
                    {!!get(child, 'metric') && (
                      <div style={{ marginTop: 5 }}>
                        <div className="scale-bars-icon">
                          <Icon type="dollar" />
                        </div>
                        <div className="scale-bars-right">
                          <div
                            style={{
                              background:
                                selectedValue === get(child, 'key', '')
                                  ? colors.salary
                                  : selectedValue
                                  ? colors.simpleBarNotClickedBackground
                                  : colors.salary,
                              border: `1px solid ${colors.salary}`,
                              marginTop: 2,
                              width: `${(get(child, 'metric', 0) * 100) /
                                maxSalary}%`,
                            }}
                            className="scale-bars"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      background:
                        selectedValue === get(child, 'key', '')
                          ? computedBarcolor
                          : selectedValue
                          ? colors.simpleBarNotClickedBackground
                          : computedBarcolor,
                      border: `1px solid ${computedBarcolor}`,
                      width: `${(get(child, 'doc_count', 0) * 100) /
                        get(max, 'doc_count', 0)}%`,
                    }}
                    className="scale-bars"
                  />
                )}
              </Tooltip>
            </div>
          </div>
        )
      })}
    </div>
  )
}

Bar.propTypes = {
  data: PT.array.isRequired,
  disabled: PT.bool,
  onBarClick: PT.func,
  selectedValue: PT.string,
}

export default Bar
