import React from 'react'

import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'

import { getDefaultSuppliedFilters } from './static/defaultStateValues'

import { tabKeys } from '.'

const actions = {
  clearAllStateData: 'clearAllStateData',
  setActiveTab: 'setActiveTab',
  setAnalyticsData: 'setAnalyticsData',
  setAnalyticsDataError: 'setAnalyticsDataError',
  setAnalyticsDataLoading: 'setAnalyticsDataLoading',
  setCandidateDataError: 'setCandidateDataError',
  setCandidateDataLoading: 'setCandidateDataLoading',
  setCandidatesData: 'setCandidatesData',
  setCompaniesData: 'setCompaniesData',
  setCompanyDataError: 'setCompanyDataError',
  setCompanyDataLoading: 'setCompanyDataLoading',
  setEducationData: 'setEducationData',
  setEmploymentData: 'setEmploymentData',
  setExtraFilters: 'setExtraFilters',
  setFacets: 'setFacets',
  setFetchingTotal: 'setFetchingTotal',
  setFiltersDrawerVisible: 'setFiltersDrawerVisible',
  setFiltersToApply: 'setFiltersToApply',
  setPublicContactFormVisible: 'setPublicContactFormVisible',
  setPublicSearchCTAVisible: 'publicSearchCTAVisible',
  setSaveSearchFormVisible: 'setSaveSearchFormVisible',
  setSearchDescription: 'setSearchDescription',
  setSearchDetailsToSave: 'setSearchDetailsToSave',
  setSearchName: 'setSearchName',
  setSelectedSearchGroup: 'setSelectedSearchGroup',
  setSkillsData: 'setSkillsData',
  setSuggestions: 'setSuggestions',
  setSuppliedFilters: 'setSuppliedFilters',
  setTotalCount: 'setTotalCount',
  setUnauthenticatedUser: 'setUnauthenticatedUser',
  updateCandidate: 'updateCandidate',
}

const crowdSegmentReducer = (state, action) => {
  let updatedStateSegment
  switch (action.type) {
    case actions.setFiltersDrawerVisible:
      updatedStateSegment = {
        filtersDrawerVisible: action.payload,
      }
      break
    case actions.setFiltersToApply:
      updatedStateSegment = {
        filtersToApply: action.payload,
      }
      break
    case actions.setExtraFilters:
      updatedStateSegment = {
        extraFiltersToApply: action.payload,
      }
      break
    case actions.setActiveTab:
      updatedStateSegment = {
        activeTab: action.payload,
      }
      break
    case actions.setAnalyticsData:
      updatedStateSegment = {
        analyticsData: action.payload,
      }
      break
    case actions.setSelectedSearchGroup:
      updatedStateSegment = {
        selectedSearchGroup: action.payload,
      }
      break
    case actions.setSkillsData:
      updatedStateSegment = {
        skillsData: action.payload,
      }
      break
    case actions.setSearchDetailsToSave:
      updatedStateSegment = {
        searchDetailsToSave: {
          ...get(state, 'searchDetailsToSave', {}),
          ...action.payload,
        },
      }
      break
    case actions.setSearchName:
      updatedStateSegment = {
        searchName: action.payload,
      }
      break
    case actions.setSearchDescription:
      updatedStateSegment = {
        searchDescription: action.payload,
      }
      break
    case actions.setSaveSearchFormVisible:
      updatedStateSegment = {
        saveSearchFormVisible: action.payload,
      }
      break
    case actions.setCandidatesData:
      updatedStateSegment = {
        candidatesData: action.payload,
      }
      break
    case actions.updateCandidate: {
      const candidates = cloneDeep(get(state, 'candidatesData'))
      const candidateIndexToUpdate = findIndex(
        candidates,
        candidate => candidate.id === get(action.payload, 'id'),
      )

      if (candidateIndexToUpdate === -1) return
      const updatedCandidate = {
        ...candidates[candidateIndexToUpdate],
        ...action.payload,
      }
      candidates[candidateIndexToUpdate] = updatedCandidate
      updatedStateSegment = {
        candidatesData: candidates,
      }
      break
    }
    case actions.setEmploymentData:
      updatedStateSegment = {
        employmentData: action.payload,
      }
      break
    case actions.setEducationData:
      updatedStateSegment = {
        educationData: action.payload,
      }
      break
    case actions.setCompaniesData:
      updatedStateSegment = {
        companiesData: action.payload,
      }
      break
    case actions.setFacets:
      updatedStateSegment = {
        facets: action.payload,
      }
      break
    case actions.setSuggestions:
      updatedStateSegment = {
        suggestions: action.payload,
      }
      break
    case actions.setSuppliedFilters:
      updatedStateSegment = {
        suppliedFilters: action.payload,
      }
      break

    case actions.setAnalyticsDataLoading:
      updatedStateSegment = {
        analyticsDataLoading: action.payload,
      }
      break
    case actions.setAnalyticsDataError:
      updatedStateSegment = {
        analyticsDataError: action.payload,
      }
      break
    case actions.setCompanyDataLoading:
      updatedStateSegment = {
        companyDataLoading: action.payload,
      }
      break
    case actions.setCompanyDataError:
      updatedStateSegment = {
        companyDataError: action.payload,
      }
      break
    case actions.setCandidateDataLoading:
      updatedStateSegment = {
        candidateDataLoading: action.payload,
      }
      break
    case actions.setCandidateDataError:
      updatedStateSegment = {
        candidateDataError: action.payload,
      }
      break
    case actions.setTotalCount:
      updatedStateSegment = {
        totalCount: action.payload,
      }
      break
    case actions.setPublicSearchCTAVisible:
      updatedStateSegment = {
        publicSearchCTAVisible: action.payload,
      }
      break
    case actions.setPublicContactFormVisible:
      updatedStateSegment = {
        publicContactFormVisible: action.payload,
      }
      break
    case actions.setFetchingTotal:
      updatedStateSegment = {
        fetchingTotal: action.payload,
      }
      break
    case actions.setUnauthenticatedUser:
      updatedStateSegment = {
        unauthenticatedUser: action.payload,
      }
      break
    case actions.clearAllStateData:
      updatedStateSegment = initialSearchState
      break
    default:
      break
  }

  return {
    ...state,
    ...updatedStateSegment,
  }
}

const initalState = {
  activeTab: tabKeys.analytics,
  analyticsData: null,
  analyticsDataError: null,
  analyticsDataLoading: false,
  candidateDataError: null,
  candidateDataLoading: false,
  candidatesData: [],
  companiesData: [],
  companyDataError: null,
  companyDataLoading: false,
  educationData: [],
  employmentData: [],
  extraFiltersToApply: {},
  facets: null,
  fetchingTotal: false,
  filtersDrawerVisible: false,
  filtersToApply: {},
  publicContactFormVisible: false,
  publicSearchCTAVisible: false,
  searchDescription: null,
  searchDetailsToSave: null,
  searchName: null,
  selectedSearchGroup: null,
  skillsData: null,
  suggestions: null,
  suppliedFilters: getDefaultSuppliedFilters(),
  totalCount: null,
  unauthenticatedUser: {},
}

const initialSearchState = {
  analyticsData: null,
  analyticsDataError: null,
  candidateDataError: null,
  candidatesData: [],
  companiesData: [],
  companyDataError: null,
  educationData: [],
  employmentData: [],
  extraFiltersToApply: {},
  facets: null,
  filtersToApply: {},
  publicContactFormVisible: false,
  publicSearchCTAVisible: false,
  saveSearchFormVisible: false,
  searchDescription: null,
  searchDetailsToSave: null,
  searchName: null,
  selectedSearchGroup: null,
  skillsData: null,
  suggestions: null,
  suppliedFilters: getDefaultSuppliedFilters(),
  totalCount: null,
}

const CrowdSegmentContext = React.createContext()
const CrowdSegmentActionsContext = React.createContext()

const CrowdSegmentProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(crowdSegmentReducer, initalState)
  const boundActions = {}
  for (let key in dispatchActions) {
    boundActions[key] = dispatchActions[key](dispatch)
  }

  return (
    <CrowdSegmentContext.Provider value={state}>
      <CrowdSegmentActionsContext.Provider value={boundActions}>
        {children}
      </CrowdSegmentActionsContext.Provider>
    </CrowdSegmentContext.Provider>
  )
}

const useCrowdSegmentState = () => {
  const context = React.useContext(CrowdSegmentContext)
  if (typeof context === 'undefined') {
    throw new Error(
      'useCrowdSegmentState must be used within a CrowdSegmentProvider',
    )
  }

  return context
}

const useCrowdSegmentDispatch = () => {
  const context = React.useContext(CrowdSegmentActionsContext)
  if (typeof context === 'undefined') {
    throw new Error(
      'useCrowdSegmentDispatch must be used within a CrowdSegmentProvider',
    )
  }

  return context
}

const dispatchActions = {
  clearAllStateData: dispatch => {
    return () => {
      dispatch({ type: actions.clearAllStateData })
    }
  },
  setActiveTab: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setActiveTab })
    }
  },
  setAnalyticsData: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setAnalyticsData })
    }
  },
  setAnalyticsDataError: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setAnalyticsDataError })
    }
  },
  setAnalyticsDataLoading: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setAnalyticsDataLoading })
    }
  },
  setCandidateDataError: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setCandidateDataError })
    }
  },
  setCandidateDataLoading: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setCandidateDataLoading })
    }
  },
  setCandidatesData: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setCandidatesData })
    }
  },
  setCompaniesData: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setCompaniesData })
    }
  },
  setCompanyDataError: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setCompanyDataError })
    }
  },
  setCompanyDataLoading: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setCompanyDataLoading })
    }
  },
  setEducationData: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setEducationData })
    }
  },
  setEmploymentData: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setEmploymentData })
    }
  },
  setExtraFilters: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setExtraFilters })
    }
  },
  setFacets: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setFacets })
    }
  },
  setFetchingTotal: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setFetchingTotal })
    }
  },
  setFiltersDrawerVisible: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setFiltersDrawerVisible })
    }
  },
  setFiltersToApply: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setFiltersToApply })
    }
  },
  setPublicContactFormVisible: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setPublicContactFormVisible })
    }
  },
  setPublicSearchCTAVisible: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setPublicSearchCTAVisible })
    }
  },
  setSaveSearchFormVisible: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSaveSearchFormVisible })
    }
  },
  setSearchDescription: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSearchDescription })
    }
  },
  setSearchDetailsToSave: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSearchDetailsToSave })
    }
  },
  setSearchName: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSearchName })
    }
  },
  setSelectedSearchGroup: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSelectedSearchGroup })
    }
  },
  setSkillsData: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSkillsData })
    }
  },
  setSuggestions: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSuggestions })
    }
  },
  setSuppliedFilters: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setSuppliedFilters })
    }
  },
  setTotalCount: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setTotalCount })
    }
  },
  setUnauthenticatedUser: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.setUnauthenticatedUser })
    }
  },
  updateCandidate: dispatch => {
    return payload => {
      dispatch({ payload, type: actions.updateCandidate })
    }
  },
}

export {
  CrowdSegmentProvider,
  useCrowdSegmentState,
  useCrowdSegmentDispatch,
  dispatchActions,
}
