import gql from 'graphql-tag'

export const getUserForSubscriptionQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      isAdmin
      client {
        name
        id
        subscription {
          id
          paymentMethods {
            last4
            brand
          }
          canceled
          needsUpdatedPayment
        }
      }
    }
  }
`
