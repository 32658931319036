import React, { useCallback } from 'react'

import { Button, Spin } from 'antd'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { useGetValidatedEmail } from '../../../hooks/data/candidates/useGetValidatedEmail'
import { isPublic } from '../../../utils/routing/routes'
import OptionalTooltip from '../../touchables/OptionalTooltip'

import EmailDisplay from './EmailDisplay'

const EmailColumn = props => {
  const gettingCreditsUsageForEmailVerification = get(
    props,
    'gettingCreditsUsageForEmailVerification',
  )
  const creditChargePerVerification = get(props, 'creditChargePerVerification')
  const updateCandidate = get(props, 'updateCandidate')
  const val = get(props, 'val')
  const row = get(props, 'row')
  const setPublicSearchCTAVisible = get(props, 'setPublicSearchCTAVisible')
  const noCreditsLeft = get(props, 'noCreditsLeft')
  const id = get(row, 'id')
  const validatedEmail = get(row, 'validatedEmail')
  const personalEmail = get(row, 'personalEmail')
  const verificationReason = get(row, 'verificationReason')

  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const setEmailOnContext = useCallback(
    ({ validatedEmail, personalEmail, verificationReason, id }) => {
      updateCandidate({
        id,
        personalEmail,
        validatedEmail,
        verificationReason,
      })
    },
    [updateCandidate, id],
  )

  const onCompleted = updateCandidate ? setEmailOnContext : null

  const {
    gettingValidatedEmail,
    notFound,
    getValidatedEmail,
  } = useGetValidatedEmail(onCompleted)

  const memoizedGetValidatedEmail = useCallback(getValidatedEmail, [])

  const handleClick = useCallback(
    id => {
      if (isPublicRoute) {
        setPublicSearchCTAVisible(true)
      } else {
        memoizedGetValidatedEmail({ personId: id })
      }
    },
    [isPublicRoute, memoizedGetValidatedEmail, setPublicSearchCTAVisible],
  )

  const tooltipTitle = gettingCreditsUsageForEmailVerification ? (
    <Spin />
  ) : noCreditsLeft ? (
    'No download credits left.'
  ) : (
    `Uses ${creditChargePerVerification} credit`
  )

  const button = (
    <Button
      type="primary"
      icon="search"
      onClick={() => handleClick(id)}
      loading={gettingValidatedEmail}
      disabled={noCreditsLeft}
    >
      Email
    </Button>
  )

  return (
    <>
      <div style={{ cursor: 'pointer', textAlign: 'center' }}>
        {notFound ? (
          <div className="text-center text-muted" style={{ fontSize: 12 }}>
            <div>No Email Found.</div>
            <div>Credit Refunded.</div>
          </div>
        ) : validatedEmail || personalEmail ? (
          <EmailDisplay
            validatedEmail={validatedEmail}
            personalEmail={personalEmail}
            val={val}
            verificationReason={verificationReason}
          />
        ) : (
          <OptionalTooltip tooltipPresent={!isPublicRoute} title={tooltipTitle}>
            {button}
          </OptionalTooltip>
        )}
      </div>
    </>
  )
}

export default EmailColumn
