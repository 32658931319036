import React from 'react'
import get from 'lodash/get'
import { Checkbox } from 'antd'

const SingleCheck = props => {
  const checked = get(props, 'checked')
  const title = get(props, 'title')
  const name = get(props, 'name')
  const setFilterValue = get(props, 'setFilterValue')

  const handleChange = e => {
    setFilterValue(name, e.target.checked)
  }

  return (
    <Checkbox checked={checked} onChange={handleChange}>
      {title}
    </Checkbox>
  )
}

export default SingleCheck
