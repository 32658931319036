import React from 'react'

import { List } from 'antd'
// @ts-ignore
import { GenericForm, SingleLineTextInput } from 'components'
import get from 'lodash/get'
import validator from 'validator'

import { getUserForBasicInfoQuery } from '../../../../../../../../../apolloClient/operations/userData/getUserForBasicInfoQuery'
import { useDynamicUser } from '../../../../../../../../../hooks/data/loggedInUser/useDynamicUser'
import { useUpdateUser } from '../../../../../../../../../hooks/data/loggedInUser/useUpdateUser'
import { pickWithInitialState } from '../../../../../../../../../utils/data/utils'
import numberConverter from '../../../../../../../../../utils/numberConverter'

/**
 * Gets base form fields
 */
const getFormFields = () => [
  {
    Component: SingleLineTextInput,
    formItemProps: {
      label: 'First name',
    },
    inputProps: {
      autoFocus: true,
      icon: 'user',
    },
    name: 'firstName',
  },
  {
    Component: SingleLineTextInput,
    formItemProps: {
      label: 'Last name',
    },
    inputProps: {
      icon: 'user',
    },
    name: 'lastName',
  },
]

/**
 * Validation function
 * @param {Object} value
 */
const isLength = (value = '') =>
  validator.isLength(value, { max: 250, min: 1 }) ? undefined : 'Required'

const validate = ({ firstName = '', lastName = '' }) => ({
  firstName: firstName && isLength(firstName),
  lastName: lastName && isLength(lastName),
})

const BasicInfoForm = () => {
  const fields = getFormFields()
  const { userProfile } = useDynamicUser({
    userQuery: getUserForBasicInfoQuery,
  })
  const { updateUser } = useUpdateUser({ userProfile })
  const { email, firstName, lastName, client } = pickWithInitialState(
    userProfile,
    [
      ['email', ''],
      ['firstName', ''],
      ['lastName', ''],
      ['client', {}],
    ],
  )

  const credits = get(client, 'settings.credits', 0)
  const clientName = get(client, 'name', '')

  return (
    <>
      <List
        className="my-account-list"
        grid={{ column: 3 }}
        itemLayout="horizontal"
        bordered={false}
        dataSource={[
          {
            description: email,
            title: 'Email',
          },
          {
            description: clientName,
            title: 'Organization Name',
          },
          {
            description: numberConverter(credits, 1),
            title: 'Credits Remaining',
          },
        ]}
        renderItem={item => (
          <List.Item style={{ borderBottom: 0, marginBottom: 10, padding: 0 }}>
            <List.Item.Meta
              title={
                <label
                  style={{
                    color: 'rgba(0, 0, 0, 0.8)',
                    fontWeight: 'bold',
                  }}
                >
                  {item.title}:
                </label>
              }
              description={item.description}
            />
          </List.Item>
        )}
      />
      <GenericForm
        fields={fields}
        formProps={{
          initialValues: {
            clientName,
            credits,
            email,
            firstName,
            lastName,
          },
          onSubmit: updateUser,
          validate,
        }}
        submitButtonContent="Save Info"
        submitButtonProps={{ block: true, style: { marginTop: 16 } }}
      />
    </>
  )
}

BasicInfoForm.propTypes = {}

export default BasicInfoForm
