import React from 'react'
import get from 'lodash/get'
import keys from 'lodash/keys'
import { Tag, Icon } from 'antd'

const SearchHeaderButton = props => {
  const panelFiltersToApply = get(props, 'panelFiltersToApply')
  const title = get(props, 'title')
  const clearFilters = get(props, 'clearFilters')

  return (
    <>
      {title}{' '}
      {keys(panelFiltersToApply).length ? (
        <Tag
          color="purple"
          closable
          onClose={e => clearFilters(e)(panelFiltersToApply)}
          style={{ fontSize: '16px', marginRight: 0 }}
        >
          {keys(panelFiltersToApply).length}
        </Tag>
      ) : null}
      <Icon type="caret-down" />
    </>
  )
}

export default SearchHeaderButton
