import React, { useState } from 'react'

import { Switch, Typography } from 'antd'
import get from 'lodash/get'

import PublicSearchModalContents from '../../../../../../../Unauthenticated/PublicSearch/components/components/PublicSearchModalContents'

const { Text } = Typography

const CustomBrandingPreview = props => {
  const [showPreview, setShowPreview] = useState(false)
  const {
    header = null,
    subheader = null,
    buttonText = null,
    buttonLink = null,
    disclaimer = null,
  } = showPreview ? get(props, 'brandingData', {}) : {}

  return (
    <div style={{ padding: '0 8px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 6,
        }}
      >
        <Text>Show Custom Branding</Text>
        <Switch style={{ marginLeft: 6 }} onChange={setShowPreview} />
      </div>
      <div
        className="ant-modal-content"
        style={{ margin: '0 auto', width: 520 }}
      >
        <div className="ant-modal-body">
          <PublicSearchModalContents
            header={header}
            subheader={subheader}
            buttonText={buttonText}
            buttonLink={buttonLink}
            disclaimer={disclaimer}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomBrandingPreview
