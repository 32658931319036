import React from 'react'

import { Form, Input } from 'antd'
import get from 'lodash/get'

import SingleLineTextInput from '../../../components/forms/SingleLineTextInput'

import { signupFormFieldTitles } from './SignupForm'

const SignupInputs = props => {
  const getFieldDecorator = get(props, 'getFieldDecorator')
  const fetching = get(props, 'fetching')

  return (
    <div>
      <Form.Item
        validateStatus={fetching ? 'validating' : undefined}
        hasFeedback={!!fetching}
        className="large-input"
      >
        {getFieldDecorator(signupFormFieldTitles.firstName, {
          rules: [
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(<Input placeholder="First Name" data-testid="signup-first-name" />)}
      </Form.Item>
      <Form.Item
        validateStatus={fetching ? 'validating' : undefined}
        hasFeedback={!!fetching}
        className="large-input"
      >
        {getFieldDecorator(signupFormFieldTitles.lastName, {
          rules: [
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(<Input placeholder="Last Name" data-testid="signup-last-name" />)}
      </Form.Item>
      <Form.Item
        validateStatus={fetching ? 'validating' : undefined}
        hasFeedback={!!fetching}
        className="large-input"
      >
        {getFieldDecorator(signupFormFieldTitles.email, {
          rules: [
            { message: 'A value is required', required: true },
            { message: 'Please provide a valid email', type: 'email' },
            { max: 250, message: 'Must be less than 250 characters' },
          ],
        })(
          <Input
            placeholder="Company Email Address"
            data-testid="signup-company-email"
          />,
        )}
      </Form.Item>
      <Form.Item
        validateStatus={fetching ? 'validating' : undefined}
        hasFeedback={!!fetching}
        className="large-input"
      >
        {getFieldDecorator(signupFormFieldTitles.password, {
          rules: [
            {
              message: 'Cannot contain only whitespace',
              whitespace: true,
            },
            { message: 'A value is required', required: true },
            { max: 250, message: 'Must be less than 250 characters' },
            {
              message:
                'Password must be at least 8 characters long and contain at least one lower and one upper case letter, and one number',
              // eslint-disable-next-line no-useless-escape
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{6,99}$/,
            },
          ],
        })(
          <SingleLineTextInput
            placeholder="Password"
            data-testid="signup-password"
            type="password"
          />,
        )}
      </Form.Item>
      <Form.Item
        validateStatus={fetching ? 'validating' : undefined}
        hasFeedback={!!fetching}
        className="large-input"
      >
        {getFieldDecorator(signupFormFieldTitles.phone, {
          rules: [
            {
              message: 'Phone number should be ten digits long',
              min: 10,
            },
            {
              max: 10,
              message: 'Phone number should have a maximum of ten digits',
            },
          ],
        })(
          <SingleLineTextInput
            addonBefore="1 +"
            placeholder="Phone"
            data-testid="signup-phone"
            maxLength={10}
            type="number"
          />,
        )}
      </Form.Item>
    </div>
  )
}

export default SignupInputs
