import React from 'react'

import CenteredLayoutWide from '../components/CenteredLayoutWide'

import SignupForm from './SignupForm'

export const STEPS = {
  CONFIRM: 'confirm',
  SIGNUP: 'signup',
}

const Signup = () => {
  return (
    <div>
      <CenteredLayoutWide>
        <div style={{ margin: '80px auto', maxWidth: 650 }}>
          <SignupForm />
        </div>
      </CenteredLayoutWide>
    </div>
  )
}

export default Signup
