import React, { useState } from 'react'

import { Button, Card, Col, Icon, Result, Row, Skeleton, Tooltip } from 'antd'
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import slice from 'lodash/slice'

import colors from '../../../../hocs/colors'
import { searchSettings } from '../static/searchSettings'

import { SkillsBars } from './components/SkillsBars'
import SkillSorter from './SkillSorter'

const { Meta } = Card

const BATCH_LENGTH = 20

const SkillsTab = ({
  error,
  loading,
  skillsData,
  toggleFilter,
  isMobile,
  filtersToApply,
}) => {
  const [currentSkills, setCurrentSkills] = useState('topSkills')
  const [skillsPage, setSkillsPage] = useState(1)

  const highestPayingSkills = orderBy(skillsData, ['salary'], ['desc'])
  const relevantSkills = orderBy(skillsData, ['relevance'], ['desc'])
  const topSkills = orderBy(skillsData, ['popularity'], ['desc'])

  const maxPopularity = get(head(topSkills), 'popularity')
  const maxRelevancy = get(head(relevantSkills), 'relevance')
  const maxSalary = get(head(highestPayingSkills), 'salary')

  const sortedSkills = {
    highestPayingSkills,
    relevantSkills,
    topSkills,
  }

  const onChangeSkillsType = value => {
    setCurrentSkills(value)
  }

  const toggleSkill = skillName =>
    toggleFilter(searchSettings.required_skills.name, skillName)

  const currentSkillsData = get(sortedSkills, currentSkills, [])

  const selectedSkills = get(
    filtersToApply,
    searchSettings.required_skills.name,
  )
  const getSkeletonRows = () => {
    const skeletonRows = []
    let i = 0
    while (i < 40) {
      if (i % 4 === 0) {
        skeletonRows.push(
          <Col span={6} key={i}>
            <Skeleton
              avatar={{ size: 'small' }}
              paragraph={{ rows: 0 }}
              title={{ width: '90%' }}
              className="skills-skeleton-with-avatar"
            />
          </Col>,
        )
      } else {
        skeletonRows.push(
          <Col span={6} key={i}>
            <Skeleton paragraph={{ rows: 0 }} title={{ width: '90%' }} />
          </Col>,
        )
      }
      i++
    }

    return skeletonRows
  }

  if (error) {
    return (
      <Result
        status="warning"
        title="Error Encountered"
        subTitle="Check back later, or try another search. "
      />
    )
  }

  if (!loading && !skillsData.length) {
    return (
      <Result
        icon={<Icon type="frown" theme="twoTone" />}
        title="Sorry, no data available with current filters"
        subTitle="Please try clearing some filters"
      />
    )
  }

  return (
    <div>
      <Row style={{ marginBottom: 0 }}>
        <Col md={23}>
          <Meta
            title="Top Skill Segments"
            description="The skills that occur most frequently among this crowd, as compared to national averages."
          />
        </Col>
        <Col md={1}>
          <div
            style={{
              float: 'right',
              marginTop: isMobile ? 5 : -15,
              width: 'max-content',
            }}
          >
            <SkillSorter
              currentSkills={currentSkills}
              onChange={onChangeSkillsType}
            />
          </div>
        </Col>
      </Row>
      {!isMobile && (
        <Row style={{ marginBottom: 0, marginTop: 15, textAlign: 'center' }}>
          <Col md={6} offset={6}>
            <span
              className="clickable"
              onClick={() => setCurrentSkills('topSkills')}
            >
              <Tooltip
                placement="top"
                title="The most frequently occurring skills within this segment."
              >
                <Icon
                  type="fire"
                  style={{
                    color: colors.orange1,
                    fontSize: 18,
                    opacity: 0.8,
                    verticalAlign: 0,
                  }}
                />
                <span className="legend-label">Volume</span>
              </Tooltip>
            </span>
          </Col>
          <Col md={6}>
            <span
              className="clickable"
              onClick={() => setCurrentSkills('relevantSkills')}
            >
              <Tooltip
                placement="top"
                title="The significance of skills in this segment, compared against the general population."
              >
                <Icon
                  type="block"
                  style={{
                    color: colors.blue1,
                    fontSize: 18,
                    opacity: 0.8,
                    verticalAlign: 0,
                  }}
                />
                <span className="legend-label">Relevance</span>
              </Tooltip>
            </span>
          </Col>
          <Col md={6}>
            <span
              className="clickable"
              onClick={() => setCurrentSkills('highestPayingSkills')}
            >
              <Tooltip
                placement="top"
                title="The skills most likely to align with higher salary outcomes."
              >
                <Icon
                  type="dollar"
                  style={{
                    color: colors.green1,
                    fontSize: 18,
                    opacity: 0.8,
                    verticalAlign: 0,
                  }}
                />
                <span className="legend-label">Salary</span>
              </Tooltip>
            </span>
          </Col>
        </Row>
      )}
      <Row>
        {skillsData.length
          ? slice(currentSkillsData, 0, skillsPage * BATCH_LENGTH).map(
              (skill, index) => (
                <SkillsBars
                  toggleSkill={toggleSkill}
                  key={`${get(skill, 'name')}${index}`}
                  name={get(skill, 'name')}
                  selected={includes(selectedSkills, get(skill, 'name'))}
                  values={[
                    {
                      name: 'popularity',
                      value: round(
                        (get(skill, 'popularity') * 100) / maxPopularity,
                      ),
                    },
                    {
                      name: 'relevancy',
                      // normalize to 70, and add 30 as a minimum
                      value:
                        round((get(skill, 'relevance') * 70) / maxRelevancy) +
                        30,
                    },
                    {
                      name: 'salary',
                      value: round((get(skill, 'salary') * 100) / maxSalary),
                    },
                  ]}
                />
              ),
            )
          : getSkeletonRows()}
      </Row>
      <Row type="flex" justify="center">
        {skillsPage * BATCH_LENGTH < skillsData.length && (
          <Button
            size="large"
            onClick={() => setSkillsPage(skillsPage + 1)}
            style={{
              alignItems: 'center',
              display: 'flex',
              fontSize: 16,
            }}
          >
            Load More
            <Icon
              type="down-circle"
              style={{ fontSize: '18px', lineHeight: '14px' }}
              theme="filled"
            />
          </Button>
        )}
      </Row>
    </div>
  )
}

export default SkillsTab
