import gql from 'graphql-tag'

export const getRequisiitonsQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      isAdmin
      roles
      client {
        trialExpired
        settings {
          id
          credits
        }
        id
        name
        subscription {
          id
          needsUpdatedPayment
        }
        jobRequisitions {
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          id
          name
          savedSearches {
            id
            name
            searchParams
            description
          }
          savedDownloads {
            id
            fileName
            url
            searchParams
            calculatedStatus
          }
          candidateData {
            id
          }
        }
      }
    }
  }
`
