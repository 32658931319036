import React from 'react'

import { Icon } from 'antd'
import get from 'lodash/get'

import {
  SEARCH_ENGINES,
  createCompanySearchQuery,
} from '../../utils/stringManipulation'

const CompanyLinksColumn = props => {
  const companyName = get(props, 'val')

  return (
    <div style={{ cursor: 'pointer', textAlign: 'center' }}>
      <div style={{ marginBottom: 5 }}>
        <Icon
          type="linkedin"
          theme="filled"
          style={{ color: '#0076b5', fontSize: 18, marginRight: 3 }}
          data-id={companyName}
          onClick={() =>
            window.open(
              createCompanySearchQuery(SEARCH_ENGINES.linkedIn, companyName),
              '_blank',
            )
          }
        />
        <Icon
          type="google"
          theme="outlined"
          style={{ fontSize: 18, marginRight: 3 }}
          data-id={companyName}
          onClick={() =>
            window.open(
              createCompanySearchQuery(SEARCH_ENGINES.google, companyName),
              '_blank',
            )
          }
        />
        <Icon
          type="search"
          theme="outlined"
          style={{ color: '#0076b5', fontSize: 18 }}
          data-id={companyName}
          onClick={() =>
            window.open(
              createCompanySearchQuery(SEARCH_ENGINES.bing, companyName),
              '_blank',
            )
          }
        />
      </div>
    </div>
  )
}

export default CompanyLinksColumn
