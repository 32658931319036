import React, { useEffect, useCallback, useState } from 'react'

import { Col, Icon, Row, Tabs, Spin, Typography } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import set from 'lodash/set'
import uniq from 'lodash/uniq'
import qs from 'query-string'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import { apolloClient } from '../../../../apolloClient'
import { countQuery } from '../../../../apolloClient/operations/search/dataQueries'
import SpinnerWithUberFacts from '../../../../components/layout/SpinnerWithUberFacts'
import withTeAuth from '../../../../hocs/withTeAuth'
import useModalManagement from '../../../../hooks/common/useModalManagement'
import usePrevious from '../../../../hooks/common/usePrevious'
import { usePublicSearch } from '../../../../hooks/data/usePublicSearch/usePublicSearch'
import { filterNames } from '../../../../utils/constants/filterNames'
import {
  isPublic,
  UNAUTHENTICATED_ROUTES,
} from '../../../../utils/routing/routes'
import AnalyticsTab from '../../../Authenticated/CrowdSegment/components/AnalyticsTab'
import CandidatesTab from '../../../Authenticated/CrowdSegment/components/CandidatesTab'
import CompaniesTab from '../../../Authenticated/CrowdSegment/components/CompaniesTab'
import CrowdPleasers from '../../../Authenticated/CrowdSegment/components/components/CrowdPleasers'
import EducationTab from '../../../Authenticated/CrowdSegment/components/EducationTab'
import EmploymentTab from '../../../Authenticated/CrowdSegment/components/EmploymentTab'
import SearchHeader from '../../../Authenticated/CrowdSegment/components/SearchHeader'
import SkillsTab from '../../../Authenticated/CrowdSegment/components/SkillsTab'
import {
  useCrowdSegmentDispatch,
  useCrowdSegmentState,
} from '../../../Authenticated/CrowdSegment/crowd-context'
import { isRangeFilter } from '../../../Authenticated/CrowdSegment/static/defaultStateValues'
import { searchSettings } from '../../../Authenticated/CrowdSegment/static/searchSettings'
import WelcomeCard from '../../../Authenticated/CrowdSegment/WelcomeCard'

import PublicContactFormModal from './PublicContactFormModal'
import PublicSearchCTA from './PublicSearchCTA'

const { Title } = Typography
const { TabPane } = Tabs

export const TOOLTIP_MSGS = {
  noResults:
    'This search will not return any results, please try adjusting your filters.',
  tooBroad:
    'Overcrowded segment. Please narrow down your search by adding more filters.',
  tooBroadOpenText: 'Overcrowded segment. Keep typing...',
}

export const getNonEmptyFiltersCount = (
  /** @type {{ [filter: any]; }} */ filters,
  /** @type {string[]} */ filterSubset,
) => {
  return Object.keys(filters).filter(key => {
    const value = filters[key]
    const passesFilter = filterSubset
      ? !isEmpty(value) && filterSubset.includes(key)
      : !isEmpty(value)
    return passesFilter
  }).length
}

const tabKeys = {
  analytics: '1',
  candidates: '6',
  companies: '5',
  education: '4',
  employment: '2',
  skills: '3',
}

const PublicSearch = props => {
  const permissions = get(props, 'permissions')
  const teDownloadCredits = get(props, 'teDownloadCredits', 0)

  // const dispatch = useCrowdSegmentDispatch()
  const [activeTab, setActiveTab] = useState(tabKeys.analytics)
  const [companiesTabPage, setCompaniesTabPage] = useState(1)
  const [candidatesTabPage, setCandidatesTabPage] = useState(1)
  // @ts-ignore
  const [selectedSearchGroup] = useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' })
  const location = useLocation()
  const { getPublicSearchData, loadingPublicSearchData } = usePublicSearch()

  const {
    analyticsData,
    analyticsDataError,
    candidateDataError,
    candidatesData,
    companiesData,
    companyDataError,
    educationData,
    employmentData,
    extraFiltersToApply,
    fetchingTotal,
    filtersToApply,
    publicContactFormVisible,
    publicSearchCTAVisible,
    searchDescription,
    searchName,
    skillsData,
    suggestions,
    suppliedFilters,
    totalCount,
    // selectedSearchGroup,
  } = useCrowdSegmentState()

  const {
    // setSelectedSearchGroup,
    clearAllStateData,
    setAnalyticsData,
    setCandidatesData,
    setCompaniesData,
    setEducationData,
    setEmploymentData,
    setExtraFilters,
    setFacets,
    setFetchingTotal,
    setFiltersToApply,
    setPublicContactFormVisible,
    setPublicSearchCTAVisible,
    setSearchDescription,
    setSearchName,
    setSkillsData,
    setSuggestions,
    setSuppliedFilters,
    setTotalCount,
  } = useCrowdSegmentDispatch()

  const isPublicRoute = isPublic(get(location, 'pathname'))

  const { openModal: openPaymentModal } = useModalManagement(false)

  const convertValuesToNumbers = arr => {
    return arr.map(val => parseInt(val))
  }

  const separateFilters = useCallback(filtersReturned => {
    const filters = {}
    const extraFilters = {}
    keys(filtersReturned).forEach(key => {
      const value = get(filtersReturned, `${key}`)
      if (has(searchSettings, `${key}`)) {
        if (isRangeFilter(key)) {
          const numberValues = convertValuesToNumbers(value)
          set(filters, `${key}`, numberValues)
        } else {
          set(filters, `${key}`, value)
        }
      } else {
        if (key === 'geo_points') {
          const geoValue = value ? value : []
          set(extraFilters, `${key}`, geoValue)
        } else {
          set(extraFilters, `${key}`, value)
        }
      }
    })
    return {
      extraFilters,
      filters,
    }
  }, [])

  const getData = useCallback(
    async id => {
      const data = await getPublicSearchData(id)
      const searchName = get(data, 'name')
      const searchDescription = get(data, 'description')
      setSearchName(searchName)
      setSearchDescription(searchDescription)
      const searchData = get(data, 'searchData')
      const tabsData = get(searchData, 'analyticsData.custom_aggs')
      setAnalyticsData(get(tabsData, 'analytics'))
      setSkillsData(get(tabsData, 'skills'))
      setEmploymentData(get(tabsData, 'employment'))
      setEducationData(get(tabsData, 'education'))

      const facetsData = get(searchData, 'analyticsData.facets')
      setFacets(facetsData)

      const suggestionsData = get(searchData, 'analyticsData.suggestions')
      setSuggestions(suggestionsData)

      const filtersData = get(searchData, 'analyticsData.filters')
      setSuppliedFilters(filtersData)
      const { filters, extraFilters } = separateFilters(filtersData)
      setFiltersToApply(filters)
      setExtraFilters(extraFilters)

      const candidatesData = get(searchData, 'candidatesData.documents', [])
      setCandidatesData(candidatesData)

      const companiesData = get(searchData, 'companiesData', [])
      setCompaniesData(companiesData)
    },
    [
      setAnalyticsData,
      setSkillsData,
      setEmploymentData,
      setEducationData,
      setSuggestions,
      setSuppliedFilters,
      getPublicSearchData,
      separateFilters,
      setCandidatesData,
      setCompaniesData,
      setExtraFilters,
      setFacets,
      setFiltersToApply,
      setSearchName,
      setSearchDescription,
    ],
  )

  const searchQuery = get(location, 'search')
  const { key } = qs.parse(searchQuery)
  const prevKey = usePrevious(key)

  useEffect(() => {
    const processQueryParams = async () => {
      await getData(key)
    }
    if (key && key !== prevKey) {
      processQueryParams()
    }
  }, [getData, location, key, prevKey])

  const formatFiltersToApply = filters => {
    const clonedFilters = cloneDeep(filters)

    let locationObj = get(clonedFilters, 'location')

    // set location filters to null, when there is no geodata
    if (locationObj) {
      if (!locationObj.geoData) {
        locationObj = null
        clonedFilters['location'] = locationObj
      }
    }

    return clonedFilters
  }

  const toggleAllFilters = (key, filters) => {
    const updatedFilters = cloneDeep(filtersToApply)
    updatedFilters[key] = filters
    setFiltersToApply(updatedFilters)
  }

  const setRange = (key, filterValue) => {
    const updatedFilters = cloneDeep(extraFiltersToApply)
    set(updatedFilters, key, filterValue)
    setExtraFilters(updatedFilters)
  }
  const setFilterValue = (filterName, filterValue) => {
    let updatedFilters = cloneDeep(filtersToApply)
    if (typeof filterValue === 'undefined') {
      updatedFilters = omit(updatedFilters, filterName)
    } else {
      set(updatedFilters, `${filterName}`, filterValue)
    }
    setFiltersToApply(updatedFilters)
  }

  const toggleExtraFilter = (key, filterValue, isRangeFilter) => {
    if (isRangeFilter) {
      setRange(key, filterValue)
      return
    }
    const updatedFilters = cloneDeep(extraFiltersToApply)
    let selectedValues = get(updatedFilters, [key], [])
    const filterSelected = selectedValues.includes(filterValue)
    if (!filterSelected) {
      selectedValues.push(filterValue)
    } else {
      selectedValues = selectedValues.filter(val => val !== filterValue)
    }
    updatedFilters[key] = selectedValues
    setExtraFilters(updatedFilters)
  }

  const toggleFilter = (filterName, filterValue) => {
    const updatedFilters = cloneDeep(filtersToApply)
    let selectedValues = get(updatedFilters, [filterName], [])
    const filterSelected = selectedValues.includes(filterValue)
    if (!filterSelected) {
      selectedValues.push(filterValue)
    } else {
      selectedValues = selectedValues.filter(val => val !== filterValue)
    }
    updatedFilters[filterName] = selectedValues
    setFiltersToApply(updatedFilters)
  }

  const setExtraFilterValue = (filterName, filterValue) => {
    let updatedFilters = cloneDeep(extraFiltersToApply)
    if (typeof filterValue === 'undefined') {
      updatedFilters = omit(updatedFilters, filterName)
    } else {
      set(updatedFilters, `${filterName}`, filterValue)
    }
    setExtraFilters(updatedFilters)
  }

  const updateAppliedFilters = (name, value) => {
    const updatedFilters = cloneDeep(filtersToApply)
    updatedFilters[name] = value
    setFiltersToApply(updatedFilters)
  }

  const fetchTotalCount = useCallback(async () => {
    const formattedFiltersToApply = formatFiltersToApply(filtersToApply)
    const combinedFilters = {
      ...formattedFiltersToApply,
      ...extraFiltersToApply,
    }
    if (isEqual(combinedFilters, {})) {
      return
    }
    setFetchingTotal(true)
    try {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: countQuery,
        variables: {
          filters: combinedFilters,
        },
      })
      const data = get(result, 'data')
      setFetchingTotal(false)
      setTotalCount(get(data, filterNames.totalCount))
    } catch (error) {
      console.log(error)
      setFetchingTotal(false)
    }
  }, [extraFiltersToApply, filtersToApply, setFetchingTotal, setTotalCount])

  useEffect(() => {
    let subscribed = true
    const fetchTotal = async () => await fetchTotalCount()
    if (subscribed) {
      fetchTotal()
    }
    return () => (subscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersToApply, extraFiltersToApply])

  const handleGeoSelection = points => {
    setExtraFilters({
      ...extraFiltersToApply,
      geo_points: points,
    })
  }

  const clearMultipleFilters = filters => {
    const updatedFilters = cloneDeep(filtersToApply)
    const customizer = objValue => {
      if (isArray(objValue)) {
        return []
      } else return null
    }
    mergeWith(updatedFilters, filters, customizer)
    setFiltersToApply(updatedFilters)
  }

  const getTooltipMsg = () => {
    if (totalCount === 0) {
      return TOOLTIP_MSGS.noResults
    } else if (totalCount > process.env.REACT_APP_MAX_SEARCH_SIZE) {
      return TOOLTIP_MSGS.tooBroad
    }
  }

  // TODO: Create showErrorMsg var for !submittable and filters > 0. Pass to OpenTextSearch and SearchHeader
  const clearAllFiltersAndResults = () => clearAllStateData()

  useEffect(() => {
    if (
      location.pathname.includes(
        UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH_WITH_CONTACT,
      )
    ) {
      setPublicContactFormVisible(true)
    }
  }, [location.pathname, setPublicContactFormVisible])

  return (
    <>
      <div>
        <PublicSearchCTA visible={publicSearchCTAVisible} />
        <PublicContactFormModal visible={publicContactFormVisible} />
        <Row
          style={{
            marginBottom: '0',
          }}
        >
          <div className={!isMobile ? 'sticky-header-wrapper' : ''}>
            <SearchHeader
              toggleAllFilters={toggleAllFilters}
              toggleFilter={toggleFilter}
              filtersToApply={filtersToApply}
              updateAppliedFilters={updateAppliedFilters}
              submittable={true}
              onSubmit={() => setPublicSearchCTAVisible(true)}
              suggestions={suggestions}
              loading={loadingPublicSearchData || fetchingTotal}
              fetchingTotal={fetchingTotal}
              totalCount={totalCount}
              setFilterValue={setFilterValue}
              clearMultipleFilters={clearMultipleFilters}
              tooltipMessage={getTooltipMsg()}
              clearAllFiltersAndResults={clearAllFiltersAndResults}
              selectedFilters={{
                ...filtersToApply,
                ...extraFiltersToApply,
              }}
              selectedSearchGroup={selectedSearchGroup}
            />
          </div>
          <Col span={24} className="te-results-container">
            {analyticsData ? (
              <>
                <div className="max-1200">
                  <Title level={2}>{searchName}</Title>
                  <Typography.Paragraph>
                    {searchDescription}
                  </Typography.Paragraph>
                </div>
                <Tabs
                  activeKey={activeTab}
                  defaultActiveKey={tabKeys.analytics}
                  className="report-body talent-eye-tabs max-1200"
                  onTabClick={key => setActiveTab(key)}
                >
                  <TabPane tab="Analytics" key={tabKeys.analytics}>
                    <AnalyticsTab
                      isMobile={isMobile}
                      analyticsData={analyticsData}
                      error={analyticsDataError}
                      toggleExtraFilter={toggleExtraFilter}
                      toggleFilter={toggleFilter}
                      extraFiltersToApply={extraFiltersToApply}
                      filtersToApply={filtersToApply}
                      loading={loadingPublicSearchData}
                      suppliedFilters={suppliedFilters}
                      selectedStates={get(
                        filtersToApply,
                        searchSettings.states.name,
                      )}
                      handleGeoSelection={handleGeoSelection}
                      geoFilterApplied={
                        !!get(extraFiltersToApply, 'geo_points') &&
                        !!get(extraFiltersToApply, 'geo_points').length
                      }
                    />
                  </TabPane>
                  <TabPane tab="Employment" key={tabKeys.employment}>
                    <EmploymentTab
                      employmentData={employmentData}
                      error={analyticsDataError}
                      toggleExtraFilter={toggleExtraFilter}
                      toggleFilter={toggleFilter}
                      extraFiltersToApply={extraFiltersToApply}
                      filtersToApply={filtersToApply}
                      loading={loadingPublicSearchData}
                      suppliedFilters={suppliedFilters}
                      isMobile={isMobile}
                    />
                  </TabPane>
                  <TabPane tab="Skills" key={tabKeys.skills}>
                    <div className="te-card-content">
                      <SkillsTab
                        error={analyticsDataError}
                        skillsData={!!skillsData && skillsData}
                        toggleFilter={toggleFilter}
                        filtersToApply={filtersToApply}
                        isMobile={isMobile}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Education" key={tabKeys.education}>
                    <EducationTab
                      educationData={educationData}
                      error={analyticsDataError}
                      toggleFilter={toggleFilter}
                      filtersToApply={filtersToApply}
                      isMobile={isMobile}
                    />
                  </TabPane>
                  {/* TODO: Can we hide the tab following an error? Can't recreate the error case to test */}
                  <TabPane
                    tab={
                      <>
                        Companies{' '}
                        <Spin spinning={loadingPublicSearchData} size="small" />{' '}
                        {companyDataError ? (
                          <Icon
                            type="exclamation-circle"
                            theme="twoTone"
                            twoToneColor="#eb2f96"
                          />
                        ) : (
                          ''
                        )}
                      </>
                    }
                    key={tabKeys.companies}
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="te-card-content" style={{ minWidth: 900 }}>
                      <div className="data-card">
                        <CompaniesTab
                          isPublicRoute={isPublicRoute}
                          data={get(companiesData, 'documents')}
                          page={companiesTabPage}
                          setPage={setCompaniesTabPage}
                          permissions={permissions}
                          error={companyDataError}
                          selectedCompanies={get(filtersToApply, 'companies')}
                          loading={loadingPublicSearchData}
                          toggleFilter={toggleFilter}
                          totalMatches={get(
                            analyticsData,
                            'companies_count',
                            0,
                          )}
                          selectedSkills={get(
                            filtersToApply,
                            searchSettings.required_skills.name,
                          )}
                          openPaymentModal={openPaymentModal}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        Candidates{' '}
                        <Spin spinning={loadingPublicSearchData} size="small" />{' '}
                        {candidateDataError ? (
                          <Icon
                            type="exclamation-circle"
                            theme="twoTone"
                            twoToneColor="#eb2f96"
                          />
                        ) : (
                          ''
                        )}
                      </>
                    }
                    key={tabKeys.candidates}
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="te-card-content" style={{ minWidth: 900 }}>
                      <div className="data-card">
                        <CandidatesTab
                          isPublicRoute={isPublicRoute}
                          data={candidatesData}
                          error={candidateDataError}
                          extraFiltersToApply={extraFiltersToApply}
                          filtersToApply={filtersToApply}
                          handleSubmit={() => setPublicSearchCTAVisible(true)}
                          loading={loadingPublicSearchData || fetchingTotal}
                          page={candidatesTabPage}
                          setPage={setCandidatesTabPage}
                          suppliedFilters={suppliedFilters}
                          totalSkillsFilters={
                            uniq([
                              ...get(suppliedFilters, 'required_skills', []),
                            ]).length
                          }
                          permissions={permissions}
                          openPaymentModal={openPaymentModal}
                          teDownloadCredits={teDownloadCredits}
                          totalMatches={get(analyticsData, 'total_matches', 0)}
                          setExtraFilterValue={setExtraFilterValue}
                          selectedSkills={get(
                            filtersToApply,
                            searchSettings.required_skills.name,
                          )}
                          toggleFilter={toggleFilter}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
                <div
                  className="max-1200"
                  style={{ marginBottom: 80, marginTop: 100 }}
                >
                  <CrowdPleasers isPublicRoute={isPublicRoute} />
                </div>
              </>
            ) : (
              <SpinnerWithUberFacts
                loading={loadingPublicSearchData}
                minLoadingSeconds={0}
              >
                <WelcomeCard
                  filtersToApply={filtersToApply}
                  toggleFilter={toggleFilter}
                  totalCount={totalCount}
                  submittable={true}
                  onSubmit={() => setPublicSearchCTAVisible(true)}
                  isPublicRoute={isPublicRoute}
                />
              </SpinnerWithUberFacts>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withTeAuth(PublicSearch)
