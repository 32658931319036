import React from 'react'

import { List } from 'antd'
import PT from 'prop-types'

const AdminListItem = props => {
  const { email, firstName, lastName } = props
  const fullName = `${firstName} ${lastName}`
  return (
    <List.Item className="admin-list-item">
      <List.Item.Meta title={fullName} description={email} />
    </List.Item>
  )
}

AdminListItem.propTypes = {
  email: PT.string.isRequired,
  fullName: PT.string.isRequired,
}

export default AdminListItem
