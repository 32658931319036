import gql from 'graphql-tag'

export const createSubscriptionForExistingCustomerMutation = gql`
  mutation CreateSubscriptionForExistingCustomer(
    $stripeCardToken: String!
    $couponCode: String
  ) {
    createSubscriptionForExistingCustomer(
      stripeCardToken: $stripeCardToken
      couponCode: $couponCode
    ) {
      id
      createdAt
      canceled
      needsUpdatedPayment
      updatedAt
      paymentMethods {
        last4
        brand
      }
    }
  }
`
