import any from 'lodash/fp/any'
import identity from 'lodash/fp/identity'
import pipe from 'lodash/fp/pipe'
import values from 'lodash/fp/values'

const hasElements = pipe(
  values,
  any(identity)
)

export const hasErrors = hasElements

export const hasValues = hasElements

export const getHelp = ({ dirty, error, invalid, submitFailed }) =>
  invalid && (dirty || submitFailed) && error

export const getValidateStatus = pipe(
  getHelp,
  value => (value ? 'error' : '')
)
