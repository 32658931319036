import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import chunk from 'lodash/chunk'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { getCandidatesDataByIdQuery } from '../../../apolloClient/operations/lookup/getCandidatesDataByIdQuery'
import usePrevious from '../../common/usePrevious'

const chunkSize = 5
export const useCandidatesById = ({ candidateIds }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const candidateIdChunks = chunk(candidateIds, chunkSize)
  const currentChunk = candidateIdChunks[currentPage - 1]

  const [getCandidatesById, { data, loading, error }] = useLazyQuery(
    getCandidatesDataByIdQuery,
  )

  const candidatesData = get(data, 'getCandidatesDataById', [])

  const previousChunk = usePrevious(currentChunk)
  useEffect(() => {
    if (!isEqual(previousChunk, currentChunk)) {
      getCandidatesById({
        variables: {
          candidateIds: currentChunk,
        },
      })
    }
  }, [currentChunk, previousChunk, getCandidatesById])

  return {
    candidatesData,
    chunkSize,
    currentPage,
    errorGettingCandidateDetails: error,
    getCandidatesById,
    loadingCandidateDetails: loading,
    setCurrentPage,
  }
}
