import gql from 'graphql-tag'

export const getUserForBasicInfoQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      email
      firstName
      lastName
      isAdmin
      client {
        name
        id
        settings {
          credits
        }
      }
    }
  }
`
