import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { getCreditsUsageForDownloadFileQuery } from '../../../apolloClient/operations/downloads/getCreditsUsageForDownloadFileQuery'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'
import { isPublic } from '../../../utils/routing/routes'

export const useCreditUsage = ({ variables }) => {
  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))

  const { data } = useQuery(getCreditsUsageForDownloadFileQuery, {
    fetchPolicy: 'network-only',
    onError: error => {
      errorService.report(error, 'getJobRequisitionsQuery')
      feedback.error({
        title: 'Error fetching Crowds..Please try again',
      })
    },
    skip: isPublicRoute,
    variables,
  })

  const creditsUsage = get(data, 'getCreditsUsageForDownloadFile', 0)

  return { creditsUsage }
}
