import React from 'react'
import PT from 'prop-types'
import { Card } from 'antd'

const FunctionalityContainer = props => {
  const { children, title } = props
  return (
    <Card bodyStyle={{ paddingTop: 8 }} title={title}>
      {children}
    </Card>
  )
}

FunctionalityContainer.propTypes = {
  title: PT.string.isRequired
}

export default FunctionalityContainer
