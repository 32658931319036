import React from 'react'

import { Button, Empty, Typography } from 'antd'
import filter from 'lodash/filter'

import useModalManagement from '../../../../../../../hooks/common/useModalManagement'
import { useClientMembers } from '../../../../../../../hooks/data/admin/useClientMembers'

import AdminListItem from './components/AdminListItem'
import CreateMemberForm from './components/CreateMemberForm'
import MemberListItem from './components/MemberListItem'
import MembersList from './components/MembersList'
import PendingInvitationListItem from './components/PendingInvitationListItem'

const ManageMembers = () => {
  const createMemberModalProps = useModalManagement(false)

  const {
    admins,
    clientName,
    currentUserId,
    invitations,
    nonAdmins,
    loadingMembers,
    subscription,
  } = useClientMembers()

  return (
    <React.Fragment>
      <MembersList
        EmptyComponent={
          <Empty style={{ margin: 24 }} description="No Pending Invitations" />
        }
        extra={
          <>
            <Button
              type="primary"
              onClick={createMemberModalProps.openModal}
              style={{ marginLeft: 8, marginTop: 5 }}
            >
              Invite Member
            </Button>
          </>
        }
        data={invitations}
        loading={loadingMembers}
        title="Pending Invitations"
        renderItem={({ item }) => (
          <PendingInvitationListItem key={item.id} {...item} />
        )}
      />
      <MembersList
        containerProps={{ marginTop: 16 }}
        data={admins}
        EmptyComponent={
          <Empty style={{ margin: 24 }} description="No Admins Found" />
        }
        loading={loadingMembers}
        title="Admins"
        renderItem={({ item }) => <AdminListItem key={item.id} {...item} />}
      />
      <MembersList
        containerProps={{ marginTop: 16 }}
        data={[
          ...filter(admins, o => o.id !== currentUserId).map(o => {
            return { ...o, key: o.id }
          }),
          ...nonAdmins.map(o => {
            return { ...o, key: o.id }
          }),
        ]}
        EmptyComponent={
          <Empty style={{ margin: 24 }} description="No Users Found" />
        }
        loading={loadingMembers}
        title="Members"
        renderItem={({ item }) => <MemberListItem key={item.id} {...item} />}
        extra={
          <>
            <Typography.Text type="secondary" style={{ marginRight: 40 }}>
              Is Admin?
            </Typography.Text>
            <Typography.Text type="secondary" style={{ marginRight: 40 }}>
              Enabled?
            </Typography.Text>
          </>
        }
      />
      <CreateMemberForm
        clientName={clientName}
        modalProps={{
          ...createMemberModalProps,
          title: 'Invite Team Member',
        }}
        subscription={subscription}
      />
    </React.Fragment>
  )
}

ManageMembers.propTypes = {}

export default ManageMembers
