import gql from 'graphql-tag'

export const getCandidatesDataByIdQuery = gql`
  query GetCandidatesDataById($candidateIds: [ID]!, $download: Boolean) {
    getCandidatesDataById(candidateIds: $candidateIds, download: $download) {
      id
      full_name
      email
      telephone_number
      age
      city
      state
      social_link
      highest_edu
      previous_edu_milestones
      current_position
      previous_position
      previous_position_milestones
      skills
      validatedEmail
      personalEmail
      verificationReason
    }
  }
`
