import React, { useEffect, useState } from 'react'

import { Button, Form, Icon, Input } from 'antd'
import { Auth } from 'aws-amplify'
import get from 'lodash/get'

import { useFormState } from '../../../hooks/common/useFormState'
import usePrevious from '../../../hooks/common/usePrevious'
import feedback from '../../../utils/feedback'
import { UNAUTHENTICATED_ROUTES } from '../../../utils/routing/routes'
import {
  useCrowdSegmentDispatch,
  useCrowdSegmentState,
} from '../../Authenticated/CrowdSegment/crowd-context'
import UnauthenticatedCard from '../components/UnauthenticatedCard'

const FORM_FIELD_TITLES = {
  CODE: 'authenticationCode',
  USERNAME: 'username',
}

const ConfirmSignup = () => {
  const { unauthenticatedUser } = useCrowdSegmentState()
  const { setUnauthenticatedUser } = useCrowdSegmentDispatch()
  const { formValues, handleChange, updateValue } = useFormState({
    [FORM_FIELD_TITLES.USERNAME]: unauthenticatedUser.email
      ? unauthenticatedUser.email
      : null,
    [FORM_FIELD_TITLES.CODE]: null,
  })
  const [confirming, setConfirming] = useState(false)

  const prevUnauthenticatedUser = usePrevious(unauthenticatedUser)

  useEffect(() => {
    const prevEmail = get(prevUnauthenticatedUser, 'email', null)
    const currEmail = get(unauthenticatedUser, 'email', null)
    if (!prevEmail && !!currEmail) {
      updateValue({
        [FORM_FIELD_TITLES.USERNAME]: unauthenticatedUser.email,
      })
    }
  }, [prevUnauthenticatedUser, unauthenticatedUser, updateValue])

  const onSubmit = async () => {
    const { username, authenticationCode } = formValues
    setUnauthenticatedUser({ email: username })
    setConfirming(true)
    try {
      await Auth.confirmSignUp(username, authenticationCode)
      feedback.success({
        content:
          'Your email has been successfully confirmed! Please sign in to continue setting up your account.',
      })
      setConfirming(false)
      window.history.go(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
    } catch (error) {
      console.log(error)
      setConfirming(false)
    }
  }

  const handleResendCode = async () => {
    const { username } = formValues
    if (!username) return
    try {
      await Auth.resendSignUp(username)
      feedback.success({
        content: 'Code sent!',
      })
    } catch (error) {
      console.log(error)
      const message = get(
        error,
        'message',
        'An error occurred. Please make sure your email is correct.',
      )
      feedback.error({ title: message })
    }
  }

  return (
    <UnauthenticatedCard>
      <h2 style={{ fontWeight: 'bold', textAlign: 'center' }}>
        Confirm your account
      </h2>
      <h5 style={{ marginBottom: 40, textAlign: 'center' }}>
        Please enter the code sent you to via email. Be sure to check your spam
        folder.
      </h5>
      <Form hideRequiredMark className="signup-form antd-auth-form">
        <Form.Item>
          <Input
            placeholder="Email"
            value={formValues[FORM_FIELD_TITLES.USERNAME]}
            id={FORM_FIELD_TITLES.USERNAME}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <Input
            id={FORM_FIELD_TITLES.CODE}
            placeholder="Authentication Code"
            onChange={handleChange}
            value={formValues[FORM_FIELD_TITLES.CODE]}
            data-lpignore="true"
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="link"
            onClick={handleResendCode}
            disabled={!formValues[FORM_FIELD_TITLES.USERNAME]}
          >
            Resend Code
          </Button>
          <Button
            loading={confirming}
            htmlType="submit"
            style={{ marginLeft: 8 }}
            type="primary"
            onClick={onSubmit}
            data-testid="signup-button"
            disabled={
              !formValues.authenticationCode ||
              !formValues.authenticationCode.length
            }
          >
            Submit
            <Icon type="double-right" />
          </Button>
        </div>
      </Form>
    </UnauthenticatedCard>
  )
}

const ConfirmSignupForm = Form.create({
  name: 'ConfirmSignupForm',
})(ConfirmSignup)
export default ConfirmSignupForm
