import React from 'react'

const HeatMapLegend = () => {
  return (
    <div>
      <div className="caps-label">CENSUS POPULATION</div>
      <div className="choropleth-legend" />
      <span style={{ float: 'right', fontSize: 12, fontStyle: 'italic' }}>
        more
      </span>
      <span style={{ float: 'left', fontSize: 12, fontStyle: 'italic' }}>
        less
      </span>
    </div>
  )
}

export default HeatMapLegend
