import React from 'react'
import CenteredLayoutWide from '../components/CenteredLayoutWide'
import SignUpFromInvitationForm from './SignupFromInvitationForm'

const SignupFromInvitation = props => {
  return (
    <div>
      <CenteredLayoutWide>
        <div
          style={{ margin: '80px auto', maxWidth: 650 }}
          className="antd-auth-form"
        >
          <SignUpFromInvitationForm />
        </div>
      </CenteredLayoutWide>
    </div>
  )
}

export default SignupFromInvitation
