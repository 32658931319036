import React from 'react'

import { Auth } from 'aws-amplify'

import errorService from '../../../../../../../utils/analytics/error'
import feedback from '../../../../../../../utils/feedback'
import FunctionalityContainer from '../FunctionalityContainer'

import ChangePasswordForm from './components/ChangePasswordForm'

const ChangePassword = () => {
  const onSubmit = formData => {
    const onChangePassword = async formData => {
      try {
        const { currentPassword, newPassword } = formData
        const user = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(user, currentPassword, newPassword)
        feedback.success({ content: 'Password changed successfully' })
      } catch (error) {
        errorService.report(error, 'ChangePassword')
        feedback.error({
          content: error.message,
          title: 'Error changing your password',
        })
      }
    }
    onChangePassword(formData)
  }

  return (
    <FunctionalityContainer title="Change Password">
      <ChangePasswordForm onSubmit={onSubmit} />
    </FunctionalityContainer>
  )
}

export default ChangePassword
