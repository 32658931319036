import React, { useState } from 'react'

import { Modal, Button, Row, Form } from 'antd'
import get from 'lodash/get'

import { apolloClient } from '../../../../../apolloClient'
import { addCandidatesToJobRequisitionMutation } from '../../../../../apolloClient/operations/requisitions/addCandidatesToJobRequisitionMutation'
import RequisitionSelect from '../../../../../components/forms/RequisitionSelect/RequisitionSelect'
import useUserForSearchRoute from '../../../../../hooks/data/loggedInUser/useUserForSearchRoute'
import { useOptionToCreateNewRequisition } from '../../../../../hooks/data/requisitions/useOptionToCreateNewRequisition'
import errorService from '../../../../../utils/analytics/error'
import feedback from '../../../../../utils/feedback'
import NewRequisitionModal from '../NewRequisitionModal'

const SaveCandidatesForm = props => {
  const visible = get(props, 'visible')
  const toggleModal = get(props, 'toggleModal')
  const selectedCandidates = get(props, 'selectedCandidates')
  const [selectedRequisitionId, setSelectedRequistionId] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [requisitionModalShown, setRequisitionModalShown] = useState(false)
  const { userProfile } = useUserForSearchRoute()
  const requisitions = get(userProfile, 'client.jobRequisitions', [])
  const { createNewRequisition } = useOptionToCreateNewRequisition({
    requisitions,
  })

  const onReqChange = value => {
    setSelectedRequistionId(value)
  }

  const saveCandidates = async () => {
    try {
      const newRequisitionId = await createNewRequisition(selectedRequisitionId)
      await apolloClient.mutate({
        mutation: addCandidatesToJobRequisitionMutation,
        variables: {
          candidatesToAdd: selectedCandidates,
          requisitionId: newRequisitionId
            ? newRequisitionId
            : selectedRequisitionId,
        },
      })
      feedback.success({ content: 'Candidates saved successfully' })
    } catch (error) {
      errorService.report(error, 'addCandidatesToJobRequisitionMutation')
      feedback.error({
        title: 'Error saving candidates... Please try again',
      })
    } finally {
      toggleModal()
    }
  }

  const onSubmit = () => {
    saveCandidates()
  }

  const toggleReqModal = () => setRequisitionModalShown(!requisitionModalShown)

  const formItemLayout = {
    labelCol: {
      md: { span: 4 },
      sm: { span: 8 },
      xs: { span: 24 },
    },
    wrapperCol: {
      md: { span: 20 },
      sm: { span: 16 },
      xs: { span: 24 },
    },
  }

  return (
    <>
      <NewRequisitionModal
        requisitions={requisitions}
        toggleReqModal={toggleReqModal}
        requisitionModalShown={requisitionModalShown}
      />
      <Modal
        zIndex={1003}
        visible={visible}
        destroyOnClose
        footer={null}
        onCancel={toggleModal}
        width={'50%'}
      >
        <Row style={{ fontSize: '20px' }}>Save Candidates</Row>
        <Form>
          <Form.Item label="Crowd" {...formItemLayout}>
            <RequisitionSelect
              setSearchTerm={setSearchTerm}
              onReqChange={onReqChange}
              requisitions={requisitions}
              searchTerm={searchTerm}
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={onSubmit}
              disabled={!selectedRequisitionId}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default SaveCandidatesForm
