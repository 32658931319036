const { getModifiedVars, getTheme } = require('components/theme')

const colors = {
  accent: '#4e57d3',
  background: '#fafafa',
  border: '#D9D9D9',
  grayedOut: '#9A9A9A',
  green: '#52c41a',
  simpleBarBackground: '#99c4ee',
  simpleBarClickedBackground: 'rgba(80,153,222)',
  simpleBarNotClickedBackground: '#dce4ec',
  simpleBarSalaryBackground: '#68CEB7',
  white: '#FFFFFF',
}

const antdOverrides = {}

const modifiedVars = getModifiedVars({ antdOverrides })

const theme = getTheme({ antdOverrides, colors })

module.exports = {
  colors,
  modifiedVars,
  theme,
}
