import React, { Component } from 'react'

import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Amplify from 'aws-amplify'
// @ts-ignore
import { withTheme } from 'components'
import compose from 'lodash/fp/compose'
import { BrowserRouter } from 'react-router-dom'

import awsconfig from './aws-exports'
import ErrorPage from './components/errorBoundary/components/ErrorPage'
import withGraphQL from './hocs/withGraphQL'
import withOverriddenTheme from './hocs/withOverriddenTheme'
import Routes from './routes'
import errorService from './utils/analytics/error'
import {
  requestFirebaseNotificationPermission,
  onMessageListener,
} from './utils/firebase'

FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORGID })

Amplify.configure(awsconfig)

// Call the firebase message listener for in-app notifications
requestFirebaseNotificationPermission()
onMessageListener()

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

class App extends Component {
  componentDidMount() {
    errorService.configure()
  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={() => <ErrorPage />}>
            <Routes />
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </Elements>
    )
  }
}

const withEnhancers = compose(withGraphQL, withTheme, withOverriddenTheme)

export default withEnhancers(App)
