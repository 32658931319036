import gql from 'graphql-tag'

export const cancelSubscriptionMutation = gql`
  mutation CancelSubscription($reasonToCancel: String!) {
    cancelSubscription(reasonToCancel: $reasonToCancel) {
      id
      canceled
    }
  }
`
