import React from 'react'
import PT from 'prop-types'
import Bar from './charts/Bar'

const BarFilters = ({
  barColor,
  data,
  disabled,
  isSalary,
  onBarClick,
  selectedValue,
  title,
  maxHeight,
  selectedFilters,
  isClickFilter
}) => {
  return (
    <div>
      {data && !!data.length && (
        <div>
          <h5 style={{ margin: '0 auto 2px', maxWidth: 350 }}>{title}</h5>
          <Bar
            barColor={barColor}
            data={data}
            disabled={!!disabled}
            isSalary={isSalary}
            onBarClick={onBarClick}
            selectedValue={selectedValue}
            maxHeight={maxHeight}
            selectedFilters={selectedFilters}
            isClickFilter={isClickFilter}
          />
        </div>
      )}
    </div>
  )
}

BarFilters.propTypes = {
  data: PT.array.isRequired,
  disabled: PT.bool,
  onBarClick: PT.func,
  selectedValue: PT.string,
  title: PT.string,
  isClickFilter: PT.bool
}

export default BarFilters
