import gql from 'graphql-tag'

export const pullTotalChargeForAddSeatsQuery = gql`
  query PullTotalChargeForAddSeats($seats: Int!) {
    pullTotalChargeForAddSeats(seats: $seats) {
      discount {
        amount
        couponCode
        discountDisplay
      }
      subtotal
      total
    }
  }
`
