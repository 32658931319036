import React from 'react'

import get from 'lodash/get'
import pick from 'lodash/pick'

import useUserForSearchRoute from '../../hooks/data/loggedInUser/useUserForSearchRoute'

const withTeAuth = BaseComponent => props => {
  const { userProfile } = useUserForSearchRoute()

  const { isSystemAdmin = false, isAdmin = false, client } = pick(userProfile, [
    'isSystemAdmin',
    'isAdmin',
    'client',
  ])
  const {
    settings,
    trialExpired,
    subscription,
    name: clientName,
  } = pick(client, ['settings', 'trialExpired', 'subscription', 'name'])

  const permissions = {
    isAdmin,
    isSystemAdmin,
    onPaymentPlan: !!settings,
    stripeNeedsUpdatedPayment: get(subscription, 'needsUpdatedPayment'),
    trialExpired,
  }

  return (
    <BaseComponent
      {...props}
      permissions={permissions}
      clientName={clientName}
      teDownloadCredits={get(settings, 'credits')}
    />
  )
}

export default withTeAuth
