import React from 'react'

import { Button } from 'antd'

const PaymentFormFooter = props => {
  const { onSubmit, stripeToken, addingPayment } = props
  return (
    <div style={{ marginTop: 30, textAlign: 'right', width: '100%' }}>
      <Button
        type="primary"
        onClick={onSubmit}
        disabled={!stripeToken}
        loading={addingPayment}
      >
        Submit
      </Button>
    </div>
  )
}

export default PaymentFormFooter
