import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { getUserForMyAccountQuery } from '../../../apolloClient/operations/userData/getUserForMyAccountQuery'

export const useUserForMyAccount = () => {
  const { data } = useQuery(getUserForMyAccountQuery)
  const userProfile = get(data, 'getUserProfile')

  return {
    userProfile,
  }
}
