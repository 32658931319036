import get from 'lodash/get'

const { useQuery } = require('@apollo/client')

const {
  getUserForAddPaymentQuery,
} = require('../../../apolloClient/operations/userData/addPaymentUserProfileQuery')

export const useUserForAddPayment = () => {
  const { data, loading } = useQuery(getUserForAddPaymentQuery)
  const userData = get(data, 'getUserProfile')

  return { loadingUserProfile: loading, userData }
}
