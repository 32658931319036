const { default: gql } = require('graphql-tag')

export const purchaseCreditsMutation = gql`
  mutation PurchaseCredits($credits: Float!) {
    purchaseCredits(credits: $credits) {
      id
      credits
    }
  }
`
