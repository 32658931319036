import { useMutation } from '@apollo/client'
import get from 'lodash/get'

import { deleteUserMutation } from '../../../apolloClient/operations/userData/deleteUserMutation'
import feedback from '../../../utils/feedback'

export const useDeleteUser = messages => {
  const successMessage = messages
    ? get(messages, 'successMessage')
    : 'User deleted successfully'
  const errorMessage = messages
    ? get(messages, 'errorMessage')
    : 'Error deleting user'

  const [deleteUserCallback] = useMutation(deleteUserMutation, {
    onCompleted: () =>
      feedback.success({
        content: successMessage,
      }),
    onError: error => {
      feedback.error({
        content: error.message,
        title: errorMessage,
      })
    },
  })

  const deleteUser = variables => {
    const userId = get(variables, 'userId')

    return deleteUserCallback({
      update: cache => {
        const normalizedId = cache.identify({
          __typename: 'User',
          id: userId,
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      variables,
    })
  }

  return { deleteUser }
}
