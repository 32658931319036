import React from 'react'

import { Modal } from 'antd'
import _ from 'lodash'
import PT from 'prop-types'
import validator from 'validator'

import { useCreateUserInvitations } from '../../../../../../../../../hooks/data/admin/useCreateUserInvitations'

import MemberInviteForm from './components/MemberInviteForm'

const getEmailHelp = emails => {
  if (!emails || !emails.length) {
    return 'Email required'
  }
  const invalidEmails = emails.filter(email => !validator.isEmail(email))
  if (invalidEmails.length > 0) {
    return `${invalidEmails[0]} is not a valid email`
  }
}

const CreateMemberForm = props => {
  const { modalProps, subscription } = props
  const { inviteUsers } = useCreateUserInvitations({
    afterUpdate: modalProps.closeModal,
  })

  const validate = ({ emails = [] }) => {
    return {
      emails: getEmailHelp(emails),
    }
  }

  const onSubmit = formValues => {
    const { emails } = _.pick(formValues, ['emails'])
    inviteUsers({ variables: { emails } })
  }

  return (
    <Modal
      {...modalProps}
      destroyOnClose
      footer={null}
      onCancel={modalProps.closeModal}
    >
      <MemberInviteForm
        submitForm={onSubmit}
        submitButtonContent="Confirm and Send"
        submitButtonProps={{ block: true, style: { marginTop: 16 } }}
        validate={validate}
        getEmailHelp={getEmailHelp}
        subscription={subscription}
      />
    </Modal>
  )
}

CreateMemberForm.propTypes = {
  clientName: PT.string.isRequired,
}

export default CreateMemberForm
