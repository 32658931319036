import React, { useState } from 'react'

import { Button, Form, Input } from 'antd'
import { Auth } from 'aws-amplify'
import get from 'lodash/get'

import { useFormState } from '../../../hooks/common/useFormState'
import { UNAUTHENTICATED_ROUTES } from '../../../utils/routing/routes'
import {
  useCrowdSegmentDispatch,
  useCrowdSegmentState,
} from '../../Authenticated/CrowdSegment/crowd-context'
import CenteredLayoutWide from '../components/CenteredLayoutWide'
import NavLinks from '../components/NavLinks'
import UnauthenticatedCard from '../components/UnauthenticatedCard'

const FORM_FIELD_TITLES = {
  EMAIL: 'email',
}

const ForgotPassword = () => {
  const [sentResetEmail, setSentResetEmail] = useState(false)
  const { setUnauthenticatedUser } = useCrowdSegmentDispatch()
  const { unauthenticatedUser } = useCrowdSegmentState()

  const { formValues, handleChange } = useFormState({
    [FORM_FIELD_TITLES.EMAIL]: get(unauthenticatedUser, 'email', ''),
  })

  const onSubmit = async () => {
    setUnauthenticatedUser({ email: formValues.email })
    try {
      await Auth.forgotPassword(formValues.email)
      setSentResetEmail(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CenteredLayoutWide>
      <div style={{ margin: '80px auto', maxWidth: 650 }}>
        <UnauthenticatedCard
          title={sentResetEmail ? 'Reset your password' : 'Forgot password?'}
          subtitle={
            sentResetEmail
              ? 'Check your email for reset instructions!'
              : 'Enter your email to receive password reset instructions.'
          }
        >
          <Form hideRequiredMark className="signup-form antd-auth-form">
            <Form.Item>
              <Input
                placeholder="Email"
                value={formValues[FORM_FIELD_TITLES.EMAIL]}
                id={FORM_FIELD_TITLES.EMAIL}
                onChange={handleChange}
              />
            </Form.Item>
          </Form>
          <div
            style={{
              alignItems: 'baseline',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <NavLinks
              containerProps={{ marginTop: '16px' }}
              links={[
                {
                  label: 'Back to Log In',
                  route: UNAUTHENTICATED_ROUTES.AUTHENTICATE,
                },
              ]}
            />
            <Button style={{ marginLeft: 8 }} type="primary" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </UnauthenticatedCard>
      </div>
    </CenteredLayoutWide>
  )
}

export default ForgotPassword
