import React from 'react'

import { Button, List, Typography, Icon, Skeleton } from 'antd'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'

import { getUserForSubscriptionQuery } from '../../../../../../../apolloClient/operations/userData/getUserForSubscriptionQuery'
import useModalManagement from '../../../../../../../hooks/common/useModalManagement/index'
import { useDynamicUser } from '../../../../../../../hooks/data/loggedInUser/useDynamicUser'
import { useUpcomingCharge } from '../../../../../../../hooks/data/payments/useUpcomingCharge'
import { pickWithInitialState } from '../../../../../../../utils/data/utils'
import UpdatePaymentModal from '../../../../../CrowdSegment/components/components/UpdatePaymentModal'
import Resubscribe from '../../../../../CrowdSegment/components/ResubscribeModal'
import FunctionalityContainer from '../FunctionalityContainer/index'

import SubscriptionCancellation from './components/SubscriptionCancellation'
import TransactionHistoryModal from './components/TransactionHistoryModal'
import UpcomingInvoiceTable from './components/UpcomingInvoiceTable'

const { Text, Title } = Typography

const Subcription = () => {
  const { userProfile, gettingUserProfile } = useDynamicUser({
    userQuery: getUserForSubscriptionQuery,
  })
  const { isAdmin, client } = pickWithInitialState(userProfile, [
    ['isAdmin', false],
    ['client', {}],
  ])
  const { name: clientName, subscription } = pickWithInitialState(client, [
    ['name', ''],
    ['subscription', {}],
  ])
  const {
    needsUpdatedPayment,
    canceled: subscriptionCanceled,
    paymentMethods,
  } = pickWithInitialState(subscription, [
    ['needsUpdatedPayment', false],
    ['canceled', false],
    ['paymentMethods', []],
  ])

  const { chargeData, loadingUpcomingCharge } = useUpcomingCharge()

  const {
    closeModal: closePayment,
    openModal: openPaymentModal,
    visible: paymentModalVisible,
  } = useModalManagement(false)
  const {
    closeModal: closeSubscription,
    openModal: openSubscription,
    visible: subscriptionModalVisible,
  } = useModalManagement(false)

  const {
    closeModal: closeResubscribe,
    openModal: openResubscribe,
    visible: resubscribeVisible,
  } = useModalManagement(false)

  const {
    closeModal: closeTransactionHistory,
    openModal: openTransactionHistory,
    visible: transactionHistoryVisible,
  } = useModalManagement(false)

  const listItems = subscriptionCanceled
    ? [
        {
          content: (
            <List.Item
              actions={[
                <Button onClick={openPaymentModal} key={1}>
                  Update
                </Button>,
              ]}
            >
              <Skeleton
                active
                loading={gettingUserProfile}
                paragraph={null}
                title={{ width: '70%' }}
              >
                <List.Item.Meta
                  title={<Title level={4}>Payment Methods</Title>}
                  description={paymentMethods.map(method => {
                    const lastFour = get(method, 'last4')
                    return (
                      <React.Fragment key={lastFour}>
                        <Text code>
                          <Icon type="credit-card" />
                          &nbsp;
                          {capitalize(get(method, 'brand'))}
                        </Text>
                        &nbsp;
                        <Text type="secondary">ending in </Text>
                        <Text strong>{lastFour}</Text>
                      </React.Fragment>
                    )
                  })}
                />
              </Skeleton>
            </List.Item>
          ),
        },
        {
          content: (
            <List.Item>
              <Button
                type="link"
                style={{ marginLeft: 'auto' }}
                onClick={
                  subscriptionCanceled ? openResubscribe : openSubscription
                }
              >
                {subscriptionCanceled
                  ? 'Reactivate My Subscription'
                  : 'Cancel My Subscription'}
              </Button>
            </List.Item>
          ),
        },
      ]
    : [
        {
          content: (
            <List.Item
              actions={[
                <Button key={1} onClick={openPaymentModal}>
                  Update
                </Button>,
              ]}
            >
              <Skeleton
                active
                loading={gettingUserProfile}
                paragraph={null}
                title={{ width: '70%' }}
              >
                <List.Item.Meta
                  title={<Title level={4}>Payment Methods</Title>}
                  description={paymentMethods.map(method => {
                    const lastFour = get(method, 'last4')
                    return (
                      <React.Fragment key={lastFour}>
                        <Text code>
                          <Icon type="credit-card" />
                          &nbsp;
                          {capitalize(get(method, 'brand'))}
                        </Text>
                        &nbsp;
                        <Text type="secondary">ending in </Text>
                        <Text strong>{lastFour}</Text>
                      </React.Fragment>
                    )
                  })}
                />
              </Skeleton>
            </List.Item>
          ),
        },
        {
          content: (
            <List.Item>
              <Skeleton
                loading={loadingUpcomingCharge}
                paragraph={null}
                title={{ width: '70%' }}
              >
                <List.Item.Meta
                  title={<Title level={4}>Next Charge</Title>}
                  description={
                    chargeData ? (
                      <>
                        {' '}
                        <Text type="warning">${get(chargeData, 'total')}</Text>
                        &nbsp;
                        <Text type="secondary">on </Text>
                        <Text strong>{get(chargeData, 'dueDate')}</Text>
                      </>
                    ) : (
                      <Text type="secondary">No upcoming charge</Text>
                    )
                  }
                />
              </Skeleton>
            </List.Item>
          ),
        },
        {
          content: (
            <List.Item>
              <Skeleton
                loading={loadingUpcomingCharge}
                paragraph={null}
                title={{ width: '70%' }}
              >
                <List.Item.Meta
                  title={<Title level={4}>Upcoming Invoice</Title>}
                  description={
                    chargeData ? (
                      <>
                        <UpcomingInvoiceTable upcomingCharge={chargeData} />
                        <Button key={1} onClick={openTransactionHistory}>
                          Transaction History
                        </Button>
                      </>
                    ) : (
                      <Text type="secondary">No upcoming charge</Text>
                    )
                  }
                />
              </Skeleton>
            </List.Item>
          ),
        },
        {
          content: (
            <List.Item>
              <Button
                type="link"
                style={{ marginLeft: 'auto' }}
                onClick={
                  subscriptionCanceled ? openResubscribe : openSubscription
                }
              >
                {subscriptionCanceled
                  ? 'Reactivate My Subscription'
                  : 'Cancel My Subscription'}
              </Button>
            </List.Item>
          ),
        },
      ]

  return (
    <>
      <TransactionHistoryModal
        modalShown={transactionHistoryVisible}
        closeModal={closeTransactionHistory}
      />
      <UpdatePaymentModal
        afterUpdate={() => {
          closePayment()
          // getPaymentMethods()
        }}
        closable={true}
        closeModal={closePayment}
        modalShown={paymentModalVisible}
        clientName={clientName}
        title="Update Payment Method"
        message="Enter your card details"
        isAdmin={isAdmin}
        permissions={{ needsUpdatedPayment, onPaymentPlan: !!subscription }}
      />
      <SubscriptionCancellation
        modalShown={subscriptionModalVisible}
        closeModal={closeSubscription}
      />
      <Resubscribe
        closable={true}
        closeModal={closeResubscribe}
        modalShown={resubscribeVisible}
        title="Reactivate Your Subscription"
        redirectAfterPayment={false}
        message="Enter your card details"
        isAdmin={isAdmin}
        clientName={clientName}
      />
      <FunctionalityContainer title="Subscription">
        <List dataSource={listItems} renderItem={item => item.content} />
      </FunctionalityContainer>
    </>
  )
}

export default Subcription
