import findKey from 'lodash/findKey'
import head from 'lodash/head'
import last from 'lodash/last'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

import { SearchStyles } from '../../../../common/enums/SearchStyles'
import { filterNames } from '../../../../utils/constants/filterNames'
import { extractKeysFromList } from '../../../../utils/data/utils'

import { defaultOptionsLists } from './defaultOptions'
import { searchSettings } from './searchSettings'
import { specialties } from './specialties'
import { states } from './states'

const {
  companyTypeOptions,
  degreeLevelOptions,
  industryOptions,
  jobCategoryOptions,
} = defaultOptionsLists

export const defaultOptions = {
  age: {
    defaultValue: [18, 85],
    name: filterNames.age,
    options: [
      { doc_count: 2002, key: 18 },
      { doc_count: 57, key: 55 },
      { doc_count: 57, key: 85 },
    ],
  },
  companyType: {
    name: filterNames.companyType,
    options: companyTypeOptions,
  },
  // degreeLevel: {
  //   name: filterNames.degreeLevel,
  //   options: degreeLevelOptions,
  // },
  highest_edu: {
    name: filterNames.highestDegreeLevel,
    options: degreeLevelOptions,
  },
  industries: {
    name: filterNames.industries,
    options: industryOptions,
  },
  jobCategory: {
    name: filterNames.jobCategory,
    options: jobCategoryOptions,
  },
  salary: {
    defaultValue: [14000, 300000],
    name: filterNames.salary,
    options: [
      { doc_count: 123, key: 14000 },
      { doc_count: 123, key: 300000 },
    ],
  },
  specialties: {
    name: filterNames.specialties,
    options: specialties,
  },
  states: {
    name: filterNames.states,
    options: states,
  },
  veteranStatus: {
    name: filterNames.veteranStatus,
    options: ['civilians', 'veterans'],
  },
  yearsOfExperience: {
    defaultValue: [0, 5],
    name: filterNames.yearsOfExperience,
    options: [
      { doc_count: 3537, key: 0 },
      { doc_count: 3537, key: 1 },
      { doc_count: 3537, key: 3 },
      { doc_count: 3537, key: 4 },
      { doc_count: 3537, key: 5 },
      { doc_count: 3537, key: 6 },
      { doc_count: 3537, key: 7 },
      { doc_count: 3537, key: 8 },
      { doc_count: 3537, key: 9 },
      { doc_count: 3537, key: 10 },
      { doc_count: 3537, key: 11 },
      { doc_count: 3537, key: 12 },
      { doc_count: 3537, key: 13 },
      { doc_count: 3537, key: 14 },
      { doc_count: 3537, key: 15 },
      { doc_count: 3537, key: 16 },
      { doc_count: 3537, key: 17 },
      { doc_count: 3537, key: 18 },
      { doc_count: 3537, key: 19 },
      { doc_count: 3537, key: 20 },
    ],
  },
}

export const isRangeFilter = filterName => {
  const matchingKey = findKey(
    searchSettings,
    filter => filter.name === filterName,
  )
  const matchingFilter = searchSettings[matchingKey]

  return matchingFilter.searchStyle === SearchStyles.Slider
}

const getRangeFilters = searches =>
  pickBy(searches, search => search.searchStyle === SearchStyles.Slider)

const getMinAndMaxFromOptions = options => {
  const selectableOptions = extractKeysFromList(options)
  const min = head(selectableOptions, 0)
  const max = last(selectableOptions, 0)

  return { max, min }
}

export const getRangeFilterDefault = filterName => {
  const matchingKey = findKey(
    defaultOptions,
    filter => filter.name === filterName,
  )
  const matchingFilter = defaultOptions[matchingKey]
  if (matchingFilter) {
    const { max, min } = getMinAndMaxFromOptions(matchingFilter.options)

    return [min, max]
  }
}

export const getDefaultSuppliedFilters = () => {
  const rangeFilters = getRangeFilters(searchSettings)
  const rangeFilterDefaultValues = mapValues(rangeFilters, filter => {
    const matchingKey = findKey(
      defaultOptions,
      option => option.name === filter.name,
    )
    const matchingDefaults = defaultOptions[matchingKey]

    const { min, max } = getMinAndMaxFromOptions(matchingDefaults.options)
    const defaultKey = matchingDefaults.name
    const defaultsObject = { [defaultKey]: [min, max] }

    return defaultsObject
  })

  return rangeFilterDefaultValues
}

export const getDefaultOptions = searchName => {
  const matchingKey = findKey(
    defaultOptions,
    option => option.name === searchName,
  )
  if (!matchingKey) {
    return
  }
  const matchingDefaults = defaultOptions[matchingKey]

  return matchingDefaults.options
}
