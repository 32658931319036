import gql from 'graphql-tag'

export const addPaymentMutation = gql`
  mutation addPayment($stripeCardToken: String!, $couponCode: String) {
    addPayment(stripeCardToken: $stripeCardToken, couponCode: $couponCode) {
      id
      createdAt
      canceled
      needsUpdatedPayment
      updatedAt
    }
  }
`
