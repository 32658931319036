import React, { useState } from 'react'

import { Modal, Button, Input, Form, Typography, Result } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'

import RequisitionSelect from '../../../../components/forms/RequisitionSelect/RequisitionSelect'
import MoreInfo from '../../../../components/touchables/MoreInfo'
import useUserForSearchRoute from '../../../../hooks/data/loggedInUser/useUserForSearchRoute'
import { useOptionToCreateNewRequisition } from '../../../../hooks/data/requisitions/useOptionToCreateNewRequisition'
import { useCreateSavedSearch } from '../../../../hooks/data/savedSearches/useCreateSavedSearch'
import { colors } from '../../../../theme'
import errorService from '../../../../utils/analytics/error'
import { encryptStr } from '../../../../utils/crypto/cryptoUtils'
import { createQueryStringForTalentSearch } from '../../../../utils/data/utils'
import feedback from '../../../../utils/feedback'
import { getPublicSearchLink } from '../../../../utils/routing'
import { useCrowdSegmentDispatch, useCrowdSegmentState } from '../crowd-context'

const { Paragraph } = Typography

const SaveSearchForm = props => {
  const visible = get(props, 'visible')
  const toggleModal = get(props, 'toggleModal')
  const filtersToSave = get(props, 'filtersToSave')
  const noFiltersSelected = get(props, 'noFiltersSelected')
  const { searchDetailsToSave } = useCrowdSegmentState()
  const { setSearchDetailsToSave } = useCrowdSegmentDispatch()

  const [selectedRequisitionId, setSelectedRequistionId] = useState(null)

  const [searchTerm, setSearchTerm] = useState('')

  const [publicSearchLink, setPublicSearchLink] = useState(null)

  const { userProfile } = useUserForSearchRoute()

  const requisitions = get(userProfile, 'client.jobRequisitions', [])
  const { createNewRequisition } = useOptionToCreateNewRequisition({
    requisitions,
  })

  const getLinkFromData = data => {
    const searchId = get(data, 'createSavedSearch.id')
    const link = getPublicSearchLink(searchId)
    setPublicSearchLink(link)
  }

  const { createSavedSearch } = useCreateSavedSearch({
    afterCreate: data => {
      getLinkFromData(data)
    },
    selectedRequisitionId,
  })

  const getGetSavedSearchLink = () => {
    if (filtersToSave) {
      const queryStringFromFilters = createQueryStringForTalentSearch(
        filtersToSave,
      )
      const encryptedParams = encryptStr(queryStringFromFilters)
      return `${process.env.REACT_APP_FRONT_END_HOST}/app/?encryptedParams=${encryptedParams}`
    }

    return ''
  }

  const handleInputChange = e => {
    const searchProperty = get(e, 'target.id')
    const value = get(e, 'target.value')
    setSearchDetailsToSave({ [searchProperty]: value })
  }

  const onReqChange = value => {
    setSelectedRequistionId(value)
  }

  const formItemLayout = {
    labelCol: {
      md: { span: 6 },
      sm: { span: 8 },
      xs: { span: 24 },
    },
    wrapperCol: {
      md: { span: 18 },
      sm: { span: 16 },
      xs: { span: 24 },
    },
  }

  const saveSearch = async () => {
    try {
      const newRequisitionId = await createNewRequisition(selectedRequisitionId)
      setSelectedRequistionId(newRequisitionId)

      await createSavedSearch({
        variables: {
          ...searchDetailsToSave,
          requisitionId: newRequisitionId
            ? newRequisitionId
            : selectedRequisitionId,
          searchParams: filtersToSave,
        },
      })

      setSelectedRequistionId(null)
    } catch (error) {
      errorService.report(error, 'addCandidatesToJobRequisitionMutation')
      feedback.error({
        title: 'Error saving your search... Please try again',
      })
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    saveSearch()
  }

  const disabled =
    !get(searchDetailsToSave, 'name') ||
    !get(searchDetailsToSave, 'name').length ||
    !selectedRequisitionId ||
    noFiltersSelected

  const handleCancel = () => {
    setPublicSearchLink(null)
    toggleModal()
  }

  return (
    <>
      <Modal
        afterClose={() => setSelectedRequistionId(null)}
        visible={visible}
        destroyOnClose
        footer={null}
        onCancel={handleCancel}
        width={'600px'}
      >
        {!publicSearchLink ? (
          <>
            <div style={{ marginBottom: 20 }}>
              <Meta
                title={
                  <>
                    Save & Share this segment with <i>your</i> crowd!
                  </>
                }
                description="Name and file this search, and we'll give you some sweet
                shareable links!"
                style={{ marginBottom: 15 }}
              />
            </div>
            <Form onSubmit={onSubmit} layout="horizontal">
              <Form.Item name="name" label="Title" required {...formItemLayout}>
                <Input
                  onChange={handleInputChange}
                  data-testid="search-name"
                  id="name"
                  value={get(searchDetailsToSave, 'name')}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                {...formItemLayout}
              >
                <Input
                  onChange={handleInputChange}
                  data-testid="search-description"
                  id="description"
                  value={get(searchDetailsToSave, 'description')}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Crowd Name"
                {...formItemLayout}
                required
              >
                <RequisitionSelect
                  setSearchTerm={setSearchTerm}
                  onReqChange={onReqChange}
                  requisitions={requisitions}
                  searchTerm={searchTerm}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  data-testid="save-search-submit"
                  disabled={disabled}
                  style={{ marginTop: 30 }}
                  block
                  type="primary"
                  size="large"
                  onClick={onSubmit}
                >
                  Save & Share this Crowd Search
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <Result
            status="success"
            extra={[
              <div key="success">
                <h2 className="text-center">Share this Link:</h2>
                <h4
                  className="text-center"
                  style={{ marginBottom: 0, marginTop: 30 }}
                >
                  with CrowdSegment users{' '}
                  <MoreInfo text="Share your results with other CrowdSegment subscribers using this link." />
                </h4>
                <Paragraph
                  copyable
                  ellipsis
                  className="shadow"
                  key="public-search"
                  style={{
                    border: `2px solid ${colors.accent}`,
                    borderRadius: 3,
                    padding: 10,
                  }}
                >
                  {getGetSavedSearchLink()}
                </Paragraph>
                <h4
                  className="text-center"
                  style={{ marginBottom: 0, marginTop: 40 }}
                >
                  with the world{' '}
                  <MoreInfo text="Share your search results with the public using this link." />
                </h4>
                <Paragraph
                  copyable
                  ellipsis
                  className="shadow"
                  key="public-search-link"
                  style={{
                    border: `2px solid ${colors.accent}`,
                    borderRadius: 3,
                    padding: 10,
                  }}
                >
                  {publicSearchLink}
                </Paragraph>
              </div>,
            ]}
          />
        )}
      </Modal>
    </>
  )
}

export default SaveSearchForm
