import React from 'react'

import {
  Tag,
  Row,
  Col,
  Icon,
  Tooltip,
  Button,
  Typography,
  Spin,
  Drawer,
} from 'antd'
import Meta from 'antd/lib/card/Meta'
import { startCase } from 'lodash'
import get from 'lodash/get'

import useModalManagement from '../../../../hooks/common/useModalManagement/index.js'
import { useSelectedCompany } from '../../../../hooks/data/selectedCompany/useSelectedCompany.js'
import numberConverter from '../../../../utils/numberConverter/index.js'
import {
  SEARCH_ENGINES,
  createCandidateSearchQuery,
  salaryRange,
  makeIntoExternalLink,
  milestoneDesc,
} from '../../../../utils/stringManipulation'
import { capitalize } from '../../../../utils/stringManipulation'
import StatCountUp from '../../components/AuthenticatedLayout/components/ReportElements/StatCountUpTE'

import CompanyView from './CompanyView.js'
import PaymentRequestAlert from './PaymentRequestAlert.js'

const { Paragraph } = Typography

const copyToClipboard = value => {
  // Copy phone number to clipboard using dummy element, selecting it and finally removing it after copy
  const dummy = document.createElement('textarea')
  document.body.appendChild(dummy)
  dummy.value = value
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

const Milestone = props => {
  const { content, icon } = props

  return (
    <div style={{ display: 'flex', padding: '3px 0' }}>
      <div style={{ width: '6%' }}>
        <i className={icon} />
      </div>
      <div style={{ width: '94%' }}>{content}</div>
    </div>
  )
}

const CandidateView = ({
  candidateData,
  toggleCandidatesForm,
  setSelectedCandidateData,
  allowSave,
  showCompanyView,
  permissions,
  openPaymentModal,
  toggleSkill,
  selectedSkills,
}) => {
  const highestEdu = get(candidateData, 'highest_edu')
  const currentPosition = get(candidateData, 'current_position')
  const socialLink = get(candidateData, 'social_link')
  const fullSocialLink = makeIntoExternalLink(socialLink)
  const {
    openModal: openDrawer,
    closeModal: closeDrawer,
    visible,
  } = useModalManagement(false)
  const { getSelectedCompanyDetails, selectedCompany } = useSelectedCompany({
    openDrawer,
  })
  const companyInfo = get(currentPosition, 'company_info')

  const hasLevelAndDegree = milestone =>
    !!milestone.degree_level && !!milestone.major_category

  const majorString = edu_ms => {
    // startCase to handle high_school degree level
    const level = hasLevelAndDegree(edu_ms)
      ? `${startCase(edu_ms.degree_level)} in ${capitalize(
          edu_ms.major_category,
        )} `
      : ''
    const school =
      hasLevelAndDegree(edu_ms) && edu_ms.university
        ? `@${edu_ms.university}`
        : edu_ms.university
        ? edu_ms.university
        : ''

    return level || school ? `${level}${school}` : null
  }

  const getValidEduMileStones = milestones => {
    return milestones.filter(hasLevelAndDegree)
  }

  const getValidCareerMileStones = milestones => {
    return milestones.filter(
      milestone => !!milestone.company || !!milestone.title,
    )
  }

  const validPrevEduMilestones = getValidEduMileStones(
    get(candidateData, 'previous_edu_milestones', []),
    [],
  )

  const validPrevPosMilestones = getValidCareerMileStones(
    get(candidateData, 'previous_position_milestones', []),
    [],
  )

  const locationString = candidateData => {
    const city = get(candidateData, 'city', '')
    const state = get(candidateData, 'state', '')

    return city && state ? `${city}, ${state}` : state ? state : null
  }

  const handleSaveCandidatesClick = () => {
    setSelectedCandidateData([{ id: get(candidateData, 'id') }])
    toggleCandidatesForm()
  }

  return (
    <>
      {showCompanyView && (
        <Drawer
          title="Candidate View"
          closable={true}
          destroyOnClose
          onClose={closeDrawer}
          visible={visible}
          style={{ overflow: 'auto' }}
          width={525}
          zIndex={1003}
        >
          <CompanyView
            companyDetails={selectedCompany}
            toggleSkill={toggleSkill}
            permissions={permissions}
            openPaymentModal={openPaymentModal}
            selectedSkills={selectedSkills}
            width={525}
          />
          <PaymentRequestAlert
            permissions={permissions}
            openPaymentModal={openPaymentModal}
            styles={{ marginBottom: 20 }}
          />
        </Drawer>
      )}
      {candidateData ? (
        <>
          <div style={{ float: 'right', fontSize: 18 }}>
            <Icon
              type="linkedin"
              theme="filled"
              style={{ color: '#0076b5', fontSize: 18, marginRight: 3 }}
              onClick={() => window.open(fullSocialLink, '_blank')}
            />
            <Icon
              type="google"
              theme="outlined"
              style={{ fontSize: 18, marginRight: 3 }}
              onClick={() =>
                window.open(
                  createCandidateSearchQuery(
                    SEARCH_ENGINES.google,
                    candidateData,
                  ),
                  '_blank',
                )
              }
            />
            <Icon
              type="search"
              theme="outlined"
              style={{ color: '#0076b5', fontSize: 18, marginRight: 3 }}
              onClick={() =>
                window.open(
                  createCandidateSearchQuery(
                    SEARCH_ENGINES.bing,
                    candidateData,
                  ),
                  '_blank',
                )
              }
            />
            {get(candidateData, 'telephone_number') ? (
              <Tooltip title="Copied to clipboard" trigger="click">
                <Icon
                  type="phone"
                  theme="twoTone"
                  style={{ fontSize: 18 }}
                  data-id={get(candidateData, 'telephone_number')}
                  onClick={async e =>
                    copyToClipboard(e.currentTarget.dataset.id)
                  }
                />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          <Meta
            title={capitalize(get(candidateData, 'full_name', ''))}
            description={
              <div style={{ paddingLeft: 15 }}>
                <div>
                  <Milestone
                    icon="fal fa-briefcase b-icon"
                    content={milestoneDesc(
                      get(currentPosition, 'title'),
                      get(currentPosition, 'company'),
                      get(currentPosition, 'start_year'),
                      get(currentPosition, 'end_year'),
                    )}
                  />
                </div>
                {locationString(candidateData) && (
                  <Milestone
                    icon="fal fa-map-marker-alt b-icon"
                    content={capitalize(locationString(candidateData))}
                  />
                )}
                {majorString(highestEdu) && (
                  <Milestone
                    icon="fal fa-graduation-cap b-icon"
                    content={majorString(highestEdu)}
                  />
                )}
              </div>
            }
          />
          <Row
            style={{
              borderRadius: 3,
              margin: '0 0 10px 0',
              paddingBottom: 15,
              paddingTop: 15,
            }}
          >
            {get(currentPosition, 'salary') && (
              <Col span={12} className="text-center">
                <StatCountUp
                  textString={salaryRange(get(currentPosition, 'salary'))}
                  fontSize={30}
                  style={{ color: 'green' }}
                  title="Est. Salary"
                />
              </Col>
            )}
            {currentPosition.tenure && currentPosition.tenure < 15 && (
              <Col span={12} className="text-center">
                <StatCountUp
                  data={
                    currentPosition.tenure && currentPosition.tenure < 15
                      ? currentPosition.tenure
                      : '-'
                  }
                  suffix={
                    currentPosition.tenure
                      ? currentPosition.tenure === 1
                        ? ' year'
                        : ' years'
                      : ''
                  }
                  fontSize={30}
                  title="Current Tenure"
                />
              </Col>
            )}
          </Row>
          {get(candidateData, 'skills', []).length > 0 && (
            <div>
              <Meta title="Skills" />
              <Paragraph ellipsis={{ expandable: true, rows: 2 }}>
                {get(candidateData, 'skills', []).map((skill, index) => (
                  <Tag
                    key={`${skill}${index}`}
                    style={{ marginBottom: 5, marginRight: 5 }}
                    color="orange"
                  >
                    {skill}
                  </Tag>
                ))}
              </Paragraph>
            </div>
          )}
          {get(currentPosition, 'company_info') && (
            <Meta
              style={{ marginTop: 15 }}
              title={
                showCompanyView ? (
                  <div
                    className="person-link"
                    onClick={() =>
                      getSelectedCompanyDetails(
                        capitalize(get(currentPosition, 'company')),
                      )
                    }
                  >
                    {capitalize(get(currentPosition, 'company'))}
                  </div>
                ) : (
                  `${capitalize(get(currentPosition, 'company'))}`
                )
              }
              description={
                <div style={{ paddingLeft: 15 }}>
                  {!!get(companyInfo, 'size') && (
                    <div>
                      Size:
                      {` ${numberConverter(
                        get(companyInfo, 'size'),
                      )} employees`}
                    </div>
                  )}
                  {!!get(companyInfo, 'avg_tenure') && (
                    <div>
                      Avg. Tenure: {get(companyInfo, 'avg_tenure')} years
                    </div>
                  )}
                  {!!get(companyInfo, 'website') && (
                    <div>
                      Website:{' '}
                      <a
                        style={{ textDecoration: 'none' }}
                        className="person-link"
                        href={`https://www.${get(companyInfo, 'website')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(companyInfo, 'website')}
                      </a>
                    </div>
                  )}
                  {!!get(companyInfo, 'industry') && (
                    <Paragraph ellipsis={{ expandable: true, rows: 2 }}>
                      Industry:{' '}
                      <Tag color="blue">{get(companyInfo, 'industry')}</Tag>
                    </Paragraph>
                  )}
                </div>
              }
            />
          )}
          {validPrevEduMilestones.length > 0 && (
            <div>
              <Meta
                title="Previous Education"
                style={{ marginTop: 15 }}
                description={
                  <div style={{ paddingLeft: 15 }}>
                    {validPrevEduMilestones.map((edu, index) => {
                      if (hasLevelAndDegree(edu)) {
                        return (
                          <Milestone
                            key={index}
                            icon="fal fa-graduation-cap b-icon"
                            content={capitalize(majorString(edu))}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                }
              />
            </div>
          )}
          {validPrevPosMilestones.length > 0 && (
            <div>
              <Meta
                title="Previous Employment"
                style={{ marginTop: 15 }}
                description={
                  <div style={{ paddingLeft: 15 }}>
                    {validPrevPosMilestones.map((milestone, index) => (
                      <Milestone
                        key={index}
                        icon="fal fa-briefcase b-icon"
                        content={milestoneDesc(
                          milestone.title,
                          milestone.company,
                          milestone.year_start,
                          milestone.year_stop,
                        )}
                      />
                    ))}
                  </div>
                }
              />
            </div>
          )}
          {allowSave && (
            <Row style={{ bottom: 0, position: 'fixed', right: '32px' }}>
              <Tooltip title="Save Candidate">
                <Button
                  type="primary"
                  icon="save"
                  style={{ float: 'right' }}
                  onClick={handleSaveCandidatesClick}
                >
                  Save
                </Button>
              </Tooltip>
            </Row>
          )}
        </>
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Spin />
        </div>
      )}
    </>
  )
}

export default CandidateView
