import React, { useEffect, useState } from 'react'

import { Card, Spin } from 'antd'

import uberFacts from '../../../assets/uberFacts'

const SpinnerWithUberFacts = ({ children, loading, minLoadingSeconds = 3 }) => {
  // Moved const vars here so we get a different uber fact each time
  const factIndex = Math.floor(Math.random() * uberFacts.length)

  const uberFactDisplay = (
    <Card style={{ fontSize: 20 }}>
      "{uberFacts[factIndex].quote}"
      <div style={{ color: '#9a9a9a', fontSize: 16, marginTop: 15 }}>
        <b>{uberFacts[factIndex].author}</b> -
        <i> {uberFacts[factIndex].titleAndCompany}</i>
      </div>
    </Card>
  )
  const [minLoading, setMinLoading] = useState(loading)

  useEffect(() => {
    setTimeout(() => setMinLoading(false), minLoadingSeconds * 1000)
  })

  return (
    <Spin spinning={loading || minLoading} tip={uberFactDisplay}>
      {children}
    </Spin>
  )
}

export default SpinnerWithUberFacts
