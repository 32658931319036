import { useState, useEffect, useCallback } from 'react'

import gql from 'graphql-tag'
import get from 'lodash/get'

import { apolloClient } from '../../../../apolloClient/index'
import errorService from '../../../../utils/analytics/error'

export const pullAmountPastDueForCustomerQuery = gql`
  query PullAmountPastDueForCustomer {
    pullAmountPastDueForCustomer {
      chargeItems {
        amount
        description
        qty
        timePeriod
        unitPrice
      }
      discount {
        amount
        couponCode
        discountDisplay
      }
      dueDate
      subtotal
      total
    }
  }
`

const useAmountPastDue = ({ onPaymentPlan, needsUpdatedPayment }) => {
  const [chargesPastDue, setChargesPastDue] = useState(null)
  const [loading, setLoading] = useState(false)

  const getAmountPastDue = useCallback(async () => {
    setLoading(true)
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        query: pullAmountPastDueForCustomerQuery,
      })
      setLoading(false)
      const chargesPastDueRetunred = get(
        response,
        'data.pullAmountPastDueForCustomer',
      )
      setChargesPastDue(chargesPastDueRetunred)
    } catch (error) {
      setLoading(false)
      errorService.report(error, 'Get pat amount due for customer')
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    async function fetch() {
      if (isSubscribed) {
        if (onPaymentPlan && needsUpdatedPayment) await getAmountPastDue()
      }
    }
    fetch()
    return () => (isSubscribed = false)
  }, [getAmountPastDue, onPaymentPlan, needsUpdatedPayment])

  return { chargesPastDue, getAmountPastDue, loading }
}

export default useAmountPastDue
