import React from 'react'

import { Col, Row } from 'antd'

import AuthenticatedLayout from '../components/AuthenticatedLayout'

import ManageMembers from './components/ManageTeamNavigator/components/ManageMembers/index'

const ManageTeam = () => {
  return (
    <AuthenticatedLayout title="Admin" gutters={true}>
      <div className="max-1200">
        <Row type="flex" align="top" gutter={16}>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <ManageMembers />
          </Col>
        </Row>
      </div>
    </AuthenticatedLayout>
  )
}

export default ManageTeam
