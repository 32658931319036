import React from 'react'

import { Modal } from 'antd'
import get from 'lodash/get'

import UpdatePaymentForm from '../../../../../components/payment/UpdatePaymentForm'
import { theme } from '../../../../../theme'

const accentColor = get(theme, 'colors.accent')

const UpdatePaymentModal = props => {
  const modalShown = get(props, 'modalShown')
  const permissions = get(props, 'permissions')
  const message = get(props, 'message')
  const closeModal = get(props, 'closeModal')
  const title = get(props, 'title')
  const closable = get(props, 'closable')
  const afterUpdate = get(props, 'afterUpdate')
  const clientName = get(props, 'clientName')
  const isAdmin = get(props, 'isAdmin')

  return (
    <Modal
      visible={modalShown}
      closable={false}
      footer={null}
      bodyStyle={{
        borderTop: `4px solid ${accentColor}`,
      }}
      onCancel={closable ? closeModal : null}
      destroyOnClose
      width={600}
    >
      <UpdatePaymentForm
        message={message}
        permissions={permissions}
        title={title}
        afterUpdate={afterUpdate}
        clientName={clientName}
        isAdmin={isAdmin}
      />
    </Modal>
  )
}

export default UpdatePaymentModal
