import React from 'react'

import get from 'lodash/get'

import { capitalize } from '../../utils/stringManipulation'
import CompanyLogo from '../media/CompanyLogo'

import PersonCompanyDisplay from './components/PersonCompanyDisplay'
import NotFoundColumn from './NotFoundColumn'

const PersonEmployerColumn = props => {
  const currentPosition = get(props, 'currentPosition')
  const tenure = get(currentPosition, 'tenure')
  const title = get(currentPosition, 'title')
  const yearsDisplay = tenure === 1 ? 'year' : 'years'
  const company = get(currentPosition, 'company')
  const id = get(currentPosition, 'company_id')
    ? get(currentPosition, 'company_id')
    : get(currentPosition, 'company_info.company_id')

  return !!title || !!company ? (
    <>
      <div>{title ? capitalize(title) : ''}</div>
      <CompanyLogo id={id} size={50} />
      <PersonCompanyDisplay title={title} company={company} />
      <div className="person-tenure">
        {tenure ? `${tenure} ${yearsDisplay}` : ''}
      </div>
    </>
  ) : (
    <NotFoundColumn centered={false} />
  )
}

export default PersonEmployerColumn
