import { useMutation } from '@apollo/client'
import get from 'lodash/get'

import { createUserInvitationsMutation } from '../../../apolloClient/operations/admin/createUserInvitationsMutation'
import { getMembersQuery } from '../../../apolloClient/operations/admin/getMembersQuery'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useCreateUserInvitations = options => {
  const afterUpdate = get(options, 'afterUpdate')
  const [inviteUsers] = useMutation(createUserInvitationsMutation, {
    onCompleted: () => {
      feedback.success({
        content: 'Invitation created successfully',
      })
      if (afterUpdate) {
        afterUpdate()
      }
    },
    onError: error => {
      errorService.report(error, 'sendInvitation')
      feedback.error({
        content: error.message,
        title: 'Error sending invitation',
      })
    },
    update: (cache, { data }) => {
      const newInvitations = get(data, 'createUserInvitations')
      const userData = cache.readQuery({
        query: getMembersQuery,
      })
      const newInvitationsRefs = newInvitations.map(invitation => {
        return {
          __ref: cache.identify(invitation),
        }
      })
      const client = get(userData, 'getUserProfile.client')
      cache.modify({
        fields: {
          members(existingMembers = {}) {
            const updatedInvitations = [
              ...existingMembers.invitations,
              ...newInvitationsRefs,
            ]
            return {
              ...existingMembers,
              invitations: updatedInvitations,
            }
          },
        },
        id: cache.identify(client),
      })
    },
  })

  return { inviteUsers }
}
