import React, { useState } from 'react'

import { Form, Button, Icon } from 'antd'
import { Auth } from 'aws-amplify'
import get from 'lodash/get'
import keys from 'lodash/keys'
import mapValues from 'lodash/mapValues'
import trim from 'lodash/trim'

import { UNAUTHENTICATED_ROUTES } from '../../../utils/routing/routes'
import { useCrowdSegmentDispatch } from '../../Authenticated/CrowdSegment/crowd-context'
import NavLinks from '../components/NavLinks'
import UnauthenticatedCard from '../components/UnauthenticatedCard'

import SignupInputs from './SignupInputs'

export const california = 'California'

export const signupFormFieldTitles = {
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  phone: 'phone',
}
const fields = keys(signupFormFieldTitles)

const SignupForm = props => {
  const [fetching, setFetching] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const { getFieldDecorator } = props.form

  const { setUnauthenticatedUser } = useCrowdSegmentDispatch()

  const trimInputs = values => {
    return mapValues(values, (val, key) => {
      if (key === signupFormFieldTitles.password) {
        return val
      } else return trim(val)
    })
  }

  const trimValues = () => {
    const { getFieldsValue } = props.form

    let formValues = trimInputs(getFieldsValue(fields))
    return formValues
  }

  const fieldValid = fieldName => {
    const { getFieldError, getFieldValue } = props.form
    const fieldValue = getFieldValue(fieldName)
    const valid =
      fieldName === signupFormFieldTitles.phone
        ? typeof getFieldError(fieldName) === 'undefined'
        : !!fieldValue &&
          fieldValue.length > 0 &&
          typeof getFieldError(fieldName) === 'undefined'
    return valid
  }

  const fieldsValid = () => {
    const { isFieldsTouched } = props.form
    const invalidNames = fields.filter(name => !fieldValid(name))
    return (
      typeof isFieldsTouched(fields) !== 'undefined' && !invalidNames.length
    )
  }

  const validateEmailForSignup = async () => {
    const data = trimValues()
    const { email, password, firstName, lastName } = data
    setSigningUp(true)
    try {
      setUnauthenticatedUser({ email, firstName, lastName })
      await Auth.signUp({
        attributes: {
          email,
          family_name: lastName,
          given_name: firstName,
        },
        password,
        username: email,
      })
      setSubmitted(true)
      setSigningUp(false)
    } catch (error) {
      console.log(error)
      if (error.code === 'UsernameExistsException') {
        setUnauthenticatedUser({ email, firstName, lastName })
      }
      setSigningUp(false)
    }
  }

  return (
    <UnauthenticatedCard>
      <h2 style={{ fontWeight: 'bold', textAlign: 'center' }}>
        Start your free trial
      </h2>
      <h5 style={{ marginBottom: 40, textAlign: 'center' }}>
        No credit card required.
      </h5>
      <Form hideRequiredMark className="signup-form">
        <SignupInputs
          getFieldDecorator={getFieldDecorator}
          validateFields={get(props, 'form.validateFields')}
          setFetching={setFetching}
          fetching={fetching}
        />
      </Form>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 16,
          padding: '0 8px',
        }}
      >
        <NavLinks
          links={[
            {
              label: 'Login',
              route: UNAUTHENTICATED_ROUTES.AUTHENTICATE,
            },
          ]}
        />
        <div>
          <Button
            htmlType="submit"
            style={{ marginLeft: 8 }}
            type="primary"
            onClick={validateEmailForSignup}
            disabled={!fieldsValid() || submitted}
            data-testid="signup-button"
            loading={signingUp}
          >
            Continue
            <Icon type="double-right" />
          </Button>
        </div>
      </div>
    </UnauthenticatedCard>
  )
}

const wrappedForm = Form.create({ name: 'SignupForm' })(SignupForm)
export default wrappedForm
