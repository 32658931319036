import React, { useState } from 'react'

import { Icon, Menu, Card, Divider } from 'antd'
import concat from 'lodash/concat'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'

import { useUserForMyAccount } from '../../../../../hooks/data/loggedInUser/useUserForMyAccount'
import { logout } from '../../../../../utils/authentication'
import { MY_ACCOUNT_ROUTES } from '../../../../../utils/routing/routes'

const basicOptions = [
  {
    icon: 'user',
    route: MY_ACCOUNT_ROUTES.BASIC_INFO,
    title: 'Basic Info',
  },
  {
    icon: 'lock',
    route: MY_ACCOUNT_ROUTES.CHANGE_PASSWORD,
    title: 'Change Password',
  },
]

const adminOptions = [
  {
    icon: 'calendar',
    route: MY_ACCOUNT_ROUTES.SUBSCRIPTION,
    title: 'Subscription',
  },
  {
    icon: 'credit-card',
    route: MY_ACCOUNT_ROUTES.BUY_CREDITS,
    title: 'Download Credits',
  },
]

const customSubdomainOptions = process.env.REACT_APP_CUSTOM_SUBDOMAIN
  ? [
      {
        icon: 'credit-card',
        route: MY_ACCOUNT_ROUTES.CUSTOM_BRANDING,
        title: 'Custom Branding',
      },
    ]
  : []

const ComponentMenu = props => {
  const [activeKey, setActiveKey] = useState(props.history.location.pathname)
  const { userProfile } = useUserForMyAccount()
  const isAdmin = get(userProfile, 'isAdmin')
  const subscription = get(userProfile, 'client.subscription')

  const menuOptions =
    isAdmin && !!subscription
      ? concat(basicOptions, adminOptions, customSubdomainOptions)
      : basicOptions

  return (
    <Card bordered bodyStyle={{ padding: 8 }}>
      <Menu
        style={{ borderRight: 'none' }}
        selectedKeys={[activeKey]}
        onSelect={({ key }) => {
          setActiveKey(key)
          props.history.push(key)
        }}
      >
        {menuOptions.map(({ icon, title, route }) => (
          <Menu.Item key={route}>
            <Icon type={icon} />
            {title}
          </Menu.Item>
        ))}
        <Divider />
        <Menu.Item key="logout" onClick={logout}>
          <Icon type="logout" />
          Log Out
        </Menu.Item>
      </Menu>
    </Card>
  )
}

ComponentMenu.propTypes = {}

export default withRouter(ComponentMenu)
