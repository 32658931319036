import { useMutation } from '@apollo/client'

import { addPaymentMutation } from '../../../apolloClient/operations/payments/addPaymentMutation'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useAddPayment = ({ onCompleted, client }) => {
  const [addPayment, { loading: addingPayment }] = useMutation(
    addPaymentMutation,
    {
      onCompleted,
      onError: error => {
        errorService.report(error, 'Payment Modal - Update Payment Method')
        feedback.error({
          title:
            'There was a problem updating your payment method... Please try again',
        })
      },
      update: async (cache, { data: { addPayment } }) => {
        cache.modify({
          fields: {
            subscription() {
              const ref = {
                __ref: cache.identify(addPayment),
              }
              return ref
            },
          },
          id: cache.identify(client),
        })
      },
    },
  )

  return { addPayment, addingPayment }
}
