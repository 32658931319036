const BUILDING_ICON_URL =
  'https://steppingblocks-shared-assets.s3.amazonaws.com/building-light.png'

const REPORTS_PERMISSIONS_LABELS = {
  DOWNLOAD: 'Read & Download',
  DOWNLOAD_FULL: 'Read & Full Download',
  DOWNLOAD_LIMITED: 'Read & Limited Download',
  READ: 'Read',
  VIEWCONTACT: 'Read & View Contact'
}

const REPORT_IMAGES = [
  require('../../assets/undraw/reports_001.svg'),
  require('../../assets/undraw/reports_002.svg'),
  require('../../assets/undraw/reports_003.svg')
]

const REPORT_DESCRIPTIONS = {
  'Graduate Outcomes':
    'Find actionable insights on alumni locations, employment, salaries, and skills',
  'Graduate Explorer':
    'Find specific alumni using demographic, education, and career related filters.',
  'Basic Graduate Outcomes':
    'Learn about employment and salary trends for your graduates.',
  'Search Match': 'Find Alumni records matching Steppingblocks records',
  'Graduation To Employment':
    "Explore your graduate's first destination of employment (Beta)",
  'Graduate Outcomes Campus': 'High-Level insights for University Leadership',
  'College Outcomes': 'High-Level insights for University Leadership'
}

const REPORT_USEDBYS = {
  'Graduate Outcomes':
    'Provosts, Deans, Career Services, Employer Relations, Institutional Research',
  'Graduate Explorer':
    'Advancement, Alumni Relations, Employer Relations, Institutional Research'
}

const REPORT_TYPES = {
  react: 'react',
  tableau: 'tableau'
}

/**
 * Gets the image source based on modulo of the index
 * @param {Array} images
 * @param {Int} index
 */
const GET_RANDOM_REPORT_IMAGE = (images = [], index) => {
  const mod = index % images.length
  return images[mod]
}

const DOCUMENT_IMAGES = [
  require('../../assets/undraw/documents_001.svg'),
  require('../../assets/undraw/documents_002.svg'),
  require('../../assets/undraw/documents_003.svg')
]

const TALENT_SEARCH_FILTER_NAMES = {
  education: 'education'
}

const RANGE_FILTERS = [
  'age',
  'incomes',
  'yearsExperience',
  'estSalary',
  'years_of_experience',
  'salary'
]

const REQUISITION_DETAIL_MODES = {
  edit: 'edit',
  create: 'create'
}

const TE_CANDIDATE_SORT_OPTIONS = [
  {
    display: 'Best Overall',
    value: 'sort'
  },
  {
    display: 'Salary Desc',
    value: 'salary_desc'
  },
  {
    display: 'Salary Asc',
    value: 'salary_asc'
  },
  {
    display: 'Company Size Desc',
    value: 'co_size_desc'
  },
  {
    display: 'Company Size Asc',
    value: 'co_size_asc'
  },
  {
    display: 'Tenure Desc',
    value: 'tenure_desc'
  },
  {
    display: 'Tenure Asc',
    value: 'tenure_asc'
  }
]

const TUTORIALS = {
  graduateExplorer: {
    title: 'Graduate Explorer',
    description:
      'Find specific alumni using demographic, education, and career related filters.',
    videoSrc: 'https://www.youtube.com/embed/GamhGz7tOew'
  },
  graduateOutcomes: {
    title: 'Graduate Outcomes',
    description:
      'Find actionable insights on alumni locations, employment, salaries, and skills.',
    videoSrc: 'https://www.youtube.com/embed/q8sk0BubHgQ'
  }
}

const INCLUDE_UNIVERSITY_EMPLOYEES = 'include_university_employees'

const IMAGES = {
  teamsThumb: 'TeamsThumb.png',
  filterThumb: 'data.steppingblocks/FilterThumb.png',
  blankMap: 'Blank_US_Map.png',
  building: 'building-light.png',
  nsfLogo: 'NSF_Logo.png'
}

module.exports = {
  BUILDING_ICON_URL,
  DOCUMENT_IMAGES,
  GET_RANDOM_REPORT_IMAGE,
  IMAGES,
  INCLUDE_UNIVERSITY_EMPLOYEES,
  RANGE_FILTERS,
  REPORT_DESCRIPTIONS,
  REPORT_IMAGES,
  REPORTS_PERMISSIONS_LABELS,
  REPORT_TYPES,
  REPORT_USEDBYS,
  REQUISITION_DETAIL_MODES,
  TALENT_SEARCH_FILTER_NAMES,
  TE_CANDIDATE_SORT_OPTIONS,
  TUTORIALS
}
