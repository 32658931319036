import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './vendorStyles'
import './index.less'
import './dark.less'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// register firebase service worker for in-app and browser based push notifications
serviceWorker.registerCustomSW('./firebase-messaging-sw.js', 'firebase')
