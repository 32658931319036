import { useMutation } from '@apollo/client'
import get from 'lodash/get'

import { createSavedSearchMutation } from '../../../apolloClient/operations/savedSearch/createSavedSearchMutation'
import { getUserDetailsForSearchQuery } from '../../../apolloClient/operations/userData/getUserDetailsForSearchQuery'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useCreateSavedSearch = ({
  afterCreate,
  selectedRequisitionId,
}) => {
  const [createSavedSearch] = useMutation(createSavedSearchMutation, {
    onCompleted: data => {
      feedback.success({ content: 'Search saved Successfully' })
      if (afterCreate) {
        afterCreate(data)
      }
    },
    onError: err => {
      errorService.report(err, 'createSavedSearchMutation')
      feedback.error({
        title: 'Error saving your search... Please try again',
      })
    },
    update(cache, { data: { createSavedSearch: newSavedSearch } }) {
      const userData = cache.readQuery({
        query: getUserDetailsForSearchQuery,
      })
      const jobRequisitions = get(
        userData,
        'getUserProfile.client.jobRequisitions',
      )
      const selectedJobRequisition = jobRequisitions.find(
        req => req.id === selectedRequisitionId,
      )
      cache.modify({
        fields: {
          savedSearches(existingSavedSearchesRef = []) {
            const newSavedSearchRef = {
              __ref: cache.identify(newSavedSearch),
            }
            return [...existingSavedSearchesRef, newSavedSearchRef]
          },
        },
        id: cache.identify(selectedJobRequisition),
      })
    },
  })

  return {
    createSavedSearch,
  }
}
