import React from 'react'

import { Card, Col, Row, Icon, Button } from 'antd'
import get from 'lodash/get'
import take from 'lodash/take'
import { Link, useLocation } from 'react-router-dom'

import { IMAGES } from '../../../utils/applicationConstants'
import { AUTHENTICATED_ROUTES, isPublic } from '../../../utils/routing/routes'

import CrowdPleasers from './components/components/CrowdPleasers'
import { useCrowdSegmentDispatch } from './crowd-context'

const WelcomeCard = props => {
  const { setPublicSearchCTAVisible } = useCrowdSegmentDispatch()
  const { requisitions = [] } = props

  const location = useLocation()
  const isPublicRoute = isPublic(get(location, 'pathname'))
  // similar stories from next-cs
  const tutorials = [
    {
      author: {
        name: 'Carlo Martinez',
      },
      cover: {
        src:
          'https://resources.steppingblocks.com/hubfs/crowdsegment-people-search-map.png',
        srcSet:
          'https://resources.steppingblocks.com/hubfs/crowdsegment-people-search-map.png 2x',
      },
      date: '04 Nov',
      link: 'https://www.crowdsegment.com/how-to/keyword-filters',
      subtitle:
        'Learn how to find the exact right matches to meet your search needs using CrowdSegment filters.',
      title: 'How to use keyword filters with CrowdSegment',
    },
    {
      author: {
        name: 'Carlo Martinez',
      },

      cover: {
        src:
          'https://resources.steppingblocks.com/hubfs/crowdsegment-lead-list.png',
        srcSet:
          'https://resources.steppingblocks.com/hubfs/crowdsegment-lead-list.png 2x',
      },
      date: '04 Dec',
      link: 'https://www.crowdsegment.com/how-to/population-insights',
      subtitle:
        'Leverage the power of our insights while filtering to find the perfect persona for your marketing leads',
      title: 'How to use Population Insights to Find Talent',
    },
    {
      author: {
        name: 'Carlo Martinez',
      },
      cover: {
        src:
          'https://resources.steppingblocks.com/hubfs/crowdsegment-research-map.png',
        srcSet:
          'https://resources.steppingblocks.com/hubfs/crowdsegment-research-map.png 2x',
      },
      date: '04 Jan',
      link: 'https://www.crowdsegment.com/how-to/market-insights',
      subtitle:
        'Learn to analyze people and company attributes to find the ideal population for your market research.',
      title: 'How to use Market Insights to Expand your Footprint',
    },
  ]

  const handleNewCrowdClick = e => {
    if (isPublicRoute) {
      e.preventDefault()
      setPublicSearchCTAVisible(true)
    }
  }

  return (
    <>
      <div
        style={{
          margin: '0 auto 0',
          maxWidth: 1200,
        }}
      >
        <Row style={{ margin: '0 -8px 30px' }}>
          <CrowdPleasers isPublicRoute={isPublicRoute} />
          <Col md={8}>
            <Card style={{ borderRadius: 6, marginBottom: 30 }}>
              <h4>Saved Crowds</h4>
              <Row style={{ marginBottom: 0 }}>
                {requisitions.length ? (
                  take(requisitions, 3).map(project => {
                    const { savedSearches = [], candidateData = [] } = project
                    return (
                      <div key={project.id} style={{ marginBottom: 10 }}>
                        <div className="bordered">
                          <Link to={AUTHENTICATED_ROUTES.REQUISITIONS}>
                            <div style={{ fontWeight: 600 }}>
                              {project.name}
                            </div>
                          </Link>
                          <div>
                            {savedSearches.length} Saved Searches |{' '}
                            {candidateData.length} Saved Prospects
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Link
                      onClick={handleNewCrowdClick}
                      to={AUTHENTICATED_ROUTES.REQUISITIONS}
                    >
                      <Button
                        type="primary"
                        ghost
                        style={{ marginBottom: 10, marginTop: -10 }}
                      >
                        Create a New Crowd
                        <Icon type="arrow-right" />
                      </Button>
                    </Link>
                  </div>
                )}
              </Row>
              {requisitions.length > 0 && (
                <Row
                  style={{
                    marginBottom: 0,
                    textAlign: 'right',
                  }}
                >
                  <Col style={{ textAlign: 'right' }}>
                    <Link to={AUTHENTICATED_ROUTES.REQUISITIONS}>See All</Link>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
          <Col md={16}>
            <Card style={{ borderRadius: 6 }}>
              <h4>Learn CrowdSegment</h4>
              {tutorials.map(tut => (
                <Row key={tut.title}>
                  <Col md={6}>
                    <img
                      alt="NSF Partner Logo"
                      src={tut.cover.src}
                      style={{ width: '80%' }}
                      className="shadow"
                    />
                  </Col>
                  <Col md={18} style={{ paddingTop: 10 }}>
                    <a
                      href={tut.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginBottom: 5,
                        }}
                      >
                        {tut.title}
                      </div>
                    </a>
                    <div className="text-muted" style={{ fontSize: 18 }}>
                      {tut.subtitle}
                    </div>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Row>
        <div className="text-center">
          <div className="text-muted" style={{ marginTop: 60 }}>
            Follow the Crowd
          </div>
          <div>
            <a
              href="https://www.facebook.com/CrowdSegment/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="facebook" className="social-link-muted" />
            </a>
            <a
              href="https://www.linkedin.com/company/crowdsegment/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="linkedin" className="social-link-muted" />
            </a>
            <a
              href="https://twitter.com/crowdsegment"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="twitter" className="social-link-muted" />
            </a>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <img
            alt="nsf logo"
            className="welcome-nsf-logo"
            style={{ marginTop: 60 }}
            src={`${process.env.REACT_APP_PUBLIC_BUCKET}${IMAGES.nsfLogo}`}
          />
          <div className="text-muted" style={{ marginTop: 10 }}>
            Proud Partners of the National Science Foundation
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeCard
