import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import _ from 'lodash'

import {
  getAuthorizationToken,
  isPublicRoute,
  logout,
} from '../utils/authentication'
import feedback from '../utils/feedback'

/**
 * Create cache instance
 */
export const cache = new InMemoryCache()

/**
 * Creates a new HTTP connection
 * @TODO Swap this for your GraphQL URI (use an environment variable)
 */
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
})

/**
 * Adds authorization header to HTTP link
 * @TODO Get the authorization token
 */
const authorizationLink = setContext(async () => ({
  headers: {
    'X-Application-Name': 'CROWD_SEGMENT',
    authorization: await getAuthorizationToken(),
  },
}))

const isJWTInvalidatedError = errors => {
  return _.find(errors, {
    message: 'Context creation failed: Invalidated Token!',
  })
}

const isJWTExpiredError = errors => {
  return _.find(errors, { message: 'Context creation failed: jwt expired' })
}

const isForbiddenError = errors => {
  return _.find(errors, { message: "You can't access that!" })
}

/**
 * Handles an error to an external service
 */

const handleErrorLink = onError(
  ({ forward, graphQLErrors, networkError, operation }) => {
    if (
      (isJWTExpiredError(graphQLErrors) ||
        isForbiddenError(graphQLErrors) ||
        isJWTInvalidatedError(graphQLErrors)) &&
      !isPublicRoute(window.location.pathname)
    ) {
      feedback.error({
        content: 'Please login again!',
        title: 'Session Expired',
      })
      setTimeout(() => logout(), 200)
    } else if (
      networkError &&
      // @ts-ignore
      (networkError.statusCode === 401 || networkError.statusCode === 403)
    ) {
      feedback.error({
        content: 'Please login again!',
        title: 'Session Expired',
      })
      logout()
      // refresh the page to see new changes
      window.location.reload()
    } else {
      return forward(operation)
    }
  },
)

/**
 * Apollo client instance
 */
export const apolloClient = new ApolloClient({
  cache,
  link: from([authorizationLink, handleErrorLink, httpLink]),
})
