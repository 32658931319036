import React from 'react'

import { Typography } from 'antd'

import { formatMoney } from '../../../../../../../../utils/stringManipulation'

const { Text } = Typography

const InvoiceFooterItem = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 25px 10px 0',
        width: 200,
      }}
    >
      <Text strong style={{ paddingRight: 20 }}>
        {label}
      </Text>
      <Text strong>{formatMoney(value)}</Text>
    </div>
  )
}

export default InvoiceFooterItem
