import { useLazyQuery } from '@apollo/client'
import get from 'lodash/get'

import { getSignedUrlForDownloadFileQuery } from '../../../apolloClient/operations/downloads/getSignedUrlForDownloadFileQuery'
import feedback from '../../../utils/feedback'

export const useGetSignedUrlForDownloadFile = () => {
  const [getSignedUrlAndDownloadFile, { loading }] = useLazyQuery(
    getSignedUrlForDownloadFileQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: response => {
        const signedUrl = get(response, 'getSignedUrlForDownloadFile')
        console.log(response)
        window.open(signedUrl)
      },
      onError: error => {
        feedback.error({ title: error.message })
      },
    },
  )

  return {
    getSignedUrlAndDownloadFile,
    gettingSignedUrl: loading,
  }
}
