export const filterNames = Object.freeze({
  age: 'age',
  candidatesKeywords: 'candidates_keywords',
  candidatesSortOptions: 'candidates_sort_options',
  certifications: 'certifications',
  companies: 'companies',
  companiesKeywords: 'companies_keywords',
  companySizes: 'company_sizes',
  companySpecialties: 'company_specialties',
  companyType: 'company_type',
  count: 'doc_count',
  firstNames: 'first_names',
  gender: 'gender',
  geoPoints: 'geo_points',
  gradYears: 'grad_years',
  highestDegreeLevel: 'highest_edu',
  includePrevious: 'include_previous',
  industries: 'industries',
  jobCategory: 'job_category',
  jobTitles: 'job_titles',
  lastNames: 'last_names',
  location: 'location',
  majors: 'majors',
  militaryStatus: 'military_status',
  preferredSkills: 'preferred_skills',
  requiredSkills: 'required_skills',
  salary: 'salary',
  skills: 'skills',
  skillsKeywords: 'skills_keywords',
  specialties: 'specialties',
  states: 'states',
  tags: 'tags',
  totalCount: 'count',
  universities: 'universities',
  veteranStatus: 'veteran_status',
  yearsOfExperience: 'years_of_experience',
})
