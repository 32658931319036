import { Auth } from 'aws-amplify'
import get from 'lodash/get'

import { cache } from '../../../apolloClient'
import { getUserForBasicInfoQuery } from '../../../apolloClient/operations/userData/getUserForBasicInfoQuery'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useUpdateUser = ({ userProfile }) => {
  const updateUser = async formData => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        family_name: get(formData, 'lastName'),
        given_name: get(formData, 'firstName'),
      })

      cache.writeQuery({
        data: {
          getUserProfile: {
            __typename: 'User',
            firstName: get(formData, 'firstName'),
            id: get(userProfile, 'id'),
            lastName: get(formData, 'lastName'),
          },
        },
        query: getUserForBasicInfoQuery,
      })

      feedback.success({ content: 'Profile updated successfully' })
    } catch (error) {
      errorService.report(error, 'BasicInfoForm')
      feedback.error({
        content: error.message,
        title: 'Error updating profile',
      })
    }
  }
  return { updateUser }
}
