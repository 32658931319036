const industryOptions = [
  'Accounting',
  'Aviation & Aerospace',
  'Legal',
  'Healthcare',
  'Arts & Entertainment',
  'Consumer Goods',
  'Engineering',
  'Automotive',
  'Finance',
  'Life Sciences',
  'Media',
  'Manufacturing',
  'Public Service',
  'Real Estate',
  'Information Technology',
  'Construction',
  'Electronic Manufacturing',
  'Services',
  'Agriculture',
  'Defense & Space',
  'Education',
  'Government',
  'Hospitality',
  'Food Production',
  'Human Resources',
  'Insurance',
  'Transportation & Logistics',
  'Consulting',
  'Research',
  'Marketing',
  'Military',
  'Natural Resources',
  'Non-Profit',
  'Religious Services',
  'Retail',
  'Telecommunications',
  'Utilities',
]

const jobCategoryOptions = [
  'Management',
  'Executive',
  'Business',
  'Health Care',
  'Education',
  'Information Technology',
  'Sales',
  'Finance',
  'Other',
  'Admin Clerical',
  'Engineering',
  'Customer Service',
  'Human Resources',
  'Accounting',
  'Construction',
  'Food Services',
  'Skilled Labor Trades',
  'Real Estate',
  'Media',
  'Design',
  'Maintenance',
  'Marketing',
  'Legal',
  'Social Services',
  'Transportation',
  'Hospitality',
  'Law Enforcement',
  'Logistics',
  'Insurance',
  'Retail',
  'Quality Control',
  'Banking',
  'Research',
  'Science',
  'Athletics',
  'Entertainment',
  'Government',
  'Purchasing',
  'Agriculture',
  'Military',
  'Religion',
  'Manufacturing',
]

const companyTypeOptions = [
  'Privately Held',
  'Public Company',
  'Educational Institution',
  'Nonprofit',
  'Government Agency',
  'Partnership',
  'Sole Proprietorship',
  'Self-Employed',
]

const degreeLevelOptions = [
  'associates',
  'bachelors',
  'certificate',
  'doctorate',
  'high school',
  'masters',
]

export const defaultOptionsLists = {
  companyTypeOptions,
  degreeLevelOptions,
  industryOptions,
  jobCategoryOptions,
}
