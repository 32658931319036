const colors = {
  blue1: '#5099de',
  blue1rgba50: 'rgba(80, 153, 222, .5)',
  blue2: '#7fb4e7',
  blue3: '#33628e',
  blue4: 'rgba(80, 153, 222)',
  blue5: '#465561',
  danger: '#fa907d',
  gray1: '#4c4c4c',
  gray2: '#9a9a9a',
  gray3: '#fafafa',
  gray4: '#e7e7e7',
  green1: '#30bc9d',
  green2: '#68ceb7',
  green3: '#338472',
  mapBlue1: '#d9e9f7',
  mapBlue10: '#0a2137',
  mapBlue2: '#b4d3f0',
  mapBlue3: '#8fbde9',
  mapBlue4: '#69a8e2',
  mapBlue5: '#4492db',
  mapBlue6: '#267ccd',
  mapBlue7: '#1f65a7',
  mapBlue8: '#184f82',
  mapBlue9: '#11385d',
  mapBubble1: '#fafafa',
  mapBubble2: '#f5bd39',
  mapBubble3: '#fd8d3c',
  mapBubble4: '#ee6352',
  offWhite: '#fafafa',
  orange1: '#ff9552',
  orange1rgba50: 'rgba(255, 149, 82, .5)',
  orange2: '#ffa871',
  orange3: '#d17a44',
  popularity: '#FF9551',
  primary: '#4e57d3',
  red1: '#ee6352',
  red2: '#f28d81',
  red3: '#a04438',
  relevancy: '#99c4ee',
  salary: '#68CEB7',
  white: '#ffffff',
}

export default colors
