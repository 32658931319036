import React from 'react'

import { Col, Input, Typography } from 'antd'
const { Search } = Input

const CouponCodeEntry = props => {
  const { couponError, onSearch } = props
  return (
    <Col span={12}>
      <Search
        placeholder="Coupon Code"
        enterButton="Apply Coupon"
        onSearch={onSearch}
      />
      {couponError && (
        <Typography.Text type="danger">Invalid Coupon</Typography.Text>
      )}
    </Col>
  )
}

export default CouponCodeEntry
