import { useEffect } from 'react'

import * as FullStory from '@fullstory/browser'
import { Auth } from 'aws-amplify'
import gql from 'graphql-tag'
import get from 'lodash/get'
import pick from 'lodash/pick'
import { useHistory } from 'react-router-dom'

import { apolloClient } from '../../apolloClient'
import useUserForAuth from '../../hooks/data/loggedInUser/useUserForAuth'
import {
  AUTHENTICATED_ROUTES,
  PUBLIC_ROUTE_LIST,
  removeTrailingForwardSlash,
  UNAUTHENTICATED_ROUTES,
} from '../routing/routes'

export const logoutUserMutation = gql`
  mutation LogoutCrowdSegmentUser {
    logoutCrowdSegmentUser
  }
`

const AUTHORIZATION_TOKEN_PATH = 'authorizationToken'
export const clearAuthorizationToken = () => {
  window['localStorage'].removeItem(AUTHORIZATION_TOKEN_PATH)
}

export const getAuthorizationToken = async () => {
  try {
    const tokens = await Auth.currentSession()
    const idToken = get(tokens, 'idToken.jwtToken')
    if (!idToken && !isPublicRoute(window.location.pathname)) {
      await logout()

      return
    }
    setAuthorizationToken(idToken)

    return idToken
  } catch (error) {
    console.log(error)
    if (!isPublicRoute(window.location.pathname)) {
      await logout()
    }
  }
}
export const isAuthenticated = () =>
  !!window['localStorage'].getItem(AUTHORIZATION_TOKEN_PATH)

export const setAuthorizationToken = authorizationToken =>
  window['localStorage'].setItem(AUTHORIZATION_TOKEN_PATH, authorizationToken)

const isAuthenticatedRoute = pathname =>
  !Object.values(UNAUTHENTICATED_ROUTES).includes(pathname)

const isSystemAdminRoute = pathname =>
  [AUTHENTICATED_ROUTES.DEMO, AUTHENTICATED_ROUTES.USER_VIEWS].includes(
    pathname,
  )

export const isPublicRoute = pathname => PUBLIC_ROUTE_LIST.includes(pathname)
// return pathname === UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH

const isPaidRoute = pathname => {
  return [
    AUTHENTICATED_ROUTES.MANAGE_TEAM,
    AUTHENTICATED_ROUTES.DOWNLOADS,
    AUTHENTICATED_ROUTES.CROWD_SEGMENT,
    AUTHENTICATED_ROUTES.REQUISITIONS,
  ].includes(removeTrailingForwardSlash(pathname))
}

const isClientDetailsRoute = pathname =>
  pathname === AUTHENTICATED_ROUTES.CLIENT_DETAILS

const useCreateListener = (history, move) =>
  useEffect(() => {
    history.listen(location => move(history, location))
  }, [history, move])

const checkSystemAdminAccess = (userData, history, { pathname }) => {
  const isSystemAdmin = get(userData, 'isSystemAdmin', false)

  if (!isSystemAdmin && isSystemAdminRoute(pathname)) {
    history.replace(AUTHENTICATED_ROUTES.DASHBOARD)
  }
}

const checkForFailedPayment = (paymentFailed, history, pathname) => {
  if (paymentFailed && isPaidRoute(pathname)) {
    history.replace(AUTHENTICATED_ROUTES.UPDATE_PAYMENT)
  }
}

const checkForTrialExpiration = (trialExpiredAndUnpaid, history, pathname) => {
  if (trialExpiredAndUnpaid && isPaidRoute(pathname)) {
    history.replace(AUTHENTICATED_ROUTES.ADD_PAYMENT)
  }
}

const checkForClientDetails = (user, history) => {
  const { pathname } = history.location

  const client = get(user, 'client')

  if (!client && !isClientDetailsRoute(pathname)) {
    history.replace(AUTHENTICATED_ROUTES.CLIENT_DETAILS)
  } else if (!!client && isClientDetailsRoute(pathname)) {
    history.replace(AUTHENTICATED_ROUTES.CROWD_SEGMENT)
  }
}

const checkForSubscriptionCancelled = (
  subscriptionCancelled,
  history,
  pathname,
) => {
  if (subscriptionCancelled && isPaidRoute(pathname))
    history.replace(AUTHENTICATED_ROUTES.RESUBSCRIBE)
}

const checkSubscriptionStatus = (userData, history, { pathname }) => {
  const client = get(userData, 'client')
  const { trialExpired, subscription } = pick(client, [
    'trialExpired',
    'subscription',
  ])

  const paymentFailed =
    !!subscription && get(subscription, 'needsUpdatedPayment')
  const trialExpiredAndUnpaid = trialExpired && !subscription
  checkForFailedPayment(paymentFailed, history, pathname)
  checkForTrialExpiration(trialExpiredAndUnpaid, history, pathname)
  checkForSubscriptionCancelled(
    get(subscription, 'canceled'),
    history,
    pathname,
  )
}

export const useProtectAuthenticatedRoutes = history => {
  const move = (history, { pathname }) => {
    const shouldMove = !isAuthenticated() && isAuthenticatedRoute(pathname)
    if (shouldMove) {
      history.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
    }
  }

  move(history, history.location)
  const { gettingUserProfile, userProfile } = useUserForAuth()

  if (!gettingUserProfile) {
    checkForClientDetails(userProfile, history)
    checkSystemAdminAccess(userProfile, history, history.location)
    checkSubscriptionStatus(userProfile, history, history.location)
  }

  useCreateListener(history, move)

  return { userProfile }
}

export const useProtectUnauthenticatedRoutes = () => {
  const history = useHistory()
  const move = (history, { pathname }) => {
    const shouldMove =
      isAuthenticated() &&
      !isAuthenticatedRoute(pathname) &&
      !isPublicRoute(pathname)

    if (shouldMove) {
      history.replace('/')
    }
  }

  move(history, history.location)

  useCreateListener(history, move)
}

export const logout = async () => {
  FullStory.anonymize()
  FullStory.shutdown()
  apolloClient.stop()
  await apolloClient.cache.reset()
  await apolloClient.resetStore()
  await Auth.signOut({ global: true })
  clearAuthorizationToken()
  document.location.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
}
