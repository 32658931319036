import gql from 'graphql-tag'

export const getMembersQuery = gql`
  query GetUserProfile {
    getUserProfile {
      id
      isAdmin
      roles
      client {
        members {
          invitations {
            id
            email
          }
          admins {
            firstName
            lastName
            id
            email
            enabled
            isAdmin
          }
          nonAdmins {
            firstName
            lastName
            id
            email
            enabled
          }
        }
        trialExpired
        settings {
          id
          credits
        }
        id
        name
        subscription {
          id
          needsUpdatedPayment
        }
      }
    }
  }
`
