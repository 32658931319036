import React from 'react'

import { Typography } from 'antd'

import { pickWithInitialState } from '../../../../../../../../utils/data/utils'

import InvoiceFooterItem from './InvoiceFooterItem'

const { Text } = Typography

const InvoiceFooter = props => {
  const { invoice } = props
  const { discount = {}, subtotal, total } = invoice
  const {
    amount: discountAmount,
    couponCode,
    discountDisplay,
  } = pickWithInitialState(discount, [
    ['amount', null],
    ['couponCode', null],
    ['discountDisplay', null],
  ])
  return (
    <div
      style={{
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {discountAmount && (
        <InvoiceFooterItem label="Subtotal" value={subtotal} />
      )}
      {discountAmount && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 25px 10px 0',
            width: 250,
          }}
        >
          <Text type="secondary" style={{ paddingRight: 10 }}>
            {`${couponCode} (${discountDisplay})`}
          </Text>
          <Text strong>-${discountAmount}</Text>
        </div>
      )}
      <InvoiceFooterItem label="Amount due" value={total} />
    </div>
  )
}

export default InvoiceFooter
