import get from 'lodash/get'

import { getUserDetailsForSearchQuery } from '../../../apolloClient/operations/userData/getUserDetailsForSearchQuery'
import feedback from '../../../utils/feedback'

const { useMutation } = require('@apollo/client')

export const useDownloadFile = ({ queryObject }) => {
  const { name, mutation } = queryObject
  const [triggerDownload, { data, loading, error }] = useMutation(mutation, {
    onCompleted: data => {
      const download = get(data, `${name}`)
      const validated = get(download, 'validated')
      if (validated) {
        feedback.success({
          content:
            "Download and email validation started successfully. We'll notify you when it's ready.",
        })
      } else {
        feedback.success({
          content: 'Download Successful',
        })
        const url = get(download, 'url')
        if (url) window.open(url)
      }
    },
    onError: () =>
      feedback.error({
        title: 'There was an error downloading your file... please try again.',
      }),
    update: (cache, { data }) => {
      const download = get(data, `${queryObject.name}`)
      const creditsUsed = get(download, 'creditsUsed')
      const userData = cache.readQuery({
        query: getUserDetailsForSearchQuery,
      })
      const settings = get(userData, 'getUserProfile.client.settings')

      cache.modify({
        fields: {
          credits(existingCredits = 0) {
            const updatedCredits = existingCredits - creditsUsed

            return updatedCredits
          },
        },
        id: cache.identify(settings),
      })
    },
  })

  const downloadResponse = get(data, `${name}`)

  return { downloadResponse, error, loading, triggerDownload }
}
