import React, { useState } from 'react'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Typography } from 'antd'
import get from 'lodash/get'

import errorService from '../../utils/analytics/error'

const { Paragraph } = Typography

const inputOptions = {
  style: {
    base: {
      fontSize: '16px',
    },
  },
}

const StripeCard = props => {
  const setStripeToken = get(props, 'setStripeToken')
  const clientName = get(props, 'clientName')
  const onChangeCallback = get(props, 'onChangeCallback')
  const [error, setError] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const getToken = async () => {
    setError(null)
    if (!stripe || !elements || !clientName) {
      return
    }
    const cardElement = elements.getElement(CardElement)
    const { error, token } = await stripe.createToken(cardElement, {
      name: clientName,
    })

    if (error) {
      console.log('[error]', error)
      errorService.report(error, 'Payment - Get Stripe Token')
      setError(error.message)
      setStripeToken(null)
    } else {
      const stripeCardToken = get(token, 'id')
      setStripeToken(stripeCardToken)
    }
  }

  const cardReady = event => {
    const err = get(event, 'error.message', null)
    if (err && err !== error) {
      setError(err)
      setStripeToken(null)
    }
    return get(event, 'complete') === true && !!clientName
  }

  const handleCardChange = event => {
    if (cardReady(event)) {
      getToken()
    } else {
      if (onChangeCallback) {
        onChangeCallback()
      }
    }
  }

  return (
    <>
      <CardElement
        options={inputOptions}
        className="te-card-signup"
        onReady={el => el.focus()}
        onChange={handleCardChange}
      />
      {error && <Paragraph type="danger">{error}</Paragraph>}
    </>
  )
}

export default StripeCard
