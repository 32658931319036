import React, { useEffect, useRef } from 'react'

import { Popover } from 'antd'

import usePrevious from '../../../../../hooks/common/usePrevious'

import SearchHeaderButton from './SearchHeaderButton'

const SearchDropdown = props => {
  const { content, selectedFilters, clearFilters, title, defaultOpen } = props

  const dropdownRef = useRef(null)

  const prevDefaultOpen = usePrevious(defaultOpen)

  /**
   * @param {HTMLElement} trigger
   * @returns {HTMLElement}
   */
  const handleGetPopupContainer = trigger =>
    /** @type {HTMLElement} */ (trigger.parentNode)

  // simulate a click on the item so the visiblity goes to false if default open turns to false
  useEffect(() => {
    if (prevDefaultOpen === false && defaultOpen === true) {
      dropdownRef.current.click()
    }
  }, [defaultOpen, prevDefaultOpen])

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomLeft"
      getPopupContainer={handleGetPopupContainer}
      defaultVisible={defaultOpen}
    >
      <span className="search-header-btn" ref={dropdownRef} id={title}>
        <SearchHeaderButton
          panelFiltersToApply={selectedFilters}
          title={title}
          clearFilters={clearFilters}
        />
      </span>
    </Popover>
  )
}

export default SearchDropdown
