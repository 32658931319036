import React from 'react'

import { Card } from 'antd'
import get from 'lodash/get'

import { theme } from '../../../theme'

const CenteredCard = props => {
  const accentColor = get(theme, 'colors.accent')
  const { children, styleOverride } = props
  const defaultStyle = {
    paddingTop: 8,
  }
  const style = styleOverride
    ? {
        ...defaultStyle,
        ...styleOverride,
      }
    : defaultStyle
  return (
    <div style={{ margin: '80px auto', maxWidth: 600 }}>
      <Card bodyStyle={style} style={{ borderTop: `8px solid ${accentColor}` }}>
        {children}
      </Card>
    </div>
  )
}

export default CenteredCard
