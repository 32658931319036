import React from 'react'
import { Row } from 'antd'
import Box from 'ui-box'
import { withRouter } from 'react-router-dom'
import ClearButton from '../../../../components/touchables/ClearButton'

const NavLinks = props => (
  <Box {...props.containerProps}>
    <Row type="flex" align="middle" justify="space-between">
      {props.links.map(({ label, route }) => (
        <ClearButton
          key={route}
          type="ghost"
          onClick={() => props.history.push(route)}
        >
          {label}
        </ClearButton>
      ))}
    </Row>
  </Box>
)

export default withRouter(NavLinks)
