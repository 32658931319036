import React from 'react'

import { List, Skeleton, Avatar, Button } from 'antd'
import get from 'lodash/get'

import { capitalize } from '../../../../../utils/stringManipulation/index'

const CompanyCandidateList = props => {
  const candidates = get(props, 'candidates')
  const handleCandidateClick = get(props, 'handleCandidateClick')

  return (
    <List
      className="demo-loadmore-list"
      // loading={initLoading}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={candidates}
      renderItem={item => (
        <List.Item
          actions={[
            <Button
              type="link"
              onClick={() => handleCandidateClick(get(item, 'id'))}
              key="list-loadmore-more"
            >
              more
            </Button>,
          ]}
        >
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    backgroundColor: '#fde3cf',
                    color: '#f56a00',
                  }}
                >
                  {item.name
                    .split(' ')
                    .map(n => n[0])
                    .join('')
                    .toUpperCase()}
                </Avatar>
              }
              title={capitalize(item.name)}
              description={capitalize(item.title)}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  )
}

export default CompanyCandidateList
