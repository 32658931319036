import React, { useState } from 'react'

import { Button, Modal, Typography, Radio, Input } from 'antd'
import get from 'lodash/get'

import { apolloClient } from '../../../../../../../../apolloClient/index'
import { cancelSubscriptionMutation } from '../../../../../../../../apolloClient/operations/subscriptions/cancelSubscriptionMutation'
import { theme } from '../../../../../../../../theme'
import errorService from '../../../../../../../../utils/analytics/error'
import feedback from '../../../../../../../../utils/feedback'

const accentColor = get(theme, 'colors.accent')
const { Paragraph, Title } = Typography

const REASONS_FOR_CANCELLATION = [
  {
    displayName: 'Issues with the website',
    value: 'not_working',
  },
  {
    displayName: "Didn't find what I was looking for",
    value: 'not_helpful',
  },
  {
    displayName: 'Too expensive',
    value: 'too_expensive',
  },
  {
    displayName: 'Just here for the free trial',
    value: 'free_trial',
  },
]

const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' }

const SubscriptionCancellation = props => {
  const modalShown = get(props, 'modalShown')
  const closeModal = get(props, 'closeModal')
  const [reasonToCancel, setReasonToCancel] = useState(null)
  const [customReasonValue, setCustomReasonValue] = useState(null)

  const onReasonChange = e => {
    setReasonToCancel(get(e, 'target.value', null))
  }

  const onOtherReasonChange = e => {
    if (reasonToCancel !== 'other') {
      setReasonToCancel('other')
    }
    setCustomReasonValue(get(e, 'target.value', null))
  }

  const cancelSubscription = async () => {
    const reason =
      reasonToCancel === 'other' ? customReasonValue : reasonToCancel
    try {
      await apolloClient.mutate({
        mutation: cancelSubscriptionMutation,
        variables: {
          reasonToCancel: reason,
        },
      })
      feedback.success({ content: 'Subscription cancelled' })
      closeModal()
    } catch (error) {
      errorService.report(error, 'Cancel Subscription')
      feedback.error({
        title:
          'There was a problem cancelling your subscription - please try again',
      })
    }
  }

  const formValid = () => {
    if (reasonToCancel !== null) {
      if (
        reasonToCancel === 'other' &&
        (!customReasonValue || !customReasonValue.length)
      ) {
        return false
      } else {
        return true
      }
    }
    return false
  }

  return (
    <Modal
      visible={modalShown}
      closable={false}
      footer={null}
      bodyStyle={{
        borderTop: `4px solid ${accentColor}`,
      }}
      onCancel={closeModal}
      destroyOnClose
    >
      <Title level={3}>We hate to see you go!</Title>
      <div style={{ marginBottom: '20px', padding: '0 22px' }}>
        <Paragraph type="secondary">
          Your feedback will help us serve others better
        </Paragraph>
        <Radio.Group
          onChange={onReasonChange}
          value={reasonToCancel}
          style={{ width: '100%' }}
        >
          {REASONS_FOR_CANCELLATION.map(reason => (
            <Radio key={reason.value} value={reason.value} style={radioStyle}>
              {reason.displayName}
            </Radio>
          ))}
          <Radio value="other" style={radioStyle}>
            <Input.TextArea
              onChange={onOtherReasonChange}
              style={{ height: 20 }}
            />
          </Radio>
        </Radio.Group>
      </div>
      <div style={{ textAlign: 'right', width: '100%' }}>
        <Button
          type="primary"
          onClick={() => cancelSubscription()}
          disabled={!formValid()}
        >
          Cancel My Subscription
        </Button>
      </div>
    </Modal>
  )
}

export default SubscriptionCancellation
