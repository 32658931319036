import { useEffect } from 'react'

import isEqual from 'lodash/isEqual'

import { sendFullStoryUserData } from '../../../utils/analytics/sendFullStoryUserData'
import usePrevious from '../../common/usePrevious'

export const useSendUserData = userProfile => {
  const prevData = usePrevious(userProfile)

  useEffect(() => {
    if (userProfile && !isEqual(prevData, userProfile)) {
      sendFullStoryUserData(userProfile)
    }
  }, [userProfile, prevData])
}
