import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { isCustomSubdomain } from '../../../utils/routing'

const {
  getClientCustomBrandingQuery,
} = require('../../../apolloClient/operations/clientCustomBranding/clientCustomBranding')

export const useClientCustomBranding = () => {
  const { data, loading, error } = useQuery(getClientCustomBrandingQuery, {
    skip: !isCustomSubdomain(),
    variables: {
      subdomain: process.env.REACT_APP_CUSTOM_SUBDOMAIN,
    },
  })
  const clientCustomBrandingData = get(data, 'getClientCustomBranding')

  return {
    clientCustomBrandingData,
    error,
    loading,
  }
}
