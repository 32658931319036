import React from 'react'
import get from 'lodash/get'
import { capitalize } from '../../../utils/stringManipulation'

const PersonCompanyDisplay = props => {
  const title = get(props, 'title')
  const company = get(props, 'company')

  return !!title && !!company ? (
    <div className="text-muted">@{capitalize(company)}</div>
  ) : !!company ? (
    <div className="text-muted">{capitalize(company)}</div>
  ) : null
}

export default PersonCompanyDisplay
