import React from 'react'

import { Row, Col, Tabs, Drawer, Typography } from 'antd'
import Meta from 'antd/lib/card/Meta'
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'
import maxBy from 'lodash/maxBy'
import round from 'lodash/round'

import CompanyLogo from '../../../../components/media/CompanyLogo'
import useModalManagement from '../../../../hooks/common/useModalManagement/index'
import { useGetCandidatesById } from '../../../../hooks/data/lookup/useGetCandidatesDataById'
import { pickWithInitialState } from '../../../../utils/data/utils/index'
import StatCountUpTE from '../../../Authenticated/components/AuthenticatedLayout/components/ReportElements/StatCountUpTE'

import BarFilters from './BarFilters'
import CandidateView from './CandidateView'
import CompanyCandidateList from './components/CompanyCandidateList'
import PillList from './components/PillList'
import SkillsBars from './components/SkillsBars'
import PaymentRequestAlert from './PaymentRequestAlert'

const { TabPane } = Tabs
const { Paragraph } = Typography

const CompanyView = props => {
  const companyDetails = get(props, 'companyDetails', {})
  const toggleSkill = get(props, 'toggleSkill')
  const selectedSkills = get(props, 'selectedSkills')
  const permissions = get(props, 'permissions', {})
  const openPaymentModal = get(props, 'openPaymentModal')
  const width = get(props, 'width', 400)
  const {
    candidatesData,
    getCandidatesDataById,
    loadingCandidateDetails,
  } = useGetCandidatesById()

  const {
    openModal: openDrawer,
    closeModal: closeDrawer,
    visible,
  } = useModalManagement(false)

  const {
    name,
    description,
    company_specialties: companySpecialties,
    skills,
    keywords,
    employeeBreakdown,
    company_id,
    topExecs,
    topEmployees,
    skillsDetail,
    avgSalary,
    avgTenure,
  } = pickWithInitialState(companyDetails, [
    ['name', ''],
    ['description', ''],
    ['company_specialties', []],
    ['skills', []],
    ['keywords', []],
    ['employeeBreakdown', []],
    ['company_id', ''],
    ['topExecs', []],
    ['topEmployees', []],
    ['skillsDetail', []],
    ['avgSalary', 0],
    ['avgTenure', 2],
  ])

  const getCandidateDetails = async candidateId => {
    try {
      getCandidatesDataById({
        variables: {
          candidateIds: [candidateId],
        },
      })
      openDrawer()
    } catch (error) {
      console.log(error)
    }
  }

  const maxPopularity = get(maxBy(skillsDetail, 'popularity'), 'popularity', 0)
  const maxRelevancy = get(maxBy(skillsDetail, 'relevance'), 'relevance', 0)

  return (
    <>
      <Drawer
        title="Candidate View"
        closable={true}
        destroyOnClose
        onClose={closeDrawer}
        visible={visible}
        style={{ overflow: 'auto' }}
        width={width}
        zIndex={1003}
      >
        <PaymentRequestAlert
          permissions={permissions}
          openPaymentModal={openPaymentModal}
          styles={{ marginBottom: 20 }}
        />
        <CandidateView
          candidateData={head(candidatesData)}
          toggleCandidatesForm={() => {}}
          setSelectedCandidateData={() => {}}
          allowSave={false}
          permissions={permissions}
          showCompanyView={false}
          openPaymentModal={openPaymentModal}
          loading={loadingCandidateDetails}
        />
      </Drawer>
      <Meta
        avatar={<CompanyLogo size={60} id={company_id} />}
        title={name}
        description={
          <Paragraph ellipsis={{ expandable: true, rows: 2 }}>
            {description}
          </Paragraph>
        }
      />
      <PillList
        color="orange"
        pills={companySpecialties}
        title="Products/Services"
      />
      <PillList color="blue" pills={skills} title="Skills" />
      <PillList color="green" pills={keywords} title="Keywords" />
      <Row>
        <Meta title="Employee Breakdown" style={{ marginTop: 10 }} />
        <Col span={12}>
          <BarFilters data={employeeBreakdown.slice(0, 5)} disabled={true} />
        </Col>
        <Col span={12} style={{ marginTop: 10, textAlign: 'center' }}>
          <Col span={24}>
            <StatCountUpTE
              fontSize={40}
              data={avgTenure}
              title="avg. tenure"
              suffix=" years"
            />
          </Col>
          <Col span={24} style={{ marginTop: 30 }}>
            <StatCountUpTE
              fontSize={40}
              data={avgSalary}
              title="avg. salary"
              prefix="$"
              color="green"
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="1" className="sidebar-tabs">
            <TabPane tab="Top Executives" key="1" className="sidebar-tab">
              <CompanyCandidateList
                candidates={topExecs}
                handleCandidateClick={getCandidateDetails}
                openModal={openPaymentModal}
              />
            </TabPane>
            {topEmployees.length && (
              <TabPane tab="Top Employees" key="2" className="sidebar-tab">
                <CompanyCandidateList
                  candidates={topEmployees}
                  handleCandidateClick={getCandidateDetails}
                  openModal={openPaymentModal}
                />
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Meta title="Top Skills" />
        {skillsDetail.map((skill, index) => (
          <SkillsBars
            toggleSkill={toggleSkill}
            key={`${get(skill, 'name')}${index}`}
            name={get(skill, 'name')}
            selected={includes(selectedSkills, get(skill, 'name'))}
            values={[
              {
                name: 'popularity',
                value: round((get(skill, 'popularity') * 100) / maxPopularity),
              },
              {
                name: 'relevancy',
                // normalize to 70, and add 30 as a minimum
                value:
                  round((get(skill, 'relevance') * 70) / maxRelevancy) + 30,
              },
            ]}
          />
        ))}
      </Row>
    </>
  )
}

export default CompanyView
