import gql from 'graphql-tag'

export const getDownloadsForJobRequisitionQuery = gql`
  query GetDownloadsForJobRequisition {
    crowdSegmentClient {
      id
      jobRequisitions {
        id
        savedDownloads {
          id
          fileName
          url
          searchParams
          calculatedStatus
        }
      }
    }
  }
`
