import React from 'react'

import CenteredLayoutWide from '../components/CenteredLayoutWide'

import ConfirmSignupForm from './ConfirmSignupForm'

const ConfirmSignup = () => {
  return (
    <div>
      <CenteredLayoutWide>
        <div style={{ margin: '80px auto', maxWidth: 650 }}>
          <ConfirmSignupForm />
        </div>
      </CenteredLayoutWide>
    </div>
  )
}

export default ConfirmSignup
