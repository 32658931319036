import React from 'react'

import { Typography } from 'antd'
import PT from 'prop-types'

const { Title } = Typography

const PageTitle = props => {
  return (
    <div style={{ marginBottom: 16, marginLeft: 35 }}>
      <Title
        className="text-center"
        level={3}
        style={{ color: 'rgba(0, 0, 0, 0.65)' }}
      >
        {props.title}
      </Title>
    </div>
  )
}

PageTitle.propTypes = {
  title: PT.string.isRequired,
}

export default PageTitle
