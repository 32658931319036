import React, { useState } from 'react'

import { Button, Form, Input } from 'antd'
import { Auth } from 'aws-amplify'

import { useFormState } from '../../../hooks/common/useFormState'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'
import { UNAUTHENTICATED_ROUTES } from '../../../utils/routing/routes'
import {
  useCrowdSegmentDispatch,
  useCrowdSegmentState,
} from '../../Authenticated/CrowdSegment/crowd-context'
import CenteredLayoutWide from '../components/CenteredLayoutWide'
import NavLinks from '../components/NavLinks'
import UnauthenticatedCard from '../components/UnauthenticatedCard'

const FORM_FIELD_TITLES = {
  CODE: 'authenticationCode',
  EMAIL: 'email',
  NEW_PASSWORD: 'newPassword',
}

const NewPassword = () => {
  const [savingNewPassword, setSavingNewPassword] = useState(false)

  const { unauthenticatedUser } = useCrowdSegmentState()
  const { setUnauthenticatedUser } = useCrowdSegmentDispatch()
  const { formValues, handleChange } = useFormState({
    [FORM_FIELD_TITLES.EMAIL]: unauthenticatedUser.email
      ? unauthenticatedUser.email
      : null,
    [FORM_FIELD_TITLES.CODE]: '',
    [FORM_FIELD_TITLES.NEW_PASSWORD]: '',
  })

  const onSubmit = async () => {
    setUnauthenticatedUser({ email: formValues[FORM_FIELD_TITLES.email] })
    setSavingNewPassword(true)
    try {
      Auth.forgotPasswordSubmit(
        formValues[FORM_FIELD_TITLES.EMAIL],
        formValues[FORM_FIELD_TITLES.CODE],
        formValues[FORM_FIELD_TITLES.NEW_PASSWORD],
      )
      setSavingNewPassword(false)
    } catch (error) {
      setSavingNewPassword(false)
      errorService.report(error, 'ForgotPassword')
      feedback.error({
        content: error.message,
        title: 'Error resetting password!',
      })
    }
  }

  return (
    <CenteredLayoutWide>
      <div style={{ margin: '80px auto', maxWidth: 650 }}>
        <UnauthenticatedCard
          title="Reset your password"
          subtitle="Please enter the authentication code sent via email. Be sure to check your spam folder."
        >
          <Form hideRequiredMark className="antd-auth-form">
            <Form.Item>
              <Input
                placeholder="Email"
                value={formValues[FORM_FIELD_TITLES.EMAIL]}
                id={FORM_FIELD_TITLES.EMAIL}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Input
                placeholder="Authentication Code"
                value={formValues[FORM_FIELD_TITLES.CODE]}
                id={FORM_FIELD_TITLES.CODE}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Input
                type="password"
                placeholder="New Password"
                value={formValues[FORM_FIELD_TITLES.NEW_PASSWORD]}
                id={FORM_FIELD_TITLES.NEW_PASSWORD}
                onChange={handleChange}
              />
            </Form.Item>
          </Form>
          <div
            style={{
              alignItems: 'baseline',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <NavLinks
              containerProps={{ marginTop: '16px' }}
              links={[
                {
                  label: 'Back to Log In',
                  route: UNAUTHENTICATED_ROUTES.AUTHENTICATE,
                },
              ]}
            />
            <Button
              style={{ marginLeft: 8 }}
              type="primary"
              onClick={onSubmit}
              loading={savingNewPassword}
            >
              Submit
            </Button>
          </div>
        </UnauthenticatedCard>
      </div>
    </CenteredLayoutWide>
  )
}

export default NewPassword
