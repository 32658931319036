import React, { useState } from 'react'

import { Col, Row, Typography } from 'antd'
import get from 'lodash/get'

import useChargeOnResubscribe from '../../hooks/data/payments/useChargeOnResubscribe'
import { useResubscribe } from '../../hooks/data/subscriptions/useResubscribe'
import StripeCard from '../stripeCard'

import ChargeSummary from './components/ChargeSummary'
import CouponCodeEntry from './components/CouponCodeEntry'
import NonAdminMessage from './components/NonAdminMessage'
import PaymentFormFooter from './components/PaymentFormFooter'

const { Paragraph, Title } = Typography

const ResubscribeForm = props => {
  const clientName = get(props, 'clientName')
  const isAdmin = get(props, 'isAdmin')
  const afterUpdate = get(props, 'afterUpdate')
  const [stripeToken, setStripeToken] = useState(null)
  const [couponCode, setCouponCode] = useState(null)
  const [couponCodeToSubmit, setCouponCodeToSubmit] = useState(null)

  const { chargesOnResubscribe, loadingCharges } = useChargeOnResubscribe({
    couponCode,
  })
  const { resubscribe, resubscribing } = useResubscribe({
    afterUpdate,
  })

  let couponError = false
  if (!loadingCharges) {
    if (!!couponCode && get(chargesOnResubscribe, 'discount')) {
      if (couponCodeToSubmit !== couponCode) setCouponCodeToSubmit(couponCode)
    } else if (!!couponCode && !get(chargesOnResubscribe, 'discount')) {
      //setCouponCode(null)
      couponError = true
      if (couponCodeToSubmit) setCouponCodeToSubmit(null)
    }
  }

  const submitPaymentInfo = () => {
    resubscribe({
      variables: {
        couponCode: couponCodeToSubmit,
        stripeCardToken: stripeToken,
      },
    })
  }

  return (
    <>
      <Title level={3}>Reactivate Your Subscription</Title>
      {isAdmin ? (
        <>
          {' '}
          <Paragraph type="secondary">
            Unlock the full power of CrowdSegment! A monthly subscription gives
            you unlimited access to CrowdSegment features and analytics.
            Subscribers get 5,000 bonus credits on signup, and are able to
            purchase additional on-demand credits.
          </Paragraph>
          <StripeCard setStripeToken={setStripeToken} clientName={clientName} />
          <Row>
            <CouponCodeEntry
              onSearch={value => setCouponCode(value)}
              couponError={couponError}
            />
            <Col span={12}>
              <div style={{ textAlign: 'right' }}>
                <ChargeSummary
                  chargeData={chargesOnResubscribe}
                  showDiscount={
                    couponCode && get(chargesOnResubscribe, 'discount')
                  }
                  loading={loadingCharges}
                />
              </div>
            </Col>
          </Row>
          <PaymentFormFooter
            onSubmit={submitPaymentInfo}
            stripeToken={stripeToken}
            addingPayment={resubscribing}
          />
        </>
      ) : (
        <NonAdminMessage />
      )}
    </>
  )
}

export default ResubscribeForm
