import React, { useEffect, useState } from 'react'

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import Amplify, { Hub } from 'aws-amplify'
import get from 'lodash/get'
import qs from 'query-string'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import awsconfig from '../aws-exports'
import { isAuthenticated, setAuthorizationToken } from '../utils/authentication'
import feedback from '../utils/feedback'
import {
  AUTHENTICATED_ROUTES,
  UNAUTHENTICATED_ROUTES,
} from '../utils/routing/routes'

import Authenticated from './Authenticated'
import AuthenticatedLayout from './Authenticated/components/AuthenticatedLayout'
import { CrowdSegmentProvider } from './Authenticated/CrowdSegment/crowd-context'
import Unauthenticated from './Unauthenticated'
import CenteredAuthenticatedLayout from './Unauthenticated/components/CenteredAuthenticatedLayout'
import PublicSearch from './Unauthenticated/PublicSearch/components/PublicSearch'

const ProtectedRoute = props => (
  <Route
    exact={props.exact}
    path={props.path}
    render={() =>
      props.signedIn ? (
        <props.render exact={props.exact} />
      ) : (
        <Redirect to={UNAUTHENTICATED_ROUTES.AUTHENTICATE} />
      )
    }
  />
)

export const PublicRoute = props => {
  const { signedIn, path } = props

  return (
    <Route
      path={path}
      render={() => {
        return signedIn ? (
          <AuthenticatedLayout borderBottom={false}>
            <props.render />
          </AuthenticatedLayout>
        ) : (
          <CenteredAuthenticatedLayout borderBottom={false}>
            <props.render />
          </CenteredAuthenticatedLayout>
        )
      }}
    />
  )
}

const Routes = () => {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()
  const queryParams = qs.parse(get(window, 'location.search'))
  const emailToImpersonate = get(queryParams, 'emailToImpersonate')
  const history = useHistory()

  if (emailToImpersonate) {
    awsconfig.Auth.clientMetadata = {
      emailToImpersonate,
    }
    Amplify.configure(awsconfig)
  }

  const COGNITO_FEEDBACK = {
    CODES: {
      RESET_REQUIRED: 'PasswordResetRequiredException',
      UNCONFIRMED_USER: 'UserNotConfirmedException',
    },
    EVENTS: {
      FORGOT_PW: 'forgotPassword',
      FORGOT_PW_SUBMIT: 'forgotPasswordSubmit',
      SIGNUP: 'signUp',
      SIGN_IN: 'signIn',
      SIGN_IN_FAILURE: 'signIn_failure',
    },
    MESSAGES: {
      NON_USER_FRIENDLY_MSG:
        'Custom auth lambda trigger is not configured for the user pool.',
    },
  }

  useEffect(() => {
    const renderMessage = errMsg => {
      let msg =
        errMsg === COGNITO_FEEDBACK.MESSAGES.NON_USER_FRIENDLY_MSG
          ? 'Invalid username or password.'
          : errMsg

      feedback.error({ title: msg })
    }

    const handleCognitoEvent = (res, history) => {
      const event = get(res, 'payload.event')
      if (event === COGNITO_FEEDBACK.EVENTS.FORGOT_PW) {
        history.replace(UNAUTHENTICATED_ROUTES.NEW_PASSWORD)
      }
      if (event === COGNITO_FEEDBACK.EVENTS.FORGOT_PW_SUBMIT) {
        history.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
      }
      if (event === COGNITO_FEEDBACK.EVENTS.SIGNUP) {
        const confirmed = get(res, 'payload.data.userConfirmed', false)

        if (!confirmed) {
          history.replace(UNAUTHENTICATED_ROUTES.CONFIRM_SIGNUP)
        }
      }
      if (event === COGNITO_FEEDBACK.EVENTS.SIGN_IN) {
        const user = get(res, 'payload.data.attributes')
        setUser(user)
      }

      if (event === COGNITO_FEEDBACK.EVENTS.SIGN_IN_FAILURE) {
        const shadowNodes = document.getElementsByTagName(
          'amplify-authenticator',
        )[0].shadowRoot
        setTimeout(() => {
          if (shadowNodes && shadowNodes.children) {
            // @ts-ignore
            shadowNodes.children[0].style.display = 'none'
          }
        }, 50)
      }
    }

    const handleCognitoError = (res, history) => {
      const errorCode = get(res, 'payload.data.code')
      if (errorCode === COGNITO_FEEDBACK.CODES.UNCONFIRMED_USER) {
        history.replace(UNAUTHENTICATED_ROUTES.CONFIRM_SIGNUP)
      }
      if (errorCode === COGNITO_FEEDBACK.CODES.RESET_REQUIRED) {
        history.replace(UNAUTHENTICATED_ROUTES.FORGOT_PASSWORD)
      }
      const errorMsg = res.payload.data?.message
        ? res.payload.data.message
        : null
      if (renderMessage && errorMsg && errorMsg.length) {
        renderMessage(errorMsg)
      }
    }

    Hub.listen('auth', res => {
      handleCognitoEvent(res, history)
      handleCognitoError(res, history)
    })

    return onAuthUIStateChange((nextAuthState, authData) => {
      const user = get(authData, 'attributes')
      if (
        nextAuthState === 'signin' &&
        !user &&
        history.location.pathname !== UNAUTHENTICATED_ROUTES.AUTHENTICATE &&
        history.location.pathname !==
          UNAUTHENTICATED_ROUTES.SIGNUP_FROM_INVITATION &&
        history.location.pathname !== UNAUTHENTICATED_ROUTES.CS_SIGNUP
      ) {
        window.location.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
        history.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
      }
      if (nextAuthState === AuthState.SignedIn && user) {
        const idToken = get(authData, 'signInUserSession.idToken.jwtToken')
        setAuthorizationToken(idToken)
        setAuthState(nextAuthState)
        setUser(user)
        history.replace(AUTHENTICATED_ROUTES.CROWD_SEGMENT)
      }
    })
  })

  const signedIn =
    (authState === AuthState.SignedIn && user) || isAuthenticated()

  return (
    <>
      <CrowdSegmentProvider>
        <Switch>
          <ProtectedRoute
            path="/app"
            render={Authenticated}
            signedIn={signedIn}
          />
          <PublicRoute
            signedIn={signedIn}
            path={UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH}
            render={PublicSearch}
          />
          <PublicRoute
            signedIn={signedIn}
            path={UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH_WITH_CONTACT}
            render={() => PublicSearch}
          />
          <Route render={Unauthenticated} />
        </Switch>
      </CrowdSegmentProvider>
    </>
  )
}

export default Routes
