import React from 'react'

import { Col, Row } from 'antd'
import findKey from 'lodash/findKey'
import get from 'lodash/get'

import { SearchStyles } from '../../../../../common/enums/SearchStyles'
import RangeFilters from '../../../../../components/forms/CustomReportFilters/components/RangeFilters'
import { filterNames } from '../../../../../utils/constants/filterNames'
import CheckboxList from '../../forms/components/CheckboxList'
import {
  defaultOptions,
  getDefaultOptions,
} from '../../static/defaultStateValues'
import CompanySizeSelect from '../CompanySizeSelect'
import GenderSelect from '../GenderSelect'
import LocationInput from '../LocationInput'
import SearchBar from '../SearchBar'
import SearchBarWithStaticSuggestions from '../SearchBarWithStaticSuggestions'
import SingleCheck from '../SingleCheck'
import SingleCheckWithCustomValues from '../SingleCheckWithCustomValues'

import GradYearSelection from './GradYearSelection'

const getSearchInput = (search, props, defaultOptions) => {
  const {
    filtersToApply,
    suggestions,
    toggleFilter,
    updateAppliedFilters,
    setFilterValue,
  } = props

  const onChangeRange = metric => selectedItems => {
    setFilterValue(metric, selectedItems, true)
  }

  const getFilterOptions = searchName => {
    const matchingKey = findKey(
      defaultOptions,
      option => option.name === searchName,
    )

    return matchingKey ? defaultOptions[matchingKey].options : []
  }

  const searchName = get(search, 'name')
  let element
  const defaultAutoSuggestions = getDefaultOptions(searchName)

  switch (true) {
    case searchName === filterNames.gradYears:
      element = (
        <GradYearSelection
          title={search.displayName}
          selectedFilters={get(filtersToApply, `${searchName}`)}
          queryName={search.queryName}
          info={search.info}
          key={searchName}
          toggleFilter={toggleFilter}
          name={searchName}
          updateAppliedFilters={updateAppliedFilters}
          countQuery={search.countQuery}
        />
      )
      break

    case searchName === filterNames.gender:
      element = (
        <GenderSelect
          key={searchName}
          selectedGenders={get(filtersToApply, `${filterNames.gender}`)}
          setFilterValue={setFilterValue}
        />
      )
      break

    case searchName === filterNames.includePrevious:
      element = (
        <Col span={24} style={{ paddingBottom: '20px' }} key={searchName}>
          <div style={{ textAlign: 'center' }}>
            <SingleCheck
              name={filterNames.includePrevious}
              title="Include previous position?"
              checked={get(filtersToApply, `${filterNames.includePrevious}`)}
              setFilterValue={setFilterValue}
            />
          </div>
        </Col>
      )
      break

    case searchName === filterNames.veteranStatus:
      element = (
        <CheckboxList
          key={searchName}
          metric={searchName}
          options={getFilterOptions(searchName)}
          selectedFilters={get(filtersToApply, searchName, [])}
          toggleExtraFilter={toggleFilter}
          toggleAllExtraFilters={setFilterValue}
        />
      )
      break

    case searchName === filterNames.companySizes:
      element = (
        <CompanySizeSelect
          displayName={search.displayName}
          key={searchName}
          selectedSizes={filtersToApply[searchName]}
          updateAppliedFilters={updateAppliedFilters}
          setFilterValue={setFilterValue}
          info={search.info}
        />
      )
      break

    case searchName === filterNames.location:
      element = (
        <Col span={24} style={{ paddingBottom: '20px' }} key={searchName}>
          <div
            style={{
              margin: '0 auto',
              marginBottom: 10,
              position: 'relative',
              width: '95%',
            }}
          >
            <LocationInput
              selectedLocation={get(filtersToApply, `${filterNames.location}`)}
              updateAppliedFilters={updateAppliedFilters}
            />
          </div>
        </Col>
      )
      break

    case search.searchStyle === SearchStyles.ImmediateAutocomplete:
      element = (
        <SearchBarWithStaticSuggestions
          options={getFilterOptions(searchName)}
          title={search.displayName}
          info={search.info}
          selectedFilters={get(filtersToApply, `${searchName}`)}
          key={searchName}
          name={searchName}
          updateAppliedFilters={updateAppliedFilters}
          searchMode={get(search, 'searchMode')}
        />
      )
      break

    case search.searchStyle === SearchStyles.Slider:
      element = (
        <RangeFilters
          title={search.displayName}
          info={search.info}
          key={searchName}
          metric={searchName}
          onChange={onChangeRange(searchName)}
          options={getFilterOptions(searchName)}
          selectedFilters={get(filtersToApply, searchName, [])}
        />
      )
      break

    case search.searchStyle === SearchStyles.SingleCheckWithCustomValues:
      element = (
        <SingleCheckWithCustomValues
          key={searchName}
          name={search.name}
          title={search.displayName}
          info={search.info}
          value={get(filtersToApply, `${searchName}`)}
          setFilterValue={setFilterValue}
          options={search.options}
          label={search.label}
        />
      )
      break

    default:
      element = (
        <SearchBar
          title={search.displayName}
          info={search.info}
          selectedFilters={get(filtersToApply, `${searchName}`)}
          filterSuggestions={get(suggestions, `${searchName}`)}
          suggestionsQuery={search.suggestionsQuery}
          queryName={search.queryName}
          key={searchName}
          toggleFilter={toggleFilter}
          name={searchName}
          updateAppliedFilters={updateAppliedFilters}
          searchMode={search.searchMode}
          defaultAutoSuggestions={defaultAutoSuggestions}
        />
      )
  }

  return element
}

const SearchGroup = props => {
  const { searches, searchButton } = props

  return (
    <div className="search-popover-box">
      <Row
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        {Object.keys(searches).map(key => {
          const value = get(searches, `${key}`)

          return getSearchInput(value, props, defaultOptions)
        })}
      </Row>
      <div className="text-center">{searchButton}</div>
    </div>
  )
}

export default SearchGroup
