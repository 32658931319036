import { useMutation } from '@apollo/client'

import { purchaseCreditsMutation } from '../../../apolloClient/operations/clientData/purchaseCreditsMutation'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const usePurchaseCredits = () => {
  const [purchaseCredits] = useMutation(purchaseCreditsMutation, {
    onCompleted: () => {
      feedback.success({
        content: `Your purchase was successful!`,
      })
    },
    onError: error => {
      errorService.report(error, 'PurchaseDownloadCredits')
      feedback.error({
        content: error.message,
        title: 'Error purchasing download credits',
      })
    },
  })

  return { purchaseCredits }
}
