import React from 'react'

import { Divider } from 'antd'
import get from 'lodash/get'

import ShowCharge from '../../../../routes/Unauthenticated/TeRegistration/components/ShowCharge'

const ChargesWithDiscount = props => {
  const { loading, charges } = props

  return (
    <>
      <ShowCharge
        loading={loading}
        chargeText="Subtotal"
        amount={get(charges, 'subtotal')}
      />
      <ShowCharge
        loading={loading}
        chargeText="Discount"
        amount={get(charges, 'discount.amount')}
      />
      <Divider style={{ margin: 3, padding: 0 }} />
    </>
  )
}

export default ChargesWithDiscount
