import { useMutation } from '@apollo/client'
import get from 'lodash/get'

import { createJobRequisitionMutation } from '../../../apolloClient/operations/requisitions/createJobRequisitionMutation'
import { getUserDetailsForSearchQuery } from '../../../apolloClient/operations/userData/getUserDetailsForSearchQuery'
import errorService from '../../../utils/analytics/error'
import feedback from '../../../utils/feedback'

export const useCreateRequisition = options => {
  const [createJobRequisition] = useMutation(createJobRequisitionMutation, {
    onCompleted: () => {
      feedback.success({ content: 'Crowd added successfully' })
      if (options && options.afterCreate) {
        options.afterCreate()
      }
    },
    onError: err => {
      errorService.report(err, 'createJobRequisitionMutation')
      feedback.error({
        title: 'Error adding Crowd... Please try again',
      })
    },
    update(cache, { data: { createJobRequisition: newRequisition } }) {
      const userData = cache.readQuery({
        query: getUserDetailsForSearchQuery,
      })
      const clientData = get(userData, 'getUserProfile.client')

      cache.modify({
        fields: {
          jobRequisitions(existingJobRequisitionsRef = []) {
            const newRequisitionReference = {
              __ref: cache.identify(newRequisition),
            }
            return [...existingJobRequisitionsRef, newRequisitionReference]
          },
        },
        id: cache.identify(clientData),
      })
    },
  })

  return {
    createJobRequisition,
  }
}
