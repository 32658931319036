const createAuthenticatedRoute = (route = '') => `/app${route}`

const createManageTeamRoute = (route = '') =>
  createAuthenticatedRoute(`/manage-team${route}`)

const createMyAccountRoute = (route = '') =>
  createAuthenticatedRoute(`/my-account${route}`)

export const isPublic = (route = '') =>
  route === UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH

export const removeTrailingForwardSlash = str => {
  if (str.charAt(str.length - 1) === '/') {
    return str.slice(0, -1)
  } else {
    return str
  }
}

export const AUTHENTICATED_ROUTES = {
  ADD_PAYMENT: createAuthenticatedRoute('/add-payment'),
  CLIENT_DETAILS: createAuthenticatedRoute('/client-details'),
  CROWD_SEGMENT: createAuthenticatedRoute(),
  DOWNLOADS: createAuthenticatedRoute('/downloads'),
  MANAGE_TEAM: createManageTeamRoute(),
  MY_ACCOUNT: createMyAccountRoute(),
  REQUISITIONS: createAuthenticatedRoute('/requisitions'),
  RESUBSCRIBE: createAuthenticatedRoute('/resubscribe'),
  UPDATE_PAYMENT: createAuthenticatedRoute('/update-payment'),
}

export const MANAGE_TEAM_ROUTES = {
  MEMBERS: createManageTeamRoute('/members'),
  TEAMS: createManageTeamRoute(),
}

export const MY_ACCOUNT_ROUTES = {
  BASIC_INFO: createMyAccountRoute(),
  BUY_CREDITS: createMyAccountRoute('/buy-credits'),
  CHANGE_PASSWORD: createMyAccountRoute('/change-password'),
  CUSTOM_BRANDING: createMyAccountRoute('/custom-branding'),
  SUBSCRIPTION: createMyAccountRoute('/subscription'),
}

export const UNAUTHENTICATED_ROUTES = {
  ACCEPT_INVITATION: '/invitation',
  AUTHENTICATE: '/login',
  CONFIRM_SIGNUP: '/confirm-signup',
  CREATE_ACCOUNT: '/signup-create-account',
  CS_SIGNUP: '/signup',
  SIGNUP_FROM_INVITATION: '/complete-signup',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password',
  PUBLIC_SEARCH: '/public-search',
  PUBLIC_SEARCH_WITH_CONTACT: '/public-contact-form',
}

export const PUBLIC_ROUTE_LIST = [
  UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH,
  UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH_WITH_CONTACT,
]
