import React, { useState } from 'react'

import { Icon, Table, Tooltip, Popconfirm, Empty } from 'antd'
import get from 'lodash/get'

import { apolloClient } from '../../../../apolloClient/index'
import { downloadQueryObjects } from '../../../../apolloClient/operations/downloads/downloads'
import { removeCandidateFromRequisitionMutation } from '../../../../apolloClient/operations/requisitions/removeCandidatesFromJobRequisitionMutation'
import CandidateDownload from '../../../../components/TableColumns/components/CandidateDownload'
import FindEmail from '../../../../components/TableColumns/FIndEmail/index.js'
import PersonEducationColumn from '../../../../components/TableColumns/PersonEducationColumn'
import PersonEmployerColumn from '../../../../components/TableColumns/PersonEmployerColumn'
import PersonNameColumn from '../../../../components/TableColumns/PersonNameColumn'
import PersonSalaryColumn from '../../../../components/TableColumns/PersonSalaryColumn'
import colors from '../../../../hocs/colors'
import useModalManagement from '../../../../hooks/common/useModalManagement/index'
import { useDownloadFile } from '../../../../hooks/data/downloads/useDownloadFile'
import { useGetCreditsUsageForEmailVerification } from '../../../../hooks/data/downloads/useGetCreditsUsageForEmailVerification'
import { useCandidatesById } from '../../../../hooks/data/lookup/useCandidatesById'
import errorService from '../../../../utils/analytics/error'
import feedback from '../../../../utils/feedback'
import { capitalize } from '../../../../utils/stringManipulation'
import AddPaymentModal from '../../CrowdSegment/components/AddPaymentModal'

import CandidateTableLoader from './components/CandidateTableLoader'
import CopyCandidatesForm from './CopyCandidatesForm'

const getColumns = ({
  gettingCreditsUsageForEmailVerification,
  creditChargePerVerification,
  removeCandidate,
  onCandidateNameClick,
  permissions,
  openPaymentModal,
}) => {
  return [
    {
      dataIndex: 'full_name',
      key: 'full_name',
      render: (val, row) => {
        return (
          <PersonNameColumn
            val={val}
            row={row}
            handleNameClick={() => onCandidateNameClick(row)}
            permissions={permissions}
            openPaymentModal={openPaymentModal}
          />
        )
      },
      title: 'Candidate',
    },
    {
      dataIndex: 'current_position',
      key: 'current_position',
      render: (val, row) => (
        <PersonEmployerColumn row={row} currentPosition={val} />
      ),
      title: 'Current Position',
    },
    {
      dataIndex: 'highest_edu',
      key: 'highest_edu',
      render: (val, row) => <PersonEducationColumn val={val} row={row} />,
      title: 'Highest Education',
    },
    {
      dataIndex: 'city',
      key: 'city',
      render: (val, row) => <PersonSalaryColumn val={val} row={row} />,
      title: 'Salary / Location',
    },
    {
      dataIndex: 'email',
      key: 'email',
      render: (val, row) => {
        return (
          <FindEmail
            val={val}
            row={row}
            showPaymentModal={openPaymentModal}
            creditChargePerVerification={creditChargePerVerification}
            gettingCreditsUsageForEmailVerification={
              gettingCreditsUsageForEmailVerification
            }
          />
        )
      },
      title: 'Email',
    },
    {
      dataIndex: 'remove',
      key: 'remove',
      render: (val, row) => {
        const candidateName = capitalize(get(row, 'full_name'))

        return (
          <Popconfirm
            title={`Remove ${candidateName} from this Crowd?`}
            onConfirm={() => removeCandidate(get(row, 'id'))}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Remove candidate">
              <Icon type="close-circle" />
            </Tooltip>
          </Popconfirm>
        )
      },
      title: '',
    },
  ]
}

const SavedCandidatesData = ({
  candidateIds,
  availableRequisitions,
  updateRequisitionOnState,
  requisitionId,
  setSelectedCandidate,
  setCandidateDetailsDrawerShown,
  permissions,
  updateUser,
  clientName,
  teDownloadCredits,
}) => {
  const [candidateModalShown, setCandidateModalShown] = useState(false)
  const [selectedCandidates, setSelectedCandidates] = useState([])
  const toggleCandidateModal = () => {
    setCandidateModalShown(!candidateModalShown)
  }
  const {
    currentPage,
    setCurrentPage,
    candidatesData: candidates,
    loadingCandidateDetails,
    chunkSize,
  } = useCandidatesById({ candidateIds })

  const selectedCandidatedIds = selectedCandidates.map(
    candidate => candidate.id,
  )
  const candidateIdsToDownload =
    selectedCandidatedIds.length < 1 ? candidateIds : selectedCandidatedIds

  const {
    triggerDownload: triggerDownloadById,
    loading: requestingDownloadById,
  } = useDownloadFile({
    queryObject: downloadQueryObjects.downloadPersonsDataById,
  })

  const {
    creditChargePerVerification,
    gettingCreditsUsageForEmailVerification,
  } = useGetCreditsUsageForEmailVerification()

  const {
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
    visible: paymentModalVisible,
  } = useModalManagement(false)

  const onCandidateNameClick = async candidate => {
    setSelectedCandidate(candidate)
    setCandidateDetailsDrawerShown(true)
  }

  const removeCandidateFromRequisition = async candidateId => {
    try {
      const response = await apolloClient.mutate({
        mutation: removeCandidateFromRequisitionMutation,
        variables: {
          candidateId: candidateId,
          requisitionId: requisitionId,
        },
      })
      const updatedRequisition = get(
        response,
        'data.removeCandidateFromRequisition',
      )
      updateRequisitionOnState(updatedRequisition)
      feedback.success({ content: 'Candidate removed successfully' })
    } catch (error) {
      errorService.report(error, 'addCandidatesToJobRequisitionMutation')
      feedback.error({
        title: 'Error removing candidate..Please try again',
      })
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCandidates(
        selectedRows.map(candidate => {
          return {
            id: candidate.id,
          }
        }),
      )
    },
  }

  const copyDisabled = selectedCandidates.length > 0 ? false : true

  const downloadFile = async ({ fileName, toggleModal, limited }) => {
    const variables = {
      candidateIds: candidateIdsToDownload,
      fileName,
      limited,
      requisitionId,
    }
    triggerDownloadById({ variables })
    toggleModal()
  }

  return (
    <>
      <AddPaymentModal
        closable={true}
        modalShown={paymentModalVisible}
        closeModal={closePaymentModal}
        title="Payment Update"
        message="Unlock the full power of CrowdSegment!"
        updateUser={updateUser}
        isAdmin={get(permissions, 'isAdmin')}
        clientName={clientName}
        permissions={permissions}
      />
      <CopyCandidatesForm
        visible={candidateModalShown}
        toggleModal={toggleCandidateModal}
        selectedCandidates={selectedCandidates}
        requisitions={availableRequisitions}
        updateRequisitionOnState={updateRequisitionOnState}
      />
      <div style={{ overflowX: 'auto', paddingRight: '10px' }}>
        <div style={{ float: 'right' }}>
          <Tooltip title="Copy Candidates">
            <Icon
              type="copy"
              style={{
                color: colors.primary,
                cursor: copyDisabled ? 'not-allowed' : 'pointer',
                fontSize: 20,
                margin: 12,
                opacity: copyDisabled ? '.5' : '1',
                width: 20,
              }}
              onClick={copyDisabled ? null : () => toggleCandidateModal()}
            />
          </Tooltip>
          {requestingDownloadById ? (
            <Icon
              type="sync"
              spin
              style={{
                color: colors.primary,
                fontSize: 15,
                margin: 12,
              }}
            />
          ) : (
            <CandidateDownload
              downloading={requestingDownloadById}
              downloadFile={downloadFile}
              permissions={permissions}
              openPaymentModal={openPaymentModal}
              totalMatches={candidateIdsToDownload.length}
              teDownloadCredits={teDownloadCredits}
              styleOverride={{ fontSize: 20 }}
              allowSizeAdjustment={false}
              selectedCandidateIds={candidateIdsToDownload}
            />
          )}
        </div>
        <Table
          style={{ clear: 'both' }}
          rowSelection={rowSelection}
          loading={loadingCandidateDetails}
          columns={getColumns({
            creditChargePerVerification,
            gettingCreditsUsageForEmailVerification,
            onCandidateNameClick,
            openPaymentModal,
            permissions,
            removeCandidate: removeCandidateFromRequisition,
          })}
          dataSource={candidates}
          pagination={{
            current: currentPage,
            onChange: setCurrentPage,
            pageSize: chunkSize,
            total: candidateIds.length,
          }}
          rowKey={record => get(record, 'id')}
          locale={{
            emptyText: loadingCandidateDetails ? (
              <CandidateTableLoader chunkSize={chunkSize} />
            ) : (
              <Empty />
            ),
          }}
        />
      </div>
    </>
  )
}

export default SavedCandidatesData
