import React, { useState } from 'react'

import { Icon, Tooltip } from 'antd'

import { colors } from '../../../theme'
import { copyToClipboard } from '../../../utils/general'

export default function EmailDisplay(props) {
  const {
    validatedEmail = null,
    personalEmail = null,
    verificationReason,
    val,
  } = props

  const unverifiedVerificationReason = 'Not Verified'

  const SingleEmail = props => {
    const {
      email,
      verificationReason = unverifiedVerificationReason,
      styles = {},
    } = props
    const [emailCopied, setEmailCopied] = useState(false)

    const unverified = verificationReason === unverifiedVerificationReason

    const copiedToClipBoardDisplay = 'Copied to Clipboard'
    const copyToClipboardDisplay = `Copy to Clipboard (${verificationReason})`

    const tooltipTitle = emailCopied
      ? copiedToClipBoardDisplay
      : copyToClipboardDisplay

    const handleCopyToClipBoard = async () => {
      await copyToClipboard({ value: email })
      setEmailCopied(true)
    }

    const grayedOutStyling = unverified ? { filter: 'grayscale()' } : {}

    return (
      email && (
        <div onClick={handleCopyToClipBoard}>
          <Tooltip title={tooltipTitle} style={styles}>
            <Icon
              type="mail"
              theme="twoTone"
              twoToneColor={colors.green}
              style={{
                display: 'block',
                fontSize: 18,
                ...grayedOutStyling,
              }}
              data-id={val}
            />
            {email}
          </Tooltip>
        </div>
      )
    )
  }

  return (
    <div className="text-center">
      <div style={personalEmail ? { marginBottom: 12 } : {}}>
        <SingleEmail
          email={validatedEmail}
          verificationReason={verificationReason}
        />
      </div>
      <SingleEmail email={personalEmail} />
    </div>
  )
}
