const CryptoJS = require('crypto-js')

// Encrypt the provided string
export const encryptStr = str => {
  const encryptedStr = CryptoJS.AES.encrypt(
    str,
    process.env.REACT_APP_CRYPTO_SECRET_KEY,
  ).toString()
  const encData = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encryptedStr),
  )
  return encData
}

// Decrypt the provided cipher text
export const decryptStr = ciphertext => {
  let decData = CryptoJS.enc.Base64.parse(ciphertext).toString(
    CryptoJS.enc.Utf8,
  )
  let bytes = CryptoJS.AES.decrypt(
    decData,
    process.env.REACT_APP_CRYPTO_SECRET_KEY,
  ).toString(CryptoJS.enc.Utf8)
  return bytes
}
