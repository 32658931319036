export const specialties = [
  'Civil Engineering',
  'Digital Strategy',
  'Robotics',
  'Virtual Reality',
  'Philanthropy',
  'Storytelling',
  'Predictive Analytics',
  'Mobile Application Developmen',
  'Pharmaceuticals',
  'Sustainable Design',
  'LED Lighting',
  'Industrial Design',
  'Construction Law',
  'Masonry',
  'Cryptocurrency',
  'Feasibility Studies',
  'Mining',
  'Beer',
  'Agriculture',
  'Data Management',
  'Renewable Energy',
  'Embroidery',
  'Digital Transformation',
  'Intellectual Property',
  'Reputation Management',
  'Venture Capital',
  'Data Science',
  'Network Security',
  'Real Estate Development',
  'Cyber Security',
  'Litigation',
  'Executive Coaching',
  'Fundraising',
  'Asset Management',
  'Bookkeeping',
  'Publishing',
  'Brand Strategy',
  'AI',
  'Animation',
  'Search Engine Marketing',
  'Fabrication',
  'Public Speaking',
  'Painting',
  'DevOps',
  'Banking',
  'Welding',
  'Cosmetic Dentistry',
  'Web Hosting',
  'Behavioral Health',
  'Supply Chain Management',
]
