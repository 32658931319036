import React from 'react'

import get from 'lodash/fp/get'
import { ThemeProvider } from 'styled-components'

import { theme } from '../../theme'

const getThemeColor = color => get(`theme.colors.${color}`)
const getAntdOverrideProp = value => get(`theme.antdOverrides.${value}`)

export const getAccentColor = getThemeColor('accent')
export const getBackgroundColor = "getThemeColor('background')"
export const getBorderColor = getThemeColor('border')
export const getPrimaryColor = getAntdOverrideProp('primaryColor')
export const getWhiteColor = getThemeColor('white')

const withOverriddenTheme = BaseComponent => props => (
  <ThemeProvider theme={theme}>
    <BaseComponent {...props} />
  </ThemeProvider>
)

export default withOverriddenTheme
