import * as FullStory from '@fullstory/browser'
import get from 'lodash/get'

export const sendFullStoryUserData = userProfile => {
  const clientName = get(userProfile, 'client.name')
  const trialExpired = get(userProfile, 'client.trialExpired')
  const subscriptionNeedsUpdatedPayment = get(
    userProfile,
    'client.subscription.needsUpdatedPayment',
  )
  const subscriptionCanceled = get(userProfile, 'client.subscription.canceled')
  const credits = get(userProfile, 'client.settings.credits')

  const {
    id,
    email,
    firstName,
    lastName,
    isAdmin,
    isSystemAdmin,
    createdAt,
  } = userProfile

  const uid = id
  const displayName = `${firstName} ${lastName}`

  const userVars = {
    clientName_str: clientName,
    createdAt_str: createdAt,
    credits_real: credits,
    displayName,
    email: email,
    isAdmin_bool: isAdmin,
    isSystemAdmin_bool: isSystemAdmin,
    subscriptionCanceled_bool: subscriptionCanceled,
    subscriptionNeedsUpdatedPayment_bool: subscriptionNeedsUpdatedPayment,
    trialExpired_bool: trialExpired,
  }

  if (userProfile) {
    FullStory.identify(uid, userVars)
  }
}
