import React, { useState } from 'react'

import {
  List,
  Collapse,
  Row,
  Col,
  Descriptions,
  Icon,
  Button,
  Card,
  Popconfirm,
  Typography,
  Tooltip,
} from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import remove from 'lodash/remove'
import moment from 'moment'
import PT from 'prop-types'

import colors from '../../../../hocs/colors'
import { useGetSignedUrlForDownloadFile } from '../../../../hooks/data/downloads/useGetSignedUrlForDownloadFile'
import { useGetDownloadsForRequisition } from '../../../../hooks/data/requisitions/useGetDownloadsForRequisition'
import { removeTimestampFromFilename } from '../../../../utils/stringManipulation'
import { DOWNLOAD_STATUSES } from '../../Downloads/components/DownloadDetail'

import EditableSavedSearch from './components/EditableSavedSearch'
import SavedCandidateData from './SavedCandidateData'

const { Panel } = Collapse
const { Item } = Descriptions
const { Paragraph } = Typography
const MAX_LIST_HEIGHT = 333

const RequisitionInfo = props => {
  const requisition = get(props, 'requisition')
  const availableRequisitions = get(props, 'availableRequisitions')
  const permissions = get(props, 'permissions')
  const updateUser = get(props, 'updateUser')
  const teDownloadCredits = get(props, 'teDownloadCredits')
  const clientName = get(props, 'clientName')
  const setSelectedCandidate = get(props, 'setSelectedCandidate', () => {})
  const setCandidateDetailsDrawerShown = get(
    props,
    'setCandidateDetailsDrawerShown',
    () => {},
  )
  const updateRequisitionOnState = get(props, 'updateRequisitionOnState')
  const deleteRequisition = get(props, 'deleteRequisition')
  const name = get(requisition, 'name')
  const createdAt = get(requisition, 'createdAt')

  const savedSearches = get(requisition, 'savedSearches', [])
  const candidateData = get(requisition, 'candidateData', [])
  const savedDownloads = get(requisition, 'savedDownloads', [])

  const [expandedKeys, setExpandedKeys] = useState([])
  const {
    loadingDownloads,
    refreshDownloadList,
  } = useGetDownloadsForRequisition()

  const candidateIds = candidateData.map(o => get(o, 'id', ''))
  const {
    getSignedUrlAndDownloadFile,
    gettingSignedUrl,
  } = useGetSignedUrlForDownloadFile()

  const onPanelClick = requisitionName => {
    const updatedKeys = cloneDeep(expandedKeys)
    if (findIndex(updatedKeys, key => key === name) !== -1) {
      remove(updatedKeys, key => key === name)
    } else {
      updatedKeys.push(requisitionName)
    }
    setExpandedKeys(updatedKeys)
  }

  const panelDescription = (
    <div>
      <div>{savedSearches ? savedSearches.length : 0} Saved Searches</div>
      <div>{candidateData.length} Saved Candidates</div>
      <div>
        Created on{' '}
        {moment(createdAt)
          .local()
          .format('MM-DD-YYYY')}
      </div>
    </div>
  )

  const onConfirmDelete = (e, requisition) => {
    e.stopPropagation()
    deleteRequisition(requisition)
  }

  return (
    <Collapse
      expandIconPosition="right"
      style={{ background: '#ffffff', border: 'none' }}
      className="requisition-collapse"
      activeKey={expandedKeys}
    >
      <Panel
        key={name}
        header={
          <List.Item
            onClick={() => onPanelClick(name)}
            actions={[
              <>
                <Popconfirm
                  title="Are you sure you want to delete this requisition?"
                  onConfirm={e => onConfirmDelete(e, requisition)}
                  onCancel={e => e.stopPropagation()}
                  okText="Yes"
                  cancelText="No"
                  onClick={e => e.stopPropagation()}
                >
                  <Button
                    type="danger"
                    ghost
                    size="small"
                    style={{ marginLeft: 10 }}
                  >
                    <Icon type="delete" /> Delete
                  </Button>
                </Popconfirm>
              </>,
            ]}
          >
            <List.Item.Meta
              className="req-header"
              title={name}
              description={panelDescription}
            />
          </List.Item>
        }
      >
        <Row>
          <Col md={16}>
            <Card title="Saved Searches" type="inner">
              {!savedSearches.length ? (
                <Item>No saved searches</Item>
              ) : (
                <List
                  style={{ maxHeight: MAX_LIST_HEIGHT, overflowY: 'auto' }}
                  dataSource={savedSearches}
                  itemLayout="horizontal"
                  renderItem={search => <EditableSavedSearch search={search} />}
                />
              )}
            </Card>
          </Col>
          <Col md={8}>
            <Card
              title={
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>Crowd Downloads</div>
                  <Tooltip title="Refresh">
                    <Icon
                      onClick={() => refreshDownloadList()}
                      type={loadingDownloads ? 'loading' : 'reload'}
                      style={{
                        color: colors.primary,
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </div>
              }
              type="inner"
            >
              <List style={{ maxHeight: MAX_LIST_HEIGHT, overflowY: 'auto' }}>
                {!savedDownloads.length ? (
                  <List.Item>No Crowd Downloads</List.Item>
                ) : (
                  savedDownloads.map(download => (
                    <List.Item.Meta
                      style={{
                        overflow: 'hidden',
                        padding: 5,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      key={download.fileName}
                      avatar={
                        <Button
                          type="primary"
                          disabled={
                            get(download, 'calculatedStatus') !==
                            DOWNLOAD_STATUSES.READY
                          }
                          loading={gettingSignedUrl}
                          shape="circle"
                          icon="download"
                          onClick={() =>
                            getSignedUrlAndDownloadFile({
                              variables: { downloadId: download.id },
                            })
                          }
                        />
                      }
                      title={
                        <Paragraph style={{ maxWidth: 148 }}>
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {removeTimestampFromFilename(
                              get(download, 'fileName'),
                            )}
                          </div>
                        </Paragraph>
                      }
                    />
                  ))
                )}
              </List>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card title="Saved Candidates" type="inner">
              {!candidateIds.length ? (
                <Item>No saved candidates</Item>
              ) : (
                <div style={{ margin: '0 -24px 0' }}>
                  <SavedCandidateData
                    candidateIds={candidateIds}
                    availableRequisitions={availableRequisitions}
                    updateRequisitionOnState={updateRequisitionOnState}
                    requisitionId={get(requisition, 'id')}
                    setSelectedCandidate={setSelectedCandidate}
                    setCandidateDetailsDrawerShown={
                      setCandidateDetailsDrawerShown
                    }
                    updateUser={updateUser}
                    permissions={permissions}
                    clientName={clientName}
                    teDownloadCredits={teDownloadCredits}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

RequisitionInfo.propTypes = {
  id: PT.string,
  name: PT.string,
}

export default RequisitionInfo
