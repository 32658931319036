import React from 'react'

import { Row, Col, Spin, Typography } from 'antd'
import get from 'lodash/get'

import MoreInfo from '../../../../../../../../../../components/touchables/MoreInfo'

const { Text } = Typography

const TotalCharges = props => {
  const { loadingChargeForSeats, addSeatsCharge } = props
  const total = get(addSeatsCharge, 'total')

  const moreChargesInfo = addSeatsCharge
    ? `
  Charges will be added to your account when team member(s) complete the
  sign up process. The price will be prorated for the remainder of the
  current billing cycle, and then a recurring charge of $${total}
  will be charged to your account.
  `
    : null

  return (
    <Row>
      <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <MoreInfo style={{ marginRight: 5 }} text={moreChargesInfo} />
          <Text strong>Total Additional Charges:</Text>
        </div>
        <Text strong>
          {loadingChargeForSeats || !addSeatsCharge ? (
            <Spin size="small" />
          ) : (
            `$${total}/Mo.`
          )}
        </Text>
      </Col>
    </Row>
  )
}

export default TotalCharges
