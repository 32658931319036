import React from 'react'

import { Select } from 'antd'
import get from 'lodash/get'

const { Option } = Select

const RequisitionSelect = props => {
  const { setSearchTerm, onReqChange, requisitions, searchTerm } = props

  return (
    <Select
      placeholder="Start typing to search or create a new Crowd"
      showSearch
      onSearch={val => {
        setSearchTerm(val)
      }}
      onChange={onReqChange}
      defaultActiveFirstOption={true}
      data-testid="project-selector"
      dropdownRender={menu => <div data-testid="project-dropdown">{menu}</div>}
      filterOption={(inputValue, option) => {
        const name = get(option, 'props.label', '')

        return name.toLowerCase().includes(inputValue.toLowerCase())
      }}
    >
      {requisitions &&
        !!requisitions.length &&
        requisitions.map(req => (
          <Option
            key={req.id}
            id={req.name.replace(/\s+/g, '-').toLowerCase()}
            label={req.name}
          >
            {req.name}
          </Option>
        ))}
      {searchTerm && (
        <Option key={searchTerm} label={searchTerm}>
          Create &quot;{searchTerm}&quot;
        </Option>
      )}
    </Select>
  )
}

export default RequisitionSelect
