import get from 'lodash/get'

import { getUserForAddPaymentQuery } from '../../../apolloClient/operations/userData/addPaymentUserProfileQuery'

const { useQuery } = require('@apollo/client')

export const useUserForUpdatePayment = () => {
  const { data, loading } = useQuery(getUserForAddPaymentQuery)
  const userData = get(data, 'getUserProfile')

  return { loadingUserProfile: loading, userData }
}
