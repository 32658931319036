import React from 'react'

import { Button, Icon, List, Tooltip, Typography } from 'antd'
import get from 'lodash/get'
import { Link } from 'react-router-dom'

import colors from '../../../../hocs/colors'
import {
  createQueryStringForTalentSearch,
  pickWithInitialState,
} from '../../../../utils/data/utils'
import numberConverter from '../../../../utils/numberConverter'
import { AUTHENTICATED_ROUTES } from '../../../../utils/routing/routes'
import {
  capitalize,
  removeTimestampFromFilename,
} from '../../../../utils/stringManipulation'
const { Paragraph } = Typography

export const DOWNLOAD_STATUSES = {
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  READY: 'READY',
}

const DownloadDetail = props => {
  const { download, getSignedUrlAndDownloadFile, downloading } = props
  const {
    createdBy,
    calculatedStatus,
    recordSize,
    fileName,
    id,
  } = pickWithInitialState(download, [
    ['createdBy'],
    ['calculatedStatus', DOWNLOAD_STATUSES.PENDING],
    ['recordSize', 0],
    ['fileName', ''],
    ['id', ''],
  ])

  const createdByLabel = createdBy
    ? `Created by: ${get(createdBy, 'firstName')} ${get(
        createdBy,
        'lastName',
        '',
      )} | `
    : ''

  return (
    <List.Item key={fileName}>
      <List.Item.Meta
        avatar={
          <Button
            type="primary"
            disabled={calculatedStatus !== DOWNLOAD_STATUSES.READY}
            loading={downloading}
            shape="circle"
            icon="download"
            onClick={() =>
              getSignedUrlAndDownloadFile({ variables: { downloadId: id } })
            }
          />
        }
        title={
          <Paragraph>
            {removeTimestampFromFilename(fileName)}
            {get(download, 'searchParams') && (
              <Tooltip title="Perform this search">
                <Link
                  to={`${
                    AUTHENTICATED_ROUTES.CROWD_SEGMENT
                  }/${createQueryStringForTalentSearch(
                    get(download, 'searchParams'),
                  )}`}
                >
                  <Icon
                    type="search"
                    style={{
                      color: colors.primary,
                      cursor: 'pointer',
                      fontSize: 16,
                      marginLeft: 20,
                    }}
                  />
                </Link>
              </Tooltip>
            )}
          </Paragraph>
        }
        description={`${createdByLabel}Created On: ${get(
          download,
          'createdAt',
          '',
        )}`}
      />
      <div
        style={{
          marginTop: 5,
          textAlign: 'center',
          width: 50,
        }}
      >
        <Tooltip title="Records size">
          <Icon type="user" theme="outlined" />
        </Tooltip>
        <div
          className="text-muted"
          style={{
            fontSize: 14,
          }}
        >
          {numberConverter(recordSize)}
        </div>
      </div>
      <div
        style={{
          marginTop: 5,
          textAlign: 'center',
          width: 100,
        }}
      >
        {calculatedStatus === DOWNLOAD_STATUSES.READY && (
          <Icon
            type="check-circle"
            theme="twoTone"
            twoToneColor="#52c41a"
            style={{ fontSize: 18 }}
          />
        )}
        {calculatedStatus === DOWNLOAD_STATUSES.PENDING && (
          <Icon
            type="sync"
            spin
            style={{ color: colors.primary, fontSize: 18 }}
          />
        )}
        {calculatedStatus === DOWNLOAD_STATUSES.FAILED && (
          <Icon
            type="close-circle"
            style={{ color: '#fa907d', fontSize: 18 }}
          />
        )}
        <div
          className="text-muted"
          style={{
            fontSize: 14,
          }}
        >
          {capitalize(
            calculatedStatus === DOWNLOAD_STATUSES.PENDING
              ? 'Validating Emails'
              : calculatedStatus,
          )}
        </div>
      </div>
    </List.Item>
  )
}

export default DownloadDetail
