import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SingleValueDonut = ({ title, containerProps, data, height = 175 }) => {
  const options = {
    chart: {
      height: height,
      plotBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: undefined,
      y: ''
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.key + ': ' + this.y + '%'
          }
        },
        showInLegend: false,
        size: 150
      }
    },
    series: [
      {
        name: 'Composition',
        dataLabels: {
          enabled: false
        },
        colorByPoint: true,
        innerSize: '92%',
        data: data
      }
    ],
    tooltip: {
      formatter: function() {
        return false
      }
    },
    credits: {
      enabled: false // this is to not show highcharts.com text
    }
  }

  return (
    <div>
      <div className="text-center" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        {title}
      </div>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default SingleValueDonut
