import React from 'react'

import { Route, Switch } from 'react-router-dom'

import withAsyncLoading from '../../hocs/withAsyncLoading'
import {
  AUTHENTICATED_ROUTES,
  UNAUTHENTICATED_ROUTES,
} from '../../utils/routing/routes'
import { loadWithDelay } from '../../utils/routing/routes/load'
import PublicSearchPage from '../Unauthenticated/PublicSearch'

import AddPayment from './components/AddPayment/AddPayment'
import AuthenticatedLayout from './components/AuthenticatedLayout'
import Resubscribe from './components/Resubscribe/Resubscribe'
import UpdatePayment from './components/UpdatePayment'
import ManageTeam from './ManageTeam'
import MyAccount from './MyAccount'
import Requisitions from './Requisitions'

const Downloads = React.lazy(loadWithDelay(import('./Downloads'), 500))
const CrowdSegment = React.lazy(loadWithDelay(import('./CrowdSegment'), 500))
const ClientDetails = React.lazy(loadWithDelay(import('./ClientDetails'), 500))

const AuthenticatedPublicSearch = () => (
  <AuthenticatedLayout>
    <PublicSearchPage />
  </AuthenticatedLayout>
)

const Authenticated = () => {
  return (
    <Switch>
      <Route
        path={AUTHENTICATED_ROUTES.MANAGE_TEAM}
        component={withAsyncLoading(ManageTeam)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.MY_ACCOUNT}
        component={withAsyncLoading(MyAccount)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.REQUISITIONS}
        component={withAsyncLoading(Requisitions)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.DOWNLOADS}
        component={withAsyncLoading(Downloads)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.UPDATE_PAYMENT}
        component={withAsyncLoading(UpdatePayment)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.RESUBSCRIBE}
        component={withAsyncLoading(Resubscribe)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.ADD_PAYMENT}
        component={withAsyncLoading(AddPayment)}
      />
      <Route
        path={AUTHENTICATED_ROUTES.CLIENT_DETAILS}
        component={withAsyncLoading(ClientDetails)}
      />
      <Route
        path={UNAUTHENTICATED_ROUTES.PUBLIC_SEARCH}
        component={AuthenticatedPublicSearch}
        exact
      />
      <Route render={withAsyncLoading(CrowdSegment)} />
    </Switch>
  )
}

export default Authenticated
