import React from 'react'

import { Icon, Layout } from 'antd'

import useModalManagement from '../../../../hooks/common/useModalManagement'

import AuthenticatedHeader from './components/AuthenticatedHeader'
import FeedbackForm from './components/FeedbackForm'
import PageTitle from './components/PageTitle'

const { Content, Sider } = Layout

const AuthenticatedLayout = props => {
  const feedbackModalProps = useModalManagement(false)
  const { siderContent = false, borderBottom = true } = props
  return (
    <Layout>
      <AuthenticatedHeader borderBottom={borderBottom} />
      {!!siderContent && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={350}
          className="search-sider"
        >
          {siderContent}
        </Sider>
      )}
      <Content className={props.gutters ? 'gutters' : ''}>
        {props.title && <PageTitle title={props.title} />}
        {props.children}
      </Content>
      <div className="feedback-btn-container">
        <FeedbackForm modalProps={feedbackModalProps} />
        <button className="feedback-btn" onClick={feedbackModalProps.openModal}>
          <Icon type="message" theme="outlined" style={{ fontSize: 30 }} />
        </button>
      </div>
    </Layout>
  )
}

export default AuthenticatedLayout
