import React from 'react'
import { Col, Row, Spin, Typography } from 'antd'
const { Text } = Typography

const ShowCharge = ({ amount, chargeText, loading }) => {
  return (
    <Row style={{ margin: 0, padding: 0 }}>
      <Col span={18}>
        <Text strong>{`${chargeText} :`}</Text>
      </Col>
      <Col span={6} style={{ textAlign: 'end' }}>
        {loading ? <Spin size="small" /> : <Text strong>{`$${amount}`}</Text>}
      </Col>
    </Row>
  )
}

export default ShowCharge
