import React, { useState } from 'react'

import { Modal, Button, Select, Row, Form } from 'antd'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import remove from 'lodash/remove'

import { apolloClient } from '../../../../apolloClient'
import { addCandidatesToJobRequisitionMutation } from '../../../../apolloClient/operations/requisitions/addCandidatesToJobRequisitionMutation'
import errorService from '../../../../utils/analytics/error'
import feedback from '../../../../utils/feedback'

const { Option } = Select

const CopyCandidatesForm = props => {
  const visible = get(props, 'visible')
  const toggleModal = get(props, 'toggleModal')
  const requisitions = get(props, 'requisitions')
  const selectedCandidates = get(props, 'selectedCandidates')
  const [selectedRequisitionId, setSelectedRequistionId] = useState(null)

  const filterCandidates = () => {
    const selectedRequisition = requisitions.find(
      req => req.id === selectedRequisitionId,
    )
    const candidateData = get(selectedRequisition, 'candidateData')
    const filteredCandidates = remove(
      selectedCandidates,
      candidate =>
        findIndex(candidateData, cand => {
          return cand.id === candidate.id
        }) === -1,
    )

    return filteredCandidates
  }

  const onReqChange = value => {
    setSelectedRequistionId(value)
  }

  const saveCandidates = async () => {
    try {
      await apolloClient.mutate({
        mutation: addCandidatesToJobRequisitionMutation,
        variables: {
          candidatesToAdd: filterCandidates(),
          requisitionId: selectedRequisitionId,
        },
      })

      feedback.success({ content: 'Candidates copied successfully' })
    } catch (error) {
      errorService.report(error, 'addCandidatesToJobRequisitionMutation')
      feedback.error({
        title: 'Error copying candidates..Please try again',
      })
    } finally {
      toggleModal()
    }
  }

  const onSubmit = () => {
    saveCandidates()
  }

  const formItemLayout = {
    labelCol: {
      md: { span: 4 },
      sm: { span: 8 },
      xs: { span: 24 },
    },
    wrapperCol: {
      md: { span: 20 },
      sm: { span: 16 },
      xs: { span: 24 },
    },
  }

  return (
    <>
      <Modal
        visible={visible}
        destroyOnClose
        footer={null}
        onCancel={toggleModal}
        width={'50%'}
      >
        <Row style={{ fontSize: '20px' }}>Copy Candidates</Row>
        <Form>
          <Form.Item label="Crowd" {...formItemLayout}>
            <Select onChange={onReqChange} defaultActiveFirstOption={true}>
              {requisitions &&
                !!requisitions.length &&
                requisitions.map(req => (
                  <Option key={req.id} value={req.id}>
                    {req.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={onSubmit}
              disabled={!selectedRequisitionId}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CopyCandidatesForm
