import { useState } from 'react'

import get from 'lodash/get'

export const useFormState = initialState => {
  const [formValues, setFormValues] = useState(initialState)

  const handleChange = e => {
    const name = get(e, 'target.id')
    const val = get(e, 'target.value')

    setFormValues({
      ...formValues,
      [name]: val,
    })
  }

  const updateValue = updateObj => {
    const newValues = {
      ...formValues,
      ...updateObj,
    }

    setFormValues(newValues)
  }

  return { formValues, handleChange, updateValue }
}
