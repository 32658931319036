import React from 'react'

import { BUILDING_ICON_URL } from '../../utils/applicationConstants'
import { createCompanyLogoUrl } from '../../utils/routing/logos'

const CompanyLogo = props => {
  const { id, size } = props

  const addDefaultSrc = e => {
    e.target.src = BUILDING_ICON_URL
  }

  const companyLogoUrl = createCompanyLogoUrl(id)

  return (
    <div className="company-logo-container" style={{ float: 'left' }}>
      <img
        src={companyLogoUrl}
        alt="company logo"
        className="company-logo"
        style={{ height: size, width: size }}
        onError={addDefaultSrc}
      />
    </div>
  )
}

export default CompanyLogo
