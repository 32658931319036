import React from 'react'

import { Row, Col, Spin } from 'antd'
import get from 'lodash/get'
import keys from 'lodash/keys'

import { getUserForCreditsTabQuery } from '../../../../../../../apolloClient/operations/userData/getUserForCreditsTabQuery'
import { useDynamicUser } from '../../../../../../../hooks/data/loggedInUser/useDynamicUser'
import useGetCreditPrices from '../../../../../../../hooks/data/payments/useGetPricesForAdditionalCredits/useGetPricesForAdditionalCredits'
import numberConverter from '../../../../../../../utils/numberConverter'

import BuyCreditCard from './components/BuyCreditCard'

const BuyCredits = () => {
  const { userProfile, gettingUserProfile } = useDynamicUser({
    userQuery: getUserForCreditsTabQuery,
  })
  const credits = get(userProfile, 'client.settings.credits')
  const { loading, prices } = useGetCreditPrices()
  const priceCards = loading
    ? null
    : keys(prices).map(credits => {
        const cost = prices[credits]
        return (
          <Col md={12} key={credits}>
            <BuyCreditCard price={cost} credits={parseInt(credits)} />
          </Col>
        )
      })

  return (
    <>
      <div
        style={{
          color: 'rgba(0, 0, 0, .85)',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
        }}
      >
        <div style={{ paddingRight: '50px' }}>
          CrowdSegment uses download credits to retrieve profile data and email
          addresses. Users are charged 0.5 credits per record for profile data
          only downloads. Users are charged 1 credit per record to retrieve
          profile data and available email addresses via downloads. In-app email
          retrieval (email button), costs 1 credit per successful response.
        </div>
        <div style={{ fontWeight: 'bold' }}>
          Credits:{' '}
          {gettingUserProfile ? (
            <Spin size="small" />
          ) : (
            numberConverter(credits, 1)
          )}
        </div>
      </div>
      <Row>{priceCards}</Row>
    </>
  )
}

export default BuyCredits
