const { default: gql } = require('graphql-tag')

export const createUserInvitationsMutation = gql`
  mutation CreateUserInvitationsMutation($emails: [EmailAddress]!) {
    createUserInvitations(emails: $emails) {
      id
      createdAt
      email
    }
  }
`
