import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { MY_ACCOUNT_ROUTES } from '../../../../../utils/routing/routes'

import BasicInfo from './components/BasicInfo'
import BuyCredits from './components/BuyCredits'
import ChangePassword from './components/ChangePassword'
import CustomBranding from './components/CustomBranding'
import Subscription from './components/Subscription'

const MyAccountNavigator = () => {
  return (
    <Switch>
      <Route
        path={MY_ACCOUNT_ROUTES.CHANGE_PASSWORD}
        component={ChangePassword}
      />
      <Route path={MY_ACCOUNT_ROUTES.SUBSCRIPTION} component={Subscription} />
      <Route path={MY_ACCOUNT_ROUTES.BUY_CREDITS} component={BuyCredits} />
      <Route
        path={MY_ACCOUNT_ROUTES.CUSTOM_BRANDING}
        component={CustomBranding}
      />
      <Route path={MY_ACCOUNT_ROUTES.BASIC_INFO} component={BasicInfo} />
    </Switch>
  )
}

export default MyAccountNavigator
