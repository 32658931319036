import React from 'react'

import { Tooltip } from 'antd'

/**
 *
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @param {boolean} props.tooltipPresent
 * @param {string | JSX.Element} props.title
 * @returns {React.ReactElement}
 */
export default function OptionalTooltip(props) {
  const { tooltipPresent = false, children, ...rest } = props

  return tooltipPresent ? <Tooltip {...rest}>{children}</Tooltip> : children
}
