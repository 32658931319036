import React from 'react'

import { AmplifyAuthenticator } from '@aws-amplify/ui-react'
import { Route, Switch } from 'react-router-dom'

import { UNAUTHENTICATED_ROUTES } from '../../utils/routing/routes'

import Authenticate from './Authenticate'
import CenteredAuthenticatedLayout from './components/CenteredAuthenticatedLayout'
import ConfirmSignup from './ConfirmSignup/ConfirmSignup'
import ForgotPassword from './ForgotPassword'
import NewPassword from './NewPassword/NewPassword'
import Signup from './Signup'
import SignupFromInvitation from './SignupFromInvitation/SignupFromInvitation'

const Unauthenticated = () => {
  return (
    <CenteredAuthenticatedLayout>
      <Switch>
        <Route
          render={() => <Authenticate />}
          path={UNAUTHENTICATED_ROUTES.AUTHENTICATE}
        />
        <Route
          render={() => <Signup />}
          path={UNAUTHENTICATED_ROUTES.CS_SIGNUP}
        />
        <Route
          render={() => <ForgotPassword />}
          path={UNAUTHENTICATED_ROUTES.FORGOT_PASSWORD}
        />
        <Route
          render={() => <NewPassword />}
          path={UNAUTHENTICATED_ROUTES.NEW_PASSWORD}
        />
        <Route
          render={() => <ConfirmSignup />}
          path={UNAUTHENTICATED_ROUTES.CONFIRM_SIGNUP}
        />
        <Route
          render={() => <SignupFromInvitation />}
          path={UNAUTHENTICATED_ROUTES.SIGNUP_FROM_INVITATION}
        />
        <Route render={() => <Authenticate />} />
      </Switch>
      <AmplifyAuthenticator hidden />
    </CenteredAuthenticatedLayout>
  )
}

export default Unauthenticated
