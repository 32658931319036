import React from 'react'

import { Skeleton } from 'antd'
import range from 'lodash/range'

const CandidateTableLoader = props => {
  const { chunkSize } = props

  return (
    <>
      {range(chunkSize).map(num => (
        <Skeleton key={num} active={true} paragraph={{ rows: 1 }} />
      ))}
    </>
  )
}

export default CandidateTableLoader
