import gql from 'graphql-tag'

export const degreeLevelAutocompleteSuggestionsQuery = gql`
  query DegreeLevelAutocompleteSuggestions($prefix: String!) {
    degreeLevelAutocompleteSuggestions(prefix: $prefix)
  }
`

export const jobCategoryAutocompleteSuggestionsQuery = gql`
  query JobCategoryAutocompleteSuggestions($prefix: String!) {
    jobCategoryAutocompleteSuggestions(prefix: $prefix)
  }
`

export const requiredSkillAutocompleteSuggestionsQuery = gql`
  query requiredSkillAutocompleteSuggestions($prefix: String!) {
    requiredSkillAutocompleteSuggestions(prefix: $prefix)
  }
`

export const jobTitleAutocompleteSuggestionsQuery = gql`
  query JobTitleAutocompleteSuggestions($prefix: String!) {
    jobTitleAutocompleteSuggestions(prefix: $prefix)
  }
`

export const companyNameAutocompleteSuggestionsQuery = gql`
  query CompanyNameAutocompleteSuggestions($prefix: String!) {
    companyNameAutocompleteSuggestions(prefix: $prefix)
  }
`

export const locationAutocompleteSuggestionsQuery = gql`
  query LocationAutocompleteSuggestions($prefix: String!) {
    locationAutocompleteSuggestions(prefix: $prefix)
  }
`

export const keywordAutocompleteSuggestionsQuery = gql`
  query KeywordAutocompleteSuggestions($prefix: String!) {
    keywordAutocompleteSuggestions(prefix: $prefix)
  }
`

export const industryAutocompleteSuggestionsQuery = gql`
  query IndustryAutocompleteSuggestions($prefix: String!) {
    industryAutocompleteSuggestions(prefix: $prefix)
  }
`

export const majorAutocompleteSuggestionsQuery = gql`
  query MajorAutocompleteSuggestions($prefix: String!) {
    majorAutocompleteSuggestions(prefix: $prefix)
  }
`

export const schoolAutocompleteSuggestionsQuery = gql`
  query SchoolAutocompleteSuggestions($prefix: String!) {
    schoolAutocompleteSuggestions(prefix: $prefix)
  }
`

export const certificationsAutocompleteSuggestionsQuery = gql`
  query CertificationsAutocompleteSuggestions($prefix: String!) {
    certificationsAutocompleteSuggestions(prefix: $prefix)
  }
`

export const companyTypeAutocompleteSuggestionsQuery = gql`
  query CompanyTypeAutocompleteSuggestions($prefix: String!) {
    companyTypeAutocompleteSuggestions(prefix: $prefix)
  }
`

export const specialtiesAutocompleteSuggestionsQuery = gql`
  query SpecialtiesAutocompleteSuggestions($prefix: String!) {
    specialtiesAutocompleteSuggestions(prefix: $prefix)
  }
`
