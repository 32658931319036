import React from 'react'

import { Button, Tooltip } from 'antd'
import get from 'lodash/get'
import keys from 'lodash/keys'

import { getUserForAuthenticatedCheckQuery } from '../../../../../apolloClient/operations/userData/getUserForAuthenticatedCheckQuery'
import { useDynamicUser } from '../../../../../hooks/data/loggedInUser/useDynamicUser'
import { removeFalsey } from '../../../../../utils/data/utils'
import {
  useCrowdSegmentDispatch,
  useCrowdSegmentState,
} from '../../crowd-context'
import SaveSearchForm from '../SaveSearchForm'

const SaveSearch = props => {
  const { selectedFilters } = props

  const {
    setPublicSearchCTAVisible,
    setSaveSearchFormVisible,
  } = useCrowdSegmentDispatch()
  const { saveSearchFormVisible } = useCrowdSegmentState()
  const { userProfile } = useDynamicUser({
    userQuery: getUserForAuthenticatedCheckQuery,
  })
  const toggleSearchForm = () => {
    if (get(userProfile, 'id')) {
      setSaveSearchFormVisible(!saveSearchFormVisible)
    } else {
      setPublicSearchCTAVisible(true)
    }
  }

  const noFiltersSelected = !keys(removeFalsey(selectedFilters)).length

  return (
    <>
      <SaveSearchForm
        toggleModal={() => toggleSearchForm()}
        visible={saveSearchFormVisible}
        filtersToSave={selectedFilters}
        noFiltersSelected={noFiltersSelected}
      />
      <Tooltip title="Save this Crowd">
        <Button
          data-testid="save-search-button"
          ghost
          icon="save"
          type="primary"
          onClick={toggleSearchForm}
          disabled={noFiltersSelected}
          style={{ margin: '0 10px' }}
        />
      </Tooltip>
    </>
  )
}

export default SaveSearch
