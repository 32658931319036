import React from 'react'
import { Empty, Tooltip } from 'antd'
import get from 'lodash/get'

const NotFoundColumn = props => {
  const centered = get(props, 'centered')
  return (
    <Tooltip title="Not Found">
      <Empty
        description=" "
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ width: centered ? '100%' : 'fit-content', margin: 0 }}
        imageStyle={{ height: '28px' }}
      />
    </Tooltip>
  )
}

export default NotFoundColumn
