import React from 'react'

import PT from 'prop-types'
import CountUp from 'react-countup'

const StatCountUp = ({
  data,
  prefix,
  style,
  suffix,
  title,
  comment,
  textString,
  fontSize = 50,
  weight,
  color = 'inherit',
  decimals = 0,
}) => (
  <div style={style}>
    {!textString ? (
      <CountUp
        prefix={prefix}
        separator=","
        start={0}
        end={data}
        style={{
          color: color,
          fontSize: fontSize,
          fontWeight: weight,
          lineHeight: 1.2,
        }}
        suffix={suffix}
        decimals={decimals}
      />
    ) : (
      <div
        style={{
          color: color,
          fontSize: fontSize,
          fontWeight: weight,
          lineHeight: 1.2,
        }}
      >{`${textString}`}</div>
    )}

    <div
      style={{
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: 14,
        marginBottom: 3,
      }}
    >
      {title}
    </div>
    <div>{comment}</div>
  </div>
)

StatCountUp.propTypes = {
  data: PT.number,
  prefix: PT.string,
  style: PT.object,
  title: PT.string,
}

export default StatCountUp
