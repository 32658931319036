import React from 'react'

import { Icon, Input } from 'antd'
import get from 'lodash/get'

const { Password } = Input

const SingleLineTextInput = React.forwardRef((props, ref) => {
  const extraProps = {}
  const inputType = get(props, 'inputType', '')

  if (get(props, 'icon')) {
    extraProps.prefix = (
      <Icon
        type={get(props, 'icon')}
        style={{ color: 'rgba(0, 0, 0, 0.25)' }}
      />
    )
  }

  if (inputType === 'password')
    return <Password ref={ref} {...props} {...extraProps} {...props.input} />

  return <Input ref={ref} {...props} {...extraProps} {...props.input} />
})

export default SingleLineTextInput
