import get from 'lodash/get'

const { useLazyQuery } = require('@apollo/client')

const {
  getDownloadsForJobRequisitionQuery,
} = require('../../../apolloClient/operations/requisitions/getDownloadsForJobRequisitionQuery')

export const useGetDownloadsForRequisition = () => {
  const [refreshDownloadList, { data, loading, error }] = useLazyQuery(
    getDownloadsForJobRequisitionQuery,
    {
      fetchPolicy: 'network-only',
    },
  )
  const downloads = get(
    data,
    'getDownloadsForJobRequisition.client.jobRequisitions.savedDownloads',
    [],
  )
  return { downloads, error, loadingDownloads: loading, refreshDownloadList }
}
