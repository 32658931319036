import React from 'react'
import SingleValueDonut from '../charts/SingleValueDonut'
import StatCountUpTE from '../../../../Authenticated/components/AuthenticatedLayout/components/ReportElements/StatCountUpTE'

const SingleValueCircle = props => {
  const { label, value, color, isMobile } = props

  const restOfPercent = 100 - value

  const data = [
    // [`%{label}`, value],
    {
      color,
      y: value
    },
    {
      color: '#F8F8F8',
      name: '',
      y: restOfPercent
    }
  ]

  return (
    <>
      <div style={{ position: 'relative', width: '100%', margin: '0 auto' }}>
        {!isMobile && <SingleValueDonut data={data} />}
        <StatCountUpTE
          style={{
            textAlign: 'center',
            marginTop: isMobile ? 0 : -114
          }}
          data={value}
          weight={500}
          title={label}
          suffix="%"
          fontSize={30}
        />
      </div>
    </>
  )
}

export default SingleValueCircle
