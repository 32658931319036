import React from 'react'

import { ApolloProvider } from '@apollo/client'

import { apolloClient } from '../../apolloClient'

const withGraphQL = BaseComponent => props => (
  <ApolloProvider client={apolloClient}>
    <BaseComponent {...props} />
  </ApolloProvider>
)

export default withGraphQL
