import gql from 'graphql-tag'

export const companiesDataQuery = gql`
  query CompaniesData($filters: JSON!) {
    companiesData(filters: $filters)
  }
`

export const countQuery = gql`
  query Count($filters: JSON!) {
    count(filters: $filters)
  }
`

export const companyDetailsQuery = gql`
  query CompanyDetails($filters: JSON!) {
    companyDetails(filters: $filters)
  }
`

export const analyticsDataQuery = gql`
  query AnalyticsData($filters: JSON!) {
    analyticsData(filters: $filters)
  }
`

export const candidatesDataQuery = gql`
  query CandidatesData(
    $filters: JSON!
    $download: Boolean
    $candidate_size: Int
  ) {
    candidatesData(
      filters: $filters
      download: $download
      candidate_size: $candidate_size
    )
  }
`
