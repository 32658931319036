import React from 'react'

import get from 'lodash/get'

import { capitalize } from '../../utils/stringManipulation'

import NotFoundColumn from './NotFoundColumn'

const PersonEducationColumn = props => {
  const val = get(props, 'val')
  const majorCategory = get(val, 'major_category')
  const university = get(val, 'university')
  const degreeLevel = get(val, 'degree_level')

  return majorCategory || university || degreeLevel ? (
    <div style={{ maxWidth: 260 }}>
      <div>
        {get(val, 'degree_level') ? capitalize(val.degree_level) : ''}{' '}
        {get(val, 'major_category')
          ? `${get(val, 'degree_level') ? ' in ' : ''} ${capitalize(
              val.major_category,
            )}`
          : ''}
      </div>
      <div className="text-muted">
        {val.university ? `@${capitalize(get(val, 'university', ''))}` : ''}
      </div>
    </div>
  ) : (
    <NotFoundColumn centered={false} />
  )
}

export default PersonEducationColumn
