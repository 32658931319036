import gql from 'graphql-tag'

export const getClientCustomBrandingQuery = gql`
  query getCustomBrandingQuery($subdomain: String) {
    getClientCustomBranding(subdomain: $subdomain) {
      id
      buttonDescription
      buttonLink
      buttonText
      disclaimer
      subheader
      header
    }
  }
`

export const editClientCustomBrandingMutation = gql`
  mutation editClientCustomBranding(
    $id: ID!
    $buttonDescription: String
    $buttonLink: String
    $buttonText: String
    $disclaimer: String
    $subheader: String
    $header: String
  ) {
    editClientCustomBranding(
      id: $id
      buttonDescription: $buttonDescription
      buttonLink: $buttonLink
      buttonText: $buttonText
      disclaimer: $disclaimer
      subheader: $subheader
      header: $header
    ) {
      id
      buttonDescription
      buttonLink
      buttonText
      disclaimer
      subheader
      header
    }
  }
`

export const createClientCustomBrandingMutation = gql`
  mutation createClientCustomBrandingMutation(
    $buttonDescription: String
    $subdomain: String!
    $buttonLink: String
    $buttonText: String
    $disclaimer: String
    $subheader: String
    $header: String!
  ) {
    createClientCustomBranding(
      subdomain: $subdomain
      buttonDescription: $buttonDescription
      buttonLink: $buttonLink
      buttonText: $buttonText
      disclaimer: $disclaimer
      subheader: $subheader
      header: $header
    ) {
      id
      buttonDescription
      buttonLink
      buttonText
      disclaimer
      subheader
      header
    }
  }
`
