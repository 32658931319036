import React from 'react'

import { Row, Result, Col, Tabs, Tooltip } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { slice } from 'lodash'

import { pickWithInitialState } from '../../../../utils/data/utils'
import StatCountUpTE from '../../../Authenticated/components/AuthenticatedLayout/components/ReportElements/StatCountUpTE'
import { searchSettings } from '../static/searchSettings'

import BarFilters from './BarFilters'
import DistributionChart from './charts/DistributionChart'
import DistributionSalary from './charts/DistributionSalary'

const { TabPane } = Tabs

const EmploymentTab = ({
  employmentData,
  error,
  toggleFilter,
  isMobile,
  filtersToApply,
}) => {
  const {
    c_current_position,
    c_previous_position,
    c_current_company,
    c_previous_company,
    hc_experience_distribution,
    avg_salary,
    c_current_industry,
    c_previous_industry,
    hc_salary_distribution,
  } = pickWithInitialState(employmentData, [
    ['c_current_position', {}],
    ['c_previous_position', {}],
    ['c_current_company', {}],
    ['c_current_industry', {}],
    ['c_previous_company', {}],
    ['c_previous_industry', {}],
    ['avg_salary', 0],
    ['avg_years_to_position', 0],
    ['hc_experience_distribution', {}],
    ['hc_salary_distribution', {}],
  ])

  const handleCurrentPosClick = () => {}

  const toggleTitleFilter = value => {
    toggleFilter(searchSettings.job_titles.name, value)
  }

  const toggleCompaniesFilter = value => {
    toggleFilter(searchSettings.companies.name, value)
  }

  const toggleIndustriesFilter = value => {
    toggleFilter(searchSettings.industries.name, value)
  }

  const selectedCompanies = filtersToApply[searchSettings.companies.name]
  const selectedTitles = filtersToApply[searchSettings.job_titles.name]
  const selectedIndustries = filtersToApply[searchSettings.industries.name]

  if (error) {
    return (
      <Result
        status="warning"
        title="Error Encountered"
        subTitle="Check back later, or try another search. "
      />
    )
  }

  return (
    <div className="te-tab-container">
      <Row style={{ marginBottom: 15 }}>
        <Col md={8}>
          <div
            className="te-card-content"
            style={!isMobile ? { height: 270 } : {}}
          >
            <Meta title="Salary" />
            <Tooltip
              placement="bottom"
              mouseEnterDelay={2}
              title="Logarithmic Scale"
            >
              <StatCountUpTE
                style={{ textAlign: 'center' }}
                data={avg_salary}
                prefix="$"
                title="Average Salary"
              />
              <DistributionSalary
                // TODO: Update data source with new eCarmen data
                data={hc_salary_distribution}
                height={140}
              />
            </Tooltip>
          </div>
        </Col>
        <Col md={16}>
          <div
            className="te-card-content"
            style={!isMobile ? { height: 270 } : {}}
          >
            <Meta title="Years of Experience" />
            <Row style={{ marginBottom: 0 }}>
              <Col span={24}>
                <div style={{ height: 50 }} />
                <DistributionChart
                  data={hc_experience_distribution}
                  height={isMobile ? 100 : 160}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        {isMobile ? (
          <Col>
            <div className="te-card-content">
              <BarFilters
                data={slice(c_current_position, 0, 6)}
                onBarClick={toggleTitleFilter}
                title="Current Titles"
                onClearFilter={handleCurrentPosClick}
                maxHeight={450}
                selectedFilters={selectedTitles}
              />
              <BarFilters
                data={c_previous_position}
                onBarClick={toggleTitleFilter}
                title="Previous Title"
                onClearFilter={handleCurrentPosClick}
                maxHeight={450}
                selectedFilters={selectedTitles}
              />
            </div>
          </Col>
        ) : (
          <Col>
            <div className="te-card-content" style={{ paddingTop: 10 }}>
              <Tabs defaultActiveKey="1" className="internal-tabs">
                <TabPane tab="Current Position" key="1">
                  <Row>
                    <Col span={8}>
                      <BarFilters
                        data={c_current_position}
                        onBarClick={toggleTitleFilter}
                        title="Current Title"
                        onClearFilter={handleCurrentPosClick}
                        maxHeight={450}
                        selectedFilters={selectedTitles}
                      />
                    </Col>
                    <Col span={8}>
                      <BarFilters
                        data={c_current_company}
                        onBarClick={toggleCompaniesFilter}
                        title="Current Company"
                        onClearFilter={handleCurrentPosClick}
                        maxHeight={450}
                        selectedFilters={selectedCompanies}
                      />
                    </Col>
                    <Col span={8}>
                      <BarFilters
                        data={c_current_industry}
                        onBarClick={toggleIndustriesFilter}
                        title="Current Industry"
                        onClearFilter={handleCurrentPosClick}
                        maxHeight={450}
                        selectedFilters={selectedIndustries}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Recruited From" key="2">
                  <Row>
                    <Col span={8}>
                      <BarFilters
                        data={c_previous_position}
                        onBarClick={toggleTitleFilter}
                        title="Previous Title"
                        onClearFilter={handleCurrentPosClick}
                        maxHeight={450}
                        selectedFilters={selectedTitles}
                      />
                    </Col>
                    <Col span={8}>
                      <BarFilters
                        data={c_previous_company}
                        onBarClick={toggleCompaniesFilter}
                        title="Previous Company"
                        onClearFilter={handleCurrentPosClick}
                        maxHeight={450}
                        selectedFilters={selectedCompanies}
                      />
                    </Col>
                    <Col span={8}>
                      <BarFilters
                        data={c_previous_industry}
                        onBarClick={toggleIndustriesFilter}
                        title="Previous Industry"
                        onClearFilter={handleCurrentPosClick}
                        maxHeight={450}
                        selectedFilters={selectedIndustries}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default EmploymentTab
