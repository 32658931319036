import React from 'react'

import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

import CenteredCard from '../../../../components/layout/CenteredCard/CenteredCard'
import AddPaymentForm from '../../../../components/payment/AddPaymentForm'
import { useUserForAddPayment } from '../../../../hooks/data/loggedInUser/useUserForAddPayment'
import { pickWithInitialState } from '../../../../utils/data/utils'
import { AUTHENTICATED_ROUTES } from '../../../../utils/routing/routes'
import AuthenticatedLayout from '../AuthenticatedLayout/index'

const AddPayment = () => {
  const { userData, loadingUserProfile } = useUserForAddPayment()
  const history = useHistory()

  const client = get(userData, 'client')

  const { isAdmin, subscription } = pickWithInitialState(userData, [
    ['isAdmin', false],
    ['subscription', null],
  ])

  const onPaymentPlan = !!subscription

  const toCrowdSegment = () => {
    history.push(AUTHENTICATED_ROUTES.CROWD_SEGMENT)
  }

  const loading = loadingUserProfile || onPaymentPlan
  return (
    <AuthenticatedLayout>
      <CenteredCard>
        {!loading && (
          <AddPaymentForm
            title="Your free trial has expired"
            message="Unlock the full power of CrowdSegment! A monthly subscription gives you unlimited access to CrowdSegment features and analytics. Subscribers get 5,000 bonus credits on signup, and are able to purchase additional on-demand credits."
            afterUpdate={toCrowdSegment}
            closable={false}
            client={client}
            isAdmin={isAdmin}
            closeModal={() => {}}
          />
        )}
      </CenteredCard>
    </AuthenticatedLayout>
  )
}

export default AddPayment
