import React from 'react'

import { Typography } from 'antd'
import get from 'lodash/get'

import ShowCharge from '../../../routes/Unauthenticated/TeRegistration/components/ShowCharge'

import ChargesWithDiscount from './components/ChargesWithDiscount'

const ChargeSummary = props => {
  const { chargeData, showDiscount, loading } = props
  return (
    <div style={{ textAlign: 'right' }}>
      {showDiscount && (
        <ChargesWithDiscount loading={loading} charges={chargeData} />
      )}
      <ShowCharge
        chargeText="Total Charge"
        amount={get(chargeData, 'total', 0)}
        loading={loading}
      />
      {get(chargeData, 'dueDate') && (
        <Typography.Text type="warning" style={{ marginBottom: 10 }}>
          {`First charge $${get(chargeData, 'total', 0)} on ${get(
            chargeData,
            'dueDate',
          )}`}
        </Typography.Text>
      )}
    </div>
  )
}

export default ChargeSummary
