import React from 'react'

import { Row, Result, Col } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { maxBy, slice } from 'lodash'
import get from 'lodash/get'

import colors from '../../../../hocs/colors'
import { pickWithInitialState } from '../../../../utils/data/utils'
import { capitalize } from '../../../../utils/stringManipulation'
import { searchSettings } from '../static/searchSettings'

import BarFilters from './BarFilters'
import SingleValueCircle from './components/SingleValueCircle'

const EducationTab = ({
  educationData,
  error,
  toggleFilter,
  isMobile,
  filtersToApply,
}) => {
  const {
    degree_levels,
    c_education_category,
    c_undergrad_universities,
    c_grad_universities,
    bachelors_pct,
    doctorate_pct,
    masters_pct,
  } = pickWithInitialState(educationData, [
    ['degree_levels', {}],
    ['c_education_category', {}],
    ['c_undergrad_universities', {}],
    ['c_grad_universities', {}],
    ['doctorate_pct', 0],
    ['masters_pct', 0],
    ['bachelors_pct', 0],
  ])

  const handleCurrentPosClick = () => {}

  const toggleSchoolFilter = value => {
    toggleFilter(searchSettings.universities.name, value)
  }

  const toggleMajorFilter = value => {
    toggleFilter(searchSettings.majors.name, value)
  }

  const selectedEducation = filtersToApply[searchSettings.universities.name]
  const selectedMajors = filtersToApply[searchSettings.majors.name]

  if (error) {
    return (
      <Result
        status="warning"
        title="Error Encountered"
        subTitle="Check back later, or try another search. "
      />
    )
  }

  return (
    <div className="te-tab-container">
      <Row style={{ marginBottom: 15 }}>
        {!isMobile && (
          <Col md={8}>
            <div
              className="te-card-content"
              style={{ height: 270, paddingTop: 0 }}
            >
              <div className="vert-parent" style={{ height: 270 }}>
                <div
                  className="vert-child"
                  style={{
                    fontSize: 24,
                    textAlign: 'center',
                  }}
                >
                  The most common education background is a
                  <b>
                    {' '}
                    {capitalize(
                      get(maxBy(degree_levels, 'doc_count'), 'key'),
                    )}{' '}
                    Degree{' '}
                  </b>
                  in
                  <b>
                    {' '}
                    {capitalize(
                      get(maxBy(c_education_category, 'doc_count'), 'key'),
                    )}
                    .
                  </b>
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col md={16}>
          <div
            className="te-card-content"
            style={!isMobile ? { height: 270 } : {}}
          >
            <Row>
              <Meta
                title="Educational Attainment"
                style={{ marginBottom: 20 }}
              />
              <Col span={8}>
                <SingleValueCircle
                  label="Bachelor's Degree"
                  value={bachelors_pct}
                  color={colors.blue2}
                  isMobile={isMobile}
                />
              </Col>
              <Col span={8}>
                <SingleValueCircle
                  label="Master's Degree"
                  value={masters_pct}
                  color={colors.green1}
                  isMobile={isMobile}
                />
              </Col>
              <Col span={8}>
                <SingleValueCircle
                  label="Doctorate Degree"
                  value={doctorate_pct}
                  color={colors.orange2}
                  isMobile={isMobile}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="te-card-content">
            {isMobile ? (
              <Row>
                <Col>
                  <BarFilters
                    data={slice(c_education_category, 0, 6)}
                    onBarClick={toggleMajorFilter}
                    title="Top Majors"
                    onClearFilter={handleCurrentPosClick}
                    maxHeight={400}
                    selectedFilters={selectedMajors}
                    isClickFilter={true}
                  />
                </Col>
                <Col>
                  <BarFilters
                    data={slice(c_undergrad_universities, 0, 6)}
                    onBarClick={toggleSchoolFilter}
                    title="Top Undergrad Schools"
                    onClearFilter={handleCurrentPosClick}
                    maxHeight={400}
                    selectedFilters={selectedEducation}
                    isClickFilter={true}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Meta title="Education History" style={{ marginBottom: 10 }} />
                <Col span={8}>
                  <BarFilters
                    data={c_education_category}
                    onBarClick={toggleMajorFilter}
                    title="Education Category"
                    onClearFilter={handleCurrentPosClick}
                    maxHeight={400}
                    selectedFilters={selectedMajors}
                    isClickFilter={true}
                  />
                </Col>
                <Col span={8}>
                  <BarFilters
                    data={c_undergrad_universities}
                    onBarClick={toggleSchoolFilter}
                    title="Top Undergrad Schools"
                    onClearFilter={handleCurrentPosClick}
                    maxHeight={400}
                    selectedFilters={selectedEducation}
                    isClickFilter={true}
                  />
                </Col>
                <Col span={8}>
                  <BarFilters
                    data={c_grad_universities}
                    onBarClick={toggleSchoolFilter}
                    title="Top Grad Schools"
                    onClearFilter={handleCurrentPosClick}
                    maxHeight={400}
                    selectedFilters={selectedEducation}
                    isClickFilter={true}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EducationTab
